export enum SFIcons {
  Acknowledgments = 'sf sf-acknowledgments',
  AllTasks = 'sf sf-all-tasks',
  Assets = 'sf sf-assets',
  Authorities = 'sf sf-authorities',
  AuthorityDocument = 'sf sf-authority-document',
  Branding = 'sf sf-branding',
  CitationTypes = 'sf sf-citation-types',
  ControlObjective = 'sf sf-control-objectives',
  Controls = 'sf sf-controls',
  DataStructure = 'sf sf-data-structure',
  Entities = 'sf sf-entities',
  EntityConfiguration = 'sf sf-entity-configuration',
  EntityGroup = 'sf sf-entity-groups',
  EntityType = 'sf sf-entity-types',
  Events = 'sf sf-events',
  Exceptions = 'sf sf-exceptions',
  Field = 'sf sf-fields',
  Framework = 'sf sf-framework',
  Geographies = 'sf sf-geographies',
  Groups = 'sf sf-groups',
  IssueType = 'sf sf-issue-types',
  Issue = 'sf sf-issues',
  Main = 'sf sf-main',
  Management = 'sf sf-management',
  MyAcknowledgment = 'sf sf-my-acknowledgment',
  MyNotification = 'sf sf-my-notifications',
  MyQuestionnairesAnswers = 'sf sf-my-questionnaires-answers',
  MyRiskAssessmentRequest = 'sf sf-my-risk-assessment-request',
  MyTasks = 'sf sf-my-tasks',
  NotificationGroup = 'sf sf-notifications-group',
  Notification = 'sf sf-notifications',
  Objectives = 'sf sf-objectives',
  Organization = 'sf sf-organizations',
  Policies = 'sf sf-policies',
  Processes = 'sf sf-processes',
  QuestionBank = 'sf sf-question-bank',
  QuestionnairesAnswers = 'sf sf-questionnaires-answers',
  Questionnaires = 'sf sf-questionnaires',
  Questions = 'sf sf-questions',
  RelationType = 'sf sf-relation-types',
  Remediation = 'sf sf-remediation-plan',
  Responsibilities = 'sf sf-responsibilities',
  RiskAssessmentRequest = 'sf sf-risk-assessment-request',
  RiskList = 'sf sf-risk-list',
  RiskMangement = 'sf sf-risk-mangement',
  RiskMethodology = 'sf sf-risk-methodology',
  RiskProject = 'sf sf-risk-project',
  RiskRegister = 'sf sf-risk-register',
  RiskStatementCategory = 'sf sf-risk-statement-category',
  RiskStatement = 'sf sf-risk-statement',
  Roles = 'sf sf-roles',
  Tasks = 'sf sf-tasks',
  Users = 'sf sf-users',
  AuditCaseResult = 'sf sf-audit-case-result',
  AuditCaseTemplate = 'sf sf-audit-case-template',
  AuditCase = 'sf sf-audit-case',
  AuditProgramReport = 'sf sf-audit-program-report',
  AuditProgramType = 'sf sf-audit-program-type',
  AuditProgram = 'sf sf-audit-program',
  AuditProjectReports = 'sf sf-audit-project-reports',
  AuditProject = 'sf sf-audit-project',
  AuditRequests = 'sf sf-audit-requests',
  AuditUniverse = 'sf sf-audit-universe',
  Audit = 'sf sf-audit',
  AuditeeCases = 'sf sf-auditee-cases',
  AuditorCases = 'sf sf-auditor-cases',
  ExportGlobalPackages = 'sf sf-export-global-packages',
  GlobalPackages = 'sf sf-global-packages',
  ImportGlobalPackage = 'sf sf-import-global-package',
  RiskThreats = 'sf sf-risk-threats',
  RiskVulnerability = 'sf sf-risk-vulnerability',
  DataIntegration = 'sf sf-data-integration',
  DismantleSilos = 'sf sf-dismantle-silos',
  EffortlessMonitoring = 'sf sf-effortless-monitoring',
  Exemption = 'sf sf-exception',
  Foster = 'sf sf-foster-innovation',
  GlobalValueTemplates = 'sf sf-global-value-templates',
  OptimizeRiskMitigation = 'sf sf-optimize-risk-mitigation',
  PackageExports = 'sf sf-package-exports',
  PackageImports = 'sf sf-package-imports',
  PrioritiesBetter = 'sf sf-prioritise-better',
  ReportingAndTracking = 'sf sf-reporting-and-tracking',
  TaskEfficiency = 'sf sf-task-efficiency',
  TrackProgressEffectively = 'sf sf-track-progress-effectively',
  AutomatedWorkflows = 'sf sf-automated-workflows',
  BoostEfficiency = 'sf sf-boost-efficiency',
  Citation = 'sf sf-citation',
  ComplianceStatus = 'sf sf-compliance-status',
  ComplianceDashboards = 'sf sf-compliance-dashboards',
  ComplianceReport = 'sf sf-compliance-report',
  AcknowledgmentRequests = 'sf sf-acknowledgment-requests',
}
