/**
 * Sightflare Service
 * Rest Api for Sightflare Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ApiResponseTransparencyCaseEvent { 
    status?: number;
    data?: ApiResponseTransparencyCaseEvent.DataEnum;
    error?: boolean;
    errorMessage?: string;
}
export namespace ApiResponseTransparencyCaseEvent {
    export type DataEnum = 'TO_CLOSED' | 'TO_ASSIGNED';
    export const DataEnum = {
        Closed: 'TO_CLOSED' as DataEnum,
        Assigned: 'TO_ASSIGNED' as DataEnum
    };
}


