import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { BaseResponse, ComplianceReportDto, PolicyBody, RequestHandlerOptions } from '@shared/classes';
import { BaseRequestControllerService } from '@shared/services/api/base-request-controller.service';
import { DataService } from '@shared/services/api/data.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ComplianceReportDataService extends BaseRequestControllerService<ComplianceReportDto> {
  constructor(
    private dataService: DataService,
    private router: Router
  ) {
    super(dataService, environment.framework, '/compliance-reports', [
      'id',
      'code',
      'tags',
      'tagsCodes',
      'name',
      'description',
      'date',
      'items',
      'targetCode',
      'control',
      'reportCode',
      'frequency',
      'policy',
      'complianceReportResults',
      'lockedBy',
      'lockedForEdit',
      'lockedUntil',
      'userAccessLevel',
      'recordStatus',
      'creatorName',
      'lastModifierName',
      'creationDate',
      'lastModificationDate',
      'complianceReportType'
    ]);
  }
  navigateToListPage() {
    this.router.navigateByUrl(`framework/compliance-report/list`);
  }
  public startReport(code, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    const newLocal = this.url + `/start-compliance-report/${code}`;
    return this.dataService.getData<BaseResponse<PolicyBody>>(newLocal, options) as Observable<BaseResponse<any>>;
  }
}
