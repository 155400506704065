import { AuditProject } from '../model';
import { TargetTypeEnum } from './view-enums';

export const AuditProjectScopeMapper = {
  [AuditProject.ProjectScopeEnum.AuthorityDocument]: [TargetTypeEnum.AuthorityDocument],
  [AuditProject.ProjectScopeEnum.Citation]: [TargetTypeEnum.Citation],
  [AuditProject.ProjectScopeEnum.ControlObjective]: [TargetTypeEnum.ControlObjective],
  [AuditProject.ProjectScopeEnum.Entity]: [
    TargetTypeEnum.Organization,
    TargetTypeEnum.Responsibility,
    TargetTypeEnum.Objective,
    TargetTypeEnum.Asset,
    TargetTypeEnum.Event,
    TargetTypeEnum.Geography,
    TargetTypeEnum.Process,
  ],
  [AuditProject.ProjectScopeEnum.Policy]: [TargetTypeEnum.Policy],
  [AuditProject.ProjectScopeEnum.PolicySection]: [TargetTypeEnum.PolicyBodySection],
  [AuditProject.ProjectScopeEnum.Risk]: [TargetTypeEnum.RiskItem],
  [AuditProject.ProjectScopeEnum.RiskProject]: [TargetTypeEnum.RiskProject],
};
