import { Injectable } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Subject } from 'rxjs';

@Injectable()
export class AppBreadcrumbService {
  public currentModuleKeyword: string;
  public showViewModeSwitch: boolean = false;
  public showTreeSwitch: boolean = false;
  public treeIcon: 'pi pi-sitemap' | 'fa-folder-tree fas' | 'fas fa-table-columns' | 'pi pi-th-large' = 'fa-folder-tree fas';
  // public treeLink:string= 'tree';
  // public listLink:string= 'list';
  public showWatchersPanel: boolean = false;
  public itemId: string;
  private itemsSource = new Subject<MenuItem[]>();

  itemsHandler = this.itemsSource.asObservable();

  setItems(items: MenuItem[]) {
    this.itemsSource.next(items);
  }
  setCurrentModuleKeyword(moduleKeyword: string) {
    this.currentModuleKeyword = moduleKeyword;
  }
  setShowViewModeSwitch(showViewModeSwitch: boolean) {
    this.showViewModeSwitch = showViewModeSwitch;
  }
  setShowTreeSwitch(showTreeSwitch: boolean) {
    this.showTreeSwitch = showTreeSwitch;
  }
  setTreeIcon(treeIcon: 'pi pi-sitemap' | 'fa-folder-tree fas' | 'fas fa-table-columns' | 'pi pi-th-large') {
    this.treeIcon = treeIcon;
  }
  setShowWatchersPanel(show: boolean) {
    this.showWatchersPanel = show;
  }
  setItemId(itemId: string) {
    this.itemId = itemId;
  }
  // setTreeLink(value:string){
  //   this.treeLink = value;
  // }
  // setListLink(value:string){
  //   this.listLink = value;
  // }
}
