import { ModuleIcons } from '../view';
import { getPathByCode } from '.';

export function getNetworkChartEntityCard(_this, badgeIcons, badgeColors, nodeHeight, nodeWidth, d, rootNodeId) {
  return `
<div style="
${d?.data?.extraData?.code == rootNodeId ? 'box-shadow: var(--primary-500) 0px 0px 20px, var(--primary-500) 0px 0px 80px, var(--primary-500) 0px 0px 200px;' : ''}
background-color:${d?.data?.extraData?.code == rootNodeId ? 'var(--surface-200);' : 'var(--surface-200);'} position:absolute;margin-top:-1px; margin-left:-1px;    width:${
    nodeWidth
  }px;height:${nodeHeight}px;border-radius:var(--border-radius);border: 2px solid var(--surface-900)">

   <div class="pie-chart-wrapper" style="margin-left:-10px;margin-top:25px;width:320px;height:300px"></div>

  <div style="color:var(--surface-900);${d?.data?.extraData?.id != 'root' && _this.showStatistics ? ' position:absolute; ' : '     text-align: center; display: flex;align-items: center;justify-content: center;'}
  left:5px;top:-5px; height:100%; max-width:95%; overflow-wrap: break-word; position:absolute;">
    <div style="display:flex; flex-direction:column; justify-content:space-between; ${d?.data?.extraData?.id != 'root' && _this.showStatistics ? ' height:100%; ' : ''}">
    <div>
    <div style="${d?.data?.extraData?.id != 'root' && _this.showStatistics ? 'font-size:22px;' : 'font-size:33px;'} color:var(--surface-900);margin-top:12px; text-overflow: ellipsis; white-space: nowrap; overflow: hidden; width: 100%;">

<div onclick="window.dispatchEvent(new CustomEvent('buttonClickView', { detail: {nodeId:'${d?.data?.extraData?.code}',target:this} }))"
class=""
style="    text-overflow: ellipsis;
white-space: nowrap;
overflow: hidden;
width: 100%;"
title="${d?.data?.extraData?.name}"
>
${d?.data?.extraData?.id != 'root' ? `<i class="${badgeIcons[d?.data?.extraData?.recordStatus]} text-${badgeColors[d?.data?.extraData?.recordStatus]}-400 " style="${_this.showStatistics ? 'font-size: 18px; ' : 'font-size: 28px; '}"></i>` : ''}
${d?.data?.extraData?.code != 'root' && d?.data?.extraData?.code ? `<a style='${d?.data?.extraData?.code == rootNodeId ? 'color:var(--surface-900) !important;' : ''}' href='${getPathByCode(d?.data?.extraData?.code)}'><strong>${d?.data?.extraData?.code || ''}</strong></a> :` : ''} ${d?.data?.extraData?.name}
</div>

</div>
<div onclick="window.dispatchEvent(new CustomEvent('buttonClickViewType', { detail: {nodeId:'${d?.data?.extraData?.entityTypeInfo?.code}',target:this} }))"
class=""
style="margin-top: 0.5rem;     text-overflow: ellipsis;
white-space: nowrap;
overflow: hidden;
width: 100%;"
title="${d?.data?.extraData?.entityTypeInfo?.name || ''}"
>
${d?.data?.extraData?.id != 'root' && _this.showStatistics && d.data?.extraData?.entityTypeInfo?.code ? `<i class="${ModuleIcons.EntityType} text${badgeColors[d?.data?.extraData?.entityTypeInfo?.recordStatus]}-400 " style="font-size: 12px;"></i>` : ''}
${d?.data?.extraData?.id != 'root' && _this.showStatistics && d.data?.extraData?.entityTypeInfo?.code ? (`<a style='${d?.data?.extraData?.code == rootNodeId ? 'color:var(--surface-900) !important;' : ''}' href='${getPathByCode(d.data?.extraData?.entityTypeInfo?.code)}'>${d.data?.extraData?.entityTypeInfo?.code}</a>` || '') + ':' : ''} ${d?.data?.extraData?.entityTypeInfo?.name || ''}
</div>
${
  _this.showStatistics
    ? `${
        d?.data?.extraData?.id != 'root'
          ? `
<div class="" style="margin-top: 0.5rem;font-size:14px;">
<i class="${ModuleIcons.RiskItem}" style="font-size: 12px;"></i>
Risks: ${d.data?.extraData?.risks?.length || '0'}
</div>
`
          : ''
      }
${
  d?.data?.extraData?.id != 'root'
    ? `
<div class="" style="margin-top: 0.5rem;font-size:14px;">
<i class="${ModuleIcons.Issue}" style="font-size: 12px;"></i>
Issues: ${d.data?.extraData?.issues?.length || '0'}
</div>
`
    : ''
}
${
  d?.data?.extraData?.id != 'root'
    ? `
<div class="" style="margin-top: 0.5rem; font-size:14px;">
<i class="${ModuleIcons.Control}" style="font-size: 12px;"></i>
Controls: ${d?.data?.extraData?.controls?.length ? d?.data?.extraData?.controls?.filter((item) => item.controlComplianceStatus != null)?.length : '0'}
</div>
`
    : ''
}`
    : ''
}

    </div>
    <div style="${!_this.showStatistics ? 'position: absolute; bottom: 5px; left: 5px; ' : ''}">
    ${
      d?.data?.extraData?.code != rootNodeId && false
        ? `<button class="p-button  p-button-primary" onclick="window.dispatchEvent(new CustomEvent('buttonClickEdit', { detail: {nodeId:'${d.data.id}',target:this} }))"><i class="pi pi-pencil"></i> </button>
    `
        : ''
    }
    </div>
    ${
      d.depth == 'asdadasd'
        ? `                              <br/>
    <div style="max-width:200px;font-size:10px;">

    </div>`
        : ''
    }
    </div>

  </div>

</div>

`;
}
export function getSimpleChartCard(_this, badgeIcons, badgeColors, nodeHeight, nodeWidth, d, rootNodeId) {
  return `
<div style="
overflow: visible;
${d?.data?.extraData?.code == rootNodeId ? 'box-shadow: var(--primary-500) 0px 0px 20px, var(--primary-500) 0px 0px 80px, var(--primary-500) 0px 0px 200px;' : ''}
background-color:${d?.data?.extraData?.code == rootNodeId ? 'var(--surface-200);' : 'var(--surface-200);'} position:absolute;margin-top:-1px; margin-left:-1px;    width:${
    nodeWidth
  }px;height:${nodeHeight}px;border-radius:var(--border-radius);border: 2px solid var(--surface-900)">

   <div class="pie-chart-wrapper" style="margin-left:-10px;margin-top:25px;width:320px;height:300px"></div>

  <div style="color:var(--surface-900);${d?.data?.extraData?.id != 'root' && _this.showStatistics ? ' position:absolute; ' : '     text-align: center; display: flex;align-items: center;justify-content: center;'}
  left:5px;top:-5px; height:100%; max-width:95%; overflow-wrap: break-word; position:absolute;">
    <div style="display:flex; flex-direction:column; justify-content:space-between; width:100%; ${d?.data?.extraData?.id != 'root' && _this.showStatistics ? ' height:100%; ' : ''}">
    <div>
    <div style="${d?.data?.extraData?.id != 'root' && _this.showStatistics ? 'font-size:22px;' : 'font-size:33px;'} color:var(--surface-900);margin-top:12px; text-overflow: ellipsis; white-space: nowrap; overflow: hidden; width: 100%;">

<div onclick="window.dispatchEvent(new CustomEvent('buttonClickView', { detail: {nodeId:'${d?.data?.extraData?.code}',target:this} }))"
class=""
style="    text-overflow: ellipsis;
white-space: nowrap;
overflow: hidden;
width: 100%;"
title="${d?.data?.extraData?.name}"
>
${d?.data?.extraData?.id != 'root' ? `<i class="${badgeIcons[d?.data?.extraData?.recordStatus]} text-${badgeColors[d?.data?.extraData?.recordStatus]}-400 " style="${_this.showStatistics ? 'font-size: 18px; ' : 'font-size: 28px; '}"></i>` : ''}
${d?.data?.extraData?.code != 'root' && d?.data?.extraData?.code ? `<a style='${d?.data?.extraData?.code == rootNodeId ? 'color:var(--surface-900) !important;' : ''}' href='${getPathByCode(d?.data?.extraData?.code)}'><strong>${d?.data?.extraData?.code || ''}</strong></a> :` : ''} ${d?.data?.extraData?.name}
</div>

</div>
<div onclick="window.dispatchEvent(new CustomEvent('buttonClickViewType', { detail: {nodeId:'${d?.data?.extraData?.entityTypeInfo?.code}',target:this} }))"
class=""
style="margin-top: 0.5rem;     text-overflow: ellipsis;
white-space: nowrap;
overflow: hidden;
width: 100%;"
title="${d?.data?.extraData?.entityTypeInfo?.name || ''}"
>
</div>

    </div>
    <div style="${'position: absolute; bottom: 5px; left: 5px; '}">
    ${
      d?.data?.extraData?.code != rootNodeId && false
        ? `<button class="p-button  p-button-primary" onclick="window.dispatchEvent(new CustomEvent('buttonClickEdit', { detail: {nodeId:'${d.data.id}',target:this} }))"><i class="pi pi-pencil"></i> </button>
    `
        : ''
    }
    </div>
    </div>

  </div>

</div>

`;
}
