import { Injectable, OnDestroy, Type } from '@angular/core';
import { LocaleService } from '@core/services/locale/locale.service';
import { ConfirmEventType, ConfirmationService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Injectable({
  providedIn: 'root',
})
export class AppDialogService implements OnDestroy {
  position: string;
  rejectButtonStyleClass: string = 'p-button-outlined';
  ref: DynamicDialogRef;
  constructor(
    private dialogService: DialogService,
    private confirmationService: ConfirmationService,
    private localeService: LocaleService
  ) {}

  confirm(callBacks: IConfirmationCallBacks, config: IConfirmationConfig = new IConfirmationConfig()) {
    config = { ...new IConfirmationConfig(), ...config }; //using spread operator to insure default values
    this.position = config.position;

    this.confirmationService.confirm({
      message: config.message,
      header: config.header,
      icon: config.icon,
      accept: () => {
        if (callBacks.accept) callBacks.accept();
      },
      reject: (type) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            if (callBacks.reject) callBacks.reject();
            break;
          case ConfirmEventType.CANCEL:
            if (callBacks.cancel) callBacks.cancel();
            break;
        }
      },
      key: config.position ? 'positionDialog' : undefined,
    });
  }
  confirmPopup(
    callBacks: IConfirmationCallBacks,
    target: EventTarget,
    config: IConfirmationConfig = new IConfirmationConfig()
  ) {
    config = { ...new IConfirmationConfig(), ...config }; //using spread operator to insure default values
    this.position = config.position;

    this.confirmationService.confirm({
      target: target,
      message: config.message,
      header: config.header,
      icon: config.icon,
      accept: () => {
        if (callBacks.accept) callBacks.accept();
      },
      reject: (type) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            if (callBacks.reject) callBacks.reject();
            break;
          case ConfirmEventType.CANCEL:
            if (callBacks.cancel) callBacks.cancel();
            break;
        }
      },
      key: 'popup',
      acceptLabel: this.localeService.translate('general.actions.yes'),
      rejectLabel: this.localeService.translate('general.actions.no'),
    });
  }

  showDialog<T = any>(
    component: Type<any>,
    header: string = 'dialog',
    onClose: Function = null,
    dialogConfig: DynamicDialogConfig<T> = null
  ) {
    const defaultConfig = {
      header: header,
      width: '70%',
      height: 'fit-content',
      contentStyle: { overflow: 'visible' },
      baseZIndex: 1001099,
      maximizable: true,
      styleClass: 'overflow-visible',
    };

    dialogConfig = { ...defaultConfig, ...dialogConfig };
    this.ref = this.dialogService.open(component, dialogConfig);

    this.ref.onClose.subscribe((data: any) => {
      if (onClose) onClose(data);
    });

    this.ref.onMaximize.subscribe((value: any) => {});
  }

  ngOnDestroy(): void {}
}

export class IConfirmationCallBacks {
  accept: Function;
  reject?: Function = null;
  cancel?: Function = null;
}
export class IConfirmationConfig {
  message?: string = 'Are you sure that you want to proceed?';
  header?: string = 'Confirmation';
  icon?: string = 'pi pi-exclamation-triangle';
  position?: IConfirmationPosition = null;
}
export enum IConfirmationPosition {
  left = 'left',
  right = 'right',
  topLeft = 'top-left',
  topRight = 'top-right',
  top = 'top',
  bottomLeft = 'bottom-left',
  bottomRight = 'bottom-right',
  bottom = 'bottom',
}
