import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { BaseResponse, RequestHandlerOptions, StatisticCollectorDto } from '@shared/classes';
import { BaseRequestControllerService } from '@shared/services/api/base-request-controller.service';
import { DataService } from '@shared/services/api/data.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StatisticCollectorDataService extends BaseRequestControllerService<StatisticCollectorDto> {
  constructor(
    private dataService: DataService,
    private router: Router
  ) {
    super(dataService, environment.dataStructure, '/statistic-collectors', [
      'id',
      'code',
      'tags',
      'tagsCodes',
      'lockedBy',
      'lockedForEdit',
      'lockedUntil',
      'userAccessLevel',
      'recordStatus',
      'creatorName',
      'lastModifierName',
      'creationDate',
      'lastModificationDate',
      'name',
      'description',
      'filter',
      'frequency',
      'lastRunDate',
    ]);
  }
  navigateToListPage() {
    this.router.navigateByUrl(`data-management/statistic-collector/list`);
  }
  start<T = StatisticCollectorDto>(code: string, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.dataService.getData<BaseResponse<T>>(`${this.url}/start-collector/${code}`, options) as Observable<
      BaseResponse<T>
    >;
  }
}
