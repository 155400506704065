import { FilterMetadata, TreeNode } from 'primeng/api';
import { FilterItem, RelationFilterItem } from '../model';
import { IColumn, ITableLoadEvent } from '../view';

export function toSearchFormat(
  data: { [s: string]: FilterMetadata[] },
  globalFilters: string[] = [],
  colsByKey: { [key: string]: IColumn },
  useTableToSearchFilterMappingV2 = true
) {
  const filterTypes = TableToSearchFilterMapping;
  let filters: FilterItem[] = [];
  let filtersTree: FilterItem = {};
  Object.keys(data).forEach((key) => {
    let fieldFilter = null;
    const colDef = colsByKey[key];
    if (key == 'global') {
      // let globalFilterObj = [];
      // globalFilters.forEach((filter) => {
      //   if (
      //     (data[key] as FilterMetadata).value != undefined &&
      //     (data[key] as FilterMetadata).value != null &&
      //     (data[key] as FilterMetadata).value != ''
      //   ) {
      //     globalFilterObj.push({
      //       property: filter,
      //       operation: useTableToSearchFilterMappingV2
      //         ? TableToSearchFilterMappingV2[(data[key] as FilterMetadata).matchMode]
      //         : TableToSearchFilterMapping[(data[key] as FilterMetadata).matchMode],
      //       value: (data[key] as FilterMetadata).value,
      //     });
      //   }
      // });
      // fieldFilter = getTreeNode(
      //   globalFilterObj,
      //   0,
      //   globalFilterObj.length,
      //   colsByKey,
      //   'OR',
      //   '',
      //   useTableToSearchFilterMappingV2
      // );
    } else {
      if (data[key].length > 0 || (colDef?.filter?.type == 'relation' && Object.keys(data?.[key])?.length > 0)) {
        let operand: any;
        let filterArray: any[];
        if (Array.isArray(data[key])) {
          operand = data[key][0].operator.toUpperCase();
          filterArray = getNotNullArray(data[key]);
        } else if (colDef?.filter?.type == 'relation') {
          //in this case the filter is an instant object and not an array of filters
          operand = 'AND';
          filterArray = getNotNullArray([data[key] as any]);
        }
        fieldFilter = getTreeNode(
          filterArray,
          0,
          filterArray.length,
          colsByKey,
          operand,
          key,
          useTableToSearchFilterMappingV2
        );
      }
    }
    if (fieldFilter) {
      filters.push(fieldFilter);
      filtersTree = getTreeNode(
        filters,
        0,
        filters.length,
        colsByKey,
        key == 'global' ? 'AND' : 'AND',
        '',
        useTableToSearchFilterMappingV2
      );
    }
  });
  return filtersTree || null;
}
export function getNotNullArray(filterList: FilterMetadata[]) {
  let ar = [];
  filterList.forEach((filter) => {
    if (filter.value !== undefined && filter.value !== null && filter.value !== '') ar.push(filter);
  });
  return ar;
}
//@NOTE: this is not just a simple tree style format, the result filters should be only in leaf nodes and stems should be only left,right
export function getTreeNode(
  nodeArray: FilterMetadata[] | any[],
  i: number,
  arrayLength: number,
  colsByKey: { [key: string]: IColumn },
  operand: FilterItem.OperandEnum = 'AND',
  property = '',
  useTableToSearchFilterMappingV2 = true
): FilterItem {
  if (arrayLength == 0) return null;
  if (i > arrayLength) return null;
  if (arrayLength - i == 2) {
    return {
      left: getTreeNodeObject(nodeArray[i], property, colsByKey[property], useTableToSearchFilterMappingV2),
      right: getTreeNodeObject(nodeArray[i + 1], property, colsByKey[property], useTableToSearchFilterMappingV2),
      operand: operand,
    };
  } else if ((arrayLength - i) % 2 == 0 && arrayLength > 0) {
    const difference = Math.trunc((arrayLength - i) / 2);
    return {
      //Math.trunc(arrayLength / 2)
      left: getTreeNode(nodeArray, i, i + difference, colsByKey, operand, property, useTableToSearchFilterMappingV2),
      right: getTreeNode(
        nodeArray,
        i + difference,
        arrayLength,
        colsByKey,
        operand,
        property,
        useTableToSearchFilterMappingV2
      ),
      operand: operand,
    };
  } else if ((arrayLength - i) % 2 == 1) {
    if (arrayLength - i > 2) {
      return {
        left: getTreeNodeObject(
          nodeArray[arrayLength - 1],
          property,
          colsByKey[property],
          useTableToSearchFilterMappingV2
        ),
        right: getTreeNode(
          nodeArray,
          i,
          Math.trunc(arrayLength - 1),
          colsByKey,
          operand,
          property,
          useTableToSearchFilterMappingV2
        ),
        operand: operand,
      };
    } else {
      return getTreeNodeObject(nodeArray[i], property, colsByKey[property], useTableToSearchFilterMappingV2);
    }
  } else if (arrayLength <= 0) {
    return getTreeNodeObject(nodeArray[i], property, colsByKey[property], useTableToSearchFilterMappingV2);
  }
}
export function getTreeNodeObject(
  nodeItem: FilterMetadata | any,
  property: string,
  colDef: IColumn,
  useTableToSearchFilterMappingV2 = true
) {
  let ret: any;
  if (colDef?.filter?.type == 'relation') {
    const relationRet: RelationFilterItem = {
      codes: nodeItem?.value?.codes,
      relationsTypes: nodeItem?.value?.relationsTypes,
      relationDirections: nodeItem?.value?.relationDirections,
    };
    ret = { ...relationRet };
  } else {
    const normalRet: FilterItem = {
      property: property,
      operation: useTableToSearchFilterMappingV2
        ? TableToSearchFilterMappingV2[nodeItem.matchMode]
        : TableToSearchFilterMapping[nodeItem.matchMode],
      value: nodeItem.value,
      typeShape: colDef?.filter?.type == 'date' ? 'DATETIME' : 'NORMAL',
    };
    ret = { ...normalRet };
  }
  return nodeItem.matchMode ? ret : { ...nodeItem };
}
export function formatFilterBuilderTree(
  filters: FilterBuilderTreeNode[],
  operand: 'AND' | 'OR',
  colsByKey: { [key: string]: IColumn }
) {
  if (filters?.length <= 0) return null;
  let allRets = [];
  filters.forEach((filterNode) => {
    let treeRet = null;
    if (filterNode.type == 'operator') {
      treeRet = formatFilterBuilderTree(filterNode?.children, filterNode?.matchMode == 'and' ? 'AND' : 'OR', colsByKey);
    } else if (filterNode?.tableEvent?.filters?.length > 0) {
      treeRet = getTreeNode(
        filterNode?.tableEvent?.filters,
        0,
        filterNode?.tableEvent?.filters?.length,
        colsByKey,
        filterNode?.tableEvent?.filters?.[0]?.operand,
        '',
        true
      );
    }
    if (treeRet) allRets.push(treeRet);
  });
  return getTreeNode(allRets, 0, allRets?.length, colsByKey, operand, '', true);
}
//@NOTE: this is not just a simple tree style format, the result filters should be only in leaf nodes and stems should be only left,right
export function getFilterBuilderTree(
  nodeArray: FilterBuilderTreeNode[],
  currentIndex: number,
  arrayLength: number,
  operand: FilterItem.OperandEnum = 'AND'
) {
  if (arrayLength == 0) return null;
  if (currentIndex > arrayLength) return null;
  const currentNode = nodeArray?.[currentIndex];
  if (currentNode?.type == 'operator') {
    if (currentNode?.children?.length > 0) {
      const childrenLength = currentNode?.children?.length;
      if (childrenLength == 2) {
        return {
          // left: getTreeFilterObject(nodeArray[i],property,colsByKey[property],useTableToSearchFilterMappingV2),
          // right: getTreeFilterObject(nodeArray[(i+1)],property,colsByKey[property],useTableToSearchFilterMappingV2),
          operand: operand,
        };
      }
      return {
        // left:getFilterBuilderTree(),
        // right:getFilterBuilderTree(),
        operand: currentNode?.matchMode?.toUpperCase(),
      };
    } else {
      return null;
    }
  } else {
  }
}
export function getTreeFilters(
  nodeArray: TreeNode[],
  i: number,
  arrayLength: number,
  colsByKey: { [key: string]: IColumn },
  operand: FilterItem.OperandEnum = 'AND',
  property = '',
  useTableToSearchFilterMappingV2 = true
): FilterItem {
  if (arrayLength == 0) return null;
  if (i > arrayLength) return null;
  if (arrayLength - i == 2) {
    return {
      left: getTreeFilterObject(nodeArray[i], property, colsByKey[property], useTableToSearchFilterMappingV2),
      right: getTreeFilterObject(nodeArray[i + 1], property, colsByKey[property], useTableToSearchFilterMappingV2),
      operand: operand,
    };
  } else if ((arrayLength - i) % 2 == 0 && arrayLength > 0) {
    const difference = Math.trunc((arrayLength - i) / 2);
    return {
      //Math.trunc(arrayLength / 2)
      left: getTreeFilters(nodeArray, i, i + difference, colsByKey, operand, property, useTableToSearchFilterMappingV2),
      right: getTreeFilters(
        nodeArray,
        i + difference,
        arrayLength,
        colsByKey,
        operand,
        property,
        useTableToSearchFilterMappingV2
      ),
      operand: operand,
    };
  } else if ((arrayLength - i) % 2 == 1) {
    if (arrayLength - i > 2) {
      return {
        left: getTreeFilterObject(
          nodeArray[arrayLength - 1],
          property,
          colsByKey[property],
          useTableToSearchFilterMappingV2
        ),
        right: getTreeFilters(
          nodeArray,
          i,
          Math.trunc(arrayLength - 1),
          colsByKey,
          operand,
          property,
          useTableToSearchFilterMappingV2
        ),
        operand: operand,
      };
    } else {
      return getTreeFilterObject(nodeArray[i], property, colsByKey[property], useTableToSearchFilterMappingV2);
    }
  } else if (arrayLength <= 0) {
    return getTreeFilterObject(nodeArray[i], property, colsByKey[property], useTableToSearchFilterMappingV2);
  }
}
export function getTreeFilterObject(
  nodeItem: FilterMetadata | any,
  property: string,
  colDef: IColumn,
  useTableToSearchFilterMappingV2 = true
) {
  return { ...nodeItem };
  return nodeItem.matchMode
    ? {
        property: property,
        operation: useTableToSearchFilterMappingV2
          ? TableToSearchFilterMappingV2[nodeItem.matchMode]
          : TableToSearchFilterMapping[nodeItem.matchMode],
        value: nodeItem.value,
        typeShape: colDef?.filter?.type == 'date' ? 'DATETIME' : 'NORMAL',
      }
    : { ...nodeItem };
}
export interface FilterBuilderTreeNode {
  children?: FilterBuilderTreeNode[];
  matchMode?: 'and' | 'or';
  type?: 'operator' | 'line' | 'node' | undefined;
  tableEvent?: ITableLoadEvent;
}
export enum TableToSearchFilterMapping {
  startsWith = 'startwith',
  contains = 'like',
  notContains = 'notLike',
  endsWith = 'endwith',
  equals = 'eq',
  notEquals = 'neq',
  dateIs = 'eq',
  dateIsNot = 'neq',
  dateIsBefore = 'lte',
  dateIsAfter = 'gt',
  in = 'in',
  between = 'btn',
  dateBefore = 'lte',
  dateAfter = 'gt',
  lt = 'lt',
  lte = 'lte',
  gt = 'gt',
  gte = 'gte',
}
export enum TableToSearchFilterMappingV2 {
  startsWith = 'START_WITH',
  contains = 'CONTAINS',
  notContains = 'NOT_CONTAINS',
  endsWith = 'END_WITH',
  equals = 'EQUAL',
  notEquals = 'NOT_EQUAL',
  dateIs = 'EQUAL',
  dateIsNot = 'NOT_EQUAL',
  dateIsBefore = 'LESS_THAN_OR_EQUAL_TO',
  dateIsAfter = 'GREATER_THAN',
  in = 'IN',
  between = 'BETWEEN',
  dateBefore = 'LESS_THAN_OR_EQUAL_TO',
  dateAfter = 'GREATER_THAN',
  lt = 'LESS_THAN',
  lte = 'LESS_THAN_OR_EQUAL_TO',
  gt = 'GREATER_THAN',
  gte = 'GREATER_THAN_OR_EQUAL_TO',
  CONTAINS_ALL = 'CONTAINS_ALL',
}
