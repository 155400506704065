import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import {
  AcknowledgmentRequest,
  AcknowledgmentRequestDto,
  ApiResponseAcknowledgmentRequestEvent,
  BaseListResponse,
  RequestHandlerOptions,
} from '@shared/classes';
import { BaseRequestControllerWithRuleService } from '@shared/services/api/custom-api-services/base-request-controller-with-rule.service';
import { DataService } from '@shared/services/api/data.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AcknowledgementRequestDataService extends BaseRequestControllerWithRuleService<
  AcknowledgmentRequest,
  ApiResponseAcknowledgmentRequestEvent.DataEnum
> {
  constructor(
    private dataService: DataService,
    private router: Router
  ) {
    super(dataService, environment.acknowledgement, '/acknowledgment-requests', [
      'id',
      'code',
      'tags',
      'tagsCodes',
      'lockedBy',
      'lockedForEdit',
      'lockedUntil',
      'isSatisfied',
      'satisfiedUsers',
      'requireSignedDocument',
      'recordStatus',
      'creatorName',
      'lastModifierName',
      'creationDate',
      'lastModificationDate',
      'name',
      'owner',
      'acknowledgers',
      'targetType',
      'targetCode',
      'status',
      'approver',
      'progress',
      'documentUrl',
      'ackReqProgress',
      'documentUrl',
      'requireSignedDocument',
      'description',
    ]);
  }
  navigateToListPage() {
    this.router.navigateByUrl(`acknowledgment/acknowledgment-request/list`);
  }
  navigateToMyAkgRequestListPage() {
    this.router.navigateByUrl(`acknowledgment/acknowledgment-request/my-acknowledgments`);
  }
  getMyAcknowledgementRequests(options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.dataService.getData<BaseListResponse<AcknowledgmentRequestDto>>(
      `${this.url}/getMyRequests`,
      options
    ) as Observable<BaseListResponse<AcknowledgmentRequestDto>>;
  }
}
