<app-input-view-switch
  [showLabelInViewMode]="showLabelInViewMode"
  [mode]="viewMode"
  [data]="data?.code || control?.value?.code || control?.value || control?.getRawValue()"
  [dataType]="multi ? tabsDataType : dataType"
  [label]="label"
  [multiViewFields]="false"
  [keepValueCallback]="setFieldResetable.bind(this)"
  [resetFieldCheckVisable]="resetFieldCheckVisable"
  [resetableChecked]="resetable"
>
  <!-- @if (tableMode) {
    <ng-container *ngIf="tableMode; then tableTemplate"></ng-container>
  } @else { -->
  @if (inlineMode && tableMode && multi) {
    <app-table-code-selector
      [additionalProjectionFields]="customProjectionFields"
      [label]="label ?? 'Target Code'"
      [floatLabel]="floatLabel"
      [control]="control"
      [targetTypes]="targetTypes"
      [dataKey]="dataKey"
      [optionValue]="optionValue"
      [inlineControl]="fControl"
      [filters]="tableFilters"
      [searchUrl]="searchUrl"
      [customSort]="customSort"
      [forcedRefresh]="forcedRefresh"
      [dialogVisable]="editModalVisible"
      [inlineMode]="true"
      [listBoxMode]="listBoxMode"
      [customObjectFields]="customObjectFields"
      [viewMode]="viewMode"
      (onInlineChange)="onInlineSave()"
    >
    </app-table-code-selector>
  } @else {
    <ng-container *ngIf="inlineMode; then inlineTemplate; else editTemplate"></ng-container>
  }
  <!-- } -->
  <!-- <ng-container *ngIf="viewMode == 'create'; then (listBoxMode ? inlineTemplate : selectorTemplate); else editTemplate"></ng-container> -->
</app-input-view-switch>
<ng-template #selectorTemplate>
  <app-drop-down-input
    [label]="label ?? ''"
    [placeholder]="placeholder ?? 'Search Target'"
    [name]="'dropdown'"
    [optionLabel]="optionLabel"
    [items]="items"
    [optionValue]="optionValue"
    [control]="fControl"
    [multi]="multi"
    [dataKey]="dataKey"
    [appendTo]="'body'"
    [lazy]="true"
    [loading]="isLoading"
    [onFilterCommand]="onFilter.bind(this)"
    [floatLabel]="floatLabel"
    [customFilter]="true"
    [showClear]="showClear"
    [showStatusBadge]="showStatusBadge"
    [badgeView]="badgeView"
    [useCustomBadgeMode]="useCustomBadgeMode"
    [customBadgeColorField]="customBadgeColorField"
    [customBadgeIconField]="customBadgeIconField"
    [disabled]="!!linkedControl?.invalid"
    (onChangesObject)="onChangeObject($event)"
  >
    <ng-template #resetCheckTemplate>
      @if (resetFieldCheckVisable) {
        <app-reset-switch-input [checked]="!resetable" (onChange)="setFieldResetable($event)"></app-reset-switch-input>
      }
    </ng-template>
  </app-drop-down-input>
</ng-template>
<ng-template #inlineTemplate>
  <app-drop-down-input
    [label]="listBoxMode ? '' : label"
    [placeholder]="placeholder ?? 'Search Target'"
    [name]="'dropdown'"
    [optionLabel]="optionLabel"
    [items]="items"
    [optionValue]="optionValue"
    [control]="editModalVisible ? inlineControl : fControl"
    [multi]="multi"
    [dataKey]="dataKey"
    [appendTo]="'body'"
    [lazy]="true"
    [loading]="isLoading"
    [onFilterCommand]="onFilter.bind(this)"
    [floatLabel]="floatLabel"
    [customFilter]="true"
    [showClear]="showClear"
    [showStatusBadge]="showStatusBadge"
    [badgeView]="badgeView"
    [useCustomBadgeMode]="useCustomBadgeMode"
    [customBadgeColorField]="customBadgeColorField"
    [customBadgeIconField]="customBadgeIconField"
    [listBoxMode]="listBoxMode"
    [disabled]="!!linkedControl?.invalid || linkedControl?.disabled"
    [useTypesSelector]="targetTypes?.length > 1"
    [listOfTypes]="targetTypes"
    (onSelectTypes)="onSelectTypes($event)"
    (onChangesObject)="onChangeObject($event)"
  >
    <ng-template #resetCheckTemplate>
      @if (resetFieldCheckVisable) {
        <app-reset-switch-input [checked]="!resetable" (onChange)="setFieldResetable($event)"></app-reset-switch-input>
      }
    </ng-template>
  </app-drop-down-input>
</ng-template>

<ng-template #editTemplate>
  <div class="field" [class.mb-1]="editModalControl?.dirty && this.editModalControl.errors">
    <span
      class="p-inputgroup flex flex-row relative hide-selection flex flex-row md:flex-nowrap flex-wrap w-full algin-items-start"
      [class.p-float-label]="floatLabel"
    >
      <ng-container *ngIf="!floatLabel; then labelTemplate"></ng-container>
      @if (multi) {
        <app-target-code-list-box-view
          [buttonEnabled]="!fControl?.disabled"
          [addButtonEnabled]="showAddButton"
          (onButtonClick)="openEditModal()"
          (onAddButtonClick)="addButtonClick($event)"
          [value]="editModalControl?.value"
          [disabled]="fControl?.disabled"
          [invalid]="fControl?.invalid"
          [dirty]="fControl?.dirty"
          [style]="'width:calc(100% - ' + (label ? '12rem' : '0rem') + ') !important; '"
        ></app-target-code-list-box-view>
      } @else {
        <input
          [type]="'text'"
          pInputText
          readonly
          [formControl]="editModalControl"
          [placeholder]="placeholder || label"
          (focus)="onFocusChange($event)"
          class="w-full border-round-left cursor-pointer"
          (click)="openEditModal()"
          (keydown)="onEditModalInputKeyDown($event)"
          [style]="
            'color:transparent; ' +
            (!fControl?.disabled ? 'width:calc(100% - (41px + 12rem)) !important; ' : '') +
            ('height: max(32px,' +
              (multi
                ? 1.543 * editModalControl?.value?.length + (0.5 * (editModalControl?.value?.length - 1) + 0.858)
                : '2.4') +
              'rem)')
          "
          [class.ng-invalid]="fControl?.invalid"
          [class.ng-dirty]="fControl?.dirty"
        />
      }
      <ng-container *ngIf="floatLabel; then labelTemplate"></ng-container>
      <ng-template #labelTemplate>
        <label
          class="md:w-12rem md:max-w-12rem md:min-w-12rem w-full mr-1 input-field-label"
          *ngIf="label && !instanceInFormRepeater"
          >{{ label }}<span *ngIf="isControlRequired" class="text-red-500"> *</span>
          @if (resetFieldCheckVisable) {
            <br />
            <app-reset-switch-input
              [checked]="!resetable"
              (onChange)="setFieldResetable($event)"
            ></app-reset-switch-input>
          }
        </label>
      </ng-template>
      @if (!multi) {
        <div
          (click)="openEditModal()"
          class="inner-absolute-input-value border-round-left p-inputtext flex flex-column gap-2"
          [style]="
            'background:transparent; width:calc(100% - (41px + ' +
            (label ? '12rem' : '0rem') +
            ')); position:absolute; height:100%;'
          "
          [class.left-0]="!label"
        >
          <ng-container *ngIf="multi; else elseTemplate">
            <ng-container *ngFor="let item of editModalControl?.value">
              <app-code-navigation *ngIf="item" [showTooltip]="true" [data]="item"></app-code-navigation>
            </ng-container>
          </ng-container>
          <ng-template #elseTemplate>
            <app-code-navigation
              *ngIf="editModalControl?.value"
              [showTooltip]="true"
              [data]="editModalControl?.value"
            ></app-code-navigation>
          </ng-template>
        </div>
        <button
          *ngIf="!fControl?.disabled"
          type="button"
          pButton
          (click)="openEditModal()"
          class="white-space-nowrap"
          [icon]="'pi pi-pencil'"
        ></button>
      }
    </span>
    @if (control?.dirty) {
      @if (control?.hasError('required')) {
        <small class="p-error p-invalid font-normal">{{ label }} {{ 'is Required' }}</small>
      }
      @if (control?.hasError('minlength')) {
        <small class="p-error p-invalid font-normal">
          {{ label }} must have a minimum length of
          {{ control?.errors?.minlength?.requiredLength }}
        </small>
      }
      @if (control?.hasError('maxlength')) {
        <small class="p-error p-invalid font-normal">
          {{ label }} must have a maximum length of
          {{ control?.errors?.maxlength?.requiredLength }}
        </small>
      }
      @if (customError && control?.invalid) {
        <small class="p-error p-invalid">{{ customErrorLabel }}</small>
      }
    }
    @if (helpText) {
      <small class="text-500 block">{{ helpText }}</small>
    }
    <ng-container *ngIf="editModalControl?.dirty">
      <small class="p-error p-invalid" *ngIf="editModalControl?.hasError('required')"
        >{{ label }} {{ 'is Required' }}</small
      >
    </ng-container>
  </div>
</ng-template>

<ng-template #okButtonTemplate>
  <button
    *ngIf="!fControl?.disabled"
    type="button"
    pButton
    (click)="onEditModalSave()"
    label="Ok"
    class="white-space-nowrap w-fit"
    [icon]="'pi pi-pencil'"
  ></button>
</ng-template>
<p-dialog
  [header]="label"
  [(visible)]="editModalVisible"
  [modal]="true"
  [style]="{ width: '80vw' }"
  [draggable]="false"
  [resizable]="true"
>
  @if (editModalVisible) {
    @if (tableMode && multi) {
      <app-table-code-selector
        [additionalProjectionFields]="customProjectionFields"
        [control]="control"
        [targetTypes]="targetTypes"
        [dataKey]="dataKey"
        [label]="label"
        [floatLabel]="floatLabel"
        [optionValue]="optionValue"
        [inlineControl]="inlineControl"
        [filters]="tableFilters"
        [searchUrl]="searchUrl"
        [customSort]="customSort"
        [forcedRefresh]="forcedRefresh"
        [listBoxMode]="listBoxMode"
        [customObjectFields]="customObjectFields"
        [dialogVisable]="editModalVisible"
        [viewMode]="viewMode"
      >
        <ng-template #okButtonTemplateRef>
          <ng-container *ngTemplateOutlet="okButtonTemplate"></ng-container
        ></ng-template>
      </app-table-code-selector>
    } @else {
      <ng-container *ngTemplateOutlet="inlineTemplate"></ng-container>
      <div class="w-full flex flex-row justify-content-end">
        <button
          *ngIf="!fControl?.disabled"
          type="button"
          pButton
          (click)="onEditModalSave()"
          label="Ok"
          class="white-space-nowrap w-fit"
          [icon]="'pi pi-pencil'"
        ></button>
      </div>
    }
  }
</p-dialog>
