/**
 * Sightflare Service
 * Rest Api for Sightflare Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BaseAccessRecord } from './baseAccessRecord';


export interface SettingAccessRecord { 
    inheritedAccessMode?: SettingAccessRecord.InheritedAccessModeEnum;
    discretionaryAccessMode?: SettingAccessRecord.DiscretionaryAccessModeEnum;
    accessLevel?: SettingAccessRecord.AccessLevelEnum;
    accessType?: SettingAccessRecord.AccessTypeEnum;
    uuid?: string;
    directAccessPrinciples?: Array<BaseAccessRecord>;
    entityAccessFields?: Array<string>;
    inheritedAccessFields?: Array<string>;
}
export namespace SettingAccessRecord {
    export type InheritedAccessModeEnum = 'ORIGIN' | 'DIRECT';
    export const InheritedAccessModeEnum = {
        Origin: 'ORIGIN' as InheritedAccessModeEnum,
        Direct: 'DIRECT' as InheritedAccessModeEnum
    };
    export type DiscretionaryAccessModeEnum = 'JOINED' | 'FORCED';
    export const DiscretionaryAccessModeEnum = {
        Joined: 'JOINED' as DiscretionaryAccessModeEnum,
        Forced: 'FORCED' as DiscretionaryAccessModeEnum
    };
    export type AccessLevelEnum = 'ADMIN' | 'OWNER' | 'UPDATE' | 'READ';
    export const AccessLevelEnum = {
        Admin: 'ADMIN' as AccessLevelEnum,
        Owner: 'OWNER' as AccessLevelEnum,
        Update: 'UPDATE' as AccessLevelEnum,
        Read: 'READ' as AccessLevelEnum
    };
    export type AccessTypeEnum = 'DEFAULT_DIRECT' | 'DEFAULT_FIELDS_BASED' | 'DEFAULT_INHERITED' | 'DEFAULT_OWNER' | 'MANUAL';
    export const AccessTypeEnum = {
        DefaultDirect: 'DEFAULT_DIRECT' as AccessTypeEnum,
        DefaultFieldsBased: 'DEFAULT_FIELDS_BASED' as AccessTypeEnum,
        DefaultInherited: 'DEFAULT_INHERITED' as AccessTypeEnum,
        DefaultOwner: 'DEFAULT_OWNER' as AccessTypeEnum,
        Manual: 'MANUAL' as AccessTypeEnum
    };
}


