/**
 * Sightflare Service
 * Rest Api for Sightflare Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface GapReportControlDto { 
    code?: string;
    name?: string;
    uuid?: string;
    controlEntity?: string;
    controlComplianceStatus?: GapReportControlDto.ControlComplianceStatusEnum;
}
export namespace GapReportControlDto {
    export type ControlComplianceStatusEnum = 'COMPLIANT' | 'NON_COMPLIANT' | 'NO_RESPONSE';
    export const ControlComplianceStatusEnum = {
        Compliant: 'COMPLIANT' as ControlComplianceStatusEnum,
        NonCompliant: 'NON_COMPLIANT' as ControlComplianceStatusEnum,
        NoResponse: 'NO_RESPONSE' as ControlComplianceStatusEnum
    };
}


