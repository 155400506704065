import { Component, Input, OnInit } from '@angular/core';
import { User, removeStartingCode } from '@shared/classes';
import { stringToColor } from '@shared/classes/helpers/string-to-color-helper';

@Component({
  selector: 'app-avatar-list',
  templateUrl: './avatar-list.component.html',
  styleUrls: ['./avatar-list.component.scss'],
})
export class AvatarListComponent implements OnInit {
  // Private
  private _users: UserAvatar[] = [];
  //public
  public colorList = [
    'red',
    'orange',
    'cyan',
    'yellow',
    'green',
    'teal',
    'blue',
    'bluegray',
    'indigo',
    'purple',
    'pink',
  ];
  public colorWeight = '500';
  public darkColorWeight = '700';
  //Component Inputs
  @Input() customTooltip: string = null;
  @Input() maxLength: number = 5;
  @Input() set users(value: User[] | string[] | string) {
    if (value) {
      //null check
      let parseVal = value as any;
      if (typeof value === 'string' || value instanceof String) {
        parseVal = [value];
      }
      this._users = parseVal.map((user) => {
        let name = user.firstName
          ? user.firstName + ' ' + user.lastName
          : user.userName
            ? user.userName
            : user.user
              ? user.user
              : user.name
                ? user.name
                : user.approverName
                  ? user.approverName
                  : user;
        let selectedColor = this.colorList[this.hashCode(removeStartingCode(name)) % this.colorList.length];
        return {
          ...user,
          shortName: this.nameToInitials(removeStartingCode(name)),
          hexColor: stringToColor(removeStartingCode(name)),
          fullName: name,
          colorClass: `bg-${selectedColor}-${this.colorWeight} text-white dark:bg-${selectedColor}-${this.darkColorWeight} dark:text-gray-50`,
          bgColor: selectedColor,
        };
      });
      this.allUserNames = this.users.map((value) => value.fullName).join(', ');
    }
  }
  @Input() size: 'large' | 'xlarge' = null;
  // Public
  get users(): UserAvatar[] {
    return this._users;
  }
  allUserNames: string = '';

  constructor() { }

  ngOnInit(): void { }

  /**
   * Track by function for ngFor loops
   *
   * @param index
   * @param item
   */
  trackByFn(index: number, item: any): any {
    return item.id || index;
  }
  /**
   * hashes a string into a 32bit integer
   * @param string string of any length
   * @returns 32bit integer
   */
  hashCode(string: string) {
    let hash = 0,
      i,
      chr;
    if (!string || string.length === 0) return hash;
    for (i = 0; i < string.length; i++) {
      chr = string.charCodeAt(i);
      hash = (hash << 5) - hash + chr;
      hash |= 0; // Convert to 32bit integer
    }
    return hash > 0 ? hash : -hash;
  }

  /**
   * return initials from full name  (2 characters only)
   *
   * ex:
   *
   *  nameToInitials('Prince') // "PR"
   *
   *  nameToInitials('FirstName LastName') // "FL"
   *
   *  nameToInitials('1stName 2ndName 3rdName 4thName 5thName') // "15"
   * @param fullName
   */
  nameToInitials(fullName: string) {
    if (!fullName) return 'N';
    const namesArray = fullName.trim().split(' ');
    if (namesArray.length === 1)
      return `${namesArray[0].charAt(0).toUpperCase()}${namesArray[0].length > 1 ? namesArray[0].charAt(1).toUpperCase() : ''}`;
    else return `${namesArray[0].charAt(0).toUpperCase()}${namesArray[namesArray.length - 1].charAt(0).toUpperCase()}`;
  }
}
//View Related Models
interface UserAvatar extends User {
  shortName?: string;
  hexColor?: string;
  fullName?: string;
  colorClass?: string;
  bgColor?: string;
}
