import { Component, Input, OnInit } from '@angular/core';
import { BaseViewItem, DataTypeEnum, UserOperationPrinciple, extractItemCode } from '@shared/classes';

@Component({
  selector: 'app-principle-list-view',
  templateUrl: './principle-list-view.component.html',
  styleUrls: ['./principle-list-view.component.scss'],
})
export class PrincipleListViewComponent
  extends BaseViewItem<UserOperationPrinciple[] | UserOperationPrinciple>
  implements OnInit
{
  @Input() showModeIcon = false;
  @Input() strictPrincipleMode = true;

  constructor() {
    super();
  }

  ngOnInit(): void {}
  principleList: ExtendedPrincipleModel[] = [];
  onSetData(): void {
    this.principleList = [];
    if (Array.isArray(this.data)) {
      this.data.forEach((principle) => {
        this.principleList.push(this.getParsedItem(principle));
      });
    } else {
      this.principleList.push(this.getParsedItem(this.data));
    }
  }
  getParsedItem(item: UserOperationPrinciple): ExtendedPrincipleModel {
    const parsedName = extractItemCode(item?.name);
    const isResponsibility = item?.principleType == 'RESPONSIBILITY';
    if (!item) return null;
    return {
      ...item,
      modeIcon: this.getModeIcon(item?.mode),
      code: isResponsibility ? parsedName?.code : null,
      label: parsedName?.label,
      dataViewType: isResponsibility ? DataTypeEnum.CodeLink : DataTypeEnum.UserListView,
      isResponsibility: isResponsibility,
    };
  }
  getModeIcon(mode: UserOperationPrinciple.ModeEnum) {
    return mode == 'ONE' ? 'pi pi-user' : 'pi pi-users';
  }
}
interface ExtendedPrincipleModel extends UserOperationPrinciple {
  code: string;
  isResponsibility: boolean;
  label: string;
  modeIcon: 'pi pi-user' | 'pi pi-users';
  dataViewType: DataTypeEnum;
}
