import { Component, Input, OnInit } from '@angular/core';
import { DataTypeEnum, DynamicComponentBase } from '@shared/classes';

@Component({
  selector: 'app-rating-input',
  templateUrl: './rating-input.component.html',
  styleUrls: ['./rating-input.component.scss'],
  providers: [{ provide: DynamicComponentBase, useExisting: RatingInputComponent }],
})
export class RatingInputComponent extends DynamicComponentBase implements OnInit {
  dataType: DataTypeEnum = DataTypeEnum.Text;
  @Input() maxNumber: number = 5;
  @Input() cancel: boolean = true;
  @Input() iconOnClass: string = 'pi pi-star-fill';
  @Input() iconOffClass: string = 'pi pi-star';
  @Input() iconCancelClass: string = 'pi pi-ban';
  constructor() {
    super();
  }

  ngOnInit(): void {}
  setInputOptions() {
    this.maxNumber = this.inputOptions?.ratingInput?.maxNumber ?? this.maxNumber;
    this.cancel = this.inputOptions?.ratingInput?.cancel ?? this.cancel;
    this.iconOnClass = this.inputOptions?.ratingInput?.iconOnClass ?? this.iconOnClass;
    this.iconOffClass = this.inputOptions?.ratingInput?.iconOffClass ?? this.iconOffClass;
    this.iconCancelClass = this.inputOptions?.ratingInput?.iconCancelClass ?? this.iconCancelClass;
  }
}
