import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DataTypeEnum, NumberInputModes } from '@shared/classes';
import { DynamicComponentBase } from '@shared/classes/view/DynamicComponentBase';
import { OverlayPanel } from 'primeng/overlaypanel';

@Component({
  selector: 'app-number-input',
  templateUrl: './number-input.component.html',
  styleUrls: ['./number-input.component.scss'],
  providers: [{ provide: DynamicComponentBase, useExisting: NumberInputComponent }],
})
export class NumberInputComponent extends DynamicComponentBase implements OnInit {
  @Input() mode: keyof typeof NumberInputModes = 'decimal';
  @Input() showButtons = false;
  @Input() maxFractionDigits = 5;
  @Input() buttonLayout: 'stacked' | 'horizontal' | 'vertical' = 'stacked';
  @Input() incrementButtonClass = 'p-button-primary';
  @Input() decrementButtonClass = 'p-button-primary';
  @Input() incrementButtonIcon = 'pi pi-angle-up';
  @Input() decrementButtonIcon = 'pi pi-angle-down';
  @Input() min: number = undefined;
  @Input() max: number = undefined;
  @Input() currency: string = 'USD';
  @Input() locale: string = 'en-US';
  @ViewChild('currencyOP') currencyOP: OverlayPanel;
  dataType: DataTypeEnum = DataTypeEnum.Number;
  currencyDataType: DataTypeEnum = DataTypeEnum.CurrencyView;

  numberValue = null;
  currencyValue = null;
  uiChange = false;
  currencyList: any[];
  constructor() {
    super();
  }

  ngOnInit(): void {
    if (this.fControl?.getRawValue() !== null) {
      if (this.mode == 'currency') {
        if (this.fControl?.getRawValue()?.currency) {
          this.currencyValue = this.fControl?.getRawValue().currency;
        }
        if (this.fControl?.getRawValue()?.value) {
          this.numberValue = this.fControl?.getRawValue().value;
        }
      } else {
        this.numberValue = this.fControl?.getRawValue();
      }
    }
    this.subs.sink = this.fControl.valueChanges.subscribe((values) => {
      if (this.uiChange) {
        this.uiChange = false;
      } else {
        if (this.mode == 'currency') {
          if (values?.currency) {
            const currencyObj = currencies.find((x) => x.code == values.currency);
            if (currencyObj) {
              this.currency = currencyObj.code;
              this.locale = currencyObj.locale;
            }
            this.currencyValue = values.currency;
          }
          if (values?.value) {
            this.numberValue = values.value;
          }
        } else {
          this.numberValue = values;
        }
      }
    });
    this.currencyList = currencies.map((item) => {
      return {
        label: item.code,
        code: item.code,
        locale: item.locale,
        codeLowerCase: item.code.toLowerCase(),

        // icon: 'pi pi-refresh',
        // command: () => {
        //     this.onSelectOption(item);
        // }
      };
    });
  }
  onSelectOption(item: { code: string; locale: string }) {
    this.currency = item.code;
    this.locale = item.locale;
    this.onChangeCurrency(item.code, null);
  }
  onChangeCurrency(item, overlayPanel) {
    if (this.mode == 'currency') {
      this.uiChange = true;
      this.currency = this.currencyValue.code;
      this.locale = this.currencyValue.locale;
      this.fControl.patchValue({ currency: this.currency, value: this.numberValue });
      overlayPanel?.hide();
    }
  }
  onChangeNumberValue(event) {
    this.uiChange = true;
    if (this.mode == 'currency') {
      if (this.numberValue === null) {
        this.fControl.patchValue(null);
      } else {
        this.fControl.patchValue({ currency: this.currency, value: this.numberValue });
      }
    } else {
      this.fControl.patchValue(this.numberValue);
    }
    this.fControl.markAllAsTouched();
    this.fControl.markAsDirty();
  }
  setInputOptions() {
    this.mode = this.inputOptions?.numberInput?.mode ?? this.mode;
    this.showButtons = this.inputOptions?.numberInput?.showButtons ?? this.showButtons;
    this.maxFractionDigits = this.inputOptions?.numberInput?.maxFractionDigits ?? this.maxFractionDigits;
    this.buttonLayout = this.inputOptions?.numberInput?.buttonLayout ?? this.buttonLayout;
    this.incrementButtonClass = this.inputOptions?.numberInput?.incrementButtonClass ?? this.incrementButtonClass;
    this.decrementButtonClass = this.inputOptions?.numberInput?.decrementButtonClass ?? this.decrementButtonClass;
    this.incrementButtonIcon = this.inputOptions?.numberInput?.incrementButtonIcon ?? this.incrementButtonIcon;
    this.decrementButtonIcon = this.inputOptions?.numberInput?.decrementButtonIcon ?? this.decrementButtonIcon;
    this.min = this.inputOptions?.numberInput?.min ?? this.min;
    this.max = this.inputOptions?.numberInput?.max ?? this.max;
  }
}
export const currencies = [
  { code: 'AED', locale: 'en-US' }, // United Arab Emirates Dirham ar-AE
  { code: 'AFN', locale: 'ps-AF' }, // Afghan Afghani
  { code: 'ALL', locale: 'sq-AL' }, // Albanian Lek
  { code: 'AMD', locale: 'hy-AM' }, // Armenian Dram
  { code: 'ANG', locale: 'nl-AW' }, // Netherlands Antillean Guilder
  { code: 'AOA', locale: 'pt-AO' }, // Angolan Kwanza
  { code: 'ARS', locale: 'es-AR' }, // Argentine Peso
  { code: 'AUD', locale: 'en-AU' }, // Australian Dollar
  { code: 'AWG', locale: 'nl-AW' }, // Aruban Florin
  { code: 'AZN', locale: 'az-AZ' }, // Azerbaijani Manat
  { code: 'BAM', locale: 'bs-Latn-BA' }, // Bosnia-Herzegovina Convertible Mark
  { code: 'BBD', locale: 'en-BB' }, // Barbadian Dollar
  { code: 'BDT', locale: 'bn-BD' }, // Bangladeshi Taka
  { code: 'BGN', locale: 'bg-BG' }, // Bulgarian Lev
  { code: 'BHD', locale: 'ar-BH' }, // Bahraini Dinar
  { code: 'BIF', locale: 'fr-BI' }, // Burundian Franc
  { code: 'BMD', locale: 'en-BM' }, // Bermudian Dollar
  { code: 'BND', locale: 'ms-BN' }, // Brunei Dollar
  { code: 'BOB', locale: 'es-BO' }, // Bolivian Boliviano
  { code: 'BRL', locale: 'pt-BR' }, // Brazilian Real
  { code: 'BSD', locale: 'en-BS' }, // Bahamian Dollar
  { code: 'BTN', locale: 'dz-BT' }, // Bhutanese Ngultrum
  { code: 'BWP', locale: 'en-BW' }, // Botswana Pula
  { code: 'BYN', locale: 'be-BY' }, // Belarusian Ruble
  { code: 'BZD', locale: 'en-BZ' }, // Belize Dollar
  { code: 'CAD', locale: 'en-CA' }, // Canadian Dollar
  { code: 'CDF', locale: 'fr-CD' }, // Congolese Franc
  { code: 'CHF', locale: 'de-CH' }, // Swiss Franc
  { code: 'CLP', locale: 'es-CL' }, // Chilean Peso
  { code: 'CNY', locale: 'zh-CN' }, // Chinese Yuan Renminbi
  { code: 'COP', locale: 'es-CO' }, // Colombian Peso
  { code: 'CRC', locale: 'es-CR' }, // Costa Rican Colon
  { code: 'CUP', locale: 'es-CU' }, // Cuban Peso
  { code: 'CVE', locale: 'pt-CV' }, // Cape Verdean Escudo
  { code: 'CZK', locale: 'cs-CZ' }, // Czech Koruna
  { code: 'DJF', locale: 'fr-DJ' }, // Djiboutian Franc
  { code: 'DKK', locale: 'da-DK' }, // Danish Krone
  { code: 'DOP', locale: 'es-DO' }, // Dominican Peso
  { code: 'DZD', locale: 'ar-DZ' }, // Algerian Dinar
  { code: 'EGP', locale: 'ar-EG' }, // Egyptian Pound
  { code: 'ERN', locale: 'aa-ER' }, // Eritrean Nakfa
  { code: 'ETB', locale: 'am-ET' }, // Ethiopian Birr
  { code: 'EUR', locale: 'en-EU' }, // Euro
  { code: 'FJD', locale: 'en-FJ' }, // Fijian Dollar
  { code: 'FKP', locale: 'en-FK' }, // Falkland Islands Pound
  { code: 'FOK', locale: 'en-FO' }, // Faroese Króna
  { code: 'GBP', locale: 'en-GB' }, // British Pound Sterling
  { code: 'GEL', locale: 'ka-GE' }, // Georgian Lari
  { code: 'GGP', locale: 'en-GG' }, // Guernsey Pound
  { code: 'GHS', locale: 'ak-GH' }, // Ghanaian Cedi
  { code: 'GIP', locale: 'en-GI' }, // Gibraltar Pound
  { code: 'GMD', locale: 'en-GM' }, // Gambian Dalasi
  { code: 'GNF', locale: 'fr-GN' }, // Guinean Franc
  { code: 'GTQ', locale: 'es-GT' }, // Guatemalan Quetzal
  { code: 'GYD', locale: 'en-GY' }, // Guyanese Dollar
  { code: 'HKD', locale: 'zh-HK' }, // Hong Kong Dollar
  { code: 'HNL', locale: 'es-HN' }, // Honduran Lempira
  { code: 'HRK', locale: 'hr-HR' }, // Croatian Kuna
  { code: 'HTG', locale: 'ht-HT' }, // Haitian Gourde
  { code: 'HUF', locale: 'hu-HU' }, // Hungarian Forint
  { code: 'IDR', locale: 'id-ID' }, // Indonesian Rupiah
  { code: 'ILS', locale: 'he-IL' }, // Israeli New Shekel
  { code: 'IMP', locale: 'en-IM' }, // Isle of Man Pound
  { code: 'INR', locale: 'hi-IN' }, // Indian Rupee
  { code: 'IQD', locale: 'ar-IQ' }, // Iraqi Dinar
  { code: 'IRR', locale: 'fa-IR' }, // Iranian Rial
  { code: 'ISK', locale: 'is-IS' }, // Icelandic Króna
  { code: 'JEP', locale: 'en-JE' }, // Jersey Pound
  { code: 'JMD', locale: 'en-JM' }, // Jamaican Dollar
  { code: 'JOD', locale: 'ar-JO' }, // Jordanian Dinar
  { code: 'JPY', locale: 'ja-JP' }, // Japanese Yen
  { code: 'KES', locale: 'sw-KE' }, // Kenyan Shilling
  { code: 'KGS', locale: 'ky-KG' }, // Kyrgyzstani Som
  { code: 'KHR', locale: 'km-KH' }, // Cambodian Riel
  { code: 'KID', locale: 'en-KI' }, // Kiribati Dollar
  { code: 'KMF', locale: 'fr-KM' }, // Comorian Franc
  { code: 'KPW', locale: 'ko-KP' }, // North Korean Won
  { code: 'KRW', locale: 'ko-KR' }, // South Korean Won
  { code: 'KWD', locale: 'ar-KW' }, // Kuwaiti Dinar
  { code: 'KYD', locale: 'en-KY' }, // Cayman Islands Dollar
  { code: 'KZT', locale: 'kk-KZ' }, // Kazakhstani Tenge
  { code: 'LAK', locale: 'lo-LA' }, // Lao Kip
  { code: 'LBP', locale: 'ar-LB' }, // Lebanese Pound
  { code: 'LKR', locale: 'si-LK' }, // Sri Lankan Rupee
  { code: 'LRD', locale: 'en-LR' }, // Liberian Dollar
  { code: 'LSL', locale: 'en-LS' }, // Lesotho Loti
  { code: 'LYD', locale: 'ar-LY' }, // Libyan Dinar
  { code: 'MAD', locale: 'ar-MA' }, // Moroccan Dirham
  { code: 'MDL', locale: 'ro-MD' }, // Moldovan Leu
  { code: 'MGA', locale: 'fr-MG' }, // Malagasy Ariary
  { code: 'MKD', locale: 'mk-MK' }, // Macedonian Denar
  { code: 'MMK', locale: 'my-MM' }, // Myanmar Kyat
  { code: 'MNT', locale: 'mn-MN' }, // Mongolian Tugrik
  { code: 'MOP', locale: 'zh-MO' }, // Macanese Pataca
  { code: 'MRU', locale: 'ar-MR' }, // Mauritanian Ouguiya
  { code: 'MUR', locale: 'en-MU' }, // Mauritian Rupee
  { code: 'MVR', locale: 'dv-MV' }, // Maldivian Rufiyaa
  { code: 'MWK', locale: 'ny-MW' }, // Malawian Kwacha
  { code: 'MXN', locale: 'es-MX' }, // Mexican Peso
  { code: 'MYR', locale: 'ms-MY' }, // Malaysian Ringgit
  { code: 'MZN', locale: 'pt-MZ' }, // Mozambican Metical
  { code: 'NAD', locale: 'en-NA' }, // Namibian Dollar
  { code: 'NGN', locale: 'ig-NG' }, // Nigerian Naira
  { code: 'NIO', locale: 'es-NI' }, // Nicaraguan Córdoba
  { code: 'NOK', locale: 'nb-NO' }, // Norwegian Krone
  { code: 'NPR', locale: 'ne-NP' }, // Nepalese Rupee
  { code: 'NZD', locale: 'en-NZ' }, // New Zealand Dollar
  { code: 'OMR', locale: 'ar-OM' }, // Omani Rial
  { code: 'PAB', locale: 'es-PA' }, // Panamanian Balboa
  { code: 'PEN', locale: 'es-PE' }, // Peruvian Nuevo Sol
  { code: 'PGK', locale: 'en-PG' }, // Papua New Guinean Kina
  { code: 'PHP', locale: 'fil-PH' }, // Philippine Peso
  { code: 'PKR', locale: 'ur-PK' }, // Pakistani Rupee
  { code: 'PLN', locale: 'pl-PL' }, // Polish Zloty
  { code: 'PYG', locale: 'es-PY' }, // Paraguayan Guarani
  { code: 'QAR', locale: 'ar-QA' }, // Qatari Riyal
  { code: 'RON', locale: 'ro-RO' }, // Romanian Leu
  { code: 'RSD', locale: 'sr-Cyrl-RS' }, // Serbian Dinar
  { code: 'RUB', locale: 'ru-RU' }, // Russian Ruble
  { code: 'RWF', locale: 'rw-RW' }, // Rwandan Franc
  { code: 'SAR', locale: 'ar-SA' }, // Saudi Riyal
  { code: 'SBD', locale: 'en-SB' }, // Solomon Islands Dollar
  { code: 'SCR', locale: 'en-SC' }, // Seychellois Rupee
  { code: 'SDG', locale: 'ar-SD' }, // Sudanese Pound
  { code: 'SEK', locale: 'sv-SE' }, // Swedish Krona
  { code: 'SGD', locale: 'en-SG' }, // Singapore Dollar
  { code: 'SHP', locale: 'en-SH' }, // Saint Helena Pound
  { code: 'SLL', locale: 'en-SL' }, // Sierra Leonean Leone
  { code: 'SOS', locale: 'so-SO' }, // Somali Shilling
  { code: 'SRD', locale: 'nl-SR' }, // Surinamese Dollar
  { code: 'SSP', locale: 'en-SS' }, // South Sudanese Pound
  { code: 'STN', locale: 'pt-ST' }, // São Tomé and Príncipe Dobra
  { code: 'SVC', locale: 'es-SV' }, // Salvadoran Colón
  { code: 'SYP', locale: 'ar-SY' }, // Syrian Pound
  { code: 'SZL', locale: 'en-SZ' }, // Swazi Lilangeni
  { code: 'THB', locale: 'th-TH' }, // Thai Baht
  { code: 'TJS', locale: 'tg-TJ' }, // Tajikistani Somoni
  { code: 'TMT', locale: 'tk-TM' }, // Turkmenistani Manat
  { code: 'TND', locale: 'ar-TN' }, // Tunisian Dinar
  { code: 'TOP', locale: 'to-TO' }, // Tongan Pa'anga
  { code: 'TRY', locale: 'tr-TR' }, // Turkish Lira
  { code: 'TTD', locale: 'en-TT' }, // Trinidad and Tobago Dollar
  { code: 'TWD', locale: 'zh-TW' }, // New Taiwan Dollar
  { code: 'TZS', locale: 'sw-TZ' }, // Tanzanian Shilling
  { code: 'UAH', locale: 'uk-UA' }, // Ukrainian Hryvnia
  { code: 'UGX', locale: 'sw-UG' }, // Ugandan Shilling
  { code: 'USD', locale: 'en-US' }, // United States Dollar
  { code: 'UYU', locale: 'es-UY' }, // Uruguayan Peso
  { code: 'UZS', locale: 'uz-UZ' }, // Uzbekistan Som
  { code: 'VES', locale: 'es-VE' }, // Venezuelan Bolívar Soberano
  { code: 'VND', locale: 'vi-VN' }, // Vietnamese Dong
  { code: 'VUV', locale: 'bi-VU' }, // Vanuatu Vatu
  { code: 'WST', locale: 'en-WS' }, // Samoan Tala
  { code: 'XAF', locale: 'fr-XAF' }, // Central African CFA Franc
  { code: 'XCD', locale: 'en-XC' }, // East Caribbean Dollar
  { code: 'XOF', locale: 'fr-XOF' }, // West African CFA Franc
  { code: 'XPF', locale: 'fr-XPF' }, // CFP Franc
  { code: 'YER', locale: 'ar-YE' }, // Yemeni Rial
  { code: 'ZAR', locale: 'en-ZA' }, // South African Rand
  { code: 'ZMW', locale: 'en-ZM' }, // Zambian Kwacha
  { code: 'ZWL', locale: 'en-ZW' }, // Zimbabwean Dollar
  // Add more currencies as needed...
];
