@if (action && (displayData | hideOrDisplayAction: action)) {
  <button
    *ngxPermissionsOnly="action.permission"
    pButton
    pRipple
    [type]="action.buttonType ?? 'button'"
    [label]="
      customLabel || action.label ? ('general.actions.' + (customLabel || action.label) | lowercase | translate) : ''
    "
    [class]="action | getButtonClass"
    [icon]="customIcon || action.icon"
    [iconPos]="action.iconPos ?? 'right'"
    [loading]="action.loading$ | async"
    [disabled]="
      customStatus$ != null
        ? !(customStatus$ | async)
        : action.status$ === null
          ? false
          : !((action.status$ | async) === null ? true : (action.status$ | async))
    "
    (click)="onButtonClick($event)"
    [pTooltip]="action.tooltipText | translate"
    [tooltipPosition]="action.tooltipPosition ?? 'top'"
    loadingIcon="pi pi-spin pi-spinner"
  >
    <ng-content></ng-content>
  </button>
}
