import { Component, OnInit } from '@angular/core';
import { BaseViewItem, IProgressBar, ProgressDto } from '@shared/classes';
import { toSafeInteger } from 'lodash-es';

@Component({
  selector: 'app-acknowledgement-request-progress-finished',
  templateUrl: './acknowledgement-request-progress-bar.component.html',
  styleUrls: ['./acknowledgement-request-progress-bar.component.scss'],
})
export class AcknowledgementRequestProgressFinishedComponent extends BaseViewItem implements OnInit {
  progressBarList: IProgressBar[] = [];
  isLoading: boolean = false;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.processProgressBarList();
  }

  processProgressBarList() {
    let ackReqProgress: ProgressDto = this.data?.ackReqProgress;
    let all = ackReqProgress['FINISHED'] + ackReqProgress['NOT_FINISHED'];
    this.progressBarList = [
      {
        value: all > 0 ? toSafeInteger((ackReqProgress['FINISHED'] / all) * 100) : 0,
        bgColor: 'bg-green-400',
        tooltipHint: (all > 0 ? toSafeInteger((ackReqProgress['FINISHED'] / all) * 100) : 0) + '% FINISHED',
      },
      {
        value: toSafeInteger((ackReqProgress['NOT_FINISHED'] / all) * 100),
        bgColor: 'bg-red-400',
        tooltipHint: (all > 0 ? toSafeInteger((ackReqProgress['NOT_FINISHED'] / all) * 100) : 0) + '% NOT_FINISHED',
      },
    ];
  }
}
