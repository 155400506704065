import { Pipe, PipeTransform } from '@angular/core';
import { LocaleService } from '@core/services/locale/locale.service';
import { translate } from '@shared/classes';

@Pipe({
  name: 'translate',
})
export class TranslatePipe implements PipeTransform {
  constructor(private localeService: LocaleService) {}
  transform(value: string, fallbackValue?: string, args?: any) {
    return translate(value, this.localeService.languageFile, fallbackValue);
  }
}
