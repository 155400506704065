<app-ip-mac-input
  [control]="control"
  [mode]="'ipv4'"
  [label]="label"
  [placeholder]="placeholder"
  [showLabelInViewMode]="showLabelInViewMode"
  [viewMode]="viewMode"
  [dataType]="dataType"
  [leftIcon]="leftIcon"
  [rightIcon]="rightIcon"
  [required]="isControlRequired"
  [customErrorLabel]="customErrorLabel"
  [patternError]="patternError"
  [disabledBlocks]="disabledBlocks"
  [value]="value"
  [customError]="customError"
></app-ip-mac-input>
<!-- [inputMask]="'999.999.999.999'" -->
