import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApiResponseExceptionEvent, BaseResponse, FrameworkException, RequestHandlerOptions } from '@shared/classes';
import { BaseRequestControllerWithRuleService } from '@shared/services/api/custom-api-services/base-request-controller-with-rule.service';
import { DataService } from 'app/shared/services/api/data.service';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FrameworkExceptionDataService extends BaseRequestControllerWithRuleService<
  FrameworkException,
  ApiResponseExceptionEvent.DataEnum
> {
  apiOptions: RequestHandlerOptions = {
    showLoading: true,
    showMsg: true,
  };

  constructor(
    private dataService: DataService,
    private router: Router
  ) {
    super(dataService, environment.framework, '/exceptions', [
      'id',
      'code',
      'tags',
      'tagsCodes',
      'name',
      'title',
      'description',
      'type',
      'targetCode',
      'status',
      'durationTo',
      'durationFrom',
      'approvers',
      'owner',
      'lockedBy',
      'lockedForEdit',
      'lockedUntil',
      'userAccessLevel',
      'recordStatus',
      'creatorName',
      'lastModifierName',
      'creationDate',
      'lastModificationDate',
    ]);
  }

  navigateToListPage() {
    this.router.navigateByUrl(`framework/exception/list`);
  }

  navigateVersionPage(version, policyId) {
    this.router.navigateByUrl(`framework/exception/${version}/view/${policyId}`);
  }
  enableExceptionJobForTest(options: RequestHandlerOptions = new RequestHandlerOptions()) {
    const newLocal = this.url + `/enableExceptionJobForTest`;
    return this.dataService.getData<BaseResponse<boolean>>(newLocal, options) as Observable<BaseResponse<boolean>>;
  }
  disableExceptionJobForTest(options: RequestHandlerOptions = new RequestHandlerOptions()) {
    const newLocal = this.url + `/disableExceptionJobForTest`;
    return this.dataService.getData<BaseResponse<boolean>>(newLocal, options) as Observable<BaseResponse<boolean>>;
  }
  // changeStatus(status, policyId, options: RequestHandlerOptions = new RequestHandlerOptions()) {
  //     return this.dataService.postData<BaseResponse<any>>(this.url + `/changeStatus/${policyId}/${status}`, {}, options) as Observable<BaseResponse<any>>;
  // }

  // public getPolicyRuleHandlers(sourceCode: String) {
  //     const newLocal = this.url + `/get-rule-handlers/${sourceCode}`;
  //     return this.dataService.getData<FlatRule[]>(
  //         newLocal,
  //         this.apiOptions
  //     ) as Observable<FlatRule[]>;
  // }
}
