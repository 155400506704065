import { PrimeIcons } from 'primeng/api';
import { FieldDto, SearchFilterDto } from '../model';
import { ModuleIcons } from './ModuleIcons';
import { ModuleKeywords } from './ModuleKeywords';
import { SFIcons } from './SFIcons';

export enum BadgeColor {
  'red' = 'red',
  'pink' = 'pink',
  'purple' = 'purple',
  'indigo' = 'indigo',
  'blue' = 'blue',
  'cyan' = 'cyan',
  'teal' = 'teal',
  'green' = 'green',
  'yellow' = 'yellow',
  'orange' = 'orange',
  'deep-orange' = 'deep-orange',
  'brown' = 'brown',
  'gray' = 'gray',
  'deep-gray' = 'deep-gray',
  'black' = 'black',
  'white' = 'white',
  'gold' = 'gold',
  'NOT_DEFINED' = 'gray',
  ATTESTATION = 'indigo',
  ASSESSMENT = 'teal',
  RISK = 'red',
  AUDIT = 'yellow',

  IT = 'deep-orange',
  BUSINESS = 'teal',
  TOP_DOWN = 'teal',
  BOTTOM_UP = 'red',
  ASSESSED = 'blue',
  FINISHED = 'green',
  DRAFT = 'gray',
  NOT_YET = 'gray',
  ANALYSIS = 'yellow',
  REMEDIATION = 'cyan',
  CLOSED_RESOLVED = 'green',
  CLOSED_UNRESOLVED = 'red',
  STARTED = 'blue',
  COMPLETED = 'red',

  true = 'green',
  false = 'red',
  Mandatory = 'green',
  Not_Mandatory = 'red',

  Attest = 'purple',
  Monitor = 'yellow',

  ALL = 'purple',
  ONE = 'yellow',

  ANNUALLY = 'teal',
  SEMIANNUAL = 'yellow',
  QUARTERLY = 'green',
  MONTHLY = 'purple',
  WEEKLY = 'pink',
  DAILY = 'indigo',

  DETECTIVE = 'pink',
  CORRECTIVE = 'purple',
  PREVENTIVE = 'green',
  DETERRENT = 'blue',
  COMPENSATING = 'teal',
  RECOVERY = 'yellow',
  DIRECTIVE = 'cyan',

  INACTIVE = 'red',
  ACTIVE = 'green',

  ATTEST = 'pink',

  QUESTION = 'green',
  TAG = 'cyan',
  RELATION_TYPE = 'yellow',
  RELATION_TYPES = 'yellow',
  FIELD = 'green',
  MONITOR = 'green',
  QUESTIONNAIRE = 'deep-orange',
  QUESTIONNAIRE_VALUE = 'indigo',
  MY_QUESTIONNAIRE_VALUE = 'yellow',
  QUESTIONNAIRE_VALUES = 'indigo',
  MY_QUESTIONNAIRE_VALUES = 'yellow',
  IMPACT_FACTOR = 'teal',
  RISK_ITEM = 'red',
  RISK_PROJECT = 'gray',
  RISK_STATEMENT = 'blue',
  RISK_STATEMENT_CATEGORY = 'orange',

  POLICY = 'purple',
  POLICY_BODY = 'teal',
  CONTROL = 'blue',
  CONTROL_OBJECTIVE = 'green',

  ENTITY = 'pink',
  ENTITY_TYPE = 'purple',
  ENTITY_TYPES = 'purple',
  ENTITY_FILTER = 'cyan',
  ENTITY_GROUP = 'cyan',
  ENTITY_GROUPS = 'cyan',
  ENTITY_LEVEL = 'green',

  CONTROL_OBJECTIVES = 'yellow',
  Category = 'green',
  Subcategory = 'teal',
  Clause = 'orange',
  None = 'red',

  REVIEW = 'pink',
  WAITING_APPROVE = 'yellow',
  APPROVED = 'teal',
  APPROVE = 'teal',
  SUBMITTED = 'yellow',
  RETIRED = 'red',
  EVENTS = 'red',
  events = 'red',
  EVENT = 'red',
  GEOGRAPHIES = 'yellow',
  geographies = 'yellow',
  GEOGRAPHY = 'yellow',
  RESPONSIBILITIES = 'purple',
  responsibilities = 'purple',
  RESPONSIBILITY = 'purple',
  ORGANIZATIONS = 'teal',
  organizations = 'teal',
  ORGANIZATION = 'teal',
  organization = 'teal',
  ASSETS = 'green',
  assets = 'green',
  ASSET = 'green',
  PROCESSES = 'pink',
  processes = 'pink',
  PROCESS = 'pink',
  OBJECTIVES = 'blue',
  objectives = 'blue',
  OBJECTIVE = 'blue',
  LOWEST = 'gray',
  LOW = 'cyan',
  NORMAL = 'teal',
  HIGH = 'orange',
  HIGHEST = 'red',
  CRITICAL = 'red',
  MODERATE = 'green',
  INFO = 'gray',

  TODO = 'cyan',
  IN_PROGRESS = 'yellow',
  DONE = 'green',
  CITATION_TYPE = 'deep-orange',
  CITATION = 'green',
  AUTHORITY_DOCUMENT = 'teal',
  AUTHORITY = 'teal',
  ACKNOWLEDGMENT_REQUEST = 'pink',
  ACKNOWLEDGMENT = 'indigo',
  TASK = 'red',
  RISK_METHODOLOGY = 'yellow',
  MCQ = 'orange',
  BOOLEAN = 'blue',
  STRING = 'green',
  NUMBER = 'yellow',
  LONG_TEXT = 'cyan',
  INTEGER = 'purple',
  DOUBLE = 'teal',
  ATTACHMENT = 'green',
  IP = 'yellow',
  IP6 = 'orange',
  MAC = 'blue',
  IPV4_MASK = 'cyan',
  DATE = 'red',
  DATETIME = 'pink',
  MULTISELECT = 'green',
  RELATION = 'yellow',
  RATING = 'cyan',
  SURVEY = 'cyan',
  SINGLE_SELECTION = 'indigo',
  GROUP_VALUE_LIST = 'gray',
  'date' = 'cyan',
  'month' = 'yellow',
  'year' = 'red',
  'single' = 'cyan',
  'multiple' = 'yellow',
  'range' = 'red',
  'DELETED' = 'red',
  'LOCKED' = 'gray',
  'LOCKED_EDIT' = 'orange',
  'IN_ACTIVE' = 'red',
  'COLLAPSE' = 'blue',
  'EXPAND' = 'green',
  'HIDE' = 'red',

  'EMPTY' = 'red',

  'TRAIL_EVENTS' = 'red',
  'TRAIL_UPDATED' = 'teal',
  'TRAIL_ACTIVE' = 'green',
  'TRAIL_LOCKED_FOR_EDIT' = 'orange',
  'TRAIL_UN_LOCKED_FOR_EDIT' = 'teal',
  'TRAIL_LOCKED' = 'gray',
  'TRAIL_UN_LOCKED' = 'gray',
  'TRAIL_CHANGE_STATE' = 'blue',
  'TRAIL_IN_ACTIVE' = 'red',
  'TRAIL_CREATED' = 'cyan',
  'TRAIL_COMMENT' = 'blue',
  'TRAIL_TASK' = 'yellow',
  'TRAIL_ACCESS_CHANGE' = 'green',
  'TRAIL_COMMENTED' = 'blue',
  'TRAIL_USER_JOIN' = 'teal',
  'TRAIL_USER_LEAVE' = 'yellow',
  'TRAIL_AUDIT_TRAIL' = 'indigo',
  'TRAIL_ARCHIVED' = 'purple',

  'CLOSED' = 'green',
  'REJECTED' = 'red',
  'NOT_FINISHED' = 'red',
  'radio' = 'blue',
  'dropdown' = 'teal',
  'AUTO' = 'green',
  'REFERENCE' = 'teal',
  'MANUAL' = 'blue',
  'MANUAL_REVERSE' = 'cyan',
  'LOCKED_ENTITY' = 'deep-gray',
  'EXCEPTION' = 'red',
  'CHECK_ITEM' = 'cyan',
  'ISSUE' = 'yellow',
  'ISSUE_TYPE' = 'teal',
  'IMPACT_FACTOR_VALUE' = 'purple',
  'NOTIFICATION' = 'green',
  'High Risks' = 'red',
  'Acceptable Risks' = 'green',
  'DIRECT' = 'green',
  'WATCHING' = 'blue',
  'New To-Do' = 'blue',
  'To-Do' = 'teal',
  'CREATED' = 'green',
  'UN_LOCKED' = 'green',
  'EXISTING' = 'yellow',
  'CHANGE_STATE' = 'yellow',
  'NEW' = 'blue',
  'UPDATED' = 'cyan',
  'COMPLIANT' = 'green',
  'NON_COMPLIANT' = 'red',
  'EXCEPTED' = 'red',
  SUCCESS = 'green',
  ERROR = 'red',
  LOADING = 'gray',
  CANCELED = 'red',
  END = 'teal',
  'ENDED' = 'cyan',
  'MITIGATE' = 'cyan',
  'TRANSFER' = 'yellow',
  'ACCEPT' = 'green',
  'IGNORE' = 'red',
  'AVOID' = 'red',
  'RISK_ASSESSMENT_REQUEST' = 'teal',
  'MY_RISK_ASSESSMENT_REQUEST' = 'cyan',
  'OTHERS' = 'green',

  'COMPANY' = 'green',
  'ADDRESS' = 'red',
  'TITLE' = 'purple',
  'PHONE' = 'pink',
  'EMAIL' = 'blue',
  'IMAGE' = 'blue',
  'SOURCE_TARGET' = 'cyan',
  'TARGET_SOURCE' = 'teal',
  'BOTH' = 'yellow',
  'NONE' = 'gray',
  'TEMPLATE' = 'green',
  'INSTANCE' = 'cyan',
  'PENDING' = 'purple',
  'READ' = 'green',
  'CREATE' = 'cyan',
  'UPDATE' = 'blue',
  'ADD_RELATIONS' = 'gray',
  'DELETE' = 'red',
  'PERMENANT_DELETE' = 'red',
  'ADMIN' = 'purple',
  'OWNER' = 'yellow',
  'NO_ACCESS' = 'red',
  'JOINED' = 'green',
  'FORCED' = 'cyan',
  'USER' = 'yellow',
  'GROUP' = 'deep-orange',
  'ROLE' = 'teal',
  'EXEMPTION' = 'cyan',
  'GLOBAL_VALUE_LIST' = 'cyan',
  'NO_RESPONSE' = 'gray',
  'MY_TASK' = 'teal',
  'MY_NOTIFICATION' = 'blue',
  'EXPORT_PACKAGE' = 'cyan',
  'IMPORT_PACKAGE' = 'purple',
  'SETTING' = 'green',
  'COMMENT' = 'teal',
  'RELATION_TYPE_ITEMS' = 'red',
  'POLICY_BODY_SECTION' = 'green',
  'COMPLIANCE_REPORT_RESULT' = 'green',
  'COMPLIANCE_REPORT' = 'teal',
  'AUDIT_TRAIL' = 'indigo',
  'CITATION_COMPLIANCE_REPORT_RESULT' = 'purple',
  'CITATION_COMPLIANCE_REPORT' = 'orange',
  'STATIC_VALUE' = 'deep-orange',
  'EMAIL_TEMPLATE' = 'teal',
  'TASK_TEMPLATE' = 'cyan',
  'ADD_TASK' = 'green',
  'ACCESS' = 'orange',
  'COMPLIANCE' = 'orange',
  'Compliance' = 'orange',
  'TASK_ADDED' = 'yellow',
  'TASK_CLOSED' = 'orange',
  'COMMENTED' = 'blue',
  'ACCESS_CHANGED' = 'red',
  'WORKFLOW_STATUS_CHANGED' = 'purple',
  'STATUS_CHANGED' = 'pink',
  'STATUS' = 'green',
  'WATCHERS' = 'pink',
  'DOCUMENT_APP' = 'orange',
  'ACKNOWLEDGEMENT_APP' = 'green',
  'ADMIN_APP' = 'red',
  'AUTHORITY_APP' = 'blue',
  'CONFIG_APP' = 'deep-gray',
  'DATA_STRUCTURE_APP' = 'teal',
  'BRANDING_APP' = 'cyan',
  'ENTITY_APP' = 'purple',
  'FRAMEWORK_APP' = 'pink',
  'ISSUES_APP' = 'yellow',
  'QUESTION_APP' = 'green',
  'RELATION_APP' = 'deep-orange',
  'RISK_APP' = 'red',
  'USER_APP' = 'indigo',
  'USER_OPERATIONS_APP' = 'deep-gray',
  'ACCEPTABLE' = 'green',
  'NOT_ACCEPTABLE' = 'red',
  'DEFAULT_DIRECT' = 'green',
  'DEFAULT_FIELDS_BASED' = 'cyan',
  'DEFAULT_INHERITED' = 'red',
  'DEFAULT_OWNER' = 'yellow',
  'ORIGIN' = 'orange',

  'PENDING_APPROVAL' = 'cyan',
  'AUDITEE_AUDITOR' = 'indigo',
  'AUDITOR_ONLY' = 'blue',
  'USER_JOIN' = 'teal',
  'USER_LEAVE' = 'yellow',
  'CLOSED_INCOMPLETED' = 'red',
  'CLOSED_COMPLETED' = 'green',
  'FIELD_WORK' = 'blue',
  'POPULATE' = 'yellow',
  'ARCHIVED' = 'purple',
  'AUDIT_CASE' = 'blue',
  'AUDIT_PROGRAM' = 'green',
  'AUDIT_PROGRAM_TYPE' = 'teal',
  'AUDIT_CASE_RESULT' = 'cyan',
  'AUDIT_PROJECT' = 'purple',
  'AUDIT_UNIVERSE' = 'pink',
  'AUDIT_CASE_TEMPLATE' = 'yellow',
  'AUDIT_PROGRAM_REPORT' = 'orange',
  'AUDIT_PROJECT_REPORT' = 'deep-orange',
  'AUDIT_REQUEST' = 'indigo',
  'MY_ACKNOWLEDGMENT' = 'teal',
  'DETAILS_REQUESTED' = 'teal',
  'AUDITED' = 'cyan',
  'CLOSED_INCOMPLETE' = 'red',
  'COMPLIANCE_REQUIREMENTS' = 'deep-orange',
  'GENERAL' = 'blue',
  'FILTER' = 'teal',
  'DASHBOARD_LAYOUT' = 'green',
  'CRITERIA_FILTER' = 'red',
  'FIELD_SET' = 'purple',
  'FORM_COMPONENT' = 'blue',
  'RELATION_TYPE_ITEM' = 'yellow',
  'DOCUMENT' = 'green',
  'DOCUMENT_VERSION' = 'teal',
  'BUCKET' = 'blue',
  'EXPORT_GLOBAL_PACKAGE' = 'cyan',
  'IMPORT_GLOBAL_PACKAGE' = 'teal',
  'VIEW' = 'purple',
  'RISK_VULNERABILITY' = 'yellow',
  'RISK_THREAT' = 'red',
  'REPORT' = 'cyan',
  'FIELD_TEMPLATE' = 'teal',
  'SUBSCRIPTION' = 'deep-orange',
  'MANUAL_SELECTION' = 'indigo',
  'POLICY_SECTION' = 'orange',
  'and' = 'blue',
  'or' = 'orange',
  'AND' = 'blue',
  'OR' = 'orange',
  'TABLE' = 'teal',
  'STATIC' = 'blue',
  'VERTICAL_BAR' = 'blue',
  'HORIZONTAL_BAR' = 'green',
  'STACKED_BAR' = 'cyan',
  'PIE' = 'teal',
  'ROSE' = 'pink',
  'DOUGHNUT' = 'orange',
  'LINE' = 'yellow',
  'POLAR_AREA' = 'purple',
  'RADAR' = 'indigo',
  'TREE_MAP' = 'red',
  'CHART' = 'orange',
  'DATA' = 'cyan',
  'CHART_AND_DATA' = 'green',
  'STANDARD' = 'green',
  'LOGARITHMIC' = 'cyan',
  'RECORD' = 'green',
  'RESOLVED' = 'green',
  'UNRESOLVED' = 'red',
  'PUBLISHED' = 'green',
  'DATA_COUNT' = 'green',
  'CUSTOM' = 'orange',
  'STATISTICS' = 'teal',
  'TIMELINE' = 'cyan',
  'QUESTIONNAIRE_REPORT' = 'green',
  'LINKS' = 'yellow',
  'RELATION_CHART_LINK' = 'red',
  'ORG_CHART_LINK' = 'purple',
  'TREE_VIEW' = 'blue',
  'DASHBOARD' = 'green',
  'TAB' = 'yellow',
  'SUN_BURST' = 'yellow',
  'SCATTERED' = 'teal',
  'BUBBLE' = 'cyan',
  'TIME_SCALE' = 'red',
  'BAR_RACE' = 'purple',
  'SEMI_CIRCLE_DOUGHNUT' = 'orange',
  'FUNNEL' = 'green',
  'PYRAMID' = 'blue',
  'HEATMAP' = 'red',
  'SANKEY' = 'cyan',
  'CONTROL_CATEGORY' = 'green',
  'UP' = 'green',
  'DOWN' = 'cyan',
  'LEFT' = 'teal',
  'RIGHT' = 'yellow',
  'RISK_HEATMAP' = 'red',
  'NOTIFICATIONS' = 'green',
  'SYSTEM_TIMELINE' = 'blue',
  'CURVED_LOGARITHMIC' = 'teal',
  'POLAR_LOGARITHMIC' = 'teal',
  'STACKED' = 'cyan',
  'DRILL_DOWN' = 'red',
  'AREA' = 'purple',
  'CURVED' = 'orange',
  'GROUPS' = 'blue',
  'WHEEL' = 'blue',
  'POLAR' = 'yellow',
  'ANCESTOR_EMPHASIS' = 'blue',
  'ADJACENT_EMPHASIS' = 'yellow',
  'FULL_STACKED' = 'blue',
  'PAGE_BREAK' = 'deep-gray',
  'AGGREGATION' = 'blue',
  'SEARCH' = 'green',
  'GROUP_BY' = 'teal',
  'AVG' = 'yellow',
  'SUM' = 'cyan',
  'MAX' = 'red',
  'MIN' = 'green',
  'FIRST' = 'purple',
  'LAST' = 'orange',
  'COUNT' = 'blue',
  'GROUP_BY_DAY' = 'red',
  'GROUP_BY_WEEK' = 'pink',
  'GROUP_BY_MONTH' = 'purple',
  'GROUP_BY_YEAR' = 'indigo',
  'GROUP_BY_HOUR' = 'blue',
  'GROUP_BY_MINUTE' = 'cyan',
  'GROUP_BY_SECOND' = 'teal',
  'GROUP_BY_DAY_OF_YEAR' = 'green',
  'GROUP_BY_WEEK_OF_YEAR' = 'yellow',
  'GROUP_BY_DAY_OF_WEEK' = 'orange',
  'GROUP_BY_DAY_NAME' = 'deep-orange',
  'GROUP_BY_QUARTER' = 'pink',
  'GROUP_BY_ISO_WEEK_YEAR' = 'cyan',
  'GROUP_BY_ISO_DAY_OF_WEEK' = 'teal',
  'REASSESSMENT' = 'yellow',
  'RISK_ITEM_REASSESSMENT' = 'yellow',
  'REASSESS' = 'yellow',
  'RESPONSE' = 'cyan',
  'TIME' = 'red',
  'COLOR' = 'cyan',
  'normal' = 'green',
  'scored' = 'blue',
  'decimal' = 'green',
  'currency' = 'blue',
  'NOT_SCORED' = 'green',
  'SCORED' = 'blue',
  'FAIL' = 'red',
  'PASS' = 'green',

  //@TODOBadge: ADD every enum here and map a color to it or use the text to color library
}
export const BadgeIcon = {
  ATTESTATION: ModuleIcons?.[ModuleKeywords.Attestation],
  ASSESSMENT: PrimeIcons.CIRCLE,
  RISK: ModuleIcons?.[ModuleKeywords.RiskItem],
  AUDIT: PrimeIcons.CIRCLE,

  IT: PrimeIcons.CIRCLE,
  BUSINESS: PrimeIcons.CIRCLE,
  TOP_DOWN: PrimeIcons.CIRCLE,
  BOTTOM_UP: PrimeIcons.CIRCLE,
  ASSESSED: PrimeIcons.CIRCLE,
  FINISHED: PrimeIcons.CIRCLE,
  DRAFT: PrimeIcons.CIRCLE,
  ANALYSIS: PrimeIcons.CIRCLE,
  REMEDIATION: ModuleIcons?.[ModuleKeywords.Remediation],
  CLOSED_RESOLVED: PrimeIcons.CIRCLE,
  CLOSED_UNRESOLVED: PrimeIcons.CIRCLE,
  STARTED: PrimeIcons.CIRCLE,
  COMPLETED: PrimeIcons.CIRCLE,

  true: PrimeIcons.CHECK,
  false: PrimeIcons.TIMES,
  PASS: PrimeIcons.CHECK,
  FAIL: PrimeIcons.TIMES,

  Attest: ModuleIcons?.[ModuleKeywords.Attestation],
  Monitor: PrimeIcons.CIRCLE,

  ANNUALLY: PrimeIcons.CIRCLE,
  SEMIANNUAL: PrimeIcons.CIRCLE,
  QUARTERLY: PrimeIcons.CIRCLE,
  MONTHLY: PrimeIcons.CIRCLE,
  WEEKLY: PrimeIcons.CIRCLE,
  DAILY: PrimeIcons.CIRCLE,

  DETECTIVE: PrimeIcons.CIRCLE,
  CORRECTIVE: PrimeIcons.CIRCLE,
  PREVENTIVE: PrimeIcons.CIRCLE,
  DETERRENT: PrimeIcons.CIRCLE,
  COMPENSATING: PrimeIcons.CIRCLE,
  RECOVERY: PrimeIcons.CIRCLE,
  DIRECTIVE: PrimeIcons.CIRCLE,

  INACTIVE: PrimeIcons.INBOX,
  ACTIVE: PrimeIcons.VERIFIED,

  ATTEST: ModuleIcons?.[ModuleKeywords.Attestation],

  QUESTION: ModuleIcons?.[ModuleKeywords.Question],
  MONITOR: PrimeIcons.CIRCLE,
  QUESTIONNAIRE: ModuleIcons?.[ModuleKeywords.Questionnaire],
  QUESTIONNAIRE_VALUE: ModuleIcons?.[ModuleKeywords.QuestionnaireValue],
  MY_QUESTIONNAIRE_VALUE: ModuleIcons?.[ModuleKeywords.MyQuestionnaireValue],
  IMPACT_FACTOR: ModuleIcons.ImpactFactor,
  RISK_ITEM: ModuleIcons?.[ModuleKeywords.RiskItem],
  RISK_PROJECT: ModuleIcons?.[ModuleKeywords.RiskProject],
  RISK_STATEMENT: ModuleIcons?.[ModuleKeywords.RiskStatement],
  RISK_STATEMENT_CATEGORY: ModuleIcons?.[ModuleKeywords.RiskStatementCategory],

  POLICY: ModuleIcons?.[ModuleKeywords.Policy],
  CONTROL: ModuleIcons?.[ModuleKeywords.Control],
  CONTROL_OBJECTIVE: ModuleIcons?.[ModuleKeywords.ControlGroup],

  ENTITY: ModuleIcons.Entity,
  ENTITY_TYPE: ModuleIcons?.[ModuleKeywords.EntityType],
  // ENTITY_FILTER: ModuleIcons.EntityFilters,
  // ENTITY_GROUP: ModuleIcons?.[ModuleKeywords.EntityGroup],
  // ENTITY_LEVEL: ModuleIcons?.[ModuleKeywords.EntityLevels],

  CONTROL_OBJECTIVES: ModuleIcons?.[ModuleKeywords.ControlGroup],
  CONTROL_CATEGORY: ModuleIcons?.[ModuleKeywords.ControlCategory],
  Category: PrimeIcons.CIRCLE,
  Subcategory: PrimeIcons.CIRCLE,
  Clause: PrimeIcons.CIRCLE,
  None: PrimeIcons.CIRCLE,

  REVIEW: PrimeIcons.CIRCLE,
  WAITING_APPROVE: PrimeIcons.CIRCLE,
  APPROVED: PrimeIcons.CHECK,
  APPROVE: PrimeIcons.CHECK,
  SUBMITTED: PrimeIcons.CIRCLE,
  RETIRED: PrimeIcons.CIRCLE,
  EVENTS: ModuleIcons?.[ModuleKeywords.Event],
  EVENT: ModuleIcons?.[ModuleKeywords.Event],
  GEOGRAPHIES: ModuleIcons?.[ModuleKeywords.Geography],
  GEOGRAPHY: ModuleIcons?.[ModuleKeywords.Geography],
  RESPONSIBILITIES: ModuleIcons?.[ModuleKeywords.Responsibility],
  RESPONSIBILITY: ModuleIcons?.[ModuleKeywords.Responsibility],
  ASSETS: ModuleIcons?.[ModuleKeywords.Asset],
  ASSET: ModuleIcons?.[ModuleKeywords.Asset],
  PROCESSES: ModuleIcons?.[ModuleKeywords.Process],
  PROCESS: ModuleIcons?.[ModuleKeywords.Process],
  OBJECTIVES: ModuleIcons?.[ModuleKeywords.Objective],
  OBJECTIVE: ModuleIcons?.[ModuleKeywords.Objective],
  assets: ModuleIcons?.[ModuleKeywords.Asset],
  events: ModuleIcons?.[ModuleKeywords.Event],
  geographies: ModuleIcons?.[ModuleKeywords.Geography],
  objectives: ModuleIcons?.[ModuleKeywords.Objective],
  processes: ModuleIcons?.[ModuleKeywords.Process],
  responsibilities: ModuleIcons?.[ModuleKeywords.Responsibility],
  LOWEST: PrimeIcons.CIRCLE,
  LOW: PrimeIcons.CIRCLE,
  NORMAL: PrimeIcons.CIRCLE,
  HIGH: PrimeIcons.CIRCLE,
  HIGHEST: PrimeIcons.CIRCLE,
  CRITICAL: PrimeIcons.CIRCLE,
  MODERATE: PrimeIcons.CIRCLE,
  INFO: PrimeIcons.CIRCLE,

  TODO: PrimeIcons.CIRCLE,
  IN_PROGRESS: PrimeIcons.CIRCLE,
  DONE: PrimeIcons.CIRCLE,
  CITATION_TYPE: ModuleIcons?.[ModuleKeywords.CitationType],
  CITATION: ModuleIcons?.[ModuleKeywords.Citation],
  // AUTHORITY_DOCUMENT: ModuleIcons?.[ModuleKeywords.AuthorityDocument],
  EXEMPTION: ModuleIcons?.[ModuleKeywords.Exemption],
  ACKNOWLEDGMENT_REQUEST: ModuleIcons?.[ModuleKeywords.AcknowledgmentRequest],
  ACKNOWLEDGMENT: ModuleIcons?.[ModuleKeywords.Acknowledgment],
  TASK: ModuleIcons?.[ModuleKeywords.Task],
  RISK_METHODOLOGY: ModuleIcons?.[ModuleKeywords.RiskMethodology],
  GLOBAL_VALUE_LIST: ModuleIcons?.[ModuleKeywords.GlobalValueList],
  MCQ: PrimeIcons.CIRCLE,
  BOOLEAN: PrimeIcons.CHECK_SQUARE,
  STRING: PrimeIcons.BARS,
  NUMBER: 'fas fa-list-ol',
  LONG_TEXT: PrimeIcons.ALIGN_JUSTIFY,
  INTEGER: 'fas fa-list-ol',
  DOUBLE: 'fas fa-list-ol',
  ATTACHMENT: PrimeIcons.PAPERCLIP,
  IP: PrimeIcons.MAP_MARKER,
  IP6: PrimeIcons.ELLIPSIS_H,
  MAC: 'pi pi-microchip',
  IPV4_MASK: PrimeIcons.EYE_SLASH,
  DATE: PrimeIcons.CALENDAR,
  DATETIME: 'pi pi-calendar-clock',
  MULTISELECT: 'pi pi-list-check',
  RELATION: PrimeIcons.LINK,
  RATING: PrimeIcons.STAR,
  SURVEY: PrimeIcons.CIRCLE,
  SINGLE_SELECTION: PrimeIcons.LIST,
  GROUP_VALUE_LIST: PrimeIcons.LIST,
  date: PrimeIcons.CALENDAR_PLUS,
  month: PrimeIcons.CALENDAR_CLOCK,
  year: PrimeIcons.CALENDAR,
  single: PrimeIcons.MINUS,
  multiple: PrimeIcons.LIST,
  range: PrimeIcons.BARS,
  DELETED: PrimeIcons.TRASH,
  LOCKED: PrimeIcons.LOCK,
  LOCKED_EDIT: PrimeIcons.LOCK,
  IN_ACTIVE: PrimeIcons.INBOX,
  COLLAPSE: PrimeIcons.CIRCLE,
  EXPAND: PrimeIcons.CIRCLE,
  HIDE: PrimeIcons.CIRCLE,

  TRAIL_EVENTS: PrimeIcons.CIRCLE,
  TRAIL_UPDATED: PrimeIcons.CIRCLE,
  TRAIL_ACTIVE: PrimeIcons.CIRCLE,
  TRAIL_LOCKED_FOR_EDIT: PrimeIcons.CIRCLE,
  TRAIL_UN_LOCKED_FOR_EDIT: PrimeIcons.CIRCLE,
  TRAIL_LOCKED: PrimeIcons.CIRCLE,
  TRAIL_IN_ACTIVE: PrimeIcons.CIRCLE,
  TRAIL_CREATED: PrimeIcons.CIRCLE,
  TRAIL_COMMENT: PrimeIcons.CIRCLE,

  CLOSED: PrimeIcons.CHECK,
  REJECTED: PrimeIcons.CIRCLE,
  radio: PrimeIcons.CIRCLE,
  dropdown: PrimeIcons.CIRCLE,
  AUTO: PrimeIcons.CIRCLE,
  REFERENCE: PrimeIcons.CIRCLE,
  MANUAL: PrimeIcons.CIRCLE,
  MANUAL_REVERSE: PrimeIcons.CIRCLE,
  LOCKED_ENTITY: PrimeIcons.CIRCLE,
  EXCEPTION: ModuleIcons?.[ModuleKeywords.Exception],
  CHECK_ITEM: PrimeIcons.CIRCLE,
  ISSUE: ModuleIcons?.[ModuleKeywords.Issue],
  ISSUE_TYPE: ModuleIcons?.[ModuleKeywords.IssueType],
  IMPACT_FACTOR_VALUE: PrimeIcons.CIRCLE,
  NOTIFICATION: ModuleIcons?.[ModuleKeywords.Notification],
  'High Risks': PrimeIcons.CIRCLE,
  'Acceptable Risks': PrimeIcons.CIRCLE,
  DIRECT: PrimeIcons.CIRCLE,
  WATCHING: PrimeIcons.CIRCLE,
  'New To-Do': PrimeIcons.CIRCLE,
  'To-Do': PrimeIcons.CIRCLE,
  CREATED: PrimeIcons.CIRCLE,
  UN_LOCKED: PrimeIcons.CIRCLE,
  EXISTING: PrimeIcons.CIRCLE,
  CHANGE_STATE: PrimeIcons.CIRCLE,
  NEW: PrimeIcons.CIRCLE,
  UPDATED: PrimeIcons.CIRCLE,
  COMPLIANT: PrimeIcons.CHECK,
  NON_COMPLIANT: PrimeIcons.TIMES,
  EXCEPTED: PrimeIcons.CIRCLE,
  SUCCESS: PrimeIcons.CHECK,
  ERROR: PrimeIcons.EXCLAMATION_TRIANGLE,
  LOADING: 'pi pi-spin pi-spinner',
  CANCELED: PrimeIcons.CIRCLE,
  END: PrimeIcons.CIRCLE,
  MITIGATE: PrimeIcons.CIRCLE,
  TRANSFER: PrimeIcons.CIRCLE,
  ACCEPT: PrimeIcons.CIRCLE,
  IGNORE: PrimeIcons.CIRCLE,
  AVOID: PrimeIcons.CIRCLE,
  RISK_ASSESSMENT_REQUEST: ModuleIcons?.[ModuleKeywords.RiskAssessmentRequest],
  MY_RISK_ASSESSMENT_REQUEST: ModuleIcons?.[ModuleKeywords.MyRiskAssessmentRequest],
  OTHERS: PrimeIcons.CIRCLE,
  IMAGE: PrimeIcons.IMAGE,
  SOURCE_TARGET: PrimeIcons.ARROW_RIGHT,
  TARGET_SOURCE: PrimeIcons.ARROW_LEFT,
  BOTH: PrimeIcons.ARROW_RIGHT_ARROW_LEFT,
  NONE: PrimeIcons.TIMES,
  RELATION_TYPE: ModuleIcons?.[ModuleKeywords.RelationType],
  ORGANIZATIONS: ModuleIcons?.[ModuleKeywords.Organization],
  ORGANIZATION: ModuleIcons?.[ModuleKeywords.Organization],
  TEMPLATE: PrimeIcons.CIRCLE,
  INSTANCE: PrimeIcons.CIRCLE,
  USER: ModuleIcons.USER,
  GROUP: ModuleIcons.GROUP,
  ROLE: ModuleIcons.ROLE,
  EXPORT_PACKAGE: 'fas fa-boxes-packing',
  IMPORT_PACKAGE: 'fas fa-boxes-packing',
  SETTING: PrimeIcons.COG,
  COMMENT: PrimeIcons.COMMENTS,
  RELATION_TYPE_ITEMS: PrimeIcons.CIRCLE,
  COMPLIANCE_REPORT_RESULT: ModuleIcons?.[ModuleKeywords.ComplianceReport],
  COMPLIANCE_REPORT: ModuleIcons?.[ModuleKeywords.ComplianceReportResult],
  AUDIT_TRAIL: PrimeIcons.CIRCLE,
  CITATION_COMPLIANCE_REPORT_RESULT: ModuleIcons?.[ModuleKeywords.ComplianceReport],
  CITATION_COMPLIANCE_REPORT: ModuleIcons?.[ModuleKeywords.ComplianceReportResult],
  STATIC_VALUE: PrimeIcons.CIRCLE,
  EMAIL_TEMPLATE: ModuleIcons?.[ModuleKeywords.EmailTemplate],
  TASK_TEMPLATE: ModuleIcons?.[ModuleKeywords.TaskTemplate],
  POLICY_BODY: ModuleIcons?.[ModuleKeywords.PolicyBody],
  POLICY_BODY_SECTION: ModuleIcons?.[ModuleKeywords.PolicyBodySection],
  ADD_TASK: PrimeIcons.PLUS_CIRCLE,
  ACCESS: PrimeIcons.USER_PLUS,
  TASK_ADDED: PrimeIcons.CIRCLE,
  TASK_CLOSED: PrimeIcons.CIRCLE,
  COMMENTED: PrimeIcons.COMMENT,
  ACCESS_CHANGED: PrimeIcons.CIRCLE,
  WORKFLOW_STATUS_CHANGED: PrimeIcons.CIRCLE,
  STATUS_CHANGED: PrimeIcons.CIRCLE,
  ACCEPTABLE: PrimeIcons.CHECK_CIRCLE,
  NOT_ACCEPTABLE: PrimeIcons.TIMES_CIRCLE,
  JOINED: PrimeIcons.CIRCLE,
  FORCED: PrimeIcons.CIRCLE,
  DEFAULT_DIRECT: PrimeIcons.CIRCLE,
  DEFAULT_FIELDS_BASED: PrimeIcons.CIRCLE,
  DEFAULT_INHERITED: PrimeIcons.CIRCLE,
  DEFAULT_OWNER: PrimeIcons.CIRCLE,
  ORIGIN: PrimeIcons.CIRCLE,
  FIELD: ModuleIcons?.[ModuleKeywords.FieldTemplate],
  CLOSED_INCOMPLETED: PrimeIcons.CIRCLE,
  CLOSED_COMPLETED: PrimeIcons.CHECK_SQUARE,
  FIELD_WORK: PrimeIcons.CIRCLE,
  POPULATE: PrimeIcons.CIRCLE,
  ARCHIVED: PrimeIcons.INBOX,
  DETAILS_REQUESTED: PrimeIcons.USER_EDIT,
  AUDITED: PrimeIcons.CHECK_CIRCLE,
  PENDING: PrimeIcons.STOPWATCH,
  PENDING_APPROVAL: PrimeIcons.STOPWATCH,
  CLOSED_INCOMPLETE: PrimeIcons.TIMES_CIRCLE,
  GENERAL: PrimeIcons.CIRCLE,
  FILTER: PrimeIcons.FILTER,
  DASHBOARD_LAYOUT: PrimeIcons.TABLE,
  CRITERIA_FILTER: PrimeIcons.FILTER_SLASH,
  FIELD_SET: PrimeIcons.LIST,
  FORM_COMPONENT: PrimeIcons.TABLE,
  RELATION_TYPE_ITEM: PrimeIcons.ARROW_RIGHT_ARROW_LEFT,
  DOCUMENT: PrimeIcons.FILE,
  DOCUMENT_VERSION: PrimeIcons.FILE_EDIT,
  BUCKET: PrimeIcons.CLOUD,
  EXPORT_GLOBAL_PACKAGE: PrimeIcons.FILE_EXPORT,
  IMPORT_GLOBAL_PACKAGE: PrimeIcons.FILE_IMPORT,
  VIEW: PrimeIcons.FOLDER,
  RISK_VULNERABILITY: PrimeIcons.CIRCLE,
  RISK_THREAT: PrimeIcons.CIRCLE,
  REPORT: 'pi pi-list-check',
  FIELD_TEMPLATE: ModuleIcons?.[ModuleKeywords.FieldTemplate],
  AUDIT_CASE: ModuleIcons[ModuleKeywords.AuditCase],
  AUDIT_PROGRAM: ModuleIcons[ModuleKeywords.AuditProgram],
  AUDIT_PROGRAM_TYPE: ModuleIcons[ModuleKeywords.AuditProgramType],
  AUDIT_CASE_RESULT: ModuleIcons[ModuleKeywords.AuditCaseResult],
  AUDIT_PROJECT: ModuleIcons[ModuleKeywords.AuditProject],
  AUDIT_UNIVERSE: ModuleIcons[ModuleKeywords.AuditUniverse],
  AUDIT_CASE_TEMPLATE: ModuleIcons[ModuleKeywords.AuditCaseTemplate],
  AUDIT_PROGRAM_REPORT: ModuleIcons[ModuleKeywords.AuditProgramReport],
  AUDIT_PROJECT_REPORT: ModuleIcons[ModuleKeywords.AuditProjectReport],
  AUDIT_REQUEST: ModuleIcons[ModuleKeywords.AuditRequest],
  SUBSCRIPTION: PrimeIcons.CIRCLE,
  ENDED: PrimeIcons.CHECK,
  MANUAL_SELECTION: PrimeIcons.CHECK,
  POLICY_SECTION: ModuleIcons?.[ModuleKeywords.PolicyBodySection],
  and: 'rotate-180 pi pi-share-alt',
  or: PrimeIcons.SHARE_ALT,
  AND: 'rotate-180 pi pi-share-alt',
  OR: PrimeIcons.SHARE_ALT,
  TABLE: PrimeIcons.TABLE,
  STATIC: SFIcons.Branding,
  VERTICAL_BAR: PrimeIcons.CHART_BAR,
  HORIZONTAL_BAR: 'far fa-chart-bar',
  STACKED_BAR: PrimeIcons.CHART_BAR,
  PIE: PrimeIcons.CHART_PIE,
  ROSE: PrimeIcons.CHART_PIE,
  DOUGHNUT: PrimeIcons.CIRCLE,
  LINE: PrimeIcons.CHART_LINE,
  POLAR_AREA: 'fas fa-chart-gantt',
  RADAR: 'fas fa-chart-gantt',
  TREE_MAP: 'pi pi-objects-column',
  CHART: PrimeIcons.CHART_PIE,
  DATA: PrimeIcons.ALIGN_JUSTIFY,
  CHART_AND_DATA: 'pi pi-objects-column',
  STANDARD: PrimeIcons.BARS,
  LOGARITHMIC: PrimeIcons.CHART_LINE,
  RECORD: PrimeIcons.BARS,
  RESOLVED: PrimeIcons.CHECK,
  UNRESOLVED: PrimeIcons.TIMES,
  PUBLISHED: 'fas fa-check-double',
  DATA_COUNT: PrimeIcons.STOPWATCH,
  CUSTOM: PrimeIcons.OBJECTS_COLUMN,
  STATISTICS: PrimeIcons.CHART_LINE,
  TIMELINE: 'pi pi-calendar-clock',
  QUESTIONNAIRE_REPORT: SFIcons.QuestionnairesAnswers,
  LINKS: PrimeIcons.LINK,
  RELATION_CHART_LINK: PrimeIcons.SHARE_ALT,
  ORG_CHART_LINK: PrimeIcons.SITEMAP,
  TREE_VIEW: 'fa-folder-tree fas',
  PAGE_BREAK: PrimeIcons.MINUS,
  DASHBOARD: PrimeIcons.TH_LARGE,
  TAB: PrimeIcons.STOP,
  SUN_BURST: PrimeIcons.SUN,
  SCATTERED: PrimeIcons.CHART_SCATTER,
  BUBBLE: PrimeIcons.CIRCLE,
  TIME_SCALE: PrimeIcons.CLOCK,
  BAR_RACE: 'far fa-chart-bar',
  SEMI_CIRCLE_DOUGHNUT: PrimeIcons.CHART_PIE,
  FUNNEL: PrimeIcons.CARET_DOWN,
  PYRAMID: PrimeIcons.CARET_UP,
  HEATMAP: PrimeIcons.CHART_SCATTER,
  SANKEY: PrimeIcons.BARS,
  NOT_DEFINED: PrimeIcons.CIRCLE,
  UP: PrimeIcons.ARROW_UP,
  DOWN: PrimeIcons.ARROW_DOWN,
  LEFT: PrimeIcons.ARROW_LEFT,
  RIGHT: PrimeIcons.ARROW_RIGHT,
  RISK_HEATMAP: PrimeIcons.TABLE,
  NOTIFICATIONS: PrimeIcons.BELL,
  SYSTEM_TIMELINE: PrimeIcons.ALIGN_JUSTIFY,
  WIDGET_TABLE: PrimeIcons.TABLE,
  WIDGET_CHART: PrimeIcons.CHART_PIE,
  WIDGET_RECORD: PrimeIcons.LIST,
  WIDGET_DATA_COUNT: PrimeIcons.STOPWATCH,
  WIDGET_STATIC: SFIcons.Branding,
  WIDGET_CUSTOM: PrimeIcons.OBJECTS_COLUMN,
  WIDGET_STATISTICS: PrimeIcons.CHART_LINE,
  WIDGET_TIMELINE: 'pi pi-calendar-clock',
  WIDGET_QUESTIONNAIRE_REPORT: SFIcons.QuestionnairesAnswers,
  WIDGET_LINKS: PrimeIcons.LINK,
  WIDGET_RELATION_CHART_LINK: PrimeIcons.SHARE_ALT,
  WIDGET_ORG_CHART_LINK: PrimeIcons.SITEMAP,
  WIDGET_TREE_VIEW: 'fa-folder-tree fas',
  WIDGET_PAGE_BREAK: PrimeIcons.MINUS,
  WIDGET_COMPLIANCE_REPORT: ModuleIcons?.[ModuleKeywords.ComplianceReportResult],
  CURVED_LOGARITHMIC: PrimeIcons.REPLY,
  POLAR_LOGARITHMIC: PrimeIcons.PAUSE_CIRCLE,
  STACKED: PrimeIcons.OBJECTS_COLUMN,
  DRILL_DOWN: PrimeIcons.FORWARD,
  AREA: PrimeIcons.CIRCLE_FILL,
  CURVED: PrimeIcons.REFRESH,
  GROUPS: PrimeIcons.USERS,
  WHEEL: PrimeIcons.SPINNER_DOTTED,
  POLAR: PrimeIcons.CHEVRON_CIRCLE_UP,
  ANCESTOR_EMPHASIS: PrimeIcons.ANGLE_DOUBLE_LEFT,
  ADJACENT_EMPHASIS: PrimeIcons.ARROW_RIGHT_ARROW_LEFT,
  FULL_STACKED: PrimeIcons.BARCODE,
  GROUP_BY_DAY: PrimeIcons.CIRCLE,
  GROUP_BY_WEEK: PrimeIcons.CIRCLE,
  GROUP_BY_MONTH: PrimeIcons.CIRCLE,
  GROUP_BY_YEAR: PrimeIcons.CIRCLE,
  GROUP_BY_HOUR: PrimeIcons.CIRCLE,
  GROUP_BY_MINUTE: PrimeIcons.CIRCLE,
  GROUP_BY_SECOND: PrimeIcons.CIRCLE,
  GROUP_BY_DAY_OF_YEAR: PrimeIcons.CIRCLE,
  GROUP_BY_WEEK_OF_YEAR: PrimeIcons.CIRCLE,
  GROUP_BY_DAY_OF_WEEK: PrimeIcons.CIRCLE,
  GROUP_BY_DAY_NAME: PrimeIcons.CIRCLE,
  GROUP_BY_QUARTER: PrimeIcons.CIRCLE,
  GROUP_BY_ISO_WEEK_YEAR: PrimeIcons.CIRCLE,
  GROUP_BY_ISO_DAY_OF_WEEK: PrimeIcons.CIRCLE,
  TAG: PrimeIcons.TAG,
  REASSESSMENT: PrimeIcons.REPLAY,
  RISK_ITEM_REASSESSMENT: PrimeIcons.REPLAY,
  REASSESS: PrimeIcons.REPLAY,
  RESPONSE: PrimeIcons.USER_EDIT,
  COLOR: PrimeIcons.PALETTE,
  TIME: PrimeIcons.STOPWATCH,
  normal: PrimeIcons.CIRCLE_FILL,
  scored: PrimeIcons.SORT_NUMERIC_UP_ALT,
  decimal: PrimeIcons.SORT_NUMERIC_UP,
  currency: PrimeIcons.MONEY_BILL,
  NOT_SCORED: PrimeIcons.CIRCLE_FILL,
  SCORED: PrimeIcons.SORT_NUMERIC_UP_ALT,
  // ['_'+DataTypeEnum.Boolean] : PrimeIcons.CIRCLE,
  // ['_'+DataTypeEnum.Text] : PrimeIcons.BARS,
  // ['_'+DataTypeEnum.Chip] : PrimeIcons.CIRCLE,
  // ['_'+DataTypeEnum.Color] : PrimeIcons.PALETTE,
  // ['_'+DataTypeEnum.Slider] : PrimeIcons.SLIDERS_H,
  // ['_'+DataTypeEnum.LongText] : PrimeIcons.ALIGN_JUSTIFY,
  // ['_'+DataTypeEnum.LongWithHoverText] : PrimeIcons.ALIGN_JUSTIFY,
  // ['_'+DataTypeEnum.SimpleText] : PrimeIcons.ALIGN_JUSTIFY,
  // ['_'+DataTypeEnum.Number] : 'fas fa-list-ol',
  // ['_'+DataTypeEnum.Dropdown] : 'pi pi-list-check',
  // ['_'+DataTypeEnum.Date] : PrimeIcons.CALENDAR,
  // ['_'+DataTypeEnum.DateLong] : PrimeIcons.CALENDAR_PLUS,
  // ['_'+DataTypeEnum.DateShort] : PrimeIcons.CALENDAR_MINUS,
  // ['_'+DataTypeEnum.DataType] : PrimeIcons.CIRCLE,
  // ['_'+DataTypeEnum.Time] : PrimeIcons.CLOCK,
  // ['_'+DataTypeEnum.Container] : 'pi pi-expand',
  // ['_'+DataTypeEnum.Blob] : PrimeIcons.FILE,
  // ['_'+DataTypeEnum.toggle] : PrimeIcons.CHECK_CIRCLE,
  // ['_'+DataTypeEnum.Link] : PrimeIcons.EXTERNAL_LINK,
  // ['_'+DataTypeEnum.User] : PrimeIcons.USER,
  // ['_'+DataTypeEnum.Group] : PrimeIcons.USERS,
  // ['_'+DataTypeEnum.Badge] : PrimeIcons.BARS,
  // ['_'+DataTypeEnum.UserListView] : PrimeIcons.ALIGN_JUSTIFY,
  // ['_'+DataTypeEnum.RadioGroup] : PrimeIcons.LIST,
  // ['_'+DataTypeEnum.TranslatedText] : PrimeIcons.ALIGN_JUSTIFY,
  // ['_'+DataTypeEnum.ResponsibilityListView] : PrimeIcons.ALIGN_JUSTIFY,
  // ['_'+DataTypeEnum.ResponsibilityWithAllOneMode] : PrimeIcons.ALIGN_JUSTIFY,
  // ['_'+DataTypeEnum.Icon] : PrimeIcons.HEART,
  // ['_'+DataTypeEnum.ProgressBar] : PrimeIcons.MINUS,
  // ['_'+DataTypeEnum.ProgressBarStacked] : PrimeIcons.ELLIPSIS_H,
  // ['_'+DataTypeEnum.MeterGroup] : PrimeIcons.ELLIPSIS_H,
  // ['_'+DataTypeEnum.DynamicComponent] : PrimeIcons.CIRCLE,
  // ['_'+DataTypeEnum.CountDown] : PrimeIcons.STOPWATCH,
  // ['_'+DataTypeEnum.CodeWithStatus] : PrimeIcons.LINK,
  // ['_'+DataTypeEnum.CodeLink] : PrimeIcons.LINK,
  // ['_'+DataTypeEnum.AvatarInfoList] : PrimeIcons.USERS,
  // ['_'+DataTypeEnum.Knob] : 'pi pi-gauge',
  // ['_'+DataTypeEnum.RiskMeter] : 'pi pi-gauge',
  // ['_'+DataTypeEnum.Image] : PrimeIcons.IMAGE,
  // ['_'+DataTypeEnum.QuestionnaireField] : PrimeIcons.LIST,
  // ['_'+DataTypeEnum.RiskItemCell] : PrimeIcons.BARS,
  // ['_'+DataTypeEnum.RelationTypeCell] : PrimeIcons.BARS,
  // ['_'+DataTypeEnum.RelationType] : PrimeIcons.PAPERCLIP,
  // ['_'+DataTypeEnum.AuditTrailAcl] : PrimeIcons.HISTORY,
  // ['_'+DataTypeEnum.AuditTrailDynamics] : PrimeIcons.HISTORY,
  // ['_'+DataTypeEnum.HumanizedText] : PrimeIcons.BARS,
  // ['_'+DataTypeEnum.PrincipleList] : PrimeIcons.USERS,
  // ['_'+DataTypeEnum.relationNiceMode] : PrimeIcons.PAPERCLIP,
  // ['_'+DataTypeEnum.gvlItem] : PrimeIcons.CIRCLE,
  // ['_'+DataTypeEnum.gvlItemList] : PrimeIcons.CIRCLE,
  // ['_'+DataTypeEnum.TimeView] : 'pi pi-calendar-clock',
  // ['_'+DataTypeEnum.PlannedProgressBar] : PrimeIcons.CHART_LINE,
  // ['_'+DataTypeEnum.CurrencyView] : PrimeIcons.DOLLAR,
  // ['_'+DataTypeEnum.SunEditorView] : 'pi pi-pen-to-square',
};
export enum DataTypeEnum {
  Boolean = 0,
  Text,
  Chip,
  Color,
  Slider,
  LongText,
  LongWithHoverText,
  SimpleText,
  Number,
  Dropdown,
  Date,
  DateLong,
  DateShort,
  DataType,
  Time,
  Container,
  Blob,
  toggle,
  Link,
  User,
  Group,
  Badge,
  UserListView,
  RadioGroup,
  TranslatedText,
  ResponsibilityListView,
  ResponsibilityWithAllOneMode,
  Icon,
  ProgressBar,
  ProgressBarStacked,
  MeterGroup,
  DynamicComponent,
  CountDown,
  CodeWithStatus,
  CodeLink,
  AvatarInfoList,
  Knob,
  RiskMeter,
  Image,
  QuestionnaireField,
  RiskItemCell,
  RelationTypeCell,
  RelationType,
  AuditTrailAcl,
  AuditTrailDynamics,
  HumanizedText,
  PrincipleList,
  relationNiceMode,
  gvlItem,
  gvlItemList,
  TimeView,
  PlannedProgressBar,
  CurrencyView,
  SunEditorView,
  PaletteView,
  StateMachineLogView,
  TagSearch,
  TabsListView,
  Attachment,
}
// @TODO: Important add missing field types
export const DataTypeToFieldTypeMapper = {
  [DataTypeEnum.Boolean]: FieldDto.TypeEnum.Boolean,
  [DataTypeEnum.Text]: FieldDto.TypeEnum.String,
  [DataTypeEnum.Chip]: FieldDto.TypeEnum.Boolean,
  [DataTypeEnum.Color]: FieldDto.TypeEnum.Color,
  [DataTypeEnum.Slider]: FieldDto.TypeEnum.Integer,
  [DataTypeEnum.LongText]: FieldDto.TypeEnum.LongText,
  [DataTypeEnum.LongWithHoverText]: FieldDto.TypeEnum.LongText,
  [DataTypeEnum.SimpleText]: FieldDto.TypeEnum.String,
  [DataTypeEnum.Number]: FieldDto.TypeEnum.Number,
  [DataTypeEnum.Dropdown]: FieldDto.TypeEnum.Multiselect,
  [DataTypeEnum.Date]: FieldDto.TypeEnum.Date,
  [DataTypeEnum.DateLong]: FieldDto.TypeEnum.Datetime,
  [DataTypeEnum.DateShort]: FieldDto.TypeEnum.Date,
  [DataTypeEnum.DataType]: FieldDto.TypeEnum.String,
  [DataTypeEnum.Time]: FieldDto.TypeEnum.Time,
  [DataTypeEnum.Container]: FieldDto.TypeEnum.String,
  [DataTypeEnum.Blob]: FieldDto.TypeEnum.Attachment,
  [DataTypeEnum.toggle]: FieldDto.TypeEnum.Boolean,
  [DataTypeEnum.Link]: FieldDto.TypeEnum.Relation,
  [DataTypeEnum.User]: FieldDto.TypeEnum.String,
  [DataTypeEnum.Group]: FieldDto.TypeEnum.String,
  [DataTypeEnum.Badge]: FieldDto.TypeEnum.SingleSelection,
  [DataTypeEnum.UserListView]: FieldDto.TypeEnum.String,
  [DataTypeEnum.RadioGroup]: FieldDto.TypeEnum.SingleSelection,
  [DataTypeEnum.TranslatedText]: FieldDto.TypeEnum.String,
  [DataTypeEnum.ResponsibilityListView]: FieldDto.TypeEnum.String,
  [DataTypeEnum.ResponsibilityWithAllOneMode]: FieldDto.TypeEnum.String,
  [DataTypeEnum.Icon]: FieldDto.TypeEnum.String,
  [DataTypeEnum.ProgressBar]: FieldDto.TypeEnum.Number,
  [DataTypeEnum.ProgressBarStacked]: FieldDto.TypeEnum.Multiselect,
  [DataTypeEnum.MeterGroup]: FieldDto.TypeEnum.Multiselect,
  [DataTypeEnum.DynamicComponent]: FieldDto.TypeEnum.GroupValueList,
  [DataTypeEnum.CountDown]: FieldDto.TypeEnum.Datetime,
  [DataTypeEnum.CodeWithStatus]: FieldDto.TypeEnum.Relation,
  [DataTypeEnum.CodeLink]: FieldDto.TypeEnum.Relation,
  [DataTypeEnum.AvatarInfoList]: FieldDto.TypeEnum.String,
  [DataTypeEnum.Knob]: FieldDto.TypeEnum.Integer,
  [DataTypeEnum.RiskMeter]: FieldDto.TypeEnum.Number,
  [DataTypeEnum.Image]: FieldDto.TypeEnum.Image,
  [DataTypeEnum.QuestionnaireField]: FieldDto.TypeEnum.GroupValueList,
  [DataTypeEnum.RiskItemCell]: FieldDto.TypeEnum.String,
  [DataTypeEnum.RelationTypeCell]: FieldDto.TypeEnum.Relation,
  [DataTypeEnum.RelationType]: FieldDto.TypeEnum.Relation,
  [DataTypeEnum.AuditTrailAcl]: FieldDto.TypeEnum.String,
  [DataTypeEnum.AuditTrailDynamics]: FieldDto.TypeEnum.String,
  [DataTypeEnum.HumanizedText]: FieldDto.TypeEnum.String,
  [DataTypeEnum.PrincipleList]: FieldDto.TypeEnum.String,
  [DataTypeEnum.relationNiceMode]: FieldDto.TypeEnum.String,
  [DataTypeEnum.gvlItem]: FieldDto.TypeEnum.GroupValueList,
  [DataTypeEnum.gvlItemList]: FieldDto.TypeEnum.GroupValueList,
  [DataTypeEnum.TimeView]: FieldDto.TypeEnum.Time,
  [DataTypeEnum.PlannedProgressBar]: FieldDto.TypeEnum.Number,
  [DataTypeEnum.CurrencyView]: FieldDto.TypeEnum.Number,
  [DataTypeEnum.SunEditorView]: FieldDto.TypeEnum.LongText,
  [DataTypeEnum.TagSearch]: 'TAG', // should add
};

export enum BadgeType {
  custom = 'badge-custom-',
  solid = 'badge-solid-',
  outline = 'badge-outline-',
  circle = 'badge-circle-',
}
export type FilterTypeEnum = 'text' | 'date' | 'numeric' | 'boolean' | 'dropdown' | 'multiDropdown' | 'slider' | 'enum';

export const FilterTypeEnum = {
  text: 'text' as FilterTypeEnum,
  date: 'date' as FilterTypeEnum,
  numeric: 'numeric' as FilterTypeEnum,
  boolean: 'boolean' as FilterTypeEnum,
  dropdown: 'dropdown' as FilterTypeEnum,
  multiDropdown: 'multiDropdown' as FilterTypeEnum,
  slider: 'slider' as FilterTypeEnum,
  enum: 'enum' as FilterTypeEnum,
};
export enum ActionTypesEnum {
  Add = 0,
  update = 1,
  Delete = 2,
  Activate = 3,
  Deactivate = 4,
  customAction = 5,
  switch = 6,
}
// export enum IViewMode {
//     view = 'VIEW',
//     edit = 'EDIT',
// }
export type IViewMode = 'view' | 'edit' | 'create' | 'list' | 'tabs';

export const IViewMode = {
  view: 'view' as IViewMode,
  edit: 'edit' as IViewMode,
  create: 'create' as IViewMode,
  list: 'list' as IViewMode,
  tabs: 'tabs' as IViewMode,
};
export const IDynamicFieldDataTypeMapper = {
  INTEGER: DataTypeEnum.Number,
  BOOLEAN: DataTypeEnum.Boolean,
  STRING: DataTypeEnum.Text,
  NUMBER: DataTypeEnum.Number,
  LONG_TEXT: DataTypeEnum.LongWithHoverText,
  MCQ: DataTypeEnum.RadioGroup,
  ATTACHMENT: DataTypeEnum.Link,
  IP: DataTypeEnum.Text,
  IP6: DataTypeEnum.Text,
  MAC: DataTypeEnum.Text,
  IPV4_MASK: DataTypeEnum.Text,
  DATE: DataTypeEnum.DateShort,
  DATETIME: DataTypeEnum.DateLong,
  MULTISELECT: DataTypeEnum.Text,
  RELATION: DataTypeEnum.Text,
  RATING: DataTypeEnum.Number,
};
export const IDynamicFieldFilterTypeMapper = {
  INTEGER: FilterTypeEnum.numeric,
  BOOLEAN: FilterTypeEnum.boolean,
  STRING: FilterTypeEnum.text,
  NUMBER: FilterTypeEnum.numeric,
  LONG_TEXT: FilterTypeEnum.text,
  MCQ: FilterTypeEnum.text,
  ATTACHMENT: FilterTypeEnum.text,
  IP: FilterTypeEnum.text,
  IP6: FilterTypeEnum.text,
  MAC: FilterTypeEnum.text,
  IPV4_MASK: FilterTypeEnum.text,
  DATE: FilterTypeEnum.date,
  DATETIME: FilterTypeEnum.date,
  MULTISELECT: FilterTypeEnum.text,
  RELATION: FilterTypeEnum.text,
  RATING: FilterTypeEnum.numeric,
};
export enum FileViewerType {
  PDF = 'PDF',
  IMAGE = 'IMAGE',
  TEXT = 'TEXT',
  VIDEO = 'VIDEO',
  AUDIO = 'AUDIO',
  OTHER = 'OTHER',
}
// Assume SearchFilterDto.TargetTypeEnum is defined somewhere
enum AdditionalTargetTypeEnum { }
// Add more additional enums here
// 'EntityGroup'='ENTITY_GROUP',

// Values to exclude from SearchFilterDto.TargetTypeEnum
const valuesToExcludeFromAcknowledgment: Array<keyof typeof SearchFilterDto.TargetTypeEnum> = [
  'CriteriaFilter',
  'FieldSet',
  'FormComponent',
  'Subscription',
  'Field',
  'RiskMethodology',
  'EntityGroup',
  'ExportPackage',
  'ImportPackage',
  'Setting',
  'Comment',
  'Relation',
  'RelationTypeItem',
  'AuditTrail',
  'StaticValue',
  // 'Filter',
  //'Client',
];

export enum DashletTypeEnum {
  Empty,
  Table,
  Doughnut,
  Bar,
  Text,
  RiskMatrix,
  Notification,
  Timeline,
  Pie,
  Radar,
  PolarArea,
  Line,
}

export enum DashboardTypeEnum {
  Main,
  Acknowledgment,
  ActivityFeed,
  Authority,
  DataStructure,
  Entity,
  Issues,
  OrganizationFramework,
  Questions,
  Relation,
  Risk,
  Users,
  Policies,
  Controls,
  AllPages,
  RiskMatrix,
  Timeline,
}

export enum AggregateType {
  Count = 'count',
}

// Create an object that excludes the specified values from SearchFilterDto.TargetTypeEnum
const TargetTypeEnum = {
  ...SearchFilterDto.TargetTypeEnum,
};

for (const value of valuesToExcludeFromAcknowledgment) {
  delete TargetTypeEnum[value];
}

// Merge with AdditionalTargetTypeEnum
Object.assign(TargetTypeEnum, AdditionalTargetTypeEnum);

// Define the final type
type AllowedTargetTypeEnum = (typeof TargetTypeEnum)[keyof typeof TargetTypeEnum] | AdditionalTargetTypeEnum;

export type TargetTypeEnum = AllowedTargetTypeEnum;

export { TargetTypeEnum };

export type RecordStatusEnumFilterWithoutDeleted = 'LOCKED' | 'ACTIVE' | 'IN_ACTIVE';
export const RecordStatusEnumFilterWithoutDeleted = {
  Locked: 'LOCKED' as RecordStatusEnumFilterWithoutDeleted,
  Active: 'ACTIVE' as RecordStatusEnumFilterWithoutDeleted,
  InActive: 'IN_ACTIVE' as RecordStatusEnumFilterWithoutDeleted,
};

////////////////////////////////

const typesWithName: Array<keyof typeof TargetTypeEnum> = [
  'Acknowledgment',
  'QuestionnaireValue',
  'MyQuestionnaireValue',
  'Task',
  'RiskMethodology',
  'Notification',
  'Subscription',
  'Exception',
  // 'Attestation',
];

const TypesWithName = {
  ...TargetTypeEnum,
};

for (const value of typesWithName) {
  delete TypesWithName[value];
}

type AllowedTypesWithName = (typeof TypesWithName)[keyof typeof TargetTypeEnum];

export type TypesWithName = AllowedTypesWithName;

export { TypesWithName };
export enum NumberInputModes {
  decimal = 'decimal',
  currency = 'currency',
}
