import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import { getProp, IColumn, parseColData } from '@shared/classes';
import { StaticValuesCodes, StaticValuesDataService } from 'app/modules/users/services/data/static-values-data.service';
import { saveAs } from 'file-saver';
import { forkJoin } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class ExportDataService {
  constructor(private staticValuesDataService: StaticValuesDataService) {}

  async exportPdf(cols: IColumn[], data: any[], fileName: string = null) {
    const exportColumns = cols.map((col) => ({
      title: col.name,
      dataKey: col.name,
    }));
    //@TODO add the name image (logo) as well not only the icon
    const headerImageObservable = this.staticValuesDataService.getValueByCode(StaticValuesCodes.ICON_LIGHT);
    const footerImageObservable = this.staticValuesDataService.getValueByCode(StaticValuesCodes.ICON_LIGHT);

    try {
      const [headerImage, footerImage] = await forkJoin([headerImageObservable, footerImageObservable]).toPromise();

      import('jspdf').then((jsPDF: any) => {
        import('jspdf-autotable').then((x) => {
          const doc = new jsPDF.default({
            orientation: 'portrait',
            unit: 'mm',
            format: [Math.max(210, cols.length * 26), Math.max(210, cols.length * 30)],
          });

          // Set up the header and footer options
          const headerOptions = {
            beforePageContent: function (data) {
              // Add the header image
              const headerImgWidth = 40; // Set the width of the header image
              const headerImgHeight = 20; // Set the height of the header image
              const headerImgX = (doc.internal.pageSize.width - headerImgWidth) / 2; // Calculate the X position to center the image
              const headerImgY = 10; // Set the Y position of the image at the top
              doc.addImage(headerImage, 'PNG', headerImgX, headerImgY, headerImgWidth, headerImgHeight);

              doc.setTextColor(0, 0, 0); // Set text color to black
              doc.setFontSize(12); // Set font size for the table header
            },
          };

          const footerOptions = {
            afterPageContent: function (data) {
              const pageCount = doc.internal.getNumberOfPages(); // Get the total number of pages
              const footerText = `Page ${data.pageNumber} of ${pageCount}`; // Create footer text

              doc.setTextColor(150, 150, 150); // Set text color to gray
              doc.setFontSize(10); // Set font size for the footer
              doc.text(footerText, data.settings.margin.left, doc.internal.pageSize.height - 10); // Position the footer text

              // Add the footer image
              const footerImgWidth = 40; // Set the width of the footer image
              const footerImgHeight = 20; // Set the height of the footer image
              const footerImgX = (doc.internal.pageSize.width - footerImgWidth) / 2; // Calculate the X position to center the image
              const footerImgY = doc.internal.pageSize.height - 30; // Set the Y position of the image
              doc.addImage(footerImage, 'PNG', footerImgX, footerImgY, footerImgWidth, footerImgHeight);
            },
          };

          // Add the header and footer options to the autoTable method
          doc.autoTable(exportColumns, data, {
            ...headerOptions,
            ...footerOptions,
            startY: 70, // Set the starting Y position for the table below the header image
            margin: { top: 60 }, // Add some margin from the top for the table
          });

          const PDF_EXTENSION = '.pdf';
          doc.save(`${fileName}_export_${formatDate(new Date(), 'yyyy-MM-dd_HH_mm_ss', 'en')}${PDF_EXTENSION}`);
        });
      });
    } catch (error) {
      console.error('Error exporting PDF:', error);
    }
  }

  exportExcel(data: any[], fileName: string = null) {
    import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(data);
      const workbook = {
        Sheets: { data: worksheet },
        SheetNames: ['data'],
      };
      const excelBuffer: any = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array',
      });
      this.saveAsExcelFile(excelBuffer, fileName || '');
    });
  }
  exportCSV(data: any[], fileName: string = null) {
    import('xlsx').then((xlsx) => {
      const CSV_TYPE = 'text/csv;charset=utf-8';
      const CSV_EXTENSION = '.csv';
      const worksheet = xlsx.utils.json_to_sheet(data);
      const csvOutput: string = xlsx.utils.sheet_to_csv(worksheet);
      saveAs(
        new Blob([csvOutput]),
        `${fileName} export_${formatDate(new Date(), 'yyyy-MM-dd HH_mm_ss', 'en')}${CSV_EXTENSION}`
      );
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    // import("file-saver").then(FileSaver => {
    const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    saveAs(data, `${fileName} export_${formatDate(new Date(), 'yyyy-MM-dd HH_mm_ss', 'en')}${EXCEL_EXTENSION}`);
    // })
  }
  exportData(type: 'pdf' | 'xls' | 'csv', data: any[], cols: IColumn[] = [], fileName = null) {
    if (type == 'xls') {
      this.exportExcel(data, fileName);
    } else if (type == 'csv') {
      this.exportCSV(data, fileName);
    } else {
      this.exportPdf(cols, data, fileName);
    }
  }
  parsedExportData(type: 'pdf' | 'xls' | 'csv' = 'pdf', dCols, exportDataItems, fileName = null) {
    let exData: any[] = [];
    exportDataItems.forEach((row) => {
      let value: any = {};
      dCols.map((col) => {
        value[col.name] = parseColData(getProp(row, col.key), col);
      });
      exData.push({ ...value });
    });
    this.exportData(type, exData, dCols, fileName);
  }
}
