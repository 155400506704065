/**
 * Sightflare Service
 * Rest Api for Sightflare Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ScopeControlsRequest { 
    codes?: Array<string>;
    scope?: ScopeControlsRequest.ScopeEnum;
    universe?: string;
}
export namespace ScopeControlsRequest {
    export type ScopeEnum = 'ENTITY' | 'CONTROL_OBJECTIVE' | 'RISK' | 'RISK_PROJECT' | 'AUTHORITY_DOCUMENT' | 'CITATION' | 'POLICY' | 'POLICY_SECTION' | 'MANUAL_SELECTION';
    export const ScopeEnum = {
        Entity: 'ENTITY' as ScopeEnum,
        ControlObjective: 'CONTROL_OBJECTIVE' as ScopeEnum,
        Risk: 'RISK' as ScopeEnum,
        RiskProject: 'RISK_PROJECT' as ScopeEnum,
        AuthorityDocument: 'AUTHORITY_DOCUMENT' as ScopeEnum,
        Citation: 'CITATION' as ScopeEnum,
        Policy: 'POLICY' as ScopeEnum,
        PolicySection: 'POLICY_SECTION' as ScopeEnum,
        ManualSelection: 'MANUAL_SELECTION' as ScopeEnum
    };
}


