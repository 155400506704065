/**
 * Sightflare Service
 * Rest Api for Sightflare Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ApiResponseLanguageEvent { 
    status?: number;
    data?: ApiResponseLanguageEvent.DataEnum;
    error?: boolean;
    errorMessage?: string;
}
export namespace ApiResponseLanguageEvent {
    export type DataEnum = 'TO_DRAFT' | 'TO_PUBLISHED';
    export const DataEnum = {
        Draft: 'TO_DRAFT' as DataEnum,
        Published: 'TO_PUBLISHED' as DataEnum
    };
}


