import { Component, ContentChild, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { DataTypeEnum, DynamicComponentBase, IAction } from '@shared/classes';
// import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
// import { Editor } from 'primeng/editor';
import { KendoEditorDropdownToolbarData } from '../kendo-editor/kendo-editor.component';

@Component({
  selector: 'app-text-editor',
  templateUrl: './text-editor.component.html',
  styleUrls: ['./text-editor.component.scss'],
  providers: [{ provide: DynamicComponentBase, useExisting: TextEditorComponent }],
})
export class TextEditorComponent extends DynamicComponentBase implements OnInit {
  @Input() advanced: boolean = false;
  @Input() hideToolbar: boolean = false;
  @Input() height: any = '240px';
  @Input() marginTop: any = '0px';
  @Input() showOnFocus: boolean = false;
  @Input() showCancelWithOnFocus: boolean = true;
  @Input() showOnFocusLabel: string = '';
  @Input() showLabelInFocusMode: boolean = true;
  @Input() sunEditorMode: boolean = false;
  @Input() showOldEditor: boolean = false;
  @Input() customDropdownData: KendoEditorDropdownToolbarData[] = [];
  @Output() onCustomDropdownOpen: EventEmitter<any> = new EventEmitter();
  isInFocus: boolean = false;
  codeEditorMode: boolean = false;
  dataLongTextType: DataTypeEnum = DataTypeEnum.LongText;
  sunEditorType: DataTypeEnum = DataTypeEnum.LongText;
  cancelFocusAction: IAction = {
    id: 1,
    label: 'Cancel',
    buttonType: 'button',
    command: this.setFocusView.bind(this, false),
    color: 'secondary',
  };
  // public Editor:any = DecoupledEditor;
  // @ViewChild('editor') editor: Editor;
  @ContentChild('toolbarActions') toolbarActions: TemplateRef<any>;
  changeEditorAction: IAction = {
    id: 99,
    label: '',
    icon: 'pi pi-sync',
    iconPos: 'left',
    buttonStyle: 'text',
    color: 'secondary',
    command: this.onChangeEditor.bind(this),
    tooltipText: 'Change Editor',
    tooltipOptions: {
      tooltipLabel: 'Change Editor',
    },
    tooltipPosition: 'top',
  };
  constructor() {
    super();
  }

  ngOnInit(): void {}
  focusEditor() {
    // this.editor?.quill?.focus();
  }
  setInputOptions() {
    this.advanced = this.inputOptions?.textEditorInput?.advanced ?? this.advanced;
    this.height = this.inputOptions?.textEditorInput?.height ?? this.height;
    this.marginTop = this.inputOptions?.textEditorInput?.marginTop ?? this.marginTop;
    this.showOnFocus = this.inputOptions?.textEditorInput?.showOnFocus ?? this.showOnFocus;
    this.showLabelInFocusMode = this.inputOptions?.textEditorInput?.showLabelInFocusMode ?? this.showLabelInFocusMode;
    this.hideToolbar = this.inputOptions?.textEditorInput?.hideToolbar ?? this.hideToolbar;
    this.sunEditorMode = this.inputOptions?.textEditorInput?.sunEditorMode ?? this.sunEditorMode;
    this.customDropdownData = this.inputOptions?.textEditorInput?.customDropdownData ?? this.customDropdownData;
  }
  onFocus(event) {
    this.setFocusView(true);
    setTimeout(() => {
      this.focusEditor();
    }, 10);
  }
  setFocusView(focus: boolean) {
    this.isInFocus = focus;
    if (focus == false) {
      this.control.reset(null);
    }
  }
  onCustomDropdownOpenAction(event) {
    this.onCustomDropdownOpen.emit(event);
  }
  // public onReady( editor: any ): void { //using `any` is a temporary workaround for https://github.com/ckeditor/ckeditor5/issues/13838
  //     const decoupledEditor = editor as DecoupledEditor;
  //     // decoupledEditor.plugins.init(Base64UploadAdapter);
  //     const element = decoupledEditor.ui.getEditableElement()!;
  //     const parent = element.parentElement!;

  //     parent.insertBefore(
  //       decoupledEditor.ui.view.toolbar.element!,
  //       element
  //     );
  //   }
  customFunction() {
    this.codeEditorMode = !this.codeEditorMode;
  }
  onResize(event: any) {
    // Update height when the div is resized
  }
  onChangeEditor() {
    this.sunEditorMode = !this.sunEditorMode;
  }
  get isDevEnvironment() {
    return false;
  }
}
