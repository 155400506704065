import { Injectable } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ComplianceReportDto, ComplianceReportResultDto, DataTypeEnum, IFieldDefinition, getEnumOptions } from '@shared/classes';
import { TargetCodeSelectorComponent } from '@shared/components/selectors/target-code-selector/target-code-selector.component';
import { BasicInputComponent } from '@shared/components/ui/input-fields/basic-input/basic-input.component';
import { SuggestionInputComponent } from '@shared/components/ui/input-fields/suggestion-input/suggestion-input.component';
import { TextEditorComponent } from '@shared/components/ui/input-fields/text-editor/text-editor.component';
import { BaseFieldDefinitionsService } from '@shared/services';

@Injectable({
  providedIn: 'root',
})
export class ComplianceReportMappingService extends BaseFieldDefinitionsService {
  recordFields: IFieldDefinition[] = [
    {
      name: 'Name',
      key: 'name',
      dataType: DataTypeEnum.Text,
      required: true,
      showInTable: true,
      description: 'Record Name',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
      useInBulk: true,
      formField: {
        componentType: BasicInputComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Enter Name',
          control: new FormControl('', Validators.required),
          dataType: DataTypeEnum.Text,
          showLabelInViewMode: false,
        },
      },
    },
    {
      name: 'Frequency',
      key: 'frequency',
      dataType: DataTypeEnum.Badge,
      required: true,
      showInTable: true,
      // permissions: AppPermissions.ReadGroup,
      description: 'Frequency of the report',
      tableDefinition: {
        filter: {
          type: 'numeric',
          display: 'menu',
          matchMode: 'lte',
        },
      },
      useInBulk: true,
      formField: {
        componentType: SuggestionInputComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Ex: 7 days',
          control: new FormControl('', Validators.required),
          dataType: DataTypeEnum.Text,
          showLabelInViewMode: false,
          inputOptions: {
            dropDownInput: {
              optionValue: 'undefined',
              multi: false,
              items: [
                { label: 'Yearly', value: 365 },
                { label: 'Quarterly', value: 90 },
                { label: 'Monthly', value: 30 },
                { label: 'Weekly', value: 7 },
                { label: 'Daily', value: 1 },
              ],
              optionLabel: 'name'
            },
            numberInput: {
              min: 0,
              maxFractionDigits: 0,

            },
          },
        },
      },
    },
    {
      name: 'Compliance Report Type',
      key: 'complianceReportType',
      dataType: DataTypeEnum.Badge,
      required: false,
      showInTable: true,
      // permissions: AppPermissions.ReadGroup,
      description: 'Compliance Report Type (Scope) of the Record',
      tableDefinition: {
        filter: {
          type: 'enum',
          display: 'menu',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          enumClass: ComplianceReportResultDto.ComplianceReportTypeEnum,
        },
      },
      formField: {
        componentType: TargetCodeSelectorComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Select Type',
          control: new FormControl(null),
          inputOptions: {
            codeSelectorInput: {
              targetTypes: [getEnumOptions(ComplianceReportDto.ComplianceReportTypeEnum)],
            },
            dropDownInput: {
              multi: false,
              items: null,
              optionLabel: 'label',
              optionValue: 'value'
            },
          },
          showLabelInViewMode: false,
        },
      },
    },
    // {
    //   name: 'Policy',
    //   key: 'policy',
    //   dataType: DataTypeEnum.CodeLink,
    //   required: true,
    //   showInTable: true,
    //   permissions: AppPermissions.ReadPolicy,
    //   description: 'Policy Linked to the control',
    //   tableDefinition: {
    //     filter: {
    //       type: 'text',
    //       display: 'menu',
    //       matchMode: 'startsWith',
    //       showMatchModes: true,
    //       showAddButton: true,
    //       showOperator: true,
    //     },
    //   },
    // },
    {
      name: 'Description',
      key: 'description',
      dataType: DataTypeEnum.Text,
      required: false,
      showInTable: false,
      // permissions: AppPermissions.ReadGroup,
      description: 'Description of the report',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
      useInBulk: true,
      formField: {
        componentType: TextEditorComponent,
        options: {
          inputOptions: {
            textEditorInput: {
              advanced: true
            }
          },
          label: '',
          name: '',
          control: new FormControl(''),
          dataType: DataTypeEnum.Text,
          showLabelInViewMode: false,
        },
      },
    },
  ];
  constructor() {
    super();
    this.setAllFields();
  }
}
