import { AppInjector } from 'app/app-injector';
import { NgxPermissionsService } from 'ngx-permissions';
import { ModuleKeywords } from './ModuleKeywords';

export enum PermissionActions {
  Create = 'create',
  Update = 'update',
  Delete = 'delete',
  Read = 'read',
}
export const AppPermissions = {
  //@TODONewModule: when you add new module make sure to define these
  // CreateEntity : PermissionActions.Create + ModuleKeywords.Entity,
  // UpdateEntity : PermissionActions.Update + ModuleKeywords.Entity,
  // DeleteEntity : PermissionActions.Delete + ModuleKeywords.Entity,
  //   ReadEntity : PermissionActions.Read   + ModuleKeywords.Entity,

  // CreateEntityGroup : PermissionActions.Create + ModuleKeywords.EntityGroup,
  // UpdateEntityGroup : PermissionActions.Update + ModuleKeywords.EntityGroup,
  // DeleteEntityGroup : PermissionActions.Delete + ModuleKeywords.EntityGroup,
  //   ReadEntityGroup : PermissionActions.Read   + ModuleKeywords.EntityGroup,

  CreateEntityType: PermissionActions.Create + ModuleKeywords.EntityType,
  UpdateEntityType: PermissionActions.Update + ModuleKeywords.EntityType,
  DeleteEntityType: PermissionActions.Delete + ModuleKeywords.EntityType,
  ReadEntityType: PermissionActions.Read + ModuleKeywords.EntityType,

  CreateQuestion: PermissionActions.Create + ModuleKeywords.Question,
  UpdateQuestion: PermissionActions.Update + ModuleKeywords.Question,
  DeleteQuestion: PermissionActions.Delete + ModuleKeywords.Question,
  ReadQuestion: PermissionActions.Read + ModuleKeywords.Question,

  CreateQuestionnaire: PermissionActions.Create + ModuleKeywords.Questionnaire,
  UpdateQuestionnaire: PermissionActions.Update + ModuleKeywords.Questionnaire,
  DeleteQuestionnaire: PermissionActions.Delete + ModuleKeywords.Questionnaire,
  ReadQuestionnaire: PermissionActions.Read + ModuleKeywords.Questionnaire,

  CreateQuestionnaireValue: PermissionActions.Create + ModuleKeywords.QuestionnaireValue,
  UpdateQuestionnaireValue: PermissionActions.Update + ModuleKeywords.QuestionnaireValue,
  DeleteQuestionnaireValue: PermissionActions.Delete + ModuleKeywords.QuestionnaireValue,
  ReadQuestionnaireValue: PermissionActions.Read + ModuleKeywords.QuestionnaireValue,

  CreateMyQuestionnaireValue: PermissionActions.Create + ModuleKeywords.MyQuestionnaireValue,
  UpdateMyQuestionnaireValue: PermissionActions.Update + ModuleKeywords.MyQuestionnaireValue,
  DeleteMyQuestionnaireValue: PermissionActions.Delete + ModuleKeywords.MyQuestionnaireValue,
  ReadMyQuestionnaireValue: PermissionActions.Read + ModuleKeywords.MyQuestionnaireValue,

  CreateRiskItem: PermissionActions.Create + ModuleKeywords.RiskItem,
  UpdateRiskItem: PermissionActions.Update + ModuleKeywords.RiskItem,
  DeleteRiskItem: PermissionActions.Delete + ModuleKeywords.RiskItem,
  ReadRiskItem: PermissionActions.Read + ModuleKeywords.RiskItem,

  CreateRiskRegister: PermissionActions.Create + ModuleKeywords.RiskRegister,
  UpdateRiskRegister: PermissionActions.Update + ModuleKeywords.RiskRegister,
  DeleteRiskRegister: PermissionActions.Delete + ModuleKeywords.RiskRegister,
  ReadRiskRegister: PermissionActions.Read + ModuleKeywords.RiskRegister,

  CreateRiskAssessmentRequest: PermissionActions.Create + ModuleKeywords.RiskAssessmentRequest,
  UpdateRiskAssessmentRequest: PermissionActions.Update + ModuleKeywords.RiskAssessmentRequest,
  DeleteRiskAssessmentRequest: PermissionActions.Delete + ModuleKeywords.RiskAssessmentRequest,
  ReadRiskAssessmentRequest: PermissionActions.Read + ModuleKeywords.RiskAssessmentRequest,

  CreateMyRiskAssessmentRequest: PermissionActions.Create + ModuleKeywords.MyRiskAssessmentRequest,
  UpdateMyRiskAssessmentRequest: PermissionActions.Update + ModuleKeywords.MyRiskAssessmentRequest,
  DeleteMyRiskAssessmentRequest: PermissionActions.Delete + ModuleKeywords.MyRiskAssessmentRequest,
  ReadMyRiskAssessmentRequest: PermissionActions.Read + ModuleKeywords.MyRiskAssessmentRequest,

  CreateRiskStatement: PermissionActions.Create + ModuleKeywords.RiskStatement,
  UpdateRiskStatement: PermissionActions.Update + ModuleKeywords.RiskStatement,
  DeleteRiskStatement: PermissionActions.Delete + ModuleKeywords.RiskStatement,
  ReadRiskStatement: PermissionActions.Read + ModuleKeywords.RiskStatement,
  CreateRiskStatementCategory: PermissionActions.Create + ModuleKeywords.RiskStatementCategory,
  UpdateRiskStatementCategory: PermissionActions.Update + ModuleKeywords.RiskStatementCategory,
  DeleteRiskStatementCategory: PermissionActions.Delete + ModuleKeywords.RiskStatementCategory,
  ReadRiskStatementCategory: PermissionActions.Read + ModuleKeywords.RiskStatementCategory,

  CreateRiskProject: PermissionActions.Create + ModuleKeywords.RiskProject,
  UpdateRiskProject: PermissionActions.Update + ModuleKeywords.RiskProject,
  DeleteRiskProject: PermissionActions.Delete + ModuleKeywords.RiskProject,
  ReadRiskProject: PermissionActions.Read + ModuleKeywords.RiskProject,
  AssingRiskProjectRiskItems: 'assign' + ModuleKeywords.RiskProject + 'RiskItems',

  CreateRiskMethodology: PermissionActions.Create + ModuleKeywords.RiskMethodology,
  UpdateRiskMethodology: PermissionActions.Update + ModuleKeywords.RiskMethodology,
  DeleteRiskMethodology: PermissionActions.Delete + ModuleKeywords.RiskMethodology,
  ReadRiskMethodology: PermissionActions.Read + ModuleKeywords.RiskMethodology,

  CreateControl: PermissionActions.Create + ModuleKeywords.Control,
  UpdateControl: PermissionActions.Update + ModuleKeywords.Control,
  DeleteControl: PermissionActions.Delete + ModuleKeywords.Control,
  ReadControl: PermissionActions.Read + ModuleKeywords.Control,
  CreateControlObjective: PermissionActions.Create + ModuleKeywords.ControlObjective,
  UpdateControlObjective: PermissionActions.Update + ModuleKeywords.ControlObjective,
  DeleteControlObjective: PermissionActions.Delete + ModuleKeywords.ControlObjective,
  ReadControlObjective: PermissionActions.Read + ModuleKeywords.ControlObjective,
  CreateControlCategory: PermissionActions.Create + ModuleKeywords.ControlCategory,
  UpdateControlCategory: PermissionActions.Update + ModuleKeywords.ControlCategory,
  DeleteControlCategory: PermissionActions.Delete + ModuleKeywords.ControlCategory,
  ReadControlCategory: PermissionActions.Read + ModuleKeywords.ControlCategory,
  CreateControlGroup: PermissionActions.Create + ModuleKeywords.ControlGroup,
  UpdateControlGroup: PermissionActions.Update + ModuleKeywords.ControlGroup,
  DeleteControlGroup: PermissionActions.Delete + ModuleKeywords.ControlGroup,
  ReadControlGroup: PermissionActions.Read + ModuleKeywords.ControlGroup,
  CreateControlProcedure: PermissionActions.Create + ModuleKeywords.ControlProcedure,
  UpdateControlProcedure: PermissionActions.Update + ModuleKeywords.ControlProcedure,
  DeleteControlProcedure: PermissionActions.Delete + ModuleKeywords.ControlProcedure,
  ReadControlProcedure: PermissionActions.Read + ModuleKeywords.ControlProcedure,
  CreatePolicy: PermissionActions.Create + ModuleKeywords.Policy,
  UpdatePolicy: PermissionActions.Update + ModuleKeywords.Policy,
  DeletePolicy: PermissionActions.Delete + ModuleKeywords.Policy,
  ReadPolicy: PermissionActions.Read + ModuleKeywords.Policy,
  CreatePublishedPolicy: PermissionActions.Create + ModuleKeywords.PublishedPolicy,
  UpdatePublishedPolicy: PermissionActions.Update + ModuleKeywords.PublishedPolicy,
  DeletePublishedPolicy: PermissionActions.Delete + ModuleKeywords.PublishedPolicy,
  ReadPublishedPolicy: PermissionActions.Read + ModuleKeywords.PublishedPolicy,
  CreatePolicyBody: PermissionActions.Create + ModuleKeywords.PolicyBody,
  UpdatePolicyBody: PermissionActions.Update + ModuleKeywords.PolicyBody,
  DeletePolicyBody: PermissionActions.Delete + ModuleKeywords.PolicyBody,
  ReadPolicyBody: PermissionActions.Read + ModuleKeywords.PolicyBody,
  CreatePolicyBodySection: PermissionActions.Create + ModuleKeywords.PolicyBodySection,
  UpdatePolicyBodySection: PermissionActions.Update + ModuleKeywords.PolicyBodySection,
  DeletePolicyBodySection: PermissionActions.Delete + ModuleKeywords.PolicyBodySection,
  ReadPolicyBodySection: PermissionActions.Read + ModuleKeywords.PolicyBodySection,
  CreateCitation: PermissionActions.Create + ModuleKeywords.Citation,
  UpdateCitation: PermissionActions.Update + ModuleKeywords.Citation,
  DeleteCitation: PermissionActions.Delete + ModuleKeywords.Citation,
  ReadCitation: PermissionActions.Read + ModuleKeywords.Citation,
  CreateCitationType: PermissionActions.Create + ModuleKeywords.CitationType,
  UpdateCitationType: PermissionActions.Update + ModuleKeywords.CitationType,
  DeleteCitationType: PermissionActions.Delete + ModuleKeywords.CitationType,
  ReadCitationType: PermissionActions.Read + ModuleKeywords.CitationType,
  CreateAuthorityDocument: PermissionActions.Create + ModuleKeywords.AuthorityDocument,
  UpdateAuthorityDocument: PermissionActions.Update + ModuleKeywords.AuthorityDocument,
  DeleteAuthorityDocument: PermissionActions.Delete + ModuleKeywords.AuthorityDocument,
  ReadAuthorityDocument: PermissionActions.Read + ModuleKeywords.AuthorityDocument,
  CreateTask: PermissionActions.Create + ModuleKeywords.Task,
  UpdateTask: PermissionActions.Update + ModuleKeywords.Task,
  DeleteTask: PermissionActions.Delete + ModuleKeywords.Task,
  ReadTask: PermissionActions.Read + ModuleKeywords.Task,

  CreateMyTask: PermissionActions.Create + ModuleKeywords.MyTask,
  UpdateMyTask: PermissionActions.Update + ModuleKeywords.MyTask,
  DeleteMyTask: PermissionActions.Delete + ModuleKeywords.MyTask,
  ReadMyTask: PermissionActions.Read + ModuleKeywords.MyTask,

  CreateIssueType: PermissionActions.Create + ModuleKeywords.IssueType,
  UpdateIssueType: PermissionActions.Update + ModuleKeywords.IssueType,
  DeleteIssueType: PermissionActions.Delete + ModuleKeywords.IssueType,
  ReadIssueType: PermissionActions.Read + ModuleKeywords.IssueType,

  CreateIssue: PermissionActions.Create + ModuleKeywords.Issue,
  UpdateIssue: PermissionActions.Update + ModuleKeywords.Issue,
  DeleteIssue: PermissionActions.Delete + ModuleKeywords.Issue,
  ReadIssue: PermissionActions.Read + ModuleKeywords.Issue,

  CreateRemediation: PermissionActions.Create + ModuleKeywords.Remediation,
  UpdateRemediation: PermissionActions.Update + ModuleKeywords.Remediation,
  DeleteRemediation: PermissionActions.Delete + ModuleKeywords.Remediation,
  ReadRemediation: PermissionActions.Read + ModuleKeywords.Remediation,

  CreateUser: PermissionActions.Create + ModuleKeywords.User,
  UpdateUser: PermissionActions.Update + ModuleKeywords.User,
  DeleteUser: PermissionActions.Delete + ModuleKeywords.User,
  ReadUser: PermissionActions.Read + ModuleKeywords.User,

  CreateRole: PermissionActions.Create + ModuleKeywords.Role,
  UpdateRole: PermissionActions.Update + ModuleKeywords.Role,
  DeleteRole: PermissionActions.Delete + ModuleKeywords.Role,
  ReadRole: PermissionActions.Read + ModuleKeywords.Role,

  CreateGroup: PermissionActions.Create + ModuleKeywords.Group,
  UpdateGroup: PermissionActions.Update + ModuleKeywords.Group,
  DeleteGroup: PermissionActions.Delete + ModuleKeywords.Group,
  ReadGroup: PermissionActions.Read + ModuleKeywords.Group,

  CreateFinding: PermissionActions.Create + ModuleKeywords.Finding,
  UpdateFinding: PermissionActions.Update + ModuleKeywords.Finding,
  DeleteFinding: PermissionActions.Delete + ModuleKeywords.Finding,
  ReadFinding: PermissionActions.Read + ModuleKeywords.Finding,

  CreateAsset: PermissionActions.Create + ModuleKeywords.Asset,
  UpdateAsset: PermissionActions.Update + ModuleKeywords.Asset,
  DeleteAsset: PermissionActions.Delete + ModuleKeywords.Asset,
  ReadAsset: PermissionActions.Read + ModuleKeywords.Asset,

  CreateEvent: PermissionActions.Create + ModuleKeywords.Event,
  UpdateEvent: PermissionActions.Update + ModuleKeywords.Event,
  DeleteEvent: PermissionActions.Delete + ModuleKeywords.Event,
  ReadEvent: PermissionActions.Read + ModuleKeywords.Event,

  CreateGeography: PermissionActions.Create + ModuleKeywords.Geography,
  UpdateGeography: PermissionActions.Update + ModuleKeywords.Geography,
  DeleteGeography: PermissionActions.Delete + ModuleKeywords.Geography,
  ReadGeography: PermissionActions.Read + ModuleKeywords.Geography,

  CreateObjective: PermissionActions.Create + ModuleKeywords.Objective,
  UpdateObjective: PermissionActions.Update + ModuleKeywords.Objective,
  DeleteObjective: PermissionActions.Delete + ModuleKeywords.Objective,
  ReadObjective: PermissionActions.Read + ModuleKeywords.Objective,

  CreateProcess: PermissionActions.Create + ModuleKeywords.Process,
  UpdateProcess: PermissionActions.Update + ModuleKeywords.Process,
  DeleteProcess: PermissionActions.Delete + ModuleKeywords.Process,
  ReadProcess: PermissionActions.Read + ModuleKeywords.Process,

  CreateResponsibility: PermissionActions.Create + ModuleKeywords.Responsibility,
  UpdateResponsibility: PermissionActions.Update + ModuleKeywords.Responsibility,
  DeleteResponsibility: PermissionActions.Delete + ModuleKeywords.Responsibility,
  ReadResponsibility: PermissionActions.Read + ModuleKeywords.Responsibility,

  CreateOrganization: PermissionActions.Create + ModuleKeywords.Organization,
  UpdateOrganization: PermissionActions.Update + ModuleKeywords.Organization,
  DeleteOrganization: PermissionActions.Delete + ModuleKeywords.Organization,
  ReadOrganization: PermissionActions.Read + ModuleKeywords.Organization,

  CreateAnyEntity: PermissionActions.Create + 'AnyEntity',
  UpdateAnyEntity: PermissionActions.Update + 'AnyEntity',
  DeleteAnyEntity: PermissionActions.Delete + 'AnyEntity',
  ReadAnyEntity: PermissionActions.Read + 'AnyEntity',

  CreateAcknowledgment: PermissionActions.Create + ModuleKeywords.Acknowledgment,
  UpdateAcknowledgment: PermissionActions.Update + ModuleKeywords.Acknowledgment,
  DeleteAcknowledgment: PermissionActions.Delete + ModuleKeywords.Acknowledgment,
  ReadAcknowledgment: PermissionActions.Read + ModuleKeywords.Acknowledgment,

  CreateAcknowledgmentRequest: PermissionActions.Create + ModuleKeywords.AcknowledgmentRequest,
  UpdateAcknowledgmentRequest: PermissionActions.Update + ModuleKeywords.AcknowledgmentRequest,
  DeleteAcknowledgmentRequest: PermissionActions.Delete + ModuleKeywords.AcknowledgmentRequest,
  ReadAcknowledgmentRequest: PermissionActions.Read + ModuleKeywords.AcknowledgmentRequest,

  CreateMyAcknowledgment: PermissionActions.Create + ModuleKeywords.MyAcknowledgment,
  UpdateMyAcknowledgment: PermissionActions.Update + ModuleKeywords.MyAcknowledgment,
  DeleteMyAcknowledgment: PermissionActions.Delete + ModuleKeywords.MyAcknowledgment,
  ReadMyAcknowledgment: PermissionActions.Read + ModuleKeywords.MyAcknowledgment,

  CreateNotification: PermissionActions.Create + ModuleKeywords.Notification,
  UpdateNotification: PermissionActions.Update + ModuleKeywords.Notification,
  DeleteNotification: PermissionActions.Delete + ModuleKeywords.Notification,
  ReadNotification: PermissionActions.Read + ModuleKeywords.Notification,

  CreateMyNotification: PermissionActions.Create + ModuleKeywords.MyNotification,
  UpdateMyNotification: PermissionActions.Update + ModuleKeywords.MyNotification,
  DeleteMyNotification: PermissionActions.Delete + ModuleKeywords.MyNotification,
  ReadMyNotification: PermissionActions.Read + ModuleKeywords.MyNotification,

  CreateFieldTemplate: PermissionActions.Create + ModuleKeywords.FieldTemplate,
  UpdateFieldTemplate: PermissionActions.Update + ModuleKeywords.FieldTemplate,
  DeleteFieldTemplate: PermissionActions.Delete + ModuleKeywords.FieldTemplate,
  ReadFieldTemplate: PermissionActions.Read + ModuleKeywords.FieldTemplate,

  CreateException: PermissionActions.Create + ModuleKeywords.Exception,
  UpdateException: PermissionActions.Update + ModuleKeywords.Exception,
  DeleteException: PermissionActions.Delete + ModuleKeywords.Exception,
  ReadException: PermissionActions.Read + ModuleKeywords.Exception,

  CreateAttestation: PermissionActions.Create + ModuleKeywords.Attestation,
  UpdateAttestation: PermissionActions.Update + ModuleKeywords.Attestation,
  DeleteAttestation: PermissionActions.Delete + ModuleKeywords.Attestation,
  ReadAttestation: PermissionActions.Read + ModuleKeywords.Attestation,

  CreateStaticValue: PermissionActions.Create + ModuleKeywords.StaticValue,
  UpdateStaticValue: PermissionActions.Update + ModuleKeywords.StaticValue,
  DeleteStaticValue: PermissionActions.Delete + ModuleKeywords.StaticValue,
  ReadStaticValue: PermissionActions.Read + ModuleKeywords.StaticValue,

  CreateRelationType: PermissionActions.Create + ModuleKeywords.RelationType,
  UpdateRelationType: PermissionActions.Update + ModuleKeywords.RelationType,
  DeleteRelationType: PermissionActions.Delete + ModuleKeywords.RelationType,
  ReadRelationType: PermissionActions.Read + ModuleKeywords.RelationType,

  CreateExemption: PermissionActions.Create + ModuleKeywords.Exemption,
  UpdateExemption: PermissionActions.Update + ModuleKeywords.Exemption,
  DeleteExemption: PermissionActions.Delete + ModuleKeywords.Exemption,
  ReadExemption: PermissionActions.Read + ModuleKeywords.Exemption,

  CreateGlobalValueList: PermissionActions.Create + ModuleKeywords.GlobalValueList,
  UpdateGlobalValueList: PermissionActions.Update + ModuleKeywords.GlobalValueList,
  DeleteGlobalValueList: PermissionActions.Delete + ModuleKeywords.GlobalValueList,
  ReadGlobalValueList: PermissionActions.Read + ModuleKeywords.GlobalValueList,

  CreateComplianceReport: PermissionActions.Create + ModuleKeywords.ComplianceReport,
  UpdateComplianceReport: PermissionActions.Update + ModuleKeywords.ComplianceReport,
  DeleteComplianceReport: PermissionActions.Delete + ModuleKeywords.ComplianceReport,
  ReadComplianceReport: PermissionActions.Read + ModuleKeywords.ComplianceReport,

  CreateComplianceReportResult: PermissionActions.Create + ModuleKeywords.ComplianceReportResult,
  UpdateComplianceReportResult: PermissionActions.Update + ModuleKeywords.ComplianceReportResult,
  DeleteComplianceReportResult: PermissionActions.Delete + ModuleKeywords.ComplianceReportResult,
  ReadComplianceReportResult: PermissionActions.Read + ModuleKeywords.ComplianceReportResult,

  CreateControlsGapReport: PermissionActions.Create + ModuleKeywords.ControlsGapReport,
  UpdateControlsGapReport: PermissionActions.Update + ModuleKeywords.ControlsGapReport,
  DeleteControlsGapReport: PermissionActions.Delete + ModuleKeywords.ControlsGapReport,
  ReadControlsGapReport: PermissionActions.Read + ModuleKeywords.ControlsGapReport,

  CreateControlsGapReportResult: PermissionActions.Create + ModuleKeywords.ControlsGapReportResult,
  UpdateControlsGapReportResult: PermissionActions.Update + ModuleKeywords.ControlsGapReportResult,
  DeleteControlsGapReportResult: PermissionActions.Delete + ModuleKeywords.ControlsGapReportResult,
  ReadControlsGapReportResult: PermissionActions.Read + ModuleKeywords.ControlsGapReportResult,

  CreateEmailTemplate: PermissionActions.Create + ModuleKeywords.EmailTemplate,
  UpdateEmailTemplate: PermissionActions.Update + ModuleKeywords.EmailTemplate,
  DeleteEmailTemplate: PermissionActions.Delete + ModuleKeywords.EmailTemplate,
  ReadEmailTemplate: PermissionActions.Read + ModuleKeywords.EmailTemplate,

  CreateTaskTemplate: PermissionActions.Create + ModuleKeywords.TaskTemplate,
  UpdateTaskTemplate: PermissionActions.Update + ModuleKeywords.TaskTemplate,
  DeleteTaskTemplate: PermissionActions.Delete + ModuleKeywords.TaskTemplate,
  ReadTaskTemplate: PermissionActions.Read + ModuleKeywords.TaskTemplate,

  CreateAuditTrail: PermissionActions.Create + ModuleKeywords.AuditTrail,
  UpdateAuditTrail: PermissionActions.Update + ModuleKeywords.AuditTrail,
  DeleteAuditTrail: PermissionActions.Delete + ModuleKeywords.AuditTrail,
  ReadAuditTrail: PermissionActions.Read + ModuleKeywords.AuditTrail,

  CreateSubscription: PermissionActions.Create + ModuleKeywords.Subscription,
  UpdateSubscription: PermissionActions.Update + ModuleKeywords.Subscription,
  DeleteSubscription: PermissionActions.Delete + ModuleKeywords.Subscription,
  ReadSubscription: PermissionActions.Read + ModuleKeywords.Subscription,

  CreateAuditCase: PermissionActions.Create + ModuleKeywords.AuditCase,
  UpdateAuditCase: PermissionActions.Update + ModuleKeywords.AuditCase,
  DeleteAuditCase: PermissionActions.Delete + ModuleKeywords.AuditCase,
  ReadAuditCase: PermissionActions.Read + ModuleKeywords.AuditCase,

  CreateAuditCaseResult: PermissionActions.Create + ModuleKeywords.AuditCaseResult,
  UpdateAuditCaseResult: PermissionActions.Update + ModuleKeywords.AuditCaseResult,
  DeleteAuditCaseResult: PermissionActions.Delete + ModuleKeywords.AuditCaseResult,
  ReadAuditCaseResult: PermissionActions.Read + ModuleKeywords.AuditCaseResult,

  CreateAuditProgram: PermissionActions.Create + ModuleKeywords.AuditProgram,
  UpdateAuditProgram: PermissionActions.Update + ModuleKeywords.AuditProgram,
  DeleteAuditProgram: PermissionActions.Delete + ModuleKeywords.AuditProgram,
  ReadAuditProgram: PermissionActions.Read + ModuleKeywords.AuditProgram,

  CreateAuditProgramReport: PermissionActions.Create + ModuleKeywords.AuditProgramReport,
  UpdateAuditProgramReport: PermissionActions.Update + ModuleKeywords.AuditProgramReport,
  DeleteAuditProgramReport: PermissionActions.Delete + ModuleKeywords.AuditProgramReport,
  ReadAuditProgramReport: PermissionActions.Read + ModuleKeywords.AuditProgramReport,

  CreateAuditProgramType: PermissionActions.Create + ModuleKeywords.AuditProgramType,
  UpdateAuditProgramType: PermissionActions.Update + ModuleKeywords.AuditProgramType,
  DeleteAuditProgramType: PermissionActions.Delete + ModuleKeywords.AuditProgramType,
  ReadAuditProgramType: PermissionActions.Read + ModuleKeywords.AuditProgramType,

  CreateAuditProject: PermissionActions.Create + ModuleKeywords.AuditProject,
  UpdateAuditProject: PermissionActions.Update + ModuleKeywords.AuditProject,
  DeleteAuditProject: PermissionActions.Delete + ModuleKeywords.AuditProject,
  ReadAuditProject: PermissionActions.Read + ModuleKeywords.AuditProject,

  CreateAuditProjectReport: PermissionActions.Create + ModuleKeywords.AuditProjectReport,
  UpdateAuditProjectReport: PermissionActions.Update + ModuleKeywords.AuditProjectReport,
  DeleteAuditProjectReport: PermissionActions.Delete + ModuleKeywords.AuditProjectReport,
  ReadAuditProjectReport: PermissionActions.Read + ModuleKeywords.AuditProjectReport,

  CreateAuditUniverse: PermissionActions.Create + ModuleKeywords.AuditUniverse,
  UpdateAuditUniverse: PermissionActions.Update + ModuleKeywords.AuditUniverse,
  DeleteAuditUniverse: PermissionActions.Delete + ModuleKeywords.AuditUniverse,
  ReadAuditUniverse: PermissionActions.Read + ModuleKeywords.AuditUniverse,

  CreateAuditCaseTemplate: PermissionActions.Create + ModuleKeywords.AuditCaseTemplate,
  UpdateAuditCaseTemplate: PermissionActions.Update + ModuleKeywords.AuditCaseTemplate,
  DeleteAuditCaseTemplate: PermissionActions.Delete + ModuleKeywords.AuditCaseTemplate,
  ReadAuditCaseTemplate: PermissionActions.Read + ModuleKeywords.AuditCaseTemplate,

  CreateAuditRequest: PermissionActions.Create + ModuleKeywords.AuditRequest,
  UpdateAuditRequest: PermissionActions.Update + ModuleKeywords.AuditRequest,
  DeleteAuditRequest: PermissionActions.Delete + ModuleKeywords.AuditRequest,
  ReadAuditRequest: PermissionActions.Read + ModuleKeywords.AuditRequest,

  CreateAuditExpense: PermissionActions.Create + ModuleKeywords.AuditExpense,
  UpdateAuditExpense: PermissionActions.Update + ModuleKeywords.AuditExpense,
  DeleteAuditExpense: PermissionActions.Delete + ModuleKeywords.AuditExpense,
  ReadAuditExpense: PermissionActions.Read + ModuleKeywords.AuditExpense,

  CreateExportGlobalPackage: PermissionActions.Create + ModuleKeywords.ExportGlobalPackage,
  UpdateExportGlobalPackage: PermissionActions.Update + ModuleKeywords.ExportGlobalPackage,
  DeleteExportGlobalPackage: PermissionActions.Delete + ModuleKeywords.ExportGlobalPackage,
  ReadExportGlobalPackage: PermissionActions.Read + ModuleKeywords.ExportGlobalPackage,

  CreateImportGlobalPackage: PermissionActions.Create + ModuleKeywords.ImportGlobalPackage,
  UpdateImportGlobalPackage: PermissionActions.Update + ModuleKeywords.ImportGlobalPackage,
  DeleteImportGlobalPackage: PermissionActions.Delete + ModuleKeywords.ImportGlobalPackage,
  ReadImportGlobalPackage: PermissionActions.Read + ModuleKeywords.ImportGlobalPackage,

  CreateRiskThreat: PermissionActions.Create + ModuleKeywords.RiskThreat,
  UpdateRiskThreat: PermissionActions.Update + ModuleKeywords.RiskThreat,
  DeleteRiskThreat: PermissionActions.Delete + ModuleKeywords.RiskThreat,
  ReadRiskThreat: PermissionActions.Read + ModuleKeywords.RiskThreat,

  CreateRiskVulnerability: PermissionActions.Create + ModuleKeywords.RiskVulnerability,
  UpdateRiskVulnerability: PermissionActions.Update + ModuleKeywords.RiskVulnerability,
  DeleteRiskVulnerability: PermissionActions.Delete + ModuleKeywords.RiskVulnerability,
  ReadRiskVulnerability: PermissionActions.Read + ModuleKeywords.RiskVulnerability,

  CreateFilter: PermissionActions.Create + ModuleKeywords.Filter,
  UpdateFilter: PermissionActions.Update + ModuleKeywords.Filter,
  DeleteFilter: PermissionActions.Delete + ModuleKeywords.Filter,
  ReadFilter: PermissionActions.Read + ModuleKeywords.Filter,

  CreateContentView: PermissionActions.Create + ModuleKeywords.ContentView,
  UpdateContentView: PermissionActions.Update + ModuleKeywords.ContentView,
  DeleteContentView: PermissionActions.Delete + ModuleKeywords.ContentView,
  ReadContentView: PermissionActions.Read + ModuleKeywords.ContentView,

  CreateDashboard: PermissionActions.Create + ModuleKeywords.Dashboard,
  UpdateDashboard: PermissionActions.Update + ModuleKeywords.Dashboard,
  DeleteDashboard: PermissionActions.Delete + ModuleKeywords.Dashboard,
  ReadDashboard: PermissionActions.Read + ModuleKeywords.Dashboard,

  CreateReport: PermissionActions.Create + ModuleKeywords.Report,
  UpdateReport: PermissionActions.Update + ModuleKeywords.Report,
  DeleteReport: PermissionActions.Delete + ModuleKeywords.Report,
  ReadReport: PermissionActions.Read + ModuleKeywords.Report,

  CreateReportScheduler: PermissionActions.Create + ModuleKeywords.ReportScheduler,
  UpdateReportScheduler: PermissionActions.Update + ModuleKeywords.ReportScheduler,
  DeleteReportScheduler: PermissionActions.Delete + ModuleKeywords.ReportScheduler,
  ReadReportScheduler: PermissionActions.Read + ModuleKeywords.ReportScheduler,

  CreateReportConfigurationTemplate: PermissionActions.Create + ModuleKeywords.ReportConfigurationTemplate,
  UpdateReportConfigurationTemplate: PermissionActions.Update + ModuleKeywords.ReportConfigurationTemplate,
  DeleteReportConfigurationTemplate: PermissionActions.Delete + ModuleKeywords.ReportConfigurationTemplate,
  ReadReportConfigurationTemplate: PermissionActions.Read + ModuleKeywords.ReportConfigurationTemplate,

  CreateTransparencyCase: PermissionActions.Create + ModuleKeywords.TransparencyCase,
  UpdateTransparencyCase: PermissionActions.Update + ModuleKeywords.TransparencyCase,
  DeleteTransparencyCase: PermissionActions.Delete + ModuleKeywords.TransparencyCase,
  ReadTransparencyCase: PermissionActions.Read + ModuleKeywords.TransparencyCase,

  CreateTransparencyCaseCategory: PermissionActions.Create + ModuleKeywords.TransparencyCaseCategory,
  UpdateTransparencyCaseCategory: PermissionActions.Update + ModuleKeywords.TransparencyCaseCategory,
  DeleteTransparencyCaseCategory: PermissionActions.Delete + ModuleKeywords.TransparencyCaseCategory,
  ReadTransparencyCaseCategory: PermissionActions.Read + ModuleKeywords.TransparencyCaseCategory,

  CreateStandard: PermissionActions.Create + ModuleKeywords.Standard,
  UpdateStandard: PermissionActions.Update + ModuleKeywords.Standard,
  DeleteStandard: PermissionActions.Delete + ModuleKeywords.Standard,
  ReadStandard: PermissionActions.Read + ModuleKeywords.Standard,

  CreateStandardSection: PermissionActions.Create + ModuleKeywords.ControlObjective, //ModuleKeywords.StandardSection,
  UpdateStandardSection: PermissionActions.Update + ModuleKeywords.ControlObjective, //ModuleKeywords.StandardSection,
  DeleteStandardSection: PermissionActions.Delete + ModuleKeywords.ControlObjective, //ModuleKeywords.StandardSection,
  ReadStandardSection: PermissionActions.Read + ModuleKeywords.ControlObjective, //ModuleKeywords.StandardSection,

  CreatePublishedStandard: PermissionActions.Create + ModuleKeywords.PublishedStandard,
  UpdatePublishedStandard: PermissionActions.Update + ModuleKeywords.PublishedStandard,
  DeletePublishedStandard: PermissionActions.Delete + ModuleKeywords.PublishedStandard,
  ReadPublishedStandard: PermissionActions.Read + ModuleKeywords.PublishedStandard,

  CreateGuideline: PermissionActions.Create + ModuleKeywords.Guideline,
  UpdateGuideline: PermissionActions.Update + ModuleKeywords.Guideline,
  DeleteGuideline: PermissionActions.Delete + ModuleKeywords.Guideline,
  ReadGuideline: PermissionActions.Read + ModuleKeywords.Guideline,

  CreatePublishedGuideline: PermissionActions.Create + ModuleKeywords.PublishedGuideline,
  UpdatePublishedGuideline: PermissionActions.Update + ModuleKeywords.PublishedGuideline,
  DeletePublishedGuideline: PermissionActions.Delete + ModuleKeywords.PublishedGuideline,
  ReadPublishedGuideline: PermissionActions.Read + ModuleKeywords.PublishedGuideline,

  CreateStatisticCollector: PermissionActions.Create + ModuleKeywords.StatisticCollector,
  UpdateStatisticCollector: PermissionActions.Update + ModuleKeywords.StatisticCollector,
  DeleteStatisticCollector: PermissionActions.Delete + ModuleKeywords.StatisticCollector,
  ReadStatisticCollector: PermissionActions.Read + ModuleKeywords.StatisticCollector,

  CreateGeneratedStatistic: PermissionActions.Create + ModuleKeywords.GeneratedStatistic,
  UpdateGeneratedStatistic: PermissionActions.Update + ModuleKeywords.GeneratedStatistic,
  DeleteGeneratedStatistic: PermissionActions.Delete + ModuleKeywords.GeneratedStatistic,
  ReadGeneratedStatistic: PermissionActions.Read + ModuleKeywords.GeneratedStatistic,

  CreateGeneratedReport: PermissionActions.Create + ModuleKeywords.GeneratedReport,
  UpdateGeneratedReport: PermissionActions.Update + ModuleKeywords.GeneratedReport,
  DeleteGeneratedReport: PermissionActions.Delete + ModuleKeywords.GeneratedReport,
  ReadGeneratedReport: PermissionActions.Read + ModuleKeywords.GeneratedReport,

  CreateTag: PermissionActions.Create + ModuleKeywords.Tag,
  UpdateTag: PermissionActions.Update + ModuleKeywords.Tag,
  DeleteTag: PermissionActions.Delete + ModuleKeywords.Tag,
  ReadTag: PermissionActions.Read + ModuleKeywords.Tag,

  CreateLanguage: PermissionActions.Create + ModuleKeywords.Language,
  UpdateLanguage: PermissionActions.Update + ModuleKeywords.Language,
  DeleteLanguage: PermissionActions.Delete + ModuleKeywords.Language,
  ReadLanguage: PermissionActions.Read + ModuleKeywords.Language,

  CreateMetric: PermissionActions.Create + ModuleKeywords.Metric,
  UpdateMetric: PermissionActions.Update + ModuleKeywords.Metric,
  DeleteMetric: PermissionActions.Delete + ModuleKeywords.Metric,
  ReadMetric: PermissionActions.Read + ModuleKeywords.Metric,

  CreateMetricValue: PermissionActions.Create + ModuleKeywords.MetricValue,
  UpdateMetricValue: PermissionActions.Update + ModuleKeywords.MetricValue,
  DeleteMetricValue: PermissionActions.Delete + ModuleKeywords.MetricValue,
  ReadMetricValue: PermissionActions.Read + ModuleKeywords.MetricValue,

  CreateMetricCategory: PermissionActions.Create + ModuleKeywords.MetricCategory,
  UpdateMetricCategory: PermissionActions.Update + ModuleKeywords.MetricCategory,
  DeleteMetricCategory: PermissionActions.Delete + ModuleKeywords.MetricCategory,
  ReadMetricCategory: PermissionActions.Read + ModuleKeywords.MetricCategory,

  CreateCampaign: PermissionActions.Create + ModuleKeywords.Campaign,
  UpdateCampaign: PermissionActions.Update + ModuleKeywords.Campaign,
  DeleteCampaign: PermissionActions.Delete + ModuleKeywords.Campaign,
  ReadCampaign: PermissionActions.Read + ModuleKeywords.Campaign,

  CreateQuestionnaireRequest: PermissionActions.Create + ModuleKeywords.QuestionnaireRequest,
  UpdateQuestionnaireRequest: PermissionActions.Update + ModuleKeywords.QuestionnaireRequest,
  DeleteQuestionnaireRequest: PermissionActions.Delete + ModuleKeywords.QuestionnaireRequest,
  ReadQuestionnaireRequest: PermissionActions.Read + ModuleKeywords.QuestionnaireRequest,

  CreateQuestionnaireCampaignRequest: PermissionActions.Create + ModuleKeywords.QuestionnaireRequest,
  UpdateQuestionnaireCampaignRequest: PermissionActions.Update + ModuleKeywords.QuestionnaireRequest,
  DeleteQuestionnaireCampaignRequest: PermissionActions.Delete + ModuleKeywords.QuestionnaireRequest,
  ReadQuestionnaireCampaignRequest: PermissionActions.Read + ModuleKeywords.QuestionnaireRequest,
};
export async function getPermissionValidItems<T>(fields: { permissions?: string | string[] }[]): Promise<T[]> {
  const permissionPromises: Promise<{ hasPermission: boolean; field: { permissions?: string | string[] } }>[] = [];
  const permissionsService = AppInjector.get(NgxPermissionsService);

  fields.forEach((field) => {
    if (field.permissions) {
      const permissionPromise = permissionsService.hasPermission(field.permissions).then((hasPermission) => ({
        hasPermission,
        field,
      }));
      permissionPromises.push(permissionPromise);
    } else {
      permissionPromises.push(Promise.resolve({ hasPermission: true, field }));
    }
  });

  const permissionResults = await Promise.all(permissionPromises);

  const validFields = permissionResults.filter((result) => result.hasPermission).map((result) => result.field);

  return validFields as T[];
}
