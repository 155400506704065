import { HttpParameterCodec, HttpParams } from '@angular/common/http';
import isArray from 'lodash-es/isArray';

/**
 * Custom parameter codec to correctly handle the plus sign in parameter
 * values. See https://github.com/angular/angular/issues/18261
 */
class ParameterCodec implements HttpParameterCodec {
  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }

  encodeValue(value: string): string {
    return encodeURIComponent(value);
  }

  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }

  decodeValue(value: string): string {
    return decodeURIComponent(value);
  }
}
const PARAMETER_CODEC = new ParameterCodec();

/**
 * Creates a new `HttpParams` with the correct codec
 */
export function toQueryParams(object: Object = null): HttpParams {
  let _params = new HttpParams({
    encoder: PARAMETER_CODEC,
  });
  if (object) {
    const ob = object?.toString() === '[object Object]' ? object : { object };
    for (const key in ob) {
      if (ob.hasOwnProperty(key) && ob[key] !== undefined && ob[key] !== null) {
        if (isArray(ob[key])) {
          ob[key]?.forEach((element) => {
            _params = _params.append(key, element.toString());
          });
        } else {
          _params = _params.set(key, ob[key]?.toString());
        }
      }
    }
  }
  return _params;
}
