import { HttpEventType } from '@angular/common/http';
import { BaseRequestControllerService } from '@shared/services/api/base-request-controller.service';
import { ToastService } from '@shared/services/toast.service';
import { AppInjector } from 'app/app-injector';
import { FilesDataService } from 'app/modules/file-manager-module/services/files-data.service';

export class FileDownloader {
  documentUrl: string;
  documentName: string;
  progress: number;
  constructor() {}

  downloadFile(fileId: string, service: BaseRequestControllerService<any>) {
    const filesDataService: any = service ? service : AppInjector.get(FilesDataService);
    const toastService = AppInjector.get(ToastService);
    toastService.success('Download in Progress!!');
    filesDataService.downloadFileWithProgress(fileId).subscribe((event) => {
      if (event.type === HttpEventType.DownloadProgress) {
        this.progress = (event.loaded / event.total) * 100;
      }
      if (event.type === HttpEventType.Response) {
        const response = event;
        const data = URL.createObjectURL(new Blob([response.body], { type: response.body.type }));

        const contentDisposition = response.headers.get('content-disposition');

        if (contentDisposition != null) {
          const fileName = contentDisposition
            .split(';')[1]
            .split('filename')[1]
            .split('=')[1]
            .replace(/["]+/g, '')
            .trim();
          this.documentName = fileName;
        } else {
          this.documentName = 'file';
        }
        // const data = window.URL.createObjectURL(newBlob);

        let link = document.createElement('a');
        link.href = data;
        link.download = this.documentName;
        // this is necessary as link.click() does not work on the latest firefox
        link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
        this.progress = 100;
        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(data);
          link.remove();
        }, 100);
      }
    });
  }
  downloadFileByCode(
    code: string,
    service: BaseRequestControllerService<any>,
    mode: 'export' | 'import',
    fallbackFilename = 'file'
  ) {
    // const filesDataService = AppInjector.get(FilesDataService);
    const toastService = AppInjector.get(ToastService);
    toastService.success('Download in Progress!!');
    (mode == 'export'
      ? service.downloadPackageWithProgressExport(code)
      : service.downloadPackageWithProgressImport(code)
    ).subscribe((event) => {
      if (event.type === HttpEventType.DownloadProgress) {
        this.progress = (event.loaded / event.total) * 100;
      }
      if (event.type === HttpEventType.Response) {
        const response = event;
        const data = URL.createObjectURL(new Blob([response.body], { type: response.body.type }));

        const contentDisposition = response.headers.get('content-disposition');

        if (contentDisposition != null) {
          const fileName = contentDisposition
            .split(';')[1]
            .split('filename')[1]
            .split('=')[1]
            .replace(/["]+/g, '')
            .trim();
          this.documentName = fileName;
        } else {
          this.documentName = fallbackFilename || 'file';
        }
        // const data = window.URL.createObjectURL(newBlob);

        let link = document.createElement('a');
        link.href = data;
        link.download = this.documentName;
        // this is necessary as link.click() does not work on the latest firefox
        link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
        this.progress = 100;
        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(data);
          link.remove();
        }, 100);
      }
    });
  }
}
