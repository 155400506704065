import { FilterMetadata, LazyLoadEvent } from 'primeng/api';

export function toMongoDBFormat(data: { [s: string]: FilterMetadata[] }, globalFilters: string[] = []) {
  const filterTypes = TableFilterMapping;
  let query: any = null;
  Object.keys(data).forEach((key) => {
    let fieldFilter = {};
    if (key == 'global') {
      fieldFilter['$or'] = [];
      globalFilters.forEach((filter) => {
        fieldFilter['$or'].push({
          [filter]: { [filterTypes[(data[key] as FilterMetadata).matchMode]]: (data[key] as FilterMetadata).value },
        });
      });
    } else {
      if (data[key].length > 0) fieldFilter['$' + data[key][0].operator] = [];
      data[key].forEach((filter) => {
        if (filter.value != null) {
          (fieldFilter['$' + filter.operator] as any[]).push({
            [key.split('.')[0]]: { [filterTypes[filter.matchMode]]: filter.value },
          });
        }
      });
    }
    if (
      fieldFilter['$or']?.length > 0 ||
      (data[key].length > 0 && fieldFilter['$' + data[key][0].operator].length > 0)
    ) {
      if (query == null) query = { $and: [] };
      (query['$and'] as any[]).push(fieldFilter);
    }
  });
  return query || {};
}
export enum TableFilterMapping {
  startsWith = '$regex',
  contains = '$regex',
  notContains = '$regex',
  endsWith = '$regex',
  equals = '$eq',
  notEquals = '$regex',
  dateIs = '$regex',
  dateIsNot = '$regex',
  dateIsBefore = '$regex',
  dateIsAfter = '$regex',
  in = '$in',
  between = '$regex',
}
