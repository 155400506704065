import { Injectable } from '@angular/core';
import { NotificationDto } from '@shared/classes';
import { MyNotificationsDataService } from 'app/modules/activity-feed/services/data/my-notifications-data.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SocketDataService {
  private _notifications = new BehaviorSubject([]);
  notifications$ = this._notifications.asObservable();

  notifications: NotificationDto[] = [];
  totalNotificationCount: number = 0;
  intervalId;
  constructor(private notificationService: MyNotificationsDataService) {
    this.initialize();
  }
  initialize() {
    this.getNotifications();
    this.intervalId = setInterval(() => {
      this.getNotifications();
    }, 600000);
  }
  getNotifications() {
    this.notificationService.getNotifications().subscribe((res: any) => {
      this.notifications = res.content as NotificationDto[]; //.filter(x=> !!!x.seen);
      this._notifications.next(this.notifications);
      this.totalNotificationCount = res.totalElements;
    });
  }
}
