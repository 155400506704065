import { Component, Input, OnInit } from '@angular/core';
import { DataTypeEnum, IViewMode } from '@shared/classes';

@Component({
  selector: 'app-input-view-switch',
  templateUrl: './input-view-switch.component.html',
  styleUrls: ['./input-view-switch.component.scss'],
})
export class InputViewSwitchComponent implements OnInit {
  @Input() mode: IViewMode = IViewMode.edit;
  @Input() data: any;
  @Input() label: any;
  dataTypes = DataTypeEnum;
  @Input() dataType: DataTypeEnum = DataTypeEnum.Text;
  @Input() showLabelInViewMode: boolean = true;
  @Input() multiViewFields: boolean = false;
  @Input() multiViewFieldStyleClass: string = 'w-full';
  @Input() multiViewFieldParentStyleClass: string = 'flex-column';
  @Input() labelSuffix: string = '';
  @Input() options: any;
  @Input() resetFieldCheckVisable: boolean = false;
  @Input() resetableChecked: boolean = false;
  @Input() keepValueCallback: (args: any) => void;

  constructor() {}

  ngOnInit(): void {}
  //   get isCodeLinkArray(): boolean {
  //     return (this.dataType == DataTypeEnum.CodeLink) && (typeof this.data !== 'string' && isArray(this.data));
  //   }
}
