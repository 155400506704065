/**
 * Sightflare Service
 * Rest Api for Sightflare Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ApiResponseAuditProgramEvent { 
    status?: number;
    data?: ApiResponseAuditProgramEvent.DataEnum;
    error?: boolean;
    errorMessage?: string;
}
export namespace ApiResponseAuditProgramEvent {
    export type DataEnum = 'TO_PENDING_APPROVAL' | 'TO_APPROVED' | 'TO_REJECTED' | 'TO_DRAFT' | 'TO_CLOSED';
    export const DataEnum = {
        PendingApproval: 'TO_PENDING_APPROVAL' as DataEnum,
        Approved: 'TO_APPROVED' as DataEnum,
        Rejected: 'TO_REJECTED' as DataEnum,
        Draft: 'TO_DRAFT' as DataEnum,
        Closed: 'TO_CLOSED' as DataEnum
    };
}


