<div
  [class]="
    'flex gap-2 select-none align-items-center justify-content-center' +
    (smallMode ? ' small-badge ' : ' badge ') +
    badgeTypes[badgeType] +
    (badgeColors[status] || badgeColors['NOT_DEFINED']) +
    ' ' +
    (flicker ? ' badge-shining ' : '') +
    (pulse ? ' badge-pulse ' : '') +
    styleClass
  "
  [style]="
    (customColor ? 'color:' + customColor + ' !important; ' : '') +
    (customColorBg && badgeTypes[badgeType] != outlineType
      ? 'background-color:' + customColorBg + ' !important; '
      : '') +
    ' min-height: 1rem; min-width: ' +
    minWidth +
    '; text-align: center; ' +
    (fullWidth ? ' max-width:100%; ' : ' ')
  "
>
  @if ((displayText || showIcon) && (badgeIcons[status] || customIcon || badgeIcons['NOT_DEFINED'])) {
    <i
      [class]="customIcon || badgeIcons[status] || badgeIcons['NOT_DEFINED']"
      [style]="smallMode ? { 'font-size': '0.8rem' } : {}"
    ></i>
  }
  {{ displayText ? text || status : null }}
  <ng-content></ng-content>
</div>
