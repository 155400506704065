import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CodeMapper, IViewMode, ModuleKeywords, TargetTypeEnum, getPathByCode, isNullObj } from '@shared/classes';
import { AcknowledgmentMappingService } from '@shared/services/mappings/acknowledgment/acknowledgment-mapping.service';
import { AcknowledgmentRequestMappingService } from '@shared/services/mappings/acknowledgment/acknowledgment-request-mapping.service';
import { MyAcknowledgmentMappingService } from '@shared/services/mappings/acknowledgment/my-acknowledgment-mapping.service';
import { AuditTrailMappingService } from '@shared/services/mappings/activity-feed/audit-trail-mapping.service';
import { EmailTemplatesMappingService } from '@shared/services/mappings/activity-feed/email-templates-mapping.service';
import { NotificationMappingService } from '@shared/services/mappings/activity-feed/notification-mapping.service';
import { TaskMappingService } from '@shared/services/mappings/activity-feed/task-mapping.service';
import { TaskTemplatesMappingService } from '@shared/services/mappings/activity-feed/task-templates-mapping.service';
import { AuditCaseMappingService } from '@shared/services/mappings/audit/audit-case-mapping.service';
import { AuditCaseResultMappingService } from '@shared/services/mappings/audit/audit-case-result-mapping.service';
import { AuditExpenseMappingService } from '@shared/services/mappings/audit/audit-expense-mapping.service';
import { AuditProgramMappingService } from '@shared/services/mappings/audit/audit-program-mapping.service';
import { AuditProgramReportMappingService } from '@shared/services/mappings/audit/audit-program-report-mapping.service';
import { AuditProgramTypeMappingService } from '@shared/services/mappings/audit/audit-program-type-mapping.service';
import { AuditProjectMappingService } from '@shared/services/mappings/audit/audit-project-mapping.service';
import { AuditProjectReportMappingService } from '@shared/services/mappings/audit/audit-project-report-mapping.service';
import { AuditRequestMappingService } from '@shared/services/mappings/audit/audit-request-mapping.service';
import { AuditTaskTemplateMappingService } from '@shared/services/mappings/audit/audit-task-template-mapping.service';
import { AuditUniverseMappingService } from '@shared/services/mappings/audit/audit-universe-mapping.service';
import { DashboardMappingService } from '@shared/services/mappings/data-structure/dashboard-mapping.service';
import { FieldsMappingService } from '@shared/services/mappings/data-structure/fields-mapping.service';
import { GeneratedStatisticMappingService } from '@shared/services/mappings/data-structure/generated-statistic-mapping.service';
import { GlobalValueListMappingService } from '@shared/services/mappings/data-structure/global-value-list-mapping.service';
import { ReportConfigurationTemplateMappingService } from '@shared/services/mappings/data-structure/report-configuration-template-mapping.service';
import { ReportMappingService } from '@shared/services/mappings/data-structure/report-mapping.service';
import { ReportSchedulerMappingService } from '@shared/services/mappings/data-structure/report-scheduler-mapping.service';
import { SearchFilterMappingService } from '@shared/services/mappings/data-structure/search-filter-mapping.service';
import { StatisticCollectorMappingService } from '@shared/services/mappings/data-structure/statistic-collector-mapping.service';
import { TagMappingService } from '@shared/services/mappings/data-structure/tag-mapping.service';
import { ViewMappingService } from '@shared/services/mappings/data-structure/view-mapping.service';
import { AssetsMappingService } from '@shared/services/mappings/entity/assets-mapping.service';
import { EntityTypesMappingService } from '@shared/services/mappings/entity/entity-types-mapping.service';
import { EventsMappingService } from '@shared/services/mappings/entity/events-mapping.service';
import { GeographiesMappingService } from '@shared/services/mappings/entity/geographies-mapping.service';
import { ObjectivesMappingService } from '@shared/services/mappings/entity/objectives-mapping.service';
import { OrganizationsMappingService } from '@shared/services/mappings/entity/organizations-mapping.service';
import { ProcessesMappingService } from '@shared/services/mappings/entity/processes-mapping.service';
import { ResponsibilitiesMappingService } from '@shared/services/mappings/entity/responsibilities-mapping.service';
import { AttestationsMappingService } from '@shared/services/mappings/framework/attestations-mapping.service';
import { AuthorityMappingService } from '@shared/services/mappings/framework/authority-mapping.service';
import { CitationMappingService } from '@shared/services/mappings/framework/citation-mapping.service';
import { CitationTypeMappingService } from '@shared/services/mappings/framework/citation-type-mapping.service';
import { ComplianceReportMappingService } from '@shared/services/mappings/framework/compliance-report-mapping.service';
import { ComplianceReportResultMappingService } from '@shared/services/mappings/framework/compliance-report-result-mapping.service';
import { ControlCategoryMappingService } from '@shared/services/mappings/framework/control-category-mapping.service';
import { ControlGroupMappingService } from '@shared/services/mappings/framework/control-group-mapping.service';
import { ControlObjectivesMappingService } from '@shared/services/mappings/framework/control-objectives-mapping.service';
import { ControlProcedureMappingService } from '@shared/services/mappings/framework/control-procedure-mapping.service';
import { ControlsMappingService } from '@shared/services/mappings/framework/controls-mapping.service';
import { ExemptionMappingService } from '@shared/services/mappings/framework/exemption-mapping.service';
import { FrameworkExceptionsMappingService } from '@shared/services/mappings/framework/framework-exceptions-mapping.service';
import { GuidelineMappingService } from '@shared/services/mappings/framework/guideline-mapping.service';
import { PolicyBodyMappingService } from '@shared/services/mappings/framework/policy-body-mapping.service';
import { PolicyBodySectionsMappingService } from '@shared/services/mappings/framework/policy-body-sections-mapping.service';
import { PolicyMappingService } from '@shared/services/mappings/framework/policy-mapping.service';
import { StandardMappingService } from '@shared/services/mappings/framework/standard-mapping.service';
import { StandardSectionMappingService } from '@shared/services/mappings/framework/standard-section-mapping.service';
import { ExportGlobalPackageMappingService } from '@shared/services/mappings/global-package/export-global-package-mapping.service';
import { ImportGlobalPackageMappingService } from '@shared/services/mappings/global-package/import-global-package-mapping.service';
import { IssueTypeMappingService } from '@shared/services/mappings/issue/issue-type-mapping.service';
import { IssuesMappingService } from '@shared/services/mappings/issue/issues-mapping.service';
import { RemediationPlanMappingService } from '@shared/services/mappings/issue/remediation-plan-mapping.service';
import { MetricCategoryMappingService } from '@shared/services/mappings/performance/metric-category-mapping.service';
import { MetricMappingService } from '@shared/services/mappings/performance/metric-mapping.service';
import { MetricValueMappingService } from '@shared/services/mappings/performance/metric-value-mapping.service';
import { QuestionMappingService } from '@shared/services/mappings/question/question-mapping.service';
import { QuestionnaireMappingService } from '@shared/services/mappings/question/questionnaire-mapping.service';
import { QuestionnaireValueMappingService } from '@shared/services/mappings/question/questionnaire-value-mapping.service';
import { RelationTypesMappingService } from '@shared/services/mappings/relation/relation-types-mapping.service';
import { MyRiskAssessmentRequestMappingService } from '@shared/services/mappings/risk/my-risk-assessment-request-mapping.service';
import { RiskAssessmentRequestMappingService } from '@shared/services/mappings/risk/risk-assessment-request-mapping.service';
import { RiskItemMappingService } from '@shared/services/mappings/risk/risk-item-mapping.service';
import { RiskProjectMappingService } from '@shared/services/mappings/risk/risk-project-mapping.service';
import { RiskStatementCategoryMappingService } from '@shared/services/mappings/risk/risk-statement-category-mapping.service';
import { RiskStatementMappingService } from '@shared/services/mappings/risk/risk-statement-mapping.service';
import { RiskThreatMappingService } from '@shared/services/mappings/risk/risk-threat-mapping.service';
import { RiskVulnerabilityMappingService } from '@shared/services/mappings/risk/risk-vulnerability-mapping.service';
import { TransparencyCaseCategoryMappingService } from '@shared/services/mappings/transparency-management/transparency-case-category-mapping.service';
import { TransparencyCaseMappingService } from '@shared/services/mappings/transparency-management/transparency-case-mapping.service';
import { MenuItem, MenuItemList } from 'app/app.menu.items';
import { AcknowledgementDataService } from 'app/modules/acknowledgement-module/services/data/acknowledgement-data.service';
import { AcknowledgementRequestDataService } from 'app/modules/acknowledgement-module/services/data/acknowledgement-request-data.service';
import { MyAcknowledgmentDataService } from 'app/modules/acknowledgement-module/services/data/my-acknowledgment-data.service';
import { EmailTemplatesDataService } from 'app/modules/activity-feed/services/data/email-templates-data.service';
import { MyNotificationsDataService } from 'app/modules/activity-feed/services/data/my-notifications-data.service';
import { MyTaskDataService } from 'app/modules/activity-feed/services/data/my-task-data.service';
import { NotificationsDataService } from 'app/modules/activity-feed/services/data/notifications-data.service';
import { TaskDataService } from 'app/modules/activity-feed/services/data/task-data.service';
import { TaskTemplatesDataService } from 'app/modules/activity-feed/services/data/task-templates-data.service';
import { AuditCaseDataService } from 'app/modules/audit-module/services/data/audit-case-data.service';
import { AuditCaseResultDataService } from 'app/modules/audit-module/services/data/audit-case-result-data.service';
import { AuditExpenseDataService } from 'app/modules/audit-module/services/data/audit-expense-data.service';
import { AuditProgramDataService } from 'app/modules/audit-module/services/data/audit-program-data.service';
import { AuditProgramReportDataService } from 'app/modules/audit-module/services/data/audit-program-report-data.service';
import { AuditProgramTypeDataService } from 'app/modules/audit-module/services/data/audit-program-type-data.service';
import { AuditProjectDataService } from 'app/modules/audit-module/services/data/audit-project-data.service';
import { AuditProjectReportDataService } from 'app/modules/audit-module/services/data/audit-project-report-data.service';
import { AuditRequestDataService } from 'app/modules/audit-module/services/data/audit-request-data.service';
import { AuditTaskTemplateDataService } from 'app/modules/audit-module/services/data/audit-task-template-data.service';
import { AuditUniverseDataService } from 'app/modules/audit-module/services/data/audit-universe-data.service';
import { DashboardDataService } from 'app/modules/data-structure-module/services/data/dashboard-data.service';
import { FieldsDataService } from 'app/modules/data-structure-module/services/data/fields-data.service';
import { GeneratedReportDataService } from 'app/modules/data-structure-module/services/data/generated-report-data.service';
import { GeneratedStatisticDataService } from 'app/modules/data-structure-module/services/data/generated-statistic-data.service';
import { GlobalValueListDataService } from 'app/modules/data-structure-module/services/data/global-value-list-data.service';
import { LanguageDataService } from 'app/modules/data-structure-module/services/data/language-data.service';
import { ReportConfigurationTemplateDataService } from 'app/modules/data-structure-module/services/data/report-configuration-template-data.service';
import { ReportDataService } from 'app/modules/data-structure-module/services/data/report-data.service';
import { ReportSchedulerDataService } from 'app/modules/data-structure-module/services/data/report-scheduler-data.service';
import { SearchFilterDataService } from 'app/modules/data-structure-module/services/data/search-filter-data.service';
import { StatisticCollectorDataService } from 'app/modules/data-structure-module/services/data/statistic-collector-data.service';
import { TagDataService } from 'app/modules/data-structure-module/services/data/tag-data.service';
import { ViewDataService } from 'app/modules/data-structure-module/services/data/view-data.service';
import { EntityDataService } from 'app/modules/entity-module/entity/entity-data.service';
import { AssetsDataService } from 'app/modules/entity-module/services/data/assets-data.service';
import { EntityTypesDataService } from 'app/modules/entity-module/services/data/entity-types-data.service';
import { EventsDataService } from 'app/modules/entity-module/services/data/events-data.service';
import { GeographiesDataService } from 'app/modules/entity-module/services/data/geographies-data.service';
import { ObjectivesDataService } from 'app/modules/entity-module/services/data/objectives-data.service';
import { OrganizationsDataService } from 'app/modules/entity-module/services/data/organizations-data.service';
import { ProcessesDataService } from 'app/modules/entity-module/services/data/processes-data.service';
import { ResponsibilitiesDataService } from 'app/modules/entity-module/services/data/responsibilities-data.service';
import { FilesDataService } from 'app/modules/file-manager-module/services/files-data.service';
import { ExportGlobalPackageDataService } from 'app/modules/global-package-module/services/data/export-global-package-data.service';
import { ImportGlobalPackageDataService } from 'app/modules/global-package-module/services/data/import-global-package-data.service';
import { IssueTypeDataService } from 'app/modules/issues-module/services/data/issue-type-data.service';
import { IssuesDataService } from 'app/modules/issues-module/services/data/issues-data.service';
import { RemediationPlanDataService } from 'app/modules/issues-module/services/data/remediation-plan-data.service';
import { ControlDataService } from 'app/modules/org-framework/service/data/ControlDataService';
import { ControlObjectiveDataService } from 'app/modules/org-framework/service/data/ControlObjectiveDataService';
import { FrameworkExceptionDataService } from 'app/modules/org-framework/service/data/FrameworkExceptionDataService';
import { PolicyDataService } from 'app/modules/org-framework/service/data/PolicyDataService';
import { AttestationDataService } from 'app/modules/org-framework/service/data/attestations-data.service';
import { AuthorityDataService } from 'app/modules/org-framework/service/data/authority-data.service';
import { CitationDataService } from 'app/modules/org-framework/service/data/citation-data.service';
import { CitationTypeDataService } from 'app/modules/org-framework/service/data/citation-type-data.service';
import { ComplianceReportDataService } from 'app/modules/org-framework/service/data/compliance-report-data.service';
import { ComplianceReportResultDataService } from 'app/modules/org-framework/service/data/compliance-report-result-data.service';
import { ControlCategoryDataService } from 'app/modules/org-framework/service/data/control-category-data.service';
import { ControlGroupDataService } from 'app/modules/org-framework/service/data/control-group-data.service';
import { ControlProcedureDataService } from 'app/modules/org-framework/service/data/control-procedure-data.service';
import { ControlsGapReportDataService } from 'app/modules/org-framework/service/data/controls-gap-report-data.service';
import { ControlsGapReportResultDataService } from 'app/modules/org-framework/service/data/controls-gap-report-result-data.service';
import { ExemptionDataService } from 'app/modules/org-framework/service/data/exemption-data.service';
import { GuidelineDataService } from 'app/modules/org-framework/service/data/guideline-data.service';
import { PolicyBodyDataService } from 'app/modules/org-framework/service/data/policy-body-data.service';
import { PolicyBodySectionDataService } from 'app/modules/org-framework/service/data/policy-body-section-data.service';
import { PublishedGuidelineDataService } from 'app/modules/org-framework/service/data/published-guideline-data.service';
import { PublishedPolicyDataService } from 'app/modules/org-framework/service/data/published-policy-data.service';
import { PublishedStandardDataService } from 'app/modules/org-framework/service/data/published-standard-data.service';
import { StandardDataService } from 'app/modules/org-framework/service/data/standard-data.service';
import { StandardSectionDataService } from 'app/modules/org-framework/service/data/standard-section-data.service';
import { MetricCategoryDataService } from 'app/modules/performance-module/services/data/metric-category-data.service';
import { MetricDataService } from 'app/modules/performance-module/services/data/metric-data.service';
import { MetricValueDataService } from 'app/modules/performance-module/services/data/metric-value-data.service';
import { CampaignDataService } from 'app/modules/questions/service/data/campaign-data.service';
import { MyQuestionsValueDataService } from 'app/modules/questions/service/data/my-questionnaire-value-data.service';
import { QuestionsDataService } from 'app/modules/questions/service/data/question-data.service';
import { QuestionnaireCampaignRequestDataService } from 'app/modules/questions/service/data/questionnaire-campaign-request-data.service';
import { QuestionnaireDataService } from 'app/modules/questions/service/data/questionnaire-data.service';
import { QuestionsValueDataService } from 'app/modules/questions/service/data/questionnaire-value-data.service';
import { RelationTypesDataService } from 'app/modules/relation-module/services/data/relation-types-data.service';
import { MyRiskAssessmentRequestDataService } from 'app/modules/risk/services/data/my-risk-assessment-request-data.service';
import { RiskAssessmentRequestDataService } from 'app/modules/risk/services/data/risk-assessment-request-data.service';
import { RiskItemDataService } from 'app/modules/risk/services/data/risk-item-data.service';
import { RiskMethodologyDataService } from 'app/modules/risk/services/data/risk-methodology-data.service';
import { RiskProjectDataService } from 'app/modules/risk/services/data/risk-project-data.service';
import { RiskStatementCategoryDataService } from 'app/modules/risk/services/data/risk-statement-category-data.service';
import { RiskStatementDataService } from 'app/modules/risk/services/data/risk-statement-data.service';
import { RiskThreatDataService } from 'app/modules/risk/services/data/risk-threat-data.service';
import { RiskVulnerabilityDataService } from 'app/modules/risk/services/data/risk-vulnerability-data.service';
import { TransparencyCaseCategoryDataService } from 'app/modules/transparency-management/services/data/transparency-case-category-data.service';
import { TransparencyCaseDataService } from 'app/modules/transparency-management/services/data/transparency-case-data.service';
import { GroupDataService } from 'app/modules/users/services/data/group-data.service';
import { RoleDataService } from 'app/modules/users/services/data/role-data.service';
import { UserDataService } from 'app/modules/users/services/data/user-data.service';
import { BaseRequestControllerService } from './api/base-request-controller.service';
import { AuditTrailService } from './audit-trail-service/relations.service';
import { BaseFieldDefinitionsService } from './base-field-definitions.service';
import { GeneratedReportMappingService } from './mappings/data-structure/generated-report-mapping.service';
import { LanguageMappingService } from './mappings/data-structure/language-mapping.service';
import { ControlsGapReportMappingService } from './mappings/framework/controls-gap-report-mapping.service';
import { ControlsGapReportResultMappingService } from './mappings/framework/controls-gap-report-result-mapping.service';
import { CampaignMappingService } from './mappings/question/campaign-mapping.service';
import { QuestionnaireCampaignRequestMappingService } from './mappings/question/questionnaire-campaign-request-mapping.service';
import { RiskMethodologyMappingService } from './mappings/risk/risk-methodology-mapping.service';

@Injectable({
  providedIn: 'root',
})
export class PathResolverService {
  entitiesModuleKeywords: ModuleKeywords[] = [
    ModuleKeywords.Asset,
    ModuleKeywords.Event,
    ModuleKeywords.Geography,
    ModuleKeywords.Responsibility,
    ModuleKeywords.Process,
    ModuleKeywords.Objective,
  ];
  renderedMenuItems: MenuItem[] = [];
  constructor(
    private router: Router,
    private location: Location,
    private acknowledgmentDataService: AcknowledgementDataService,
    private acknowledgmentRequestDataService: AcknowledgementRequestDataService,
    private documentDataService: FilesDataService,
    private issuesDataService: IssuesDataService,
    private issueTypeDataService: IssueTypeDataService,
    private remediationPlanDataService: RemediationPlanDataService,
    private controlDataService: ControlDataService,
    private controlObjectiveDataService: ControlObjectiveDataService,
    private frameworkExceptionDataService: FrameworkExceptionDataService,
    private policyDataService: PolicyDataService,
    private myQuestionnaireValueDataService: MyQuestionsValueDataService,
    private questionDataService: QuestionsDataService,
    private questionnaireDataService: QuestionnaireDataService,
    private questionnaireValueDataService: QuestionsValueDataService,
    private entityDataService: EntityDataService,
    private entityTypesDataService: EntityTypesDataService,
    private riskItemDataService: RiskItemDataService,
    private riskMethodologyDataService: RiskMethodologyDataService,
    private riskProjectDataService: RiskProjectDataService,
    private riskStatementDataService: RiskStatementDataService,
    private riskAssessmentRequestDataService: RiskAssessmentRequestDataService,
    private myRiskAssessmentRequestDataService: MyRiskAssessmentRequestDataService,
    private riskStatementCategoryDataService: RiskStatementCategoryDataService,
    private groupDataService: GroupDataService,
    private roleDataService: RoleDataService,
    private userDataService: UserDataService,
    private notificationDataService: NotificationsDataService,
    private myNotificationDataService: MyNotificationsDataService,
    private taskDataService: TaskDataService,
    private myTaskDataService: MyTaskDataService,
    private responsibilitiesDataService: ResponsibilitiesDataService,
    private assetsDataService: AssetsDataService,
    private eventsDataService: EventsDataService,
    private objectivesDataService: ObjectivesDataService,
    private processesDataService: ProcessesDataService,
    private geographiesDataService: GeographiesDataService,
    private tagsDataService: RelationTypesDataService,
    private attestationDataService: AttestationDataService,
    private organizationsDataService: OrganizationsDataService,
    private exemptionDataService: ExemptionDataService,
    private fieldsDataService: FieldsDataService,
    private globalValueListService: GlobalValueListDataService,
    private policyBodyService: PolicyBodyDataService,
    private policyBodySectionService: PolicyBodySectionDataService,
    private complianceReportService: ComplianceReportDataService,
    private complianceReportResultService: ComplianceReportResultDataService,
    private gapReportService: ControlsGapReportDataService,
    private gapReportResultService: ControlsGapReportResultDataService,
    private emailTemplatesService: EmailTemplatesDataService,
    private taskTemplatesService: TaskTemplatesDataService,
    private myAcknowledgmentService: MyAcknowledgmentDataService,
    private auditTrailService: AuditTrailService,
    private auditUniverseService: AuditUniverseDataService,
    private auditProgramTypeService: AuditProgramTypeDataService,
    private auditProgramService: AuditProgramDataService,
    private auditProjectService: AuditProjectDataService,
    private auditCaseService: AuditCaseDataService,
    private auditCaseTemplateService: AuditTaskTemplateDataService,
    private auditCaseResultService: AuditCaseResultDataService,
    private auditProgramReportService: AuditProgramReportDataService,
    private auditProjectReportService: AuditProjectReportDataService,
    private auditRequestService: AuditRequestDataService,
    private auditExpenseService: AuditExpenseDataService,
    private riskThreatService: RiskThreatDataService,
    private riskVulnerabilityService: RiskVulnerabilityDataService,
    private filterDataService: SearchFilterDataService,
    private viewDataService: ViewDataService,
    private dashboardDataService: DashboardDataService,
    private reportDataService: ReportDataService,
    private exportGlobalPackageService: ExportGlobalPackageDataService,
    private importGlobalPackageService: ImportGlobalPackageDataService,
    private transparencyCaseCategoryService: TransparencyCaseCategoryDataService,
    private transparencyCaseService: TransparencyCaseDataService,
    private standardService: StandardDataService,
    private standardSectionService: StandardSectionDataService,
    private publishedStandardService: PublishedStandardDataService,
    private guidelineService: GuidelineDataService,
    private publishedGuidelineService: PublishedGuidelineDataService,
    private citationService: CitationDataService,
    private authorityService: AuthorityDataService,
    private citationTypeService: CitationTypeDataService,
    private controlCategoryService: ControlCategoryDataService,
    private controlGroupService: ControlGroupDataService,
    private controlProcedureService: ControlProcedureDataService,
    private reportConfigurationTemplateService: ReportConfigurationTemplateDataService,
    private publishedPolicyService: PublishedPolicyDataService,
    private tagService: TagDataService,
    private statisticCollectorService: StatisticCollectorDataService,
    private generatedStatisticService: GeneratedStatisticDataService,
    private reportSchedulerDataService: ReportSchedulerDataService,
    private metricDataService: MetricDataService,
    private metricValueDataService: MetricValueDataService,
    private metricCategoryDataService: MetricCategoryDataService,
    private generatedReportDataService: GeneratedReportDataService,
    private languageDataService: LanguageDataService,
    private campaignDataService: CampaignDataService,
    private questionnaireRequestDataService: QuestionnaireCampaignRequestDataService,
    //Mapping Services
    private acknowledgmentDataMappingService: AcknowledgmentMappingService,
    private acknowledgmentRequestDataMappingService: AcknowledgmentRequestMappingService,
    private issuesDataMappingService: IssuesMappingService,
    private issueTypeDataMappingService: IssueTypeMappingService,
    private remediationPlanDataMappingService: RemediationPlanMappingService,
    private controlDataMappingService: ControlsMappingService,
    private controlObjectiveDataMappingService: ControlObjectivesMappingService,
    private frameworkExceptionDataMappingService: FrameworkExceptionsMappingService,
    private policyDataMappingService: PolicyMappingService,
    private questionDataMappingService: QuestionMappingService,
    private questionnaireDataMappingService: QuestionnaireMappingService,
    private questionnaireValueDataMappingService: QuestionnaireValueMappingService,
    private entityTypesDataMappingService: EntityTypesMappingService,
    private riskItemDataMappingService: RiskItemMappingService,
    private riskProjectDataMappingService: RiskProjectMappingService,
    private riskStatementDataMappingService: RiskStatementMappingService,
    private riskAssessmentRequestDataMappingService: RiskAssessmentRequestMappingService,
    private myRiskAssessmentRequestDataMappingService: MyRiskAssessmentRequestMappingService,
    private riskStatementCategoryDataMappingService: RiskStatementCategoryMappingService,
    private notificationDataMappingService: NotificationMappingService,
    private myNotificationDataMappingService: NotificationMappingService,
    private taskDataMappingService: TaskMappingService,
    // private myTaskDataMappingService: MyTaskMappingService,
    private responsibilitiesDataMappingService: ResponsibilitiesMappingService,
    private assetsDataMappingService: AssetsMappingService,
    private eventsDataMappingService: EventsMappingService,
    private objectivesDataMappingService: ObjectivesMappingService,
    private processesDataMappingService: ProcessesMappingService,
    private geographiesDataMappingService: GeographiesMappingService,
    private tagsDataMappingService: RelationTypesMappingService,
    private attestationDataMappingService: AttestationsMappingService,
    private organizationsDataMappingService: OrganizationsMappingService,
    private exemptionDataMappingService: ExemptionMappingService,
    private fieldsDataMappingService: FieldsMappingService,
    private globalValueListMappingService: GlobalValueListMappingService,
    private policyBodyMappingService: PolicyBodyMappingService,
    private policyBodySectionMappingService: PolicyBodySectionsMappingService,
    private complianceReportMappingService: ComplianceReportMappingService,
    private complianceReportResultMappingService: ComplianceReportResultMappingService,
    private gapReportMappingService: ControlsGapReportMappingService,
    private gapReportResultMappingService: ControlsGapReportResultMappingService,
    private emailTemplatesMappingService: EmailTemplatesMappingService,
    private taskTemplatesMappingService: TaskTemplatesMappingService,
    private myAcknowledgmentMappingService: MyAcknowledgmentMappingService,
    private audtiTrailMappingService: AuditTrailMappingService,
    private auditUniverseMappingService: AuditUniverseMappingService,
    private auditProgramTypeMappingService: AuditProgramTypeMappingService,
    private auditProgramMappingService: AuditProgramMappingService,
    private auditProjectMappingService: AuditProjectMappingService,
    private auditCaseMappingService: AuditCaseMappingService,
    private auditCaseTemplateMappingService: AuditTaskTemplateMappingService,
    private auditCaseResultMappingService: AuditCaseResultMappingService,
    private auditProgramReportMappingService: AuditProgramReportMappingService,
    private auditProjectReportMappingService: AuditProjectReportMappingService,
    private auditRequestMappingService: AuditRequestMappingService,
    private auditExpenseMappingService: AuditExpenseMappingService,
    private riskThreatMappingService: RiskThreatMappingService,
    private riskVulnerabilityMappingService: RiskVulnerabilityMappingService,
    private filterMappingService: SearchFilterMappingService,
    private viewMappingService: ViewMappingService,
    private dashboardMappingService: DashboardMappingService,
    private reportMappingService: ReportMappingService,
    private exportGlobalPackageMappingService: ExportGlobalPackageMappingService,
    private importGlobalPackageMappingService: ImportGlobalPackageMappingService,
    private transparencyCaseCategoryMappingService: TransparencyCaseCategoryMappingService,
    private transparencyCaseMappingService: TransparencyCaseMappingService,
    private standardMappingService: StandardMappingService,
    private standardSectionMappingService: StandardSectionMappingService,
    private guidelineMappingService: GuidelineMappingService,
    private citationMappingService: CitationMappingService,
    private authorityMappingService: AuthorityMappingService,
    private citationTypeMappingService: CitationTypeMappingService,
    private controlCategoryMappingService: ControlCategoryMappingService,
    private controlGroupMappingService: ControlGroupMappingService,
    private controlProcedureMappingService: ControlProcedureMappingService,
    private reportConfigurationTemplateMappingService: ReportConfigurationTemplateMappingService,
    private tagMappingService: TagMappingService,
    private statisticCollectorMappingService: StatisticCollectorMappingService,
    private generatedStatisticMappingService: GeneratedStatisticMappingService,
    private reportSchedulerMappingService: ReportSchedulerMappingService,
    private metricMappingService: MetricMappingService,
    private metricValueMappingService: MetricValueMappingService,
    private metricCategoryMappingService: MetricCategoryMappingService,
    private riskMethodologyMappingService: RiskMethodologyMappingService,
    private generatedReportMappingService: GeneratedReportMappingService,
    private languageMappingService: LanguageMappingService,
    private campaignMappingService: CampaignMappingService,
    private questionnaireRequestMappingService: QuestionnaireCampaignRequestMappingService
  ) {}

  getModuleKeywordByCode(code) {
    if (isNullObj(code) || typeof code !== 'string') return null;
    return CodeMapper[code?.substring(0, 3)];
  }
  getPathByCode(code: string, viewMode: IViewMode = 'view', data: any = null) {
    return this.isValidCode(code) ? getPathByCode(code, viewMode, data) : '';
  }
  isValidCode(code: string): boolean {
    if (isNullObj(code) || typeof code !== 'string') return false;

    let moduleKeyword = CodeMapper[code?.substring(0, 3)];
    if (isNullObj(moduleKeyword) || isNaN(Number.parseInt(code?.substring(3)))) {
      return false;
    }
    return true;
  }
  navigateByCode(code: string, viewMode: IViewMode = 'view', data: any = null) {
    let path = this.getPathByCode(code, viewMode, data);
    this.router.navigateByUrl(path);
  }
  getDataServiceByCode(code: string) {
    const moduleKeyword = this.getModuleKeywordByCode(code);
    return this.getDataServiceByModuleKeyword(moduleKeyword);
  }
  getDataServiceByModuleKeyword(moduleKeyword: ModuleKeywords) {
    if (!this.dataServiceByModuleKeywordMap[moduleKeyword]) {
      throw new Error(`NO MATCH FOR "${moduleKeyword}" WITH PREDEFINED DATA SERVICE`);
    }
    return this.dataServiceByModuleKeywordMap[moduleKeyword] as BaseRequestControllerService<any>;
  }
  getDataServiceByTargetType(targetType: TargetTypeEnum) {
    if (!this.dataServiceByTargetTypeMap[targetType]) {
      throw new Error(`NO MATCH FOR "${targetType}" WITH PREDEFINED DATA SERVICE`);
    }
    return this.dataServiceByTargetTypeMap[targetType] as BaseRequestControllerService<any>;
  }
  getMappingServiceByModuleKeyword(moduleKeyword: ModuleKeywords) {
    // @TODO: Add mapper for module keyword
    if (!this.mappingServiceByModuleKeywordMap[moduleKeyword]) {
      throw new Error(`NO MATCH FOR "${moduleKeyword}" WITH PREDEFINED MAPPING SERVICE`);
    }
    return this.mappingServiceByModuleKeywordMap[moduleKeyword] as BaseFieldDefinitionsService;
  }
  getMappingServiceByTargetType(targetType: TargetTypeEnum) {
    if (!this.mappingServiceByTargetTypeMap[targetType]) {
      throw new Error(`NO MATCH FOR "${targetType}" WITH PREDEFINED MAPPING SERVICE`);
    }
    return this.mappingServiceByTargetTypeMap[targetType] as BaseFieldDefinitionsService;
  }
  //@TODONewModule: when you add new module make sure to define these
  get dataServiceByTargetTypeMap() {
    return {
      [TargetTypeEnum.Acknowledgment]: this.acknowledgmentDataService,
      [TargetTypeEnum.AcknowledgmentRequest]: this.acknowledgmentRequestDataService,
      [TargetTypeEnum.Control]: this.controlDataService,
      [TargetTypeEnum.ControlObjective]: this.controlObjectiveDataService,
      [TargetTypeEnum.EntityType]: this.entityTypesDataService,
      [TargetTypeEnum.MyQuestionnaireValue]: this.myQuestionnaireValueDataService,
      [TargetTypeEnum.Policy]: this.policyDataService,
      [TargetTypeEnum.PublishedPolicies]: this.publishedPolicyService,
      [TargetTypeEnum.Question]: this.questionDataService,
      [TargetTypeEnum.Questionnaire]: this.questionnaireDataService,
      [TargetTypeEnum.QuestionnaireValue]: this.questionnaireValueDataService,
      [TargetTypeEnum.RiskItem]: this.riskItemDataService,
      [TargetTypeEnum.RiskMethodology]: this.riskMethodologyDataService,
      [TargetTypeEnum.RiskProject]: this.riskProjectDataService,
      [TargetTypeEnum.RiskAssessmentRequest]: this.riskAssessmentRequestDataService,
      ['MY_RISK_ASSESSMENT_REQUEST']: this.myRiskAssessmentRequestDataService,
      [TargetTypeEnum.RiskStatement]: this.riskStatementDataService,
      [TargetTypeEnum.RiskStatementCategory]: this.riskStatementCategoryDataService,
      [TargetTypeEnum.Task]: this.taskDataService,
      ['MY_TASK']: this.myTaskDataService,
      [TargetTypeEnum.Notification]: this.notificationDataService,
      ['MY_NOTIFICATION']: this.myNotificationDataService,
      [TargetTypeEnum.Responsibility]: this.responsibilitiesDataService,
      [TargetTypeEnum.Asset]: this.assetsDataService,
      [TargetTypeEnum.Event]: this.eventsDataService,
      [TargetTypeEnum.Objective]: this.objectivesDataService,
      [TargetTypeEnum.Process]: this.processesDataService,
      [TargetTypeEnum.Geography]: this.geographiesDataService,
      [TargetTypeEnum.Issue]: this.issuesDataService,
      [TargetTypeEnum.IssueType]: this.issueTypeDataService,
      [TargetTypeEnum.Remediation]: this.remediationPlanDataService,
      [TargetTypeEnum.Exception]: this.frameworkExceptionDataService,
      [TargetTypeEnum.RelationType]: this.tagsDataService,
      [TargetTypeEnum.Attestation]: this.attestationDataService,
      [TargetTypeEnum.Organization]: this.organizationsDataService,
      [TargetTypeEnum.Exemption]: this.exemptionDataService,
      [TargetTypeEnum.Field]: this.fieldsDataService,
      [TargetTypeEnum.GlobalValueList]: this.globalValueListService,
      [TargetTypeEnum.PolicyBody]: this.policyBodyService,
      [TargetTypeEnum.PolicyBodySection]: this.policyBodySectionService,
      [TargetTypeEnum.ComplianceReport]: this.complianceReportService,
      [TargetTypeEnum.ComplianceReportResult]: this.complianceReportResultService,
      ['GAP_REPORT']: this.gapReportService,
      ['GAP_REPORT_RESULT']: this.gapReportResultService,
      [TargetTypeEnum.EmailTemplate]: this.emailTemplatesService,
      [TargetTypeEnum.TaskTemplate]: this.taskTemplatesService,
      [TargetTypeEnum.MyAcknowledgment]: this.myAcknowledgmentService,
      [TargetTypeEnum.AuditTrail]: this.auditTrailService,
      [TargetTypeEnum.AuditUniverse]: this.auditUniverseService,
      [TargetTypeEnum.AuditProgramType]: this.auditProgramTypeService,
      [TargetTypeEnum.AuditProgram]: this.auditProgramService,
      [TargetTypeEnum.AuditProgramReport]: this.auditProgramReportService,
      [TargetTypeEnum.AuditProject]: this.auditProjectService,
      [TargetTypeEnum.AuditProjectReport]: this.auditProjectReportService,
      [TargetTypeEnum.AuditCase]: this.auditCaseService,
      [TargetTypeEnum.AuditCase]: this.auditCaseService,
      [TargetTypeEnum.AuditCaseTemplate]: this.auditCaseTemplateService,
      [TargetTypeEnum.AuditCaseResult]: this.auditCaseResultService,
      [TargetTypeEnum.AuditRequest]: this.auditRequestService,
      [TargetTypeEnum.AuditExpense]: this.auditExpenseService,
      [TargetTypeEnum.RiskThreat]: this.riskThreatService,
      [TargetTypeEnum.RiskVulnerability]: this.riskVulnerabilityService,
      [TargetTypeEnum.Filter]: this.filterDataService,
      [TargetTypeEnum.FieldTemplate]: this.fieldsDataService,
      [TargetTypeEnum.ContentView]: this.viewDataService,
      ['DASHBOARD']: this.dashboardDataService,
      [TargetTypeEnum.Report]: this.reportDataService,
      [TargetTypeEnum.ExportGlobalPackage]: this.exportGlobalPackageService,
      [TargetTypeEnum.ImportGlobalPackage]: this.importGlobalPackageService,
      [TargetTypeEnum.TransparencyCaseCategory]: this.transparencyCaseCategoryService,
      [TargetTypeEnum.TransparencyCase]: this.transparencyCaseService,
      [TargetTypeEnum.Standard]: this.standardService,
      // [TargetTypeEnum.StandardSection]: this.standardSectionService,
      [TargetTypeEnum.PublishedStandard]: this.publishedStandardService,
      [TargetTypeEnum.Guideline]: this.guidelineService,
      [TargetTypeEnum.PublishedGuideline]: this.publishedGuidelineService,
      [TargetTypeEnum.Citation]: this.citationService,
      [TargetTypeEnum.CitationType]: this.citationTypeService,
      [TargetTypeEnum.AuthorityDocument]: this.authorityService,
      [TargetTypeEnum.ControlCategory]: this.controlCategoryService,
      [TargetTypeEnum.ControlGroup]: this.controlGroupService,
      [TargetTypeEnum.ControlProcedure]: this.controlProcedureService,
      [TargetTypeEnum.ReportConfigurationTemplate]: this.reportConfigurationTemplateService,
      [TargetTypeEnum.Tag]: this.tagService,
      [TargetTypeEnum.StatisticCollector]: this.statisticCollectorService,
      [TargetTypeEnum.GeneratedStatistic]: this.generatedStatisticService,
      [TargetTypeEnum.FieldTemplate]: this.fieldsDataService,
      [TargetTypeEnum.ReportScheduler]: this.reportSchedulerDataService,
      [TargetTypeEnum.Metric]: this.metricDataService,
      [TargetTypeEnum.MetricValue]: this.metricValueDataService,
      [TargetTypeEnum.MetricCategory]: this.metricCategoryDataService,
      [TargetTypeEnum.GeneratedReport]: this.generatedReportDataService,
      [TargetTypeEnum.Language]: this.languageDataService,
      [TargetTypeEnum.Campaign]: this.campaignDataService,
      [TargetTypeEnum.QuestionnaireRequest]: this.questionnaireRequestDataService,
    };
  }
  get dataServiceByModuleKeywordMap() {
    return {
      [ModuleKeywords.Acknowledgment]: this.acknowledgmentDataService,
      [ModuleKeywords.AcknowledgmentRequest]: this.acknowledgmentRequestDataService,
      [ModuleKeywords.Control]: this.controlDataService,
      [ModuleKeywords.ControlObjective]: this.controlObjectiveDataService,
      [ModuleKeywords.EntityType]: this.entityTypesDataService,
      [ModuleKeywords.MyQuestionnaireValue]: this.myQuestionnaireValueDataService,
      [ModuleKeywords.Policy]: this.policyDataService,
      [ModuleKeywords.PublishedPolicy]: this.publishedPolicyService,
      [ModuleKeywords.Question]: this.questionDataService,
      [ModuleKeywords.Questionnaire]: this.questionnaireDataService,
      [ModuleKeywords.QuestionnaireValue]: this.questionnaireValueDataService,
      [ModuleKeywords.RiskItem]: this.riskItemDataService,
      [ModuleKeywords.RiskMethodology]: this.riskMethodologyDataService,
      [ModuleKeywords.RiskProject]: this.riskProjectDataService,
      [ModuleKeywords.RiskAssessmentRequest]: this.riskAssessmentRequestDataService,
      [ModuleKeywords.MyRiskAssessmentRequest]: this.myRiskAssessmentRequestDataService,
      [ModuleKeywords.RiskStatement]: this.riskStatementDataService,
      [ModuleKeywords.RiskStatementCategory]: this.riskStatementCategoryDataService,
      [ModuleKeywords.Notification]: this.notificationDataService,
      [ModuleKeywords.MyNotification]: this.myNotificationDataService,
      [ModuleKeywords.Task]: this.taskDataService,
      [ModuleKeywords.MyTask]: this.myTaskDataService,
      [ModuleKeywords.Responsibility]: this.responsibilitiesDataService,
      [ModuleKeywords.Asset]: this.assetsDataService,
      [ModuleKeywords.Event]: this.eventsDataService,
      [ModuleKeywords.Objective]: this.objectivesDataService,
      [ModuleKeywords.Process]: this.processesDataService,
      [ModuleKeywords.Geography]: this.geographiesDataService,
      [ModuleKeywords.Issue]: this.issuesDataService,
      [ModuleKeywords.IssueType]: this.issueTypeDataService,
      [ModuleKeywords.Remediation]: this.remediationPlanDataService,
      [ModuleKeywords.Exception]: this.frameworkExceptionDataService,
      [ModuleKeywords.RelationType]: this.tagsDataService,
      [ModuleKeywords.Attestation]: this.attestationDataService,
      [ModuleKeywords.Organization]: this.organizationsDataService,
      [ModuleKeywords.Exemption]: this.exemptionDataService,
      [ModuleKeywords.FieldTemplate]: this.fieldsDataService,
      [ModuleKeywords.GlobalValueList]: this.globalValueListService,
      [ModuleKeywords.PolicyBody]: this.policyBodyService,
      [ModuleKeywords.PolicyBodySection]: this.policyBodySectionService,
      [ModuleKeywords.ComplianceReport]: this.complianceReportService,
      [ModuleKeywords.ComplianceReportResult]: this.complianceReportResultService,
      [ModuleKeywords.ControlsGapReport]: this.gapReportService,
      [ModuleKeywords.ControlsGapReportResult]: this.gapReportResultService,
      [ModuleKeywords.EmailTemplate]: this.emailTemplatesService,
      [ModuleKeywords.TaskTemplate]: this.taskTemplatesService,
      [ModuleKeywords.MyAcknowledgment]: this.myAcknowledgmentService,
      [ModuleKeywords.AuditTrail]: this.auditTrailService,
      [ModuleKeywords.AuditUniverse]: this.auditUniverseService,
      [ModuleKeywords.AuditProgramType]: this.auditProgramTypeService,
      [ModuleKeywords.AuditProgram]: this.auditProgramService,
      [ModuleKeywords.AuditProgramReport]: this.auditProgramReportService,
      [ModuleKeywords.AuditProject]: this.auditProjectService,
      [ModuleKeywords.AuditProjectReport]: this.auditProjectReportService,
      [ModuleKeywords.AuditCase]: this.auditCaseService,
      [ModuleKeywords.AuditCaseTemplate]: this.auditCaseTemplateService,
      [ModuleKeywords.AuditCaseResult]: this.auditCaseResultService,
      [ModuleKeywords.AuditRequest]: this.auditRequestService,
      [ModuleKeywords.AuditExpense]: this.auditExpenseService,
      [ModuleKeywords.RiskThreat]: this.riskThreatService,
      [ModuleKeywords.RiskVulnerability]: this.riskVulnerabilityService,
      [ModuleKeywords.Filter]: this.filterDataService,
      [ModuleKeywords.ContentView]: this.viewDataService,
      [ModuleKeywords.Dashboard]: this.dashboardDataService,
      [ModuleKeywords.Report]: this.reportDataService,
      [ModuleKeywords.ExportGlobalPackage]: this.exportGlobalPackageService,
      [ModuleKeywords.ImportGlobalPackage]: this.importGlobalPackageService,
      [ModuleKeywords.TransparencyCaseCategory]: this.transparencyCaseCategoryService,
      [ModuleKeywords.TransparencyCase]: this.transparencyCaseService,
      [ModuleKeywords.Standard]: this.standardService,
      // [ModuleKeywords.StandardSection]: this.standardSectionService,
      [ModuleKeywords.PublishedStandard]: this.publishedStandardService,
      [ModuleKeywords.Guideline]: this.guidelineService,
      [ModuleKeywords.PublishedGuideline]: this.publishedGuidelineService,
      [ModuleKeywords.Citation]: this.citationService,
      [ModuleKeywords.CitationType]: this.citationTypeService,
      [ModuleKeywords.AuthorityDocument]: this.authorityService,
      [ModuleKeywords.ControlCategory]: this.controlCategoryService,
      [ModuleKeywords.ControlGroup]: this.controlGroupService,
      [ModuleKeywords.ControlProcedure]: this.controlProcedureService,
      [ModuleKeywords.ReportConfigurationTemplate]: this.reportConfigurationTemplateService,
      [ModuleKeywords.Tag]: this.tagService,
      [ModuleKeywords.StatisticCollector]: this.statisticCollectorService,
      [ModuleKeywords.GeneratedStatistic]: this.generatedStatisticService,
      ['MY_TASK']: this.myTaskDataService,
      ['MY_NOTIFICATION']: this.myNotificationDataService,
      [ModuleKeywords.ReportScheduler]: this.reportSchedulerDataService,
      [ModuleKeywords.Metric]: this.metricDataService,
      [ModuleKeywords.MetricValue]: this.metricValueDataService,
      [ModuleKeywords.MetricCategory]: this.metricCategoryDataService,
      [ModuleKeywords.GeneratedReport]: this.generatedReportDataService,
      [ModuleKeywords.Language]: this.languageDataService,
      [ModuleKeywords.Campaign]: this.campaignDataService,
      [ModuleKeywords.QuestionnaireRequest]: this.questionnaireRequestDataService,
    };
  }
  get mappingServiceByTargetTypeMap() {
    return {
      [TargetTypeEnum.Acknowledgment]: this.acknowledgmentDataMappingService,
      [TargetTypeEnum.AcknowledgmentRequest]: this.acknowledgmentRequestDataMappingService,
      [TargetTypeEnum.Control]: this.controlDataMappingService,
      [TargetTypeEnum.ControlObjective]: this.controlObjectiveDataMappingService,
      [TargetTypeEnum.EntityType]: this.entityTypesDataMappingService,
      //   [TargetTypeEnum.MyQuestionnaireValue]: this.myQuestionnaireValueDataMappingService,
      [TargetTypeEnum.Policy]: this.policyDataMappingService,
      [TargetTypeEnum.PublishedPolicies]: this.policyDataMappingService,
      [TargetTypeEnum.Question]: this.questionDataMappingService,
      [TargetTypeEnum.Questionnaire]: this.questionnaireDataMappingService,
      [TargetTypeEnum.QuestionnaireValue]: this.questionnaireValueDataMappingService,
      [TargetTypeEnum.RiskItem]: this.riskItemDataMappingService,
      //   [TargetTypeEnum.RiskMethodology]: this.riskMethodologyDataMappingService,
      [TargetTypeEnum.RiskProject]: this.riskProjectDataMappingService,
      [TargetTypeEnum.RiskAssessmentRequest]: this.riskAssessmentRequestDataMappingService,
      ['MY_RISK_ASSESSMENT_REQUEST']: this.myRiskAssessmentRequestDataMappingService,
      [TargetTypeEnum.RiskStatement]: this.riskStatementDataMappingService,
      [TargetTypeEnum.RiskStatementCategory]: this.riskStatementCategoryDataMappingService,
      [TargetTypeEnum.Task]: this.taskDataMappingService,
      //   ['MY_TASK']: this.myTaskDataMappingService,
      [TargetTypeEnum.Notification]: this.notificationDataMappingService,
      ['MY_NOTIFICATION']: this.myNotificationDataMappingService,
      [TargetTypeEnum.Responsibility]: this.responsibilitiesDataMappingService,
      [TargetTypeEnum.Asset]: this.assetsDataMappingService,
      [TargetTypeEnum.Event]: this.eventsDataMappingService,
      [TargetTypeEnum.Objective]: this.objectivesDataMappingService,
      [TargetTypeEnum.Process]: this.processesDataMappingService,
      [TargetTypeEnum.Geography]: this.geographiesDataMappingService,
      [TargetTypeEnum.Issue]: this.issuesDataMappingService,
      [TargetTypeEnum.IssueType]: this.issueTypeDataMappingService,
      [TargetTypeEnum.Remediation]: this.remediationPlanDataMappingService,
      [TargetTypeEnum.Exception]: this.frameworkExceptionDataMappingService,
      [TargetTypeEnum.RelationType]: this.tagsDataMappingService,
      [TargetTypeEnum.Attestation]: this.attestationDataMappingService,
      [TargetTypeEnum.Organization]: this.organizationsDataMappingService,
      [TargetTypeEnum.Exemption]: this.exemptionDataMappingService,
      [TargetTypeEnum.Field]: this.fieldsDataMappingService,
      [TargetTypeEnum.GlobalValueList]: this.globalValueListMappingService,
      [TargetTypeEnum.PolicyBody]: this.policyBodyMappingService,
      [TargetTypeEnum.PolicyBodySection]: this.policyBodySectionMappingService,
      [TargetTypeEnum.ComplianceReport]: this.complianceReportMappingService,
      [TargetTypeEnum.ComplianceReportResult]: this.complianceReportResultMappingService,
      ['GAP_REPORT']: this.gapReportMappingService,
      ['GAP_REPORT_RESULT']: this.gapReportResultMappingService,
      [TargetTypeEnum.EmailTemplate]: this.emailTemplatesMappingService,
      [TargetTypeEnum.TaskTemplate]: this.taskTemplatesMappingService,
      ['MY_ACKNOWLEDGMENT']: this.myAcknowledgmentMappingService,
      [TargetTypeEnum.AuditTrail]: this.audtiTrailMappingService,
      [TargetTypeEnum.AuditUniverse]: this.auditUniverseMappingService,
      [TargetTypeEnum.AuditProgramType]: this.auditProgramTypeMappingService,
      [TargetTypeEnum.AuditProgram]: this.auditProgramMappingService,
      [TargetTypeEnum.AuditProgramReport]: this.auditProgramReportMappingService,
      [TargetTypeEnum.AuditProject]: this.auditProjectMappingService,
      [TargetTypeEnum.AuditProjectReport]: this.auditProjectMappingService,
      [TargetTypeEnum.AuditCase]: this.auditCaseMappingService,
      [TargetTypeEnum.AuditCaseResult]: this.auditCaseResultMappingService,
      [TargetTypeEnum.AuditRequest]: this.auditRequestMappingService,
      [TargetTypeEnum.AuditExpense]: this.auditExpenseMappingService,
      [TargetTypeEnum.RiskThreat]: this.riskThreatMappingService,
      [TargetTypeEnum.RiskVulnerability]: this.riskVulnerabilityMappingService,
      [TargetTypeEnum.Filter]: this.filterMappingService,
      [TargetTypeEnum.ContentView]: this.viewMappingService,
      ['DASHBOARD']: this.dashboardMappingService,
      [TargetTypeEnum.Report]: this.reportMappingService,
      [TargetTypeEnum.ExportGlobalPackage]: this.exportGlobalPackageMappingService,
      [TargetTypeEnum.ImportGlobalPackage]: this.importGlobalPackageMappingService,
      [TargetTypeEnum.TransparencyCaseCategory]: this.transparencyCaseCategoryMappingService,
      [TargetTypeEnum.TransparencyCase]: this.transparencyCaseMappingService,
      [TargetTypeEnum.Standard]: this.standardMappingService,
      // [TargetTypeEnum.StandardSection]: this.standardSectionMappingService,
      [TargetTypeEnum.PublishedStandard]: this.standardMappingService,
      [TargetTypeEnum.Guideline]: this.guidelineMappingService,
      [TargetTypeEnum.PublishedGuideline]: this.guidelineMappingService,
      [TargetTypeEnum.Citation]: this.citationMappingService,
      [TargetTypeEnum.CitationType]: this.guidelineMappingService,
      [TargetTypeEnum.AuthorityDocument]: this.guidelineMappingService,
      [TargetTypeEnum.ControlCategory]: this.controlCategoryMappingService,
      [TargetTypeEnum.ControlGroup]: this.controlCategoryMappingService,
      [TargetTypeEnum.ControlProcedure]: this.controlCategoryMappingService,
      [TargetTypeEnum.ReportConfigurationTemplate]: this.reportConfigurationTemplateMappingService,
      [TargetTypeEnum.Tag]: this.tagMappingService,
      [TargetTypeEnum.StatisticCollector]: this.statisticCollectorMappingService,
      [TargetTypeEnum.GeneratedStatistic]: this.generatedStatisticMappingService,
      ['MY_TASK']: this.taskDataMappingService,
      [TargetTypeEnum.AuditCaseTemplate]: this.auditCaseTemplateMappingService,
      [TargetTypeEnum.FieldTemplate]: this.fieldsDataMappingService,
      [TargetTypeEnum.ReportScheduler]: this.reportSchedulerMappingService,
      [TargetTypeEnum.Metric]: this.metricMappingService,
      [TargetTypeEnum.MetricValue]: this.metricValueMappingService,
      [TargetTypeEnum.MetricCategory]: this.metricCategoryMappingService,
      [TargetTypeEnum.RiskMethodology]: this.riskMethodologyMappingService,
      [TargetTypeEnum.GeneratedReport]: this.generatedReportMappingService,
      [TargetTypeEnum.Language]: this.languageMappingService,
      [TargetTypeEnum.Campaign]: this.campaignMappingService,
      [TargetTypeEnum.QuestionnaireRequest]: this.questionnaireRequestMappingService,
    };
  }
  get mappingServiceByModuleKeywordMap() {
    return {
      [ModuleKeywords.Acknowledgment]: this.acknowledgmentDataMappingService,
      [ModuleKeywords.AcknowledgmentRequest]: this.acknowledgmentRequestDataMappingService,
      [ModuleKeywords.Control]: this.controlDataMappingService,
      [ModuleKeywords.ControlObjective]: this.controlObjectiveDataMappingService,
      [ModuleKeywords.EntityType]: this.entityTypesDataMappingService,
      //   [ModuleKeywords.MyQuestionnaireValue]: this.myQuestionnaireValueDataMappingService,
      [ModuleKeywords.Policy]: this.policyDataMappingService,
      [ModuleKeywords.PublishedPolicy]: this.policyDataMappingService,
      [ModuleKeywords.Question]: this.questionDataMappingService,
      [ModuleKeywords.Questionnaire]: this.questionnaireDataMappingService,
      [ModuleKeywords.QuestionnaireValue]: this.questionnaireValueDataMappingService,
      [ModuleKeywords.RiskItem]: this.riskItemDataMappingService,
      //   [ModuleKeywords.RiskMethodology]: this.riskMethodologyDataMappingService,
      [ModuleKeywords.RiskProject]: this.riskProjectDataMappingService,
      [ModuleKeywords.RiskAssessmentRequest]: this.riskAssessmentRequestDataMappingService,
      [ModuleKeywords.MyRiskAssessmentRequest]: this.myRiskAssessmentRequestDataMappingService,
      [ModuleKeywords.RiskStatement]: this.riskStatementDataMappingService,
      [ModuleKeywords.RiskStatementCategory]: this.riskStatementCategoryDataMappingService,
      [ModuleKeywords.Notification]: this.notificationDataMappingService,
      //   [ModuleKeywords.MyNotification]: this.myNotificationDataMappingService,
      [ModuleKeywords.Task]: this.taskDataMappingService,
      //   [ModuleKeywords.MyTask]: this.myTaskDataMappingService,
      [ModuleKeywords.Responsibility]: this.responsibilitiesDataMappingService,
      [ModuleKeywords.Asset]: this.assetsDataMappingService,
      [ModuleKeywords.Event]: this.eventsDataMappingService,
      [ModuleKeywords.Objective]: this.objectivesDataMappingService,
      [ModuleKeywords.Process]: this.processesDataMappingService,
      [ModuleKeywords.Geography]: this.geographiesDataMappingService,
      [ModuleKeywords.Issue]: this.issuesDataMappingService,
      [ModuleKeywords.IssueType]: this.issueTypeDataMappingService,
      [ModuleKeywords.Remediation]: this.remediationPlanDataMappingService,
      [ModuleKeywords.Exception]: this.frameworkExceptionDataMappingService,
      [ModuleKeywords.RelationType]: this.tagsDataMappingService,
      [ModuleKeywords.Attestation]: this.attestationDataMappingService,
      [ModuleKeywords.Organization]: this.organizationsDataMappingService,
      [ModuleKeywords.Exemption]: this.exemptionDataMappingService,
      [ModuleKeywords.FieldTemplate]: this.fieldsDataMappingService,
      [ModuleKeywords.GlobalValueList]: this.globalValueListMappingService,
      [ModuleKeywords.PolicyBody]: this.policyBodyMappingService,
      [ModuleKeywords.PolicyBodySection]: this.policyBodySectionMappingService,
      [ModuleKeywords.ComplianceReport]: this.complianceReportMappingService,
      [ModuleKeywords.ComplianceReportResult]: this.complianceReportResultMappingService,
      [ModuleKeywords.ControlsGapReport]: this.gapReportMappingService,
      [ModuleKeywords.ControlsGapReportResult]: this.gapReportResultMappingService,
      [ModuleKeywords.EmailTemplate]: this.emailTemplatesMappingService,
      [ModuleKeywords.TaskTemplate]: this.taskTemplatesMappingService,
      [ModuleKeywords.Notification]: this.notificationDataMappingService,
      [ModuleKeywords.MyAcknowledgment]: this.myAcknowledgmentMappingService,
      [ModuleKeywords.AuditTrail]: this.audtiTrailMappingService,
      [ModuleKeywords.AuditUniverse]: this.auditUniverseMappingService,
      [ModuleKeywords.AuditProgramType]: this.auditProgramTypeMappingService,
      [ModuleKeywords.AuditProgram]: this.auditProgramMappingService,
      [ModuleKeywords.AuditProgramReport]: this.auditProgramReportMappingService,
      [ModuleKeywords.AuditProject]: this.auditProjectMappingService,
      [ModuleKeywords.AuditProjectReport]: this.auditProjectReportMappingService,
      [ModuleKeywords.AuditCase]: this.auditCaseMappingService,
      [ModuleKeywords.AuditCaseTemplate]: this.auditCaseTemplateMappingService,
      [ModuleKeywords.AuditCaseResult]: this.auditCaseResultMappingService,
      [ModuleKeywords.AuditRequest]: this.auditRequestMappingService,
      [ModuleKeywords.AuditExpense]: this.auditExpenseMappingService,
      [ModuleKeywords.RiskThreat]: this.riskThreatMappingService,
      [ModuleKeywords.RiskVulnerability]: this.riskVulnerabilityMappingService,
      [ModuleKeywords.Filter]: this.filterMappingService,
      [ModuleKeywords.ContentView]: this.viewMappingService,
      [ModuleKeywords.Dashboard]: this.dashboardMappingService,
      [ModuleKeywords.Report]: this.reportMappingService,
      [ModuleKeywords.ExportGlobalPackage]: this.exportGlobalPackageMappingService,
      [ModuleKeywords.ImportGlobalPackage]: this.importGlobalPackageMappingService,
      [ModuleKeywords.TransparencyCaseCategory]: this.transparencyCaseCategoryMappingService,
      [ModuleKeywords.TransparencyCase]: this.transparencyCaseMappingService,
      [ModuleKeywords.Standard]: this.standardMappingService,
      // [ModuleKeywords.StandardSection]: this.standardSectionMappingService,
      [ModuleKeywords.PublishedStandard]: this.standardMappingService,
      [ModuleKeywords.Guideline]: this.guidelineMappingService,
      [ModuleKeywords.PublishedGuideline]: this.guidelineMappingService,
      [ModuleKeywords.Citation]: this.citationMappingService,
      [ModuleKeywords.CitationType]: this.citationTypeMappingService,
      [ModuleKeywords.AuthorityDocument]: this.authorityMappingService,
      [ModuleKeywords.ControlCategory]: this.controlCategoryMappingService,
      [ModuleKeywords.ControlGroup]: this.controlGroupMappingService,
      [ModuleKeywords.ControlProcedure]: this.controlProcedureMappingService,
      [ModuleKeywords.ReportConfigurationTemplate]: this.reportConfigurationTemplateMappingService,
      [ModuleKeywords.Tag]: this.tagMappingService,
      [ModuleKeywords.StatisticCollector]: this.statisticCollectorMappingService,
      [ModuleKeywords.GeneratedStatistic]: this.generatedStatisticMappingService,
      ['MY_TASK']: this.taskDataMappingService,
      ['MY_NOTIFICATION']: this.myNotificationDataMappingService,
      [ModuleKeywords.ReportScheduler]: this.reportSchedulerMappingService,
      [ModuleKeywords.Metric]: this.metricMappingService,
      [ModuleKeywords.MetricValue]: this.metricValueMappingService,
      [ModuleKeywords.MetricCategory]: this.metricCategoryMappingService,
      [ModuleKeywords.RiskMethodology]: this.riskMethodologyMappingService,
      [ModuleKeywords.GeneratedReport]: this.generatedReportMappingService,
      [ModuleKeywords.Language]: this.languageMappingService,
      [ModuleKeywords.Campaign]: this.campaignMappingService,
      [ModuleKeywords.QuestionnaireRequest]: this.questionnaireRequestMappingService,
    };
  }
  changeViewByPath(viewMode: IViewMode) {
    let path = this.router.url;
    path = path.replace('/view/', `/${viewMode}/`);
    path = path.replace('/edit/', `/${viewMode}/`);
    this.router.navigateByUrl(path);
  }
  changeTreeViewByPath(viewMode: string, switchOptions: { value: string }[], clipUrl = false) {
    let path = this.router.url;
    switchOptions?.forEach((option) => {
      const regex = new RegExp(clipUrl ? `/${option?.value}.*` : `/${option?.value}`);
      path = path.replace(regex, `/${viewMode}`);
    });
    // path = path.replace('/tree', `/${viewMode}`);
    this.router.navigateByUrl(path);
  }
  checkIfCodeIsEntity(code: string) {
    if (isNullObj(code)) return false;

    let moduleKeyword = this.getModuleKeywordByCode(code);
    return this.checkIfModuleIsEntity(moduleKeyword);
  }
  checkIfModuleIsEntity(moduleKeyword: ModuleKeywords) {
    if (isNullObj(moduleKeyword)) return false;
    return this.entitiesModuleKeywords.indexOf(moduleKeyword) != -1;
  }
  getModuleKeywordByPath(path: string) {
    let moduleKeyword: ModuleKeywords;
    MenuItemList?.forEach((rootItem) => {
      if (!isNullObj(rootItem?.items)) {
        rootItem?.items.forEach((item) => {
          if (!isNullObj(item?.items)) {
            item?.items.forEach((childItem) => {
              if (childItem?.routerLink?.indexOf(path) != -1) {
                moduleKeyword = childItem?.moduleKeyword;
              }
            });
          } else {
            if (item?.routerLink?.indexOf(path) != -1) {
              moduleKeyword = item?.moduleKeyword;
            }
          }
        });
      }
    });
    return moduleKeyword;
  }
  getRelatedModulesByPath(path: string) {
    let moduleKeyword: ModuleKeywords;
    let relatedModules: ModuleKeywords[] = [];
    //rendered menu items is set through appMenu component because it already went through permission handling
    if (isNullObj(this.renderedMenuItems)) return null;

    this.renderedMenuItems?.forEach((rootItem) => {
      if (!isNullObj(rootItem?.items)) {
        rootItem?.items.forEach((item) => {
          if (!isNullObj(item?.items)) {
            item?.items.forEach((childItem) => {
              if (childItem?.routerLink?.indexOf(path) != -1) {
                moduleKeyword = childItem?.moduleKeyword;
                rootItem?.items?.forEach((pItem) => {
                  if (!isNullObj(pItem?.moduleKeyword)) relatedModules.push(pItem?.moduleKeyword);
                  if (!isNullObj(pItem?.items)) {
                    pItem?.items.forEach((pChildItem) => {
                      if (!isNullObj(pChildItem?.moduleKeyword)) relatedModules.push(pChildItem?.moduleKeyword);
                    });
                  }
                });
              }
            });
          } else {
            if (item?.routerLink?.indexOf(path) != -1) {
              moduleKeyword = item?.moduleKeyword;
              rootItem?.items?.forEach((pItem) => {
                if (!isNullObj(pItem?.moduleKeyword)) relatedModules.push(pItem?.moduleKeyword);
                if (!isNullObj(pItem?.items)) {
                  pItem?.items.forEach((pChildItem) => {
                    if (!isNullObj(pChildItem?.moduleKeyword)) relatedModules.push(pChildItem?.moduleKeyword);
                  });
                }
              });
            }
          }
        });
      }
    });
    return { moduleKeyword, relatedModules };
  }
  getCurrentPathRelatedModules() {
    const currentPath = this.location.path();

    return this.getRelatedModulesByPath(currentPath);
  }
}
