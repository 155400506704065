import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { ApiResponseStandardEvent, Standard } from '@shared/classes';
import { BaseRequestControllerWithRuleService } from '@shared/services/api/custom-api-services/base-request-controller-with-rule.service';
import { DataService } from '@shared/services/api/data.service';
@Injectable({
  providedIn: 'root',
})
export class StandardDataService extends BaseRequestControllerWithRuleService<
  Standard,
  ApiResponseStandardEvent.DataEnum
> {
  constructor(
    private dataService: DataService,
    private router: Router
  ) {
    super(dataService, environment.framework, '/standards', [
      'creatorName',
      'creationDate',
      'lastModifierName',
      'lastModificationDate',
      'id',
      'tags',
      'tagsCodes',
      'searchScore',
      'recordStatus',
      'uuid',
      'description',
      'code',
      'label',
      'parent',
      'parents',
      'module',
      'systemGenerated',
      'softDeleteDate',
      'name',
      'owner',
      'status',
      'approvers',
      'reviewers',
      'sections',
      'policySections',
      'citations',
      'state',
      'codePrefix',
      'lockedUntil',
      'lockedForEdit',
      'lockedBy',
      'userAccessLevel',
    ]);
  }
  // public getApprove(policyIdOrCode) {
  //   const newLocal = this.url + `/${policyIdOrCode}/approve`;
  //   return this.dataService.getData<BaseResponse<PolicyBody>>(
  //     newLocal,
  //     this.apiOptions
  //   ) as Observable<BaseResponse<any>>;
  // }
  navigateToListPage() {
    this.router.navigateByUrl(`framework/standard/list`);
  }
}
