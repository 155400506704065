import { Injectable } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { AppPermissions, DataTypeEnum, IFieldDefinition, TargetTypeEnum } from '@shared/classes';
import { TargetCodeSelectorComponent } from '@shared/components/selectors/target-code-selector/target-code-selector.component';
import { BasicInputComponent } from '@shared/components/ui/input-fields/basic-input/basic-input.component';
import { TextEditorComponent } from '@shared/components/ui/input-fields/text-editor/text-editor.component';
import { BaseFieldDefinitionsService } from '@shared/services/base-field-definitions.service';

@Injectable({
  providedIn: 'root',
})
export class ControlObjectivesMappingService extends BaseFieldDefinitionsService {
  recordFields: IFieldDefinition[] = [
    {
      name: 'Name',
      key: 'name',
      dataType: DataTypeEnum.Text,
      required: true,
      showInTable: true,
      description: 'Record Name',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
      importerDefinition: {
        type: 'text',
        field: {
          validators: null,
        },
      },
      useInBulk: true,
      formField: {
        componentType: BasicInputComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Enter Name',
          control: new FormControl('', Validators.required),
          dataType: DataTypeEnum.Text,
          showLabelInViewMode: false,
        },
      },
    },
    {
      name: 'Category',
      key: 'controlCategory',
      dataType: DataTypeEnum.CodeLink,
      required: false,
      showInTable: true,
      permissions: AppPermissions.ReadControlCategory,
      description: 'Control Category of the record',
      tableDefinition: {
        filter: {
          type: 'recordCode',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          recordCodeType: TargetTypeEnum.ControlCategory,
        },
      },
      importerDefinition: {
        type: 'text_content',
        field: {
          validators: [
            {
              key: 'text_content',
              value: {
                type: 'text_content',
                type2: 'include',
                value1: 'CCA-',
                value2: '',
                checked: false,
                remote: false,
                prohibitInput: false,
                hintShow: true,
                hintText: 'must start with CCA-',
              },
            },
          ],
        },
      },
      useInBulk: true,
      formField: {
        componentType: TargetCodeSelectorComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Search Control Categories',
          control: new FormControl(null),
          inputOptions: {
            codeSelectorInput: {
              targetTypes: ['CONTROL_CATEGORY'],
            },
            dropDownInput: {
              multi: false,
              items: null,
              customProjectionFields: ['code', 'recordStatus', 'label', 'name'],
              optionLabel: 'name',
              optionValue: 'code'
            },
          },
          showLabelInViewMode: false,
        },
      },
    },
    {
      name: 'Policy Sections',
      key: 'policySections',
      dataType: DataTypeEnum.CodeLink,
      required: false,
      showInTable: false,
      permissions: AppPermissions.ReadPolicyBodySection,
      description: 'Policy Body Sections of the record',
      tableDefinition: {
        filter: {
          type: 'recordCode',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          recordCodeType: TargetTypeEnum.PolicyBodySection,
        },
      },
      importerDefinition: {
        type: 'text_content',
        field: {
          validators: null,
        },
        isArray: true,
        isCommaDelimiter: true,
      },
      useInBulk: true,
      formField: {
        componentType: TargetCodeSelectorComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Search Policy Sections',
          control: new FormControl(null),
          inputOptions: {
            codeSelectorInput: {
              targetTypes: ['POLICY_BODY_SECTION'],
            },
            dropDownInput: {
              multi: false,
              items: null,
              customProjectionFields: ['code', 'recordStatus', 'label', 'name'],
              optionLabel: 'name',
              optionValue: 'code'
            },
          },
          showLabelInViewMode: false,
        },
      },
    },
    {
      name: 'Citations',
      key: 'citations',
      dataType: DataTypeEnum.CodeLink,
      required: false,
      showInTable: false,
      permissions: AppPermissions.ReadCitation,
      description: 'Citations of the record',
      tableDefinition: {
        filter: {
          type: 'recordCode',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          recordCodeType: TargetTypeEnum.Citation,
        },
      },
      importerDefinition: {
        type: 'text_content',
        field: {
          validators: null,
        },
        isArray: true,
        isCommaDelimiter: true,
      },
      useInBulk: true,
      formField: {
        componentType: TargetCodeSelectorComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Search Citations',
          control: new FormControl(null),
          inputOptions: {
            codeSelectorInput: {
              targetTypes: ['CITATION'],
            },
            dropDownInput: {
              multi: false,
              items: null,
              customProjectionFields: ['code', 'recordStatus', 'label', 'name'],
              optionLabel: 'name',
              optionValue: 'code'
            },
          },
          showLabelInViewMode: false,
        },
      },
    },
    // {
    //     name: "Owner",
    //     key: "owner",
    //     dataType: DataTypeEnum.ResponsibilityListView,
    //     required: true,
    //     showInTable: true,
    //     // permissions: AppPermissions.ReadGroup,
    //     description: "Owner group of the asset",
    //     tableDefinition: {
    //         filter: {
    //             type: "text",
    //             display: "menu",
    //             matchMode: "startsWith",
    //             showMatchModes: true,
    //             showAddButton: true,
    //             showOperator: true,
    //         },
    //     }
    // },
    // {
    //     name: "Policy",
    //     key: "policy",
    //     dataType: DataTypeEnum.CodeLink,
    //     required: true,
    //     showInTable: true,
    //     permissions: AppPermissions.ReadPolicy,
    //     description: "Policy Linked to the control objective",
    //     tableDefinition: {
    //         filter: {
    //             type: 'recordCode',
    //             matchMode: "in",
    //             showMatchModes: false,
    //             showAddButton: false,
    //             showOperator: false,
    //             recordCodeType: TargetTypeEnum.Policy
    //         },
    //     }
    // },
    // {
    //     name: "Section",
    //     key: "policySection",
    //     dataType: DataTypeEnum.CodeLink,
    //     required: true,
    //     showInTable: true,
    //     permissions: AppPermissions.ReadPolicyBodySection,
    //     description: "Policy Section Linked to the control objective",
    //     tableDefinition: {
    //         filter: {
    //             type: 'recordCode',
    //             matchMode: "in",
    //             showMatchModes: false,
    //             showAddButton: false,
    //             showOperator: false,
    //             recordCodeType: TargetTypeEnum.Policy
    //         },
    //     }
    // },
    // {
    //     name: "Respondent",
    //     key: "attestationRespondent.name",
    //     dataType: DataTypeEnum.User,
    //     required: true,
    //     showInTable: true,
    //     // permissions: AppPermissions.ReadGroup,
    //     description: "Respondent of the control objective changes",
    //     tableDefinition: {
    //         filter: {
    //             type: "text",
    //             display: "menu",
    //             matchMode: "startsWith",
    //             showMatchModes: true,
    //             showAddButton: true,
    //             showOperator: true,
    //         },
    //     }
    // },
    // {
    //     name: "Compliance Status",
    //     key: "progress",
    //     dataType: DataTypeEnum.DynamicComponent,
    //     required: false,
    //     showInTable: true,
    //     description: "Compliance Status of the control objective respondents",
    //     tableDefinition: {
    //         options: {
    //             dynamicComponent: ControlComplianceProgressBarComponent
    //         },
    //         passRowAsData: true
    //     }
    // },
    // {
    //     name: "Control Frequency",
    //     key: "controlFrequency",
    //     dataType: DataTypeEnum.Number,
    //     required: true,
    //     showInTable: true,
    //     description: "Control Frequency of the control objective",
    //     tableDefinition: {
    //         filter: {
    //             type: "numeric",
    //             display: "menu",
    //             matchMode: "lte",
    //         },
    //     }
    // },
    // {
    //     name: "Control Classification",
    //     key: "controlClassification",
    //     dataType: DataTypeEnum.Badge,
    //     required: true,
    //     showInTable: true,
    //     description: "Control Classification of the control group",
    //     tableDefinition: {
    //         filter: {
    //             type: "enum",
    //             display: "menu",
    //             matchMode: "in",
    //             showMatchModes: false,
    //             showAddButton: false,
    //             showOperator: false,
    //             enumClass: ControlGroupDto.ControlClassificationEnum,
    //         },
    //     }
    // },
    // {
    //     name: "Entities",
    //     key: "controlEntities",
    //     dataType: DataTypeEnum.CodeLink,
    //     required: false,
    //     showInTable: true,
    //     permissions: AppPermissions.ReadAnyEntity,
    //     description: "Entities Linked to the control objective",
    //     tableDefinition: {
    //         filter: {
    //             type: "text",
    //             display: "menu",
    //             matchMode: "startsWith",
    //             showMatchModes: true,
    //             showAddButton: true,
    //             showOperator: true,
    //         },
    //     }
    // },
    // {
    //     name: "Questionnaire",
    //     key: "controlAttestationTemplate",
    //     dataType: DataTypeEnum.CodeLink,
    //     required: false,
    //     showInTable: true,
    //     permissions: AppPermissions.ReadQuestionnaire,
    //     description: "Questionnaire Linked to the control objective",
    //     tableDefinition: {
    //         filter: {
    //             type: 'recordCode',
    //             matchMode: "in",
    //             showMatchModes: false,
    //             showAddButton: false,
    //             showOperator: false,
    //             recordCodeType: TargetTypeEnum.Questionnaire
    //         },
    //     }
    // },
    {
      name: 'Source of Control',
      key: 'sourceOfControl',
      dataType: DataTypeEnum.Text,
      required: false,
      showInTable: false,
      description: 'Source of Control of the record',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
      useInBulk: true,
      formField: {
        componentType: BasicInputComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Enter Source Of Control',
          control: new FormControl(''),
          dataType: DataTypeEnum.Text,
          showLabelInViewMode: false,
        },
      },
    },
    {
      name: 'Source Reference',
      key: 'sourceReference',
      dataType: DataTypeEnum.Text,
      required: false,
      showInTable: false,
      description: 'Source Reference of the record',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
      useInBulk: true,
      formField: {
        componentType: BasicInputComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Enter Source Reference',
          control: new FormControl(''),
          dataType: DataTypeEnum.Text,
          showLabelInViewMode: false,
        },
      },
    },
    {
      name: 'Unique Identifier',
      key: 'uniqueIdentifier',
      dataType: DataTypeEnum.Text,
      required: false,
      showInTable: false,
      description: 'Unique Identifier of the record',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
      useInBulk: true,
      formField: {
        componentType: BasicInputComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Enter Unique Identifier',
          control: new FormControl(''),
          dataType: DataTypeEnum.Text,
          showLabelInViewMode: false,
        },
      },
    },
    {
      name: 'Description',
      key: 'description',
      dataType: DataTypeEnum.LongWithHoverText,
      required: false,
      showInTable: true,
      description: 'Record Description',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
      importerDefinition: {
        type: 'text',
        field: {
          validators: null,
        },
      },
      useInBulk: true,
      formField: {
        componentType: TextEditorComponent,
        options: {
          inputOptions: {
            textEditorInput: {
              advanced: true
            }
          },
          label: '',
          name: '',
          control: new FormControl(''),
          dataType: DataTypeEnum.Text,
          showLabelInViewMode: false,
        },
      },
    },
  ];
  constructor() {
    super();
    this.setAllFields();
  }
}
