/**
 * Sightflare Service
 * Rest Api for Sightflare Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RuleHandler } from './ruleHandler';


export interface FlatRule { 
    ruleHandler?: RuleHandler;
    ruleType?: FlatRule.RuleTypeEnum;
}
export namespace FlatRule {
    export type RuleTypeEnum = 'STATE_MACHINE_EVENT' | 'ACTION' | 'FORM_EDIT_VALIDATOR' | 'FIELD_EDIT_VALIDATOR';
    export const RuleTypeEnum = {
        StateMachineEvent: 'STATE_MACHINE_EVENT' as RuleTypeEnum,
        Action: 'ACTION' as RuleTypeEnum,
        FormEditValidator: 'FORM_EDIT_VALIDATOR' as RuleTypeEnum,
        FieldEditValidator: 'FIELD_EDIT_VALIDATOR' as RuleTypeEnum
    };
}


