import { Component, Input, OnInit } from '@angular/core';
import { DataTypeEnum } from '@shared/classes';
import { DynamicComponentBase } from '@shared/classes/view/DynamicComponentBase';

@Component({
  selector: 'app-p-slider-input',
  templateUrl: './p-slider-input.component.html',
  styleUrls: ['./p-slider-input.component.scss'],
  providers: [{ provide: DynamicComponentBase, useExisting: PSliderInputComponent }],
})
export class PSliderInputComponent extends DynamicComponentBase implements OnInit {
  @Input() min: number = 0;
  @Input() max: number = 100;
  @Input() animate = true;
  @Input() orientation: 'horizontal' | 'vertical' = 'horizontal';
  @Input() step: number = 1;
  @Input() range = false;
  @Input() valueSuffix = '';
  @Input() labelClassStyle = '';
  dataType: DataTypeEnum = DataTypeEnum.ProgressBar;
  constructor() {
    super();
  }

  ngOnInit(): void {}

  setInputOptions() {
    this.animate = this.inputOptions?.sliderInput.animate;
    this.orientation = this.inputOptions?.sliderInput.orientation;
    this.range = this.inputOptions?.sliderInput.range;
  }
}
