import { Pipe, PipeTransform } from '@angular/core';
import { IAction } from '@shared/classes/view/ButtonAction';

@Pipe({
  name: 'hideOrDisplayAction',
})
export class HideOrDisplayActionPipe implements PipeTransform {
  transform(data: any, action: IAction): boolean {
    if (!!action && !!action.displayCommand) {
      return action.displayCommand(data);
    }
    return true;
  }
}
