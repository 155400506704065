import { BreadCrumb } from '../model';
import { IAction } from './ButtonAction';
import { ImporterColumnDefinition } from './ImporterDefinitions';
import { IViewMode, TargetTypeEnum } from './view-enums';

export class PageConfigParams {
  /**
   * BreadCrumb Items to show in the navigation menu
   *
   * @type {BreadCrumb}
   * @memberof BasePage
   */
  breadCrumb: BreadCrumb;
  /**
   * Header Actions Passby Data (not required)
   *
   * @type {any}
   * @memberof BasePage
   */
  pageActions?: IAction[] = [];
}
export class ImportPageConfigParams {
  /**
   * BreadCrumb Items to show in the navigation menu
   *
   * @type {BreadCrumb}
   * @memberof BasePage
   */
  breadCrumb: BreadCrumb;
  /**
   * Header Actions Passby Data (not required)
   *
   * @type {any}
   * @memberof BasePage
   */
  pageActions?: IAction[] = [];
  /**
   * importer columns binding information
   *
   * @type {ImporterColumnDefinition}
   * @memberof ImportBasePage
   */
  public colDefinitions: ImporterColumnDefinition[] = [];
  /**
   * importer extra body fields to be added per element
   *
   * @type {any}
   * @memberof ImportBasePage
   */
  public extraBodyObjects?: any = null;
}
export class ItemPageConfigParams {
  /**
   * BreadCrumb Items to show in the navigation menu
   *
   * @type {BreadCrumb}
   * @memberof ItemPageConfigParams
   */
  breadCrumb: BreadCrumb;
  /**
   * Header Actions Passby Data (not required)
   *
   * @type {any}
   * @memberof ItemPageConfigParams
   */
  pageActions?: IAction[] = [];
  /**
   * item id or code from route
   *
   * @type {string}
   * @memberof ItemPageConfigParams
   */
  itemId: string = null;

  /**
   * Relations table required mapper
   *
   * @type {TargetTypeEnum}
   * @memberof ItemPageConfigParams
   */
  fromType: TargetTypeEnum = null;

  /**
   * View Mode of the page (edit, view, list, create)
   *
   * @type {IViewMode}
   * @memberof ItemPageConfigParams
   */
  viewMode?: IViewMode = null;
}
export class PageStaticConfigParams {
  /**
   * Shared Keyword for router url and permissions
   *
   * @example Please Use PascalCase ex: EntityLevels
   * @type {string}
   * @memberof BasePage
   */
  moduleKeyword?: string = '';
  /**
   * additional prefix to the router for navigation
   *
   * @example Please Use KebabCase without ending / for singularity ex: entity-levels
   * @type {string}
   * @memberof BasePage
   */
  routePrefix?: string = '';
  /**
   * additional middle route to the router for navigation
   *
   * @example Please Use KebabCase without ending / for singularity ex: entity-levels
   * @type {string}
   * @memberof BasePage
   */
  routeMiddlefix?: string = '';
}
export class LinkingPageConfigParams {
  /**
   * UnlinkedString is the continuation of the api that fetches the unlinked items
   *
   * @type {string}
   * @memberof LinkingPageConfigParams
   */
  unLinkedString: string;
  /**
   * BreadCrumb Items to show in the navigation menu
   *
   * @type {BreadCrumb}
   * @memberof BasePage
   */
  breadCrumb: BreadCrumb;
  /**
   * Header Actions Passby Data (not required)
   *
   * @type {any}
   * @memberof BasePage
   */
  pageActions?: IAction[] = [];
}