import { Component, Input, OnInit } from '@angular/core';
import { BaseViewItem } from '@shared/classes/view/BaseViewItem';

@Component({
  selector: 'app-text-view',
  templateUrl: './text-view.component.html',
  styleUrls: ['./text-view.component.scss'],
  host: {
    '[style.min-width]': '0',
  },
})
export class TextViewComponent extends BaseViewItem implements OnInit {
  @Input() filter: string;
  @Input() useStaticMaxWidth: boolean = true;
  @Input() clamp = true;
  constructor() {
    super();
  }

  ngOnInit(): void {}
}
