import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DataTypeEnum } from '@shared/classes';
import { DynamicComponentBase } from '@shared/classes/view/DynamicComponentBase';

@Component({
  selector: 'app-basic-input',
  templateUrl: './basic-input.component.html',
  styleUrls: ['./basic-input.component.scss'],
  providers: [{ provide: DynamicComponentBase, useExisting: BasicInputComponent }],
})
export class BasicInputComponent extends DynamicComponentBase implements OnInit {
  dataType: DataTypeEnum = DataTypeEnum.Text;
  @Output() onFocus: EventEmitter<any> = new EventEmitter();

  constructor() {
    super();
  }

  ngOnInit(): void {}
  setInputOptions() {}
  onFocusChange(event) {
    this.onFocus.emit(event);
  }
}
