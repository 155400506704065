import { isNullObj } from "./general-functions";

/**
 * hash function to consistently return a 6-digit colour
 * @param str input string for hash
 */
export function stringToColor(str: string) {
  if(isNullObj(str)) return "#ffffff";
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = '#';
  for (let i = 0; i < 3; i++) {
    let value = (hash >> (i * 8)) & 0xff;
    // Ensure the value is not too high to avoid bright colors
    if (value > 200) value = 200;
    color += ('00' + value.toString(16)).substr(-2);
  }
  return color;
}