import { environment } from '@env/environment';
import { isNullObj } from './general-functions';

export type ProductionType = 'dev' | 'test' | 'prod';
export function isAllowedInEnvironment(requiredProductionType: ProductionType) {
  if (isNullObj(requiredProductionType)) return true;
  if (ProductionTypeAccessLevel[environment.production] >= ProductionTypeAccessLevel[requiredProductionType]) {
    return true;
  } else {
    return false;
  }
}
export function isAllowedInProductionEnv() {
  return isAllowedInEnvironment('prod');
}
export function isAllowedInTestEnv() {
  return isAllowedInEnvironment('test');
}
export function isAllowedInDevEnv() {
  return isAllowedInEnvironment('dev');
}
export function isProductionEnv() {
  return environment.production === 'prod';
}
export function isTestEnv() {
  return environment.production === 'test';
}
export function isDevEnv() {
  return environment.production === 'dev';
}
export const ProductionTypeAccessLevel: { [key in ProductionType]: number } = {
  // bigger number means higher access
  dev: 2,
  test: 1,
  prod: 0,
};
