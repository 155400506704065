import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { Policy, RequestHandlerOptions } from '@shared/classes';
import { BaseRequestControllerService } from '@shared/services/api/base-request-controller.service';
import { DataService } from '@shared/services/api/data.service';
@Injectable({
  providedIn: 'root',
})
export class PublishedPolicyDataService extends BaseRequestControllerService<Policy> {
  constructor(
    private dataService: DataService,
    private router: Router
  ) {
    super(dataService, environment.framework, '/published-policies', [
      'id',
      'code',
      'tags',
      'tagsCodes',
      'owner',
      'name',
      'validFrom',
      'validTo',
      'description',
      'policyBody',
      'externalReferences',
      'exceptionDuration',
      'exceptionDurationFrom',
      'exceptionDurationTo',
      'allowExceptions',
      'reviewers',
      'approvers',
      'approvedBy',
      'policyStatus',
      'reviewedBy',
      'progress',
      'lockedBy',
      'lockedForEdit',
      'lockedUntil',
      'userAccessLevel',
      'recordStatus',
      'creatorName',
      'lastModifierName',
      'creationDate',
      'lastModificationDate',
    ]);
  }

  apiOptions: RequestHandlerOptions = {
    showLoading: true,
    showMsg: true,
  };

  navigateToListPage() {
    this.router.navigateByUrl(`framework/published/published-policy/list`);
  }
}
