import { Injectable } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { AppPermissions, DataTypeEnum, IFieldDefinition, TargetTypeEnum } from '@shared/classes';
import { TargetCodeSelectorComponent } from '@shared/components/selectors/target-code-selector/target-code-selector.component';
import { BasicInputComponent } from '@shared/components/ui/input-fields/basic-input/basic-input.component';
import { CheckBoxComponent } from '@shared/components/ui/input-fields/check-box/check-box.component';
import { TextEditorComponent } from '@shared/components/ui/input-fields/text-editor/text-editor.component';
import { BaseFieldDefinitionsService } from '@shared/services';

@Injectable({
  providedIn: 'root',
})
export class CitationMappingService extends BaseFieldDefinitionsService {
  recordFields: IFieldDefinition[] = [
    {
      name: "Authority Document",
      key: "authorityDocument",
      dataType: DataTypeEnum.CodeLink,
      required: true,
      showInTable: true,
      permissions: AppPermissions.ReadAuthorityDocument,
      description: "Authority document of the record",
      tableDefinition: {
        filter: {
          type: "recordCode",
          matchMode: "in",
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          recordCodeType: TargetTypeEnum.AuthorityDocument,
        },
      },
      formField: {
        componentType: TargetCodeSelectorComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Search Records',
          control: new FormControl(null),
          inputOptions: {
            codeSelectorInput: {
              targetTypes: [TargetTypeEnum.AuthorityDocument],
            },
            dropDownInput: {
              multi: false,
              items: null,
              customProjectionFields: ['code', 'recordStatus', 'label', 'name', 'uuid', 'uniqueId'],
              optionLabel: 'name',
              optionValue: 'code'
            },
          },
          showLabelInViewMode: false,
        },
      },
    },
    {
      name: 'Framework Ref',
      key: 'frameworkRef',
      dataType: DataTypeEnum.Text,
      required: true,
      showInTable: true,
      description: 'Framework reference of the record',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
      useInBulk: true,
      formField: {
        componentType: BasicInputComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Enter Framework Reference',
          control: new FormControl('', Validators.required),
          dataType: DataTypeEnum.Text,
          showLabelInViewMode: false,
        },
      },
    },
    {
      name: 'Title',
      key: 'title',
      dataType: DataTypeEnum.Text,
      required: true,
      showInTable: true,
      description: 'Title of the record',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
      useInBulk: true,
      formField: {
        componentType: BasicInputComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Enter Title',
          control: new FormControl('', Validators.required),
          dataType: DataTypeEnum.Text,
          showLabelInViewMode: false,
        },
      },
    },
    {
      name: 'Name',
      key: 'name',
      dataType: DataTypeEnum.Text,
      required: true,
      showInTable: true,
      description: 'Name of the record',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
      useInBulk: true,
      formField: {
        componentType: BasicInputComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Enter Name',
          control: new FormControl('', Validators.required),
          dataType: DataTypeEnum.Text,
          showLabelInViewMode: false,
        },
      },
    },
    {
      name: 'Citation Type',
      key: 'citationType',
      dataType: DataTypeEnum.CodeLink,
      required: true,
      showInTable: true,
      permissions: AppPermissions.ReadCitationType,
      description: 'Citation type of the record',
      tableDefinition: {
        filter: {
          type: 'recordCode',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          recordCodeType: TargetTypeEnum.CitationType,
        },
      },
      useInBulk: true,
      formField: {
        componentType: TargetCodeSelectorComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Search Citation Types',
          control: new FormControl(null, Validators.required),
          inputOptions: {
            codeSelectorInput: {
              targetTypes: [TargetTypeEnum.CitationType],
            },
            dropDownInput: {
              multi: false,
              items: null,
              optionLabel: 'name',
              optionValue: 'code'
            },
          },
          showLabelInViewMode: false,
        },
      },
    },
    {
      name: 'Mandatory',
      key: 'mandatory',
      dataType: DataTypeEnum.Badge,
      required: false,
      showInTable: true,
      description: 'Is the record mandatory?',
      tableDefinition: {
        filter: {
          type: 'boolean',
          display: 'menu',
          matchMode: 'equals',
        },
      },
      useInBulk: true,
      formField: {
        componentType: CheckBoxComponent,
        options: {
          label: '',
          name: '',
          control: new FormControl(null)
        }
      }
    },
    {
      name: 'Exempted',
      key: 'isExempted',
      dataType: DataTypeEnum.Badge,
      required: false,
      showInTable: true,
      description: 'Is the record exempted?',
      tableDefinition: {
        filter: {
          type: 'boolean',
          display: 'menu',
          matchMode: 'equals',
        },
      },
      formField: {
        componentType: CheckBoxComponent,
        options: {
          label: '',
          name: '',
          control: new FormControl(null)
        }
      }
    },
    {
      name: 'Parent',
      key: 'parent',
      dataType: DataTypeEnum.CodeLink,
      required: false,
      showInTable: true,
      permissions: AppPermissions.ReadCitation,
      description: 'Parent of the record',
      tableDefinition: {
        filter: {
          type: 'recordCode',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          recordCodeType: TargetTypeEnum.Citation,
        },
      },
      formField: {
        componentType: TargetCodeSelectorComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Search Records',
          control: new FormControl(null),
          inputOptions: {
            codeSelectorInput: {
              targetTypes: [TargetTypeEnum.Citation],
            },
            dropDownInput: {
              multi: false,
              items: null,
              optionLabel: 'name',
              optionValue: 'code'
            },
          },
          showLabelInViewMode: false,
        },
      },
    },
    {
      name: "Description",
      key: "description",
      dataType: DataTypeEnum.LongText,
      required: false,
      showInTable: false,
      description: "Record Description",
      tableDefinition: {
        filter: {
          type: "text",
          display: "menu",
          matchMode: "contains",
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
      useInBulk: true,
      formField: {
        componentType: TextEditorComponent,
        options: {
          inputOptions: {
            textEditorInput: {
              advanced: true
            }
          },
          label: '',
          name: '',
          control: new FormControl(''),
          dataType: DataTypeEnum.Text,
          showLabelInViewMode: false,
        },
      },
    },
  ];
  constructor() {
    super();
    this.setAllFields();
  }
}
