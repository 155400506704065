<div class="bs-progress" [style]="containerStyle">
  @for (bar of data; track bar) {
    <div
      [pTooltip]="bar.tooltipHint || (bar.value | json | toFixedDigits) + '%'"
      [tooltipPosition]="bar.tooltipPosition || 'top'"
      [class]="
        'bs-progress-bar ' +
        (bar.striped ? ' bs-progress-bar-striped ' : '') +
        (bar.animated ? ' bs-progress-bar ' : '') +
        (bar.bgColor ?? '')
      "
      role="progressbar"
      [style]="
        'width: ' + bar.value + '%; ' + (bar.styleBasedColor ? ' background-color:' + bar.styleBasedColor + '; ' : '')
      "
    ></div>
  }
</div>
