import { Injectable } from '@angular/core';
import { ApiResponseBoolean, FlatRule, RequestHandlerOptions } from '@shared/classes';
import { Observable } from 'rxjs';
import { BaseRequestControllerService } from '../base-request-controller.service';
import { DataService } from '../data.service';

@Injectable({
  providedIn: 'root',
})
export class BaseRequestControllerWithRuleService<T, Events> extends BaseRequestControllerService<T> {
  constructor(
    private requestHandler: DataService,
    baseUrl: string = '',
    subUrl: string = '',
    defaultProjectionFields = ['id', 'code']
  ) {
    super(requestHandler, baseUrl, subUrl, defaultProjectionFields);
  }
  getRuleHandlers(idOrCode: string, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.requestHandler.getData<FlatRule[]>(`${this.url}/get-rule-handlers/${idOrCode}`, options) as Observable<
      FlatRule[]
    >;
  }
  changeStatus(
    id: string,
    status: Events,
    body: { messages?: any } = {},
    options: RequestHandlerOptions = new RequestHandlerOptions()
  ) {
    return this.requestHandler.postData<ApiResponseBoolean>(
      `${this.url}/changeStatus/${id}/${status}`,
      body,
      options
    ) as Observable<ApiResponseBoolean>;
  }
}
