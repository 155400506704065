import { Injectable } from '@angular/core';
import { DataTypeEnum, IFieldDefinition, SearchFilterDto, TargetTypeEnum } from '@shared/classes';
import { BaseFieldDefinitionsService } from '@shared/services';

@Injectable({
  providedIn: 'root',
})
export class SearchFilterMappingService extends BaseFieldDefinitionsService {
  recordFields: IFieldDefinition[] = [
    {
      name: 'Name',
      key: 'name',
      dataType: DataTypeEnum.Text,
      required: true,
      showInTable: true,
      description: 'Name of the record',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
    {
      name: 'Result Type',
      key: 'type',
      dataType: DataTypeEnum.Badge,
      required: true,
      showInTable: true,
      description: 'Result Type of the filter',
      tableDefinition: {
        filter: {
          type: 'enum',
          display: 'menu',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          enumClass: SearchFilterDto.TypeEnum,
        },
      },
    },
    {
      name: 'Filter Type',
      key: 'filterType',
      dataType: DataTypeEnum.Badge,
      required: true,
      showInTable: true,
      description: 'Filter Type of the record',
      tableDefinition: {
        filter: {
          type: 'enum',
          display: 'menu',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          enumClass: SearchFilterDto.FilterTypeEnum,
        },
      },
    },
    {
      name: 'Target Type',
      key: 'targetType',
      dataType: DataTypeEnum.Badge,
      required: true,
      showInTable: true,
      description: 'Target of the record',
      tableDefinition: {
        filter: {
          type: 'enum',
          display: 'menu',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          enumClass: TargetTypeEnum,
        },
      },
    },
  ];
  constructor() {
    super();
    this.setAllFields();
  }
}
