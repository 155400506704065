/**
 * Sightflare Service
 * Rest Api for Sightflare Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AppliedFilter } from './appliedFilter';


export interface Column { 
    key?: string;
    name?: string;
    order?: number;
    visible?: boolean;
    groupRowsBy?: Column.GroupRowsByEnum;
    appliedFilters?: Array<AppliedFilter>;
    sort?: Column.SortEnum;
}
export namespace Column {
    export type GroupRowsByEnum = 'HEADER_ROW' | 'EXPAND_ROW' | 'SPAN_ROW';
    export const GroupRowsByEnum = {
        HeaderRow: 'HEADER_ROW' as GroupRowsByEnum,
        ExpandRow: 'EXPAND_ROW' as GroupRowsByEnum,
        SpanRow: 'SPAN_ROW' as GroupRowsByEnum
    };
    export type SortEnum = 'ASC' | 'DESC';
    export const SortEnum = {
        Asc: 'ASC' as SortEnum,
        Desc: 'DESC' as SortEnum
    };
}


