import { Component, OnInit } from '@angular/core';
import { DynamicComponentBase } from '@shared/classes';

@Component({
  selector: 'app-run-as-input',
  templateUrl: './run-as-input.component.html',
  styleUrl: './run-as-input.component.scss',
})
export class RunAsInputComponent extends DynamicComponentBase implements OnInit {
  constructor() {
    super();
  }
  ngOnInit(): void {}
  setInputOptions(): void {}
}
