<app-input-view-switch
  [showLabelInViewMode]="showLabelInViewMode"
  [mode]="viewMode"
  [data]="data || control?.value"
  [dataType]="dataType"
  [label]="label"
  [keepValueCallback]="setFieldResetable.bind(this)"
  [resetFieldCheckVisable]="resetFieldCheckVisable"
  [resetableChecked]="resetable"
>
  @if (selectionMode == 'radio') {
    <div class="flex flex-row md:flex-nowrap flex-wrap gap-1 w-full algin-items-start">
      <!-- <h5>{{label}} <span *ngIf="isControlRequired" class="text-red-500"> *</span></h5> -->
      @if (!floatLabel) {
        @if (label && !instanceInFormRepeater) {
          <label [for]="name || label" class="md:w-12rem md:max-w-12rem md:min-w-12rem w-full input-field-label"
            >{{ label }}
            @if (isControlRequired) {
              <span class="text-red-500"> *</span>
            }
            @if (resetFieldCheckVisable) {
              <br />
              <app-reset-switch-input
                [checked]="!resetable"
                (onChange)="setFieldResetable($event)"
              ></app-reset-switch-input>
            }
            @if (showScore && passingScore > fControl?.value) {
              <app-badge-item
                [status]="passingScore > fControl?.value ? 'false' : 'true'"
                [text]="passingScore > fControl?.value ? 'Fail' : 'Pass'"
                class="mr-2 mt-1"
              ></app-badge-item>
            }
          </label>
        }
      }
      <div class="flex flex-column w-full">
        @for (item of groupItems; track item; let i = $index) {
          <div class="field-radiobutton w-full">
            <p-radioButton [name]="name" [value]="item.value" [formControl]="fControl" [inputId]="name + '_' + i">
            </p-radioButton>
            <label [for]="name + '_' + i">
              <span class="flex flex-row gap-2 flex-wrap">
                <span>
                  {{ item.label }}
                </span>
                @if (showScore) {
                  <app-badge-item
                    [status]="passingScore > item?.value ? 'false' : 'true'"
                    [text]="passingScore > item?.value ? 'Fail' : 'Pass'"
                    class="mr-2"
                  ></app-badge-item>
                }
              </span>
            </label>
          </div>
        }
      </div>
    </div>
  } @else {
    <app-drop-down-input
      [label]="label"
      [placeholder]="label"
      [name]="'single_selection'"
      [optionLabel]="'label'"
      [items]="groupItems"
      [optionValue]="'value'"
      [control]="fControl"
      [viewMode]="viewMode"
      [badgeView]="false"
      [scored]="scored"
      [showScore]="showScore"
      [passingScore]="passingScore"
    ></app-drop-down-input>
  }
</app-input-view-switch>
