/**
 * Sightflare Service
 * Rest Api for Sightflare Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { StateMachineLog } from './stateMachineLog';
import { AccessRecord } from './accessRecord';


export interface Language { 
    creatorName?: string;
    creationDate?: string;
    lastModifierName?: string;
    lastModificationDate?: string;
    id?: string;
    searchScore?: number;
    recordStatus?: Language.RecordStatusEnum;
    uuid?: string;
    description?: string;
    code: string;
    label?: string;
    tags?: Array<string>;
    tagsCodes?: Array<string>;
    parent?: string;
    parents?: Array<string>;
    module?: Language.ModuleEnum;
    systemGenerated?: boolean;
    softDeleteDate?: string;
    events?: Array<StateMachineLog>;
    name?: string;
    langCode?: string;
    direction?: Language.DirectionEnum;
    langFile?: string;
    isDefault?: boolean;
    icon?: string;
    status?: Language.StatusEnum;
    state?: Language.StateEnum;
    codePrefix?: string;
    lockedUntil?: string;
    lockedForEdit?: boolean;
    lockedBy?: string;
    userAccessLevel?: AccessRecord;
}
export namespace Language {
    export type RecordStatusEnum = 'DELETED' | 'LOCKED' | 'ACTIVE' | 'IN_ACTIVE';
    export const RecordStatusEnum = {
        Deleted: 'DELETED' as RecordStatusEnum,
        Locked: 'LOCKED' as RecordStatusEnum,
        Active: 'ACTIVE' as RecordStatusEnum,
        InActive: 'IN_ACTIVE' as RecordStatusEnum
    };
    export type ModuleEnum = 'ALL' | 'DOCUMENT_APP' | 'ACKNOWLEDGMENT_APP' | 'ADMIN_APP' | 'CONFIG_APP' | 'DATA_STRUCTURE_APP' | 'BRANDING_APP' | 'ENTITY_APP' | 'FRAMEWORK_APP' | 'ISSUES_APP' | 'QUESTION_APP' | 'RELATION_APP' | 'RISK_APP' | 'USER_APP' | 'USER_OPERATIONS_APP' | 'AUDIT_APP' | 'HUB_APP' | 'TRANSPARENCY_APP' | 'PERFORMANCE_APP' | 'BCM_APP';
    export const ModuleEnum = {
        All: 'ALL' as ModuleEnum,
        DocumentApp: 'DOCUMENT_APP' as ModuleEnum,
        AcknowledgmentApp: 'ACKNOWLEDGMENT_APP' as ModuleEnum,
        AdminApp: 'ADMIN_APP' as ModuleEnum,
        ConfigApp: 'CONFIG_APP' as ModuleEnum,
        DataStructureApp: 'DATA_STRUCTURE_APP' as ModuleEnum,
        BrandingApp: 'BRANDING_APP' as ModuleEnum,
        EntityApp: 'ENTITY_APP' as ModuleEnum,
        FrameworkApp: 'FRAMEWORK_APP' as ModuleEnum,
        IssuesApp: 'ISSUES_APP' as ModuleEnum,
        QuestionApp: 'QUESTION_APP' as ModuleEnum,
        RelationApp: 'RELATION_APP' as ModuleEnum,
        RiskApp: 'RISK_APP' as ModuleEnum,
        UserApp: 'USER_APP' as ModuleEnum,
        UserOperationsApp: 'USER_OPERATIONS_APP' as ModuleEnum,
        AuditApp: 'AUDIT_APP' as ModuleEnum,
        HubApp: 'HUB_APP' as ModuleEnum,
        TransparencyApp: 'TRANSPARENCY_APP' as ModuleEnum,
        PerformanceApp: 'PERFORMANCE_APP' as ModuleEnum,
        BcmApp: 'BCM_APP' as ModuleEnum
    };
    export type DirectionEnum = 'RTL' | 'LTR';
    export const DirectionEnum = {
        Rtl: 'RTL' as DirectionEnum,
        Ltr: 'LTR' as DirectionEnum
    };
    export type StatusEnum = 'DRAFT' | 'PUBLISHED';
    export const StatusEnum = {
        Draft: 'DRAFT' as StatusEnum,
        Published: 'PUBLISHED' as StatusEnum
    };
    export type StateEnum = 'DRAFT' | 'PUBLISHED';
    export const StateEnum = {
        Draft: 'DRAFT' as StateEnum,
        Published: 'PUBLISHED' as StateEnum
    };
}


