@if (!loadingFields) {
  <div class="w-full">
    <div class="grid p-fluid">
      @for (field of dynamicFields; track field; let i1 = $index) {
        <div [class]="'dynamic-field-item ' + (showPopupEditButton || showGetValueButton ? 'col-10' : 'col-12')">
          <ng-template
            appDynamicComponent
            [mode]="showPopupEditButton ? 'view' : viewMode"
            [dynamicComponentInfo]="field"
          ></ng-template>
        </div>
        @if (showPopupEditButton) {
          <div class="col-2 flex flex-column align-items-end justify-content-end px-2">
            <app-button [action]="showEditPopupAction" [commandData]="i1"></app-button>
          </div>
        }
        @if (showGetValueButton && !showPopupEditButton) {
          <div class="col-2 flex flex-column align-items-end px-2">
            <app-button [action]="getFieldValueAction" [commandData]="field"></app-button>
          </div>
        }
      }
    </div>
  </div>
}
@if (loadingFields) {
  <div class="custom-skeleton">
    <!-- <div class="flex mb-3">
    <p-skeleton shape="circle" size="4rem" styleClass="mr-2"></p-skeleton>
    <div class="w-full mb-2">
      <p-skeleton styleClass="mb-2"></p-skeleton>
      <p-skeleton width="50%" styleClass="mb-2"></p-skeleton>
      <p-skeleton width="20%" styleClass="mb-2"></p-skeleton>
      <p-skeleton width="80%" height="2rem" styleClass="mb-2"></p-skeleton>
      <p-skeleton width="65%" height="4rem"></p-skeleton>
    </div>
  </div> -->
    <div class="w-full flex flex-column gap-3">
      <p-skeleton width="100%" height="2rem"></p-skeleton>
      <p-skeleton width="100%" height="2rem"></p-skeleton>
      <p-skeleton width="100%" height="2rem"></p-skeleton>
    </div>
  </div>
}
@if (dynamicFields?.length > popupActiveDynamicFieldIndex) {
  <p-dialog
    [header]="dynamicFields?.[popupActiveDynamicFieldIndex]?.options?.label"
    [(visible)]="displayDialog"
    [modal]="true"
    [style]="{ width: '80vw' }"
    [maximizable]="true"
    [draggable]="false"
    [resizable]="false"
  >
    <div class="col-12 dynamic-field-item">
      @if (displayDialog) {
        <ng-template
          appDynamicComponent
          [mode]="'edit'"
          [dynamicComponentInfo]="dynamicFields?.[popupActiveDynamicFieldIndex]"
        ></ng-template>
      }
    </div>
    <ng-template pTemplate="footer">
      <!-- <p-button icon="pi pi-check" (click)="displayDialog=false" label="Ok" styleClass="p-button-text"></p-button> -->
      @if (viewMode != 'view') {
        <div class="w-full flex align-items-end justify-content-end gap-2 py-0 px-3">
          <!-- <button pButton type="button" label="Select a file" (click)="fileInput.click()"></button> -->
          <app-button
            [action]="getFieldValueAction"
            [commandData]="dynamicFields?.[popupActiveDynamicFieldIndex]"
          ></app-button>
        </div>
      }
    </ng-template>
  </p-dialog>
}
