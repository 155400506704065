/**
 * Sightflare Service
 * Rest Api for Sightflare Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface EntityTypeInfo { 
    name?: string;
    code?: string;
    icon?: string;
    recordStatus?: EntityTypeInfo.RecordStatusEnum;
    treeViewMode?: EntityTypeInfo.TreeViewModeEnum;
}
export namespace EntityTypeInfo {
    export type RecordStatusEnum = 'DELETED' | 'LOCKED' | 'ACTIVE' | 'IN_ACTIVE';
    export const RecordStatusEnum = {
        Deleted: 'DELETED' as RecordStatusEnum,
        Locked: 'LOCKED' as RecordStatusEnum,
        Active: 'ACTIVE' as RecordStatusEnum,
        InActive: 'IN_ACTIVE' as RecordStatusEnum
    };
    export type TreeViewModeEnum = 'COLLAPSE' | 'EXPAND' | 'HIDE';
    export const TreeViewModeEnum = {
        Collapse: 'COLLAPSE' as TreeViewModeEnum,
        Expand: 'EXPAND' as TreeViewModeEnum,
        Hide: 'HIDE' as TreeViewModeEnum
    };
}


