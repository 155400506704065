<app-input-view-switch
  [showLabelInViewMode]="showLabelInViewMode"
  [mode]="viewMode"
  [dataType]="dataTypes.gvlItemList"
  [data]="getList(control.value)"
  [label]="label"
  [keepValueCallback]="setFieldResetable.bind(this)"
  [resetFieldCheckVisable]="resetFieldCheckVisable"
  [resetableChecked]="resetable"
>
  <app-drop-down-input
    [label]="label"
    [placeholder]="'Global Value List'"
    [name]="'dropdown'"
    [items]="items"
    [optionLabel]="'value'"
    [optionValue]="'value'"
    [control]="fControl"
    [multi]="gvlSelectionMod"
    [listBoxMode]="gvlListMode"
    [lazy]="true"
    [loading]="isLoading"
    [floatLabel]="floatLabel"
    [showLabelInViewMode]="false"
    [customFilter]="false"
  >
    <ng-template #resetCheckTemplate>
      @if (resetFieldCheckVisable) {
        <app-reset-switch-input [checked]="!resetable" (onChange)="setFieldResetable($event)"></app-reset-switch-input>
      }
    </ng-template>
    <ng-template let-items #selectedItemTemplate>
      @if (!(items && items.length > 0)) {
        <p>{{ 'Global Value List' }}</p>
      } @else {
        <app-view-switch
          [data]="getList(items)"
          [viewType]="dataTypes.gvlItemList"
          [options]="{
            showNumeric: gvlShowNumeric,
            showDescriptions: gvlShowDescription,
          }"
        ></app-view-switch>
      }
    </ng-template>
    <ng-template let-item #itemTemplate>
      <app-view-switch
        [data]="getObject(item)"
        [viewType]="dataTypes.gvlItem"
        [options]="{
          showNumeric: gvlShowNumeric,
          showDescription: gvlShowDescription,
        }"
      ></app-view-switch> </ng-template
  ></app-drop-down-input>

  <!-- <ng-template let-item
    #itemTemplate>
    {{item.value}}
  </ng-template> -->
</app-input-view-switch>
