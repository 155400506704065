import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { IViewMode, getViewMode } from '@shared/classes';
import { BehaviorSubject, Subscription, filter } from 'rxjs';
import { AppDialogService, IConfirmationCallBacks, IConfirmationConfig } from './app-dialog.service';

@Injectable({
  providedIn: 'root',
})
export class ViewModeService implements OnDestroy {
  private sub: Subscription;
  private _viewMode = new BehaviorSubject(IViewMode.view);
  viewModeState = this._viewMode.asObservable();

  public get viewMode() {
    return this._viewMode.getValue();
  }
  set viewMode(state: IViewMode) {
    this._viewMode.next(state);
  }
  isDashboardPage: boolean = false;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dialogService: AppDialogService
  ) {
    // this.sub = this.route.params.subscribe(params=>{
    //      this.viewMode = params['viewMode'] == 'view' ? IViewMode.view : IViewMode.edit

    // })
    // this.route.url.subscribe(uri =>{

    // })
    // this.router.events.subscribe(routerEvent=>{

    // })
    router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      this.viewMode = getViewMode(event.urlAfterRedirects);
      this.isDashboardPage = event?.urlAfterRedirects?.includes('/dashboards/');
    });
  }
  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
  showConfirmation(callBacks: IConfirmationCallBacks, config: IConfirmationConfig = new IConfirmationConfig()) {
    this.dialogService.confirm(callBacks, config);
  }
}
