import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { MetricValueDto } from '@shared/classes';
import { BaseRequestControllerService, DataService } from '@shared/services';

@Injectable({
  providedIn: 'root',
})
export class MetricValueDataService extends BaseRequestControllerService<MetricValueDto> {
  constructor(
    private dataService: DataService,
    private router: Router
  ) {
    super(dataService, environment.performance, '/metric-values', [
      'id',
      'code',
      'tags',
      'tagsCodes',
      'lockedBy',
      'lockedForEdit',
      'lockedUntil',
      'userAccessLevel',
      'recordStatus',
      'creatorName',
      'lastModifierName',
      'creationDate',
      'lastModificationDate',
      'name',
      'description',
      'submitter',
      'status',
      'attachment',
      'date',
      'value',
      'notes',
      'calculationType',
    ]);
  }

  navigateToListPage() {
    this.router.navigateByUrl(`performance/metric-value/list`);
  }
}
