import { Injectable, OnDestroy } from "@angular/core";
// import { Router } from "@angular/router";
import { Router } from "@angular/router";
import { BaseRequestControllerService } from "@shared/services/api/base-request-controller.service";
import { ToastService } from "@shared/services/toast.service";
import { BasePage } from "./BasePage";
import { LinkingPageConfigParams, PageStaticConfigParams } from "./PageConfigParams";

@Injectable()
export class LinkingBasePage<T> extends BasePage implements OnDestroy {

  /**
   * unlinked Items that were fetched from the api
   *
   * @type {any[]}
   * @memberof LinkingBasePage
   */
  public unLinkedItems: any[];

  /**
   * UnlinkedString is the continuation of the api that fetches the unlinked items
   *
   * @type {string}
   * @memberof LinkingPageConfigParams
   */
  private unLinkedString: string;

  /**
   * is loading the item data
   *
   * @type {boolean}
   * @memberof ItemBasePage
   */
  public isLoadingItemData: boolean = false;

  /**
   * is loading the list data
   *
   * @type {boolean}
   * @memberof LinkingBasePage
   */
  public isLoadingListData: boolean = false;

  /**
   * Item Data Store
   *
   * @type {T}
   * @memberof ItemBasePage
   */
  private _currentData: T = null;
  public get currentData() {
    return this._currentData;
  }
  public set currentData(data) {
    this._currentData = { ...data };
    this.isLoadingItemData = false;
    this.onSetData();
  }

  public SetPageValues(config: LinkingPageConfigParams = new LinkingPageConfigParams()) {
    this.breadCrumb = config.breadCrumb;
    this.pageActions = config.pageActions ?? [];
    this.unLinkedString = config.unLinkedString;
    this.getUnlinkedItems(this.unLinkedString);

    // const breadCrumbService = AppInjector.get(AppBreadcrumbService);
    // const licenseHandler = AppInjector.get(LicenseHandlerService);
  }

  constructor(
    config: PageStaticConfigParams = new PageStaticConfigParams(),
    private routerNav: Router,
    private reqService: BaseRequestControllerService<T>,
    private toast: ToastService
  ) {
    super(config);
    this.moduleKeyword = config.moduleKeyword ?? '';
    this.routePrefix = config.routePrefix ? config.routePrefix + '/' : (config.routePrefix ?? '');
    this.routeMiddlefix = config.routeMiddlefix ? '/' + config.routeMiddlefix : (config.routeMiddlefix ?? '');
  }

  itemSelected(id: string) {
    this.isLoadingItemData = true;
    this.onGetItemData();

    this.subs.sink = this.reqService.getByIdOrCode<T>(id).subscribe({
      next: (res) => {
        this.currentData = res.data;
      },
    });
  }

  onGetItemData() { }
  onSetData() { }

  getUnlinkedItems(unLinkedString: string) {
    this.isLoadingListData = true;
    this.isLoadingItemData = true;
    this.reqService.getUnlinkedItems(unLinkedString).subscribe({
      next: (res) => {
        this.isLoadingListData = false;
        this.unLinkedItems = res.data;
        if (this.unLinkedItems?.length > 0) {
          this.itemSelected(this.unLinkedItems[0]?.code);
        }
      },
    });
  }

  processLinking(event) {
    const reqServ = this.reqService as any;
    if (reqServ.linkControlObjectives) {
      this.isLoadingItemData = true;
      reqServ.linkControlObjectives((this.currentData as any).code, { link: event?.readyToLink ?? [], unLink: event?.readyToUnlink ?? [], addAndLink: event?.addAndLink ?? [] }).subscribe(res => {
        this.isLoadingItemData = false;
        this.getUnlinkedItems(this.unLinkedString);
      });
    }
  }
}