<app-input-view-switch
  [showLabelInViewMode]="showLabelInViewMode"
  [mode]="viewMode"
  [data]="data || control?.value"
  [dataType]="dataType"
  [label]="label"
  [keepValueCallback]="setFieldResetable.bind(this)"
  [resetFieldCheckVisable]="resetFieldCheckVisable"
  [resetableChecked]="resetable"
>
  <div
    [class]="
      'field-checkbox h-full flex flex-row md:flex-nowrap flex-wrap gap-1 w-full algin-items-start ' + styleClass
    "
  >
    @if (labelLeft) {
      @if (label && !instanceInFormRepeater) {
        <label
          [for]="name"
          class="m-0 md:w-12rem md:max-w-12rem md:min-w-12rem w-full"
          [class.input-field-label]="!labelWidthFit"
          [style]="
            labelWidthFit
              ? 'max-width: fit-content !important; width: fit-content !important; min-width: fit-content;'
              : ''
          "
          >{{ label }}
          @if (isControlRequired) {
            <span class="text-red-500"> *</span>
          }
          @if (isControlRequiredTrue) {
            <span class="text-red-500"> Must Be Checked</span>
          }
          @if (resetFieldCheckVisable) {
            <br />
            <app-reset-switch-input
              [checked]="!resetable"
              (onChange)="setFieldResetable($event)"
            ></app-reset-switch-input>
          }
        </label>
      }
    }
    <p-inputSwitch
      [formControl]="fControl"
      [inputId]="name"
      [trueValue]="trueValue"
      [falseValue]="falseValue"
      class="align-self-start"
    ></p-inputSwitch>
    @if (!labelLeft) {
      @if (label && !instanceInFormRepeater) {
        <label
          [for]="name"
          class="m-0 md:w-12rem md:max-w-12rem md:min-w-12rem w-full"
          [class.input-field-label]="!labelWidthFit"
          [style]="
            labelWidthFit
              ? 'max-width: fit-content !important; width: fit-content !important; min-width: fit-content;'
              : ''
          "
          >{{ label }}
          @if (isControlRequired) {
            <span class="text-red-500"> *</span>
          }
          @if (isControlRequiredTrue) {
            <span class="text-red-500"> Must Be Checked</span>
          }
          @if (resetFieldCheckVisable) {
            <br />
            <app-reset-switch-input
              [checked]="!resetable"
              (onChange)="setFieldResetable($event)"
            ></app-reset-switch-input>
          }
        </label>
      }
    }
  </div>
  <!-- @if (resetFieldCheckVisable) {
  <app-reset-switch-input [checked]="!resetable" (onChange)="setFieldResetable($event)"></app-reset-switch-input>
  } -->
</app-input-view-switch>
