/**
 * Sightflare Service
 * Rest Api for Sightflare Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface RelationFilterItem { 
    codes?: Array<string>;
    relationsTypes?: Array<string>;
    relationDirections?: Array<RelationFilterItem.RelationDirectionsEnum>;
    operand?: RelationFilterItem.OperandEnum;
    left?: RelationFilterItem;
    right?: RelationFilterItem;
}
export namespace RelationFilterItem {
    export type RelationDirectionsEnum = 'AUTO' | 'MANUAL' | 'REFERENCE';
    export const RelationDirectionsEnum = {
        Auto: 'AUTO' as RelationDirectionsEnum,
        Manual: 'MANUAL' as RelationDirectionsEnum,
        Reference: 'REFERENCE' as RelationDirectionsEnum
    };
    export type OperandEnum = 'AND' | 'OR';
    export const OperandEnum = {
        And: 'AND' as OperandEnum,
        Or: 'OR' as OperandEnum
    };
}


