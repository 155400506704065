/**
 * Sightflare Service
 * Rest Api for Sightflare Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ApiResponseRiskProjectEvent { 
    status?: number;
    data?: ApiResponseRiskProjectEvent.DataEnum;
    error?: boolean;
    errorMessage?: string;
}
export namespace ApiResponseRiskProjectEvent {
    export type DataEnum = 'TO_DRAFT' | 'TO_ASSESSMENT' | 'TO_MONITOR' | 'TO_REASSESSMENT' | 'TO_RETIRED';
    export const DataEnum = {
        Draft: 'TO_DRAFT' as DataEnum,
        Assessment: 'TO_ASSESSMENT' as DataEnum,
        Monitor: 'TO_MONITOR' as DataEnum,
        Reassessment: 'TO_REASSESSMENT' as DataEnum,
        Retired: 'TO_RETIRED' as DataEnum
    };
}


