import { Component, Input, OnInit } from '@angular/core';
import { DataTypeEnum, DynamicComponentBase } from '@shared/classes';

@Component({
  selector: 'app-risk-meter-bar',
  templateUrl: './risk-meter-bar.component.html',
  styleUrls: ['./risk-meter-bar.component.scss'],
})
export class RiskMeterBarComponent extends DynamicComponentBase implements OnInit {
  dataType: DataTypeEnum = DataTypeEnum.RiskMeter;

  setInputOptions() {
    this.riskMeth = this.inputOptions?.extra?.riskMeth ?? this.riskMeth;
  }
  private _value;
  get value() {
    return this._value;
  }
  @Input() set value(value) {
    this._value = value;
    this.data = value;
  }
  @Input() riskMeth;
  constructor() {
    super();
  }

  ngOnInit(): void {}
}
