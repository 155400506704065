import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toDateObj',
})
export class ToDateObjPipe implements PipeTransform {
  transform(value: any, isArray: boolean = false): any {
    if (!value) {
      return isArray ? [] : value;
    }
    if (isArray) {
      return (value as string[]).map((x) => (x != null ? new Date(x) : null));
    }
    return new Date(value);
  }
}
