import { Component, ContentChild, Input, OnInit, TemplateRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DataTypeEnum, DynamicComponentBase, extractItemCode } from '@shared/classes';
import { LazyLoadEvent } from 'primeng/api';
import { Subject, debounceTime } from 'rxjs';

@Component({
  selector: 'app-auto-complete-input',
  templateUrl: './auto-complete-input.component.html',
  styleUrls: ['./auto-complete-input.component.scss'],
  providers: [{ provide: DynamicComponentBase, useExisting: AutoCompleteInputComponent }],
})
export class AutoCompleteInputComponent extends DynamicComponentBase implements OnInit {
  uiChange: boolean = false;
  @Input() searchModel;
  @Input() dropdown: boolean = true;
  @Input() suggestions = [];
  @Input() optionLabel = 'name';
  @Input() onSelectItem = (event) => {
    this.uiChange = true;
    if (!this.multi) {
      this.fControl.patchValue(
        this.searchModel ? (this.optionValue ? this.searchModel?.[this.optionValue] : this.searchModel) : null
      );
      // this.searchModel = event?.[this.optionValue];
    } else {
      this.fControl.patchValue(
        this.searchModel
          ? this.searchModel?.map((item) => {
              return this.optionValue ? item?.[this.optionValue] : item;
            })
          : null
      );
    }
    // Then remove focus from the autocomplete input field
  };
  filteredItems;

  dataType: DataTypeEnum = DataTypeEnum.Text;
  @Input() multi = false;
  private _items: any[] = [];
  get items() {
    return this._items;
  }
  @Input() set items(items: any[]) {
    this._items = items;
  }
  @Input() showClear = true;
  @Input() optionValue: string; //@Note: only 1 field is bindable through primeng, if we need to modify the object a lot more then consider changing the formcontrol into onSelect changes
  @Input() dataKey = null;
  @Input() virtualScroll: boolean; //@TODO the implementation for virtual scroll should be remapped to handle pagination
  @Input() virtualScrollItemSize: number = 38;
  @Input() lazy: boolean = false;
  @Input() onLazyLoadCommand: Function = (event: LazyLoadEvent) => {};
  @Input() onFilterCommand: Function = (event: any) => {};
  @Input() loading: boolean = false;
  @Input() customFilter: boolean;
  @Input() appendTo: string = null;
  @Input() completeOnFocus: boolean = true;
  @Input() group: boolean = false;
  @ContentChild('itemTemplate') itemTemplate: TemplateRef<any>;
  @ContentChild('resetCheckTemplate') resetCheckTemplate: TemplateRef<any>;

  public filterValue: string;
  filterValueUpdate = new Subject<string>();

  constructor() {
    super();
    this.filterValueUpdate
      .pipe(
        debounceTime(200)
        // distinctUntilChanged()
      )
      .subscribe((value) => {
        this.onFilterCommand ? this.onFilterCommand(value) : this.filterItems(value);
      });
  }

  ngOnInit(): void {
    let patchVal =
      this.optionValue != undefined
        ? this.control?.getRawValue()
        : this.control?.getRawValue()?.name || this.control?.getRawValue()?.[this.optionLabel];
    this.editModalControl.patchValue(patchVal);
    this.subs.sink = this.onChanges.subscribe((res) => {
      let patchVal =
        this.optionValue != undefined
          ? this.control?.getRawValue()
          : this.control?.getRawValue()?.name || this.control?.getRawValue()?.[this.optionLabel];

      this.editModalControl.patchValue(patchVal);
    });
    if (this.control) {
      this.subs.sink = this.fControl.valueChanges.subscribe((changes) => {
        if (!this.uiChange) {
          this.searchModel = changes;
        }
        this.uiChange = false;
      });
    }
  }

  setInputOptions() {
    this.multi = this.inputOptions?.dropDownInput?.multi ?? this.multi;
    this.items = this.inputOptions?.dropDownInput?.items ?? this.items;
    this.optionLabel = this.inputOptions?.dropDownInput?.optionLabel ?? this.optionLabel;
    this.showClear = this.inputOptions?.dropDownInput?.showClear ?? this.showClear;
    this.optionValue = this.inputOptions?.dropDownInput?.optionValue ?? this.optionValue;
    this.dataKey = this.inputOptions?.dropDownInput?.dataKey ?? this.dataKey;

    this.virtualScroll = this.inputOptions?.dropDownInput?.virtualScroll ?? this.virtualScroll;
    this.virtualScrollItemSize = this.inputOptions?.dropDownInput?.virtualScrollItemSize ?? this.virtualScrollItemSize;
    this.lazy = this.inputOptions?.dropDownInput?.lazy ?? this.lazy;
    this.loading = this.inputOptions?.dropDownInput?.loading ?? this.loading;
    this.onLazyLoadCommand = this.inputOptions?.dropDownInput?.onLazyLoadCommand ?? this.onLazyLoadCommand;
    this.onFilterCommand = this.inputOptions?.dropDownInput?.onFilterCommand ?? this.onFilterCommand;
    this.customFilter = this.inputOptions?.dropDownInput?.customFilter ?? this.customFilter;
    this.appendTo = this.inputOptions?.dropDownInput?.appendTo ?? this.appendTo;

    this.completeOnFocus = this.inputOptions?.dropDownInput?.lazy ?? this.completeOnFocus;
  }

  filterItems(event) {
    //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
    let filtered: any[] = [];
    let query = event;

    for (let i = 0; i < this.suggestions.length; i++) {
      let item = this.suggestions[i];
      if (item.label.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(item);
      }
    }

    this.filteredItems = filtered;
  }
  onCompleteMethod(event) {
    if (
      event.query ===
      (this.searchModel
        ? this.optionValue
          ? this.searchModel?.[this.optionValue]
          : this.optionLabel
            ? this.searchModel[this.optionLabel]
            : this.searchModel
        : null)
    ) {
      this.filterValueUpdate.next('');
    } else {
      this.filterValueUpdate.next(event.query);
    }
  }
  beforeFocusValue;
  isFocused: boolean = false;
  onFocusFilter(event) {
    this.beforeFocusValue = this.searchModel;
    this.isFocused = true;

    setTimeout(() => {
      event.target.select();
    }, 0);
  }
  onLoseFocusFilter(event) {
    this.isFocused = false;
  }
  getViewData() {
    let value = this.editModalControl.value || this.data;
    if (typeof value === 'string' || value instanceof String) {
      const code = extractItemCode(value as any)?.code;
      if (code) this.dataType = DataTypeEnum.CodeLink;
      else this.dataType = DataTypeEnum.Text;
      return code || value;
    } else if (Array.isArray(value)) {
      const ret = value.map((x) =>
        x.name ? extractItemCode(x.name)?.code : x?.principle ? extractItemCode(x.principle)?.code : x
      );
      this.dataType = DataTypeEnum.CodeLink;
      return ret;
    } else {
      const ret = value?.name ? value?.name : value?.principle ? value.principle : value;
      const code = extractItemCode(ret)?.code;
      if (code) this.dataType = DataTypeEnum.CodeLink;
      else this.dataType = DataTypeEnum.Text;
      return code || ret;
    }
  }
  editModalControl: FormControl = new FormControl(null);
  onClear() {
    this.searchModel = undefined;
    this.onSelectItem(null);
    setTimeout(() => {
      this.searchModel = undefined;
    }, 10);
  }
}
