import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toFixedDigits',
})
export class ToFixedDigitsPipe implements PipeTransform {
  transform(value: any, fixedDigits = 2, parseToFloat: 'float' | 'int' = 'float', args?: any): string {
    if (typeof value === 'number')
      return parseToFloat == 'float' ? value?.toFixed(fixedDigits) : parseInt(value + '')?.toFixed(fixedDigits);
    if (typeof value === 'string')
      return parseToFloat == 'float' ? parseFloat(value)?.toFixed(fixedDigits) : parseInt(value)?.toFixed(fixedDigits);
    else return '0.00';
  }
}
