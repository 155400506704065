import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { BaseListResponse, BaseResponse, FilterItem, RequestHandlerOptions, Task } from '@shared/classes';
import { BaseRequestControllerService } from '@shared/services/api/base-request-controller.service';
import { DataService } from '@shared/services/api/data.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MyTaskDataService extends BaseRequestControllerService<Task> {
  constructor(
    private dataService: DataService,
    private router: Router
  ) {
    super(dataService, environment.userOperations, '/my-tasks', [
      'id',
      'code',
      'tags',
      'tagsCodes',
      'name',
      'lockedBy',
      'lockedForEdit',
      'lockedUntil',
      'userAccessLevel',
      'recordStatus',
      'version',
      'creatorName',
      'lastModifierName',
      'creationDate',
      'lastModificationDate',
      'relatedToType',
      'relatedToCode',
      'principle',
      'data',
      'status',
      'subject',
      'description',
      'priority',
      'completed',
      'sortId',
      'tags',
      'sender',
      'dueDate',
    ]);
  }

  private _prepareFilters(data: any) {
    let filters: FilterItem[] = [];
    if (!!data?.searchTerm) {
      filters = [{ property: 'task', operation: 'CONTAINS', value: data.searchTerm }];
    }
    return filters;
  }

  navigateToTaskListPage() {
    this.router.navigateByUrl(`/activity-feed/my-task/list`);
  }

  getByIdOrCode<T>(idOrCode: string, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.dataService.getData<BaseResponse<T>>(this.url + '/getByIdOrCode/' + idOrCode, options) as Observable<
      BaseResponse<T>
    >;
  }

  myTasks(options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.dataService.getData<Task[]>(`${this.url}/my`, options) as Observable<BaseListResponse<Task>>;
  }
  toggleTask(taskId: string, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.dataService.getData<Task>(`${this.url}/toggle/${taskId}`, options) as Observable<BaseResponse<Task>>;
  }
}
