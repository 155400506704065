import { AggregateType } from '../view/view-enums';

export function parseAggregateData(
  items: any[],
  key: string,
  type: AggregateType,
  enumContainer: any = null,
  idKey: any = null
) {
  let ret = {};
  if (enumContainer) {
    Object.keys(enumContainer).forEach((key) => {
      ret[enumContainer[key]] = 0;
    });
  }
  if (items) {
    if (idKey) {
      items.forEach((item) => {
        if (item[key + '_' + type] && item.group_id[idKey] != null && item.group_id[idKey] != undefined)
          ret[item.group_id[idKey]] = item[key + '_' + type];
      });
    } else {
      items.forEach((item) => {
        if (item[key + '_' + type] && item.group_id != null && item.group_id != undefined) ret[item.group_id[key]] = item[key + '_' + type];
      });
    }
  }
  return ret;
}
export function parseAggregateDataV2(
  items: any[],
  key: string,
  type: AggregateType,
  enumContainer: any = null,
  idKey: any = null
) {
  let ret = {};
  if (enumContainer) {
    Object.keys(enumContainer).forEach((key) => {
      ret[enumContainer[key]] = 0;
    });
  }
  if (items) {
    if (idKey) {
      items.forEach((item) => {
        if (item[key + '_' + type] && item.group_id[idKey] != null && item.group_id[idKey] != undefined)
          ret[item.group_id[idKey]] = ret[item.group_id[idKey]]
            ? ret[item.group_id[idKey]] + item[key + '_' + type]
            : item[key + '_' + type];
      });
    } else {
      items.forEach((item) => {
        if (item[key + '_' + type] && item.group_id != null && item.group_id != undefined) ret[item.group_id] = item[key + '_' + type];
      });
    }
  }
  return ret;
}
export function fixAggregateId(groupByFields: string[], response) {
  // if (isNullObj(groupByFields)) { //@TODO: discuss this case and come up with appropriate handling for it
  return { ...response };
  // }
  // return groupByFields?.length > 1
  //   ? { ...response }
  //   : {
  //       ...response,
  //       aggregation_value: response?.aggregation_value?.map((x) => {
  //         return { ...x, group_id: isString(x.group_id) ? { [groupByFields?.[0]]: x.group_id } : x.group_id };
  //       }),
  //     };
}
