import { Component, Input, OnInit } from '@angular/core';
import { DataTypeEnum } from '@shared/classes';
import { DynamicComponentBase } from '@shared/classes/view/DynamicComponentBase';

@Component({
  selector: 'app-mcq',
  templateUrl: './mcq.component.html',
  styleUrls: ['./mcq.component.scss'],
  providers: [{ provide: DynamicComponentBase, useExisting: MCQComponent }],
})
export class MCQComponent extends DynamicComponentBase implements OnInit {
  @Input() groupItems: { label: string; value: any }[] = [];
  @Input() selectionMode: 'radio' | 'dropdown' = 'radio';
  dataType: DataTypeEnum = DataTypeEnum.Text;
  @Input() scored: boolean = false;
  @Input() showScore: boolean = false;
  @Input() passingScore: number = 0;
  constructor() {
    super();
  }

  ngOnInit(): void {}

  setInputOptions() {
    this.groupItems = this.inputOptions?.radioInput?.groupItems ?? this.groupItems;
    this.selectionMode = this.inputOptions?.radioInput?.selectionMode ?? this.selectionMode;
    this.scored = this.inputOptions?.radioInput?.scored ?? this.scored;
    this.showScore = this.inputOptions?.radioInput?.showScore ?? this.showScore;
    this.passingScore = this.inputOptions?.radioInput?.passingScore ?? this.passingScore;
  }
}
