<app-input-view-switch
  [showLabelInViewMode]="showLabelInViewMode"
  [mode]="viewMode"
  [data]="data || control?.value"
  [dataType]="dataType"
  [label]="label ?? 'Icon'"
  [keepValueCallback]="setFieldResetable.bind(this)"
  [resetFieldCheckVisable]="resetFieldCheckVisable"
  [resetableChecked]="resetable"
>
  <app-drop-down-input
    [label]="label ?? 'Type'"
    [placeholder]="placeholder ?? 'Search Types'"
    [name]="'dropdown'"
    [optionLabel]="'label'"
    [items]="items"
    [optionValue]="optionValue"
    [control]="fControl"
    [multi]="multi"
    [badgeView]="true"
    [lazy]="false"
    [loading]="isLoading"
    [floatLabel]="floatLabel"
    [viewType]="dataType"
    [appendTo]="appendTo"
    ><ng-template #resetCheckTemplate>
      @if (resetFieldCheckVisable) {
        <app-reset-switch-input [checked]="!resetable" (onChange)="setFieldResetable($event)"></app-reset-switch-input>
      }</ng-template
  ></app-drop-down-input>
</app-input-view-switch>
