import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  _data;
  set data(value) {
    this._data = value;
  }
  get data() { return this._data; }

  clear() {
    this.data = null;
  }
}