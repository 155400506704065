<app-input-view-switch
  [showLabelInViewMode]="showLabelInViewMode"
  [mode]="viewMode"
  [data]="data || control?.value"
  [dataType]="dataType"
  [label]="label ?? 'Icon'"
  [keepValueCallback]="setFieldResetable.bind(this)"
  [resetFieldCheckVisable]="resetFieldCheckVisable"
  [resetableChecked]="resetable"
>
  <app-drop-down-input
    [label]="label ?? 'Icon'"
    [placeholder]="placeholder ?? 'Search Icons'"
    [name]="'dropdown'"
    [optionLabel]="'label'"
    [items]="items"
    [optionValue]="optionValue"
    [control]="fControl"
    [multi]="multi"
    [lazy]="false"
    [loading]="isLoading"
    [floatLabel]="floatLabel"
    [viewType]="dataType"
    [virtualScroll]="true"
    [virtualScrollItemSize]="30.4"
    [appendTo]="appendTo"
  >
    <ng-template #resetCheckTemplate>
      @if (resetFieldCheckVisable) {
        <app-reset-switch-input [checked]="!resetable" (onChange)="setFieldResetable($event)"></app-reset-switch-input>
      }
    </ng-template>
  </app-drop-down-input>
  <!-- <div class="p-inputgroup">
        <div class="p-float-label">
            <button pButton pRipple type="button" class="p-button p-button-secondary w-full border-round px-2 justify-content-start"
            [icon]="fControl.value || 'pi pi-info-circle'"
            [iconPicker]="fControl.value"
            [ipIconPack]="['fa', 'fa5', 'fa6', 'mat', 'pi']" [ipPosition]="'bottom'" [ipWidth]="'250px'"
            [ipIconSize]="'16px'" [ipIconVerticalPadding]="'6px'" [ipIconHorizontalPadding]="'10px'"
            [ipKeepSearchFilter]="'false'" [ipPlaceHolder]="'Search for icon'" [ipFallbackIcon]="fallbackIcon"
            [ipButtonStyleClass]="'icon-selector-btn p-button'" [ipDivSearchStyleClass]="'icon-selector-search-div'"
            [ipInputSearchStyleClass]="'p-inputtext p-component p-element w-full icon-selector-search'"
            (iconPickerSelect)="onIconPickerSelect($event)"
            ></button>
            <input hidden class="p-inputtext p-component p-element ng-dirty ng-valid p-filled ng-touched" name="iconCss" [value]="123" /> -->
  <!-- <input pInputText type="text" name="iconCss" [formControl]="fControl" [iconPicker]="fControl.value"
                [ipIconPack]="['fa', 'fa5', 'fa6', 'mat', 'pi']" [ipPosition]="'bottom'" [ipWidth]="'250px'"
                [ipIconSize]="'16px'" [ipIconVerticalPadding]="'6px'" [ipIconHorizontalPadding]="'10px'"
                [ipKeepSearchFilter]="'false'" [ipPlaceHolder]="'Search for icon'" [ipFallbackIcon]="fallbackIcon"
                [ipButtonStyleClass]="'icon-selector-btn p-button'" [ipDivSearchStyleClass]="'icon-selector-search-div'"
                [ipInputSearchStyleClass]="'p-inputtext p-component p-element w-full icon-selector-search'"
                (iconPickerSelect)="onIconPickerSelect($event)" [placeholder]="'Choose an Icon'" /> -->
  <!-- <label htmlFor="iconCss">Icon</label>
        </div> -->
  <!-- <span class="p-inputgroup-addon">
            <i [ngClass]="fControl.value || 'pi pi-info-circle'"></i>
        </span> -->
  <!-- </div> -->
</app-input-view-switch>
<!-- <i [ngClass]="fControl.value"></i> -->
<!-- [ipFallbackIcon]="fallbackIcon" -->
