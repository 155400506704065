import { Component, Input, OnInit } from '@angular/core';
import { GlobalValueListDto, GlobalValueListItem } from '@shared/classes';
import { ITablePageable } from '@shared/classes/view/AppTable';
import { DynamicComponentBase } from '@shared/classes/view/DynamicComponentBase';
import { DataTypeEnum } from '@shared/classes/view/view-enums';
import { GlobalValueListDataService } from 'app/modules/data-structure-module/services/data/global-value-list-data.service';
import { ControlDataService } from 'app/modules/org-framework/service/data/ControlDataService';

@Component({
  selector: 'app-gvl-selector',
  templateUrl: './gvl-selector.component.html',
  styleUrls: ['./gvl-selector.component.scss'],
})
export class GvlSelectorComponent extends DynamicComponentBase implements OnInit {
  gvlCode = null;
  gvlListMode = false;
  gvlSelectionMod = false;
  gvlInlineMode = false;
  gvlMinSelection = null;
  gvlMaxSelection = null;
  gvlShowDescription = false;
  gvlShowNumeric = false;
  dataTypes = DataTypeEnum;

  gvlObjectMapper: Map<string, GlobalValueListItem> = new Map<string, GlobalValueListItem>();

  private _controlEntity = null;
  get controlEntity() {
    return this._controlEntity;
  }
  @Input() set controlEntity(controlEntity) {
    this._controlEntity = controlEntity;
    this.getOptions();
  }

  constructor(private service: GlobalValueListDataService) {
    super();
  }

  items: any[] = [];
  isLoading: boolean = false;
  ngOnInit(): void {}

  setInputOptions() {
    this.gvlCode = this.inputOptions?.gvlInput.gvlCode;
    this.gvlListMode = this.inputOptions?.gvlInput.gvlListMode;
    this.gvlSelectionMod = this.inputOptions?.gvlInput.gvlSelectionMode;
    this.gvlInlineMode = this.inputOptions?.gvlInput.gvlInlineMode;
    this.gvlMinSelection = this.inputOptions?.gvlInput.gvlMinSelection;
    this.gvlMaxSelection = this.inputOptions?.gvlInput.gvlMaxSelection;
    this.gvlShowDescription = this.inputOptions?.gvlInput.gvlShowDescription;
    this.gvlShowNumeric = this.inputOptions?.gvlInput.gvlShowNumeric;

    this.getOptions();
    this.validate(this.control.value);
    this.control.valueChanges.subscribe((x) => {
      this.validate(x);
    });
  }
  getOptions() {
    this.isLoading = true;
    this.subs.sink = this.service.getByIdOrCode<GlobalValueListDto>(this.gvlCode).subscribe({
      next: (res) => {
        const list: any = [...res.data.values];

        // for (let i = 0; i < list.length; i++) {
        //   const element = Object.assign({}, list[i]);
        //   list[i].value = element;
        //   list[i].sValue = element.value;
        // }

        this.setMapper(list);

        // if (this.viewMode == 'view') {
        //   this.data = this.data.map(x => this.gvlObjectMapper.get(x).value.split(',')[0]);
        // }

        this.items = list;
      },
      complete: () => {
        this.isLoading = false;
      },
    });
  }

  getList(items) {
    if (!items) return [];
    return items.map((x) => this.gvlObjectMapper.get(x));
  }

  getObject(item) {
    const obj = this.gvlObjectMapper.get(item.value ?? item);
    return obj;
  }

  setMapper(items: any[]) {
    items.forEach((x) => {
      this.gvlObjectMapper.set(x.value, x);
    });
  }

  validate(list) {
    if (list && this.gvlSelectionMod && this.gvlMinSelection != null && this.gvlMaxSelection != null) {
      if (list.length > this.gvlMaxSelection || list.length < this.gvlMinSelection) {
        this.control.setErrors({
          error:
            'Selected Items are out of the min/max range, min: ' +
            this.gvlMinSelection +
            ' max: ' +
            this.gvlMaxSelection,
        });
        this.control.markAsDirty();
      } else {
        this.control.setErrors(null);
        this.control.markAsPristine();
      }
    }
  }
}
