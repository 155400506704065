import { PrimeIcons } from 'primeng/api';
import { AuditTrailDto, EntityTypeFieldDto, NotificationDto } from '../model';
import { ModuleKeywords } from './ModuleKeywords';
import { SFIcons } from './SFIcons';

export const ModuleIcons = {
  //@TODONewModule: when you add new module make sure to define these
  // [ModuleKeywords.EntityLevels]: SFIcons.Entities,//PrimeIcons.SORT_AMOUNT_UP_ALT,
  [ModuleKeywords.EntityType]: SFIcons.EntityType, //PrimeIcons.BOOK,
  ['Entity']: SFIcons.Entities, // PrimeIcons.SITEMAP,
  [ModuleKeywords.Question]: SFIcons.QuestionBank, //PrimeIcons.QUESTION_CIRCLE,
  [ModuleKeywords.Questionnaire]: SFIcons.Questionnaires, //PrimeIcons.COMMENTS,
  [ModuleKeywords.Campaign]: SFIcons.Questionnaires, //PrimeIcons.COMMENTS,
  [ModuleKeywords.QuestionnaireRequest]: SFIcons.Questionnaires, //PrimeIcons.COMMENTS,
  [ModuleKeywords.QuestionnaireValue]: SFIcons.QuestionnairesAnswers, //PrimeIcons.BOOK,
  [ModuleKeywords.MyQuestionnaireValue]: SFIcons.MyQuestionnairesAnswers, //PrimeIcons.WALLET,
  [ModuleKeywords.RiskItem]: SFIcons.RiskRegister, //PrimeIcons.EXCLAMATION_CIRCLE,
  [ModuleKeywords.RiskProject]: SFIcons.RiskProject, //PrimeIcons.INBOX,
  [ModuleKeywords.RiskStatement]: SFIcons.RiskStatement, //PrimeIcons.COMMENT,
  [ModuleKeywords.RiskStatementCategory]: SFIcons.RiskStatementCategory, //PrimeIcons.QRCODE,
  // [ModuleKeywords.ImpactFactor]:PrimeIcons.SLIDERS_V,
  [ModuleKeywords.RiskMethodology]: SFIcons.RiskMethodology, //PrimeIcons.CHART_LINE,
  [ModuleKeywords.Control]: SFIcons.Controls, //PrimeIcons.ARROWS_ALT,
  [ModuleKeywords.ControlCategory]: SFIcons.Controls, //PrimeIcons.ARROWS_ALT,
  [ModuleKeywords.ControlGroup]: SFIcons.ControlObjective, //PrimeIcons.SORT_AMOUNT_UP_ALT,
  [ModuleKeywords.ControlProcedure]: 'pi pi-list-check', //PrimeIcons.SORT_AMOUNT_UP_ALT,
  [ModuleKeywords.Policy]: SFIcons.Policies, //PrimeIcons.TICKET,
  [ModuleKeywords.PublishedPolicy]: SFIcons.Policies, //PrimeIcons.PALETTE,
  [ModuleKeywords.PolicyBody]: PrimeIcons.BRIEFCASE, //PrimeIcons.TICKET,
  [ModuleKeywords.PolicyBodySection]: PrimeIcons.FILE_EDIT, //PrimeIcons.TICKET,
  [ModuleKeywords.Exception]: SFIcons.Exceptions, //PrimeIcons.EXCLAMATION_CIRCLE,
  [ModuleKeywords.AuthorityDocument]: SFIcons.Authorities, //PrimeIcons.TICKET,
  [ModuleKeywords.CitationType]: SFIcons.CitationTypes, //PrimeIcons.INBOX,
  [ModuleKeywords.Citation]: SFIcons.Citation, //PrimeIcons.LIST,
  [ModuleKeywords.Exemption]: SFIcons.Exemption, //PrimeIcons.TABLE,
  [ModuleKeywords.Task]: SFIcons.Tasks, //PrimeIcons.BOOKMARK,
  [ModuleKeywords.MyTask]: SFIcons.MyTasks, //PrimeIcons.BOOKMARK,
  ['MyTasks']: SFIcons.MyTasks, //PrimeIcons.BOOKMARK,
  ['AllTasks']: SFIcons.AllTasks, //PrimeIcons.BOOKMARK,
  [ModuleKeywords.IssueType]: SFIcons.IssueType, //PrimeIcons.MONEY_BILL,
  [ModuleKeywords.Issue]: SFIcons.Issue, //PrimeIcons.PAPERCLIP,
  [ModuleKeywords.Remediation]: SFIcons.Remediation, //PrimeIcons.TH_LARGE,
  [ModuleKeywords.User]: SFIcons.Users, //PrimeIcons.USER,
  [ModuleKeywords.Role]: SFIcons.Roles, //PrimeIcons.SHIELD,
  [ModuleKeywords.Group]: SFIcons.Groups, //PrimeIcons.USERS,
  [ModuleKeywords.Finding]: PrimeIcons.COG,
  [ModuleKeywords.Asset]: SFIcons.Assets, //PrimeIcons.PALETTE,
  [ModuleKeywords.Event]: SFIcons.Events, //PrimeIcons.CALENDAR_TIMES,
  [ModuleKeywords.Geography]: SFIcons.Geographies, //PrimeIcons.MAP,
  [ModuleKeywords.Objective]: SFIcons.Objectives, //PrimeIcons.CHART_LINE,
  [ModuleKeywords.Process]: SFIcons.Processes, //PrimeIcons.CALCULATOR,
  [ModuleKeywords.Responsibility]: SFIcons.Responsibilities, //PrimeIcons.USER_PLUS,
  [ModuleKeywords.Organization]: SFIcons.Organization, //PrimeIcons.BUILDING,
  [ModuleKeywords.Acknowledgment]: SFIcons.Acknowledgments, //PrimeIcons.BOOK,
  [ModuleKeywords.AcknowledgmentRequest]: SFIcons.AcknowledgmentRequests, //PrimeIcons.INBOX,
  [ModuleKeywords.Notification]: SFIcons.Notification, //PrimeIcons.BELL,
  ['AllNotification']: SFIcons.NotificationGroup, //PrimeIcons.BELL,
  [ModuleKeywords.MyNotification]: SFIcons.MyNotification, //PrimeIcons.BELL,
  [ModuleKeywords.FieldTemplate]: SFIcons.Field, //PrimeIcons.LIST,
  // [ModuleKeywords.Tags]: SFIcons.RelationTypes,//PrimeIcons.TAGS,
  [ModuleKeywords.RelationType]: SFIcons.RelationType, //PrimeIcons.TAGS,
  [ModuleKeywords.RelationType]: SFIcons.RelationType, //PrimeIcons.TAGS,
  [ModuleKeywords.Attestation]: 'pi pi-file-check',
  [ModuleKeywords.RiskAssessmentRequest]: SFIcons.RiskAssessmentRequest, // PrimeIcons.FILE,
  [ModuleKeywords.MyRiskAssessmentRequest]: SFIcons.MyRiskAssessmentRequest, // PrimeIcons.FILE,
  [ModuleKeywords.GlobalValueList]: SFIcons.GlobalValueTemplates, // PrimeIcons.LIST, // PrimeIcons.FILE,
  [ModuleKeywords.ComplianceReport]: SFIcons.ComplianceStatus, //PrimeIcons.CHART_LINE,
  [ModuleKeywords.ComplianceReportResult]: SFIcons.ComplianceReport, //PrimeIcons.CHART_LINE,
  [ModuleKeywords.ControlsGapReport]: SFIcons.ComplianceStatus, //PrimeIcons.CHART_LINE,
  [ModuleKeywords.ControlsGapReportResult]: SFIcons.ComplianceReport, //PrimeIcons.CHART_LINE,
  [ModuleKeywords.EmailTemplate]: PrimeIcons.ENVELOPE,
  [ModuleKeywords.TaskTemplate]: SFIcons.TaskEfficiency, // PrimeIcons.CALENDAR_TIMES,
  [ModuleKeywords.MyAcknowledgment]: SFIcons.MyAcknowledgment,
  [ModuleKeywords.AuditTrail]: SFIcons.Audit, // PrimeIcons.LIST,
  [ModuleKeywords.AuditCase]: SFIcons.AuditCase, //PrimeIcons.COMMENT,
  [ModuleKeywords.AuditCaseResult]: SFIcons.AuditCaseResult, //PrimeIcons.COMMENTS,
  [ModuleKeywords.AuditUniverse]: SFIcons.AuditUniverse, // PrimeIcons.STAR_FILL,
  [ModuleKeywords.AuditProgram]: SFIcons.AuditProgram, // PrimeIcons.LIST,
  [ModuleKeywords.AuditProgramType]: SFIcons.AuditProgramType, //PrimeIcons.INFO_CIRCLE,
  [ModuleKeywords.AuditProgramReport]: SFIcons.AuditProgramReport, //PrimeIcons.CHECK_CIRCLE,
  [ModuleKeywords.AuditProject]: SFIcons.AuditProject, // PrimeIcons.PAPERCLIP,
  [ModuleKeywords.AuditProjectReport]: SFIcons.AuditProjectReports, //PrimeIcons.CHECK_SQUARE,
  [ModuleKeywords.AuditCaseTemplate]: SFIcons.AuditCaseTemplate, //PrimeIcons.CALENDAR_TIMES,
  [ModuleKeywords.AuditRequest]: SFIcons.AuditRequests, // PrimeIcons.CALENDAR_PLUS,
  [ModuleKeywords.AuditExpense]: PrimeIcons.MONEY_BILL,
  [ModuleKeywords.ImportGlobalPackage]: SFIcons.ImportGlobalPackage, // PrimeIcons.FILE_IMPORT,
  [ModuleKeywords.ExportGlobalPackage]: SFIcons.ExportGlobalPackages, //PrimeIcons.FILE_EXPORT,
  [ModuleKeywords.RiskThreat]: SFIcons.RiskThreats,
  [ModuleKeywords.RiskVulnerability]: SFIcons.RiskVulnerability,
  [ModuleKeywords.Filter]: PrimeIcons.FILTER,
  [ModuleKeywords.ContentView]: PrimeIcons.EYE,
  [ModuleKeywords.Dashboard]: PrimeIcons.TABLE,
  [ModuleKeywords.Report]: PrimeIcons.BARS,
  [ModuleKeywords.Standard]: SFIcons.Policies,
  [ModuleKeywords.ControlObjective]: PrimeIcons.FILE_EDIT,
  [ModuleKeywords.PublishedStandard]: SFIcons.Policies,
  [ModuleKeywords.Guideline]: SFIcons.Assets,
  [ModuleKeywords.PublishedGuideline]: SFIcons.AllTasks,
  [ModuleKeywords.StatisticCollector]: PrimeIcons.CHART_SCATTER,
  [ModuleKeywords.GeneratedStatistic]: PrimeIcons.IMAGES,
  [ModuleKeywords.GeneratedReport]: PrimeIcons.IMAGE,
  [ModuleKeywords.ReportConfigurationTemplate]: PrimeIcons.IMAGE,
  [ModuleKeywords.Tag]: 'pi pi-tag',
  [ModuleKeywords.ReportScheduler]: PrimeIcons.CALENDAR_CLOCK,
  [ModuleKeywords.Metric]: PrimeIcons.STOPWATCH,
  [ModuleKeywords.MetricValue]: PrimeIcons.CHECK_CIRCLE,
  [ModuleKeywords.MetricCategory]: PrimeIcons.BOOK,
  [ModuleKeywords.Language]: PrimeIcons.LANGUAGE,
  //Enum icons
  PERFORMANCE: PrimeIcons.GAUGE,
  ASSETS: SFIcons.Assets, //PrimeIcons.PALETTE,
  ASSET: SFIcons.Assets, //PrimeIcons.PALETTE,

  EVENTS: SFIcons.Events, //PrimeIcons.CALENDAR_TIMES,
  EVENT: SFIcons.Events, //PrimeIcons.CALENDAR_TIMES,

  GEOGRAPHIES: SFIcons.Geographies, //PrimeIcons.MAP,
  GEOGRAPHY: SFIcons.Geographies, //PrimeIcons.MAP,

  OBJECTIVES: SFIcons.Objectives, //PrimeIcons.CHART_LINE,

  PROCESSES: SFIcons.Processes, //PrimeIcons.CALCULATOR,

  RESPONSIBILITIES: SFIcons.Responsibilities, //PrimeIcons.USER_PLUS,

  FRAMEWORK: SFIcons.Framework,

  MyAcknowledgements: SFIcons.MyAcknowledgment,
  RiskList: SFIcons.RiskList,
  AuthorityDocument: SFIcons.AuthorityDocument,
  DataStructure: SFIcons.DataStructure,
  Questions: SFIcons.Questions,
  Management: SFIcons.Management,
  GlobalPackage: SFIcons.DataStructure,
};

export const AppEnumIcons = {
  [AuditTrailDto.TypeEnum.Active]: PrimeIcons.VERIFIED,
  ['INACTIVE']: PrimeIcons.INBOX,
  [AuditTrailDto.TypeEnum.Created]: PrimeIcons.SAVE,
  [AuditTrailDto.TypeEnum.Events]: PrimeIcons.CALENDAR,
  [AuditTrailDto.TypeEnum.Locked]: PrimeIcons.LOCK,
  [AuditTrailDto.TypeEnum.UnLocked]: PrimeIcons.UNLOCK,
  [AuditTrailDto.TypeEnum.LockedForEdit]: PrimeIcons.LOCK,
  [AuditTrailDto.TypeEnum.UnLockedForEdit]: PrimeIcons.UNLOCK,
  [AuditTrailDto.TypeEnum.Updated]: PrimeIcons.FILE_EDIT,
  [AuditTrailDto.TypeEnum.InActive]: PrimeIcons.INBOX,
  [AuditTrailDto.TypeEnum.ChangeState]: PrimeIcons.ARROW_RIGHT_ARROW_LEFT,
  [AuditTrailDto.TypeEnum.AccessChange]: PrimeIcons.USERS,
  [AuditTrailDto.TypeEnum.Commented]: PrimeIcons.COMMENT,
  [AuditTrailDto.TypeEnum.UserJoin]: PrimeIcons.USER_PLUS,
  [AuditTrailDto.TypeEnum.UserLeave]: PrimeIcons.USER_MINUS,
  [EntityTypeFieldDto.CategoryEnum.Asset]: SFIcons.Assets, //PrimeIcons.PALETTE,
  [EntityTypeFieldDto.CategoryEnum.Event]: SFIcons.Events, //PrimeIcons.CALENDAR_TIMES,
  [EntityTypeFieldDto.CategoryEnum.Geography]: SFIcons.Geographies, //PrimeIcons.MAP,
  [EntityTypeFieldDto.CategoryEnum.Objective]: SFIcons.Objectives, //PrimeIcons.CHART_LINE,
  [EntityTypeFieldDto.CategoryEnum.Process]: SFIcons.Processes, //PrimeIcons.CALCULATOR,
  [EntityTypeFieldDto.CategoryEnum.Responsibility]: SFIcons.Responsibilities, //PrimeIcons.USER_PLUS,
  [NotificationDto.NotificationTypeEnum.Archived]: PrimeIcons.INBOX,
  [NotificationDto.NotificationTypeEnum.AuditTrail]: PrimeIcons.HISTORY,
  [NotificationDto.NotificationTypeEnum.Comment]: PrimeIcons.COMMENT,
  [NotificationDto.NotificationTypeEnum.Task]: PrimeIcons.BOOKMARK,
};
