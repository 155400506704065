import { Injectable } from '@angular/core';
import has from 'lodash-es/has';
@Injectable({
  providedIn: 'root',
})
export class CacheService {
  private data = {};
  constructor() {}

  public get(code) {
    return this.data[code];
  }
  public has(code) {
    return has(this.data, code);
  }
  public set(code, value) {
    this.data[code] = value;
  }
  public remove(code) {
    delete this.data[code];
  }
  public clear() {
    this.data = {};
  }
}
