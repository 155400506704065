import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { isNullObj } from '@shared/classes';
import { BehaviorSubject, filter } from 'rxjs';
import { PathResolverService } from '../path-resolver.service';
import { HubDataService } from './hub-data.service';

@Injectable({
  providedIn: 'root',
})
export class CodeLabelCacheService {
  private cache = new Map<string, BehaviorSubject<any>>();
  private currentCodeList: string[] = [];
  timeoutId: any;
  constructor(
    private hubDataService: HubDataService,
    private router: Router,
    private pathService: PathResolverService
  ) {
    router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      this.cache = new Map<string, BehaviorSubject<any>>();
    });
  }
  getSynchronousCachedCode(code: string): any {
    const cachedItem = this.cache.get(code);
    if (cachedItem) {
      return cachedItem?.value;
    } else {
      return null;
    }
  }
  addCode(
    code: string,
    currentValues: { code?: string; name?: string; label?: string; recordStatus?: any } = null,
    overrideValues: { code?: string; label?: string; recordStatus?: any } = null
  ): BehaviorSubject<any> {
    if (!this.pathService.isValidCode(code)) return new BehaviorSubject<any>({ loading: false });
    // Cancel any previous timeout
    clearTimeout(this.timeoutId);

    const cachedItem = this.cache.get(code);
    let retCache: BehaviorSubject<any>;
    if (cachedItem) {
      retCache = cachedItem;
    } else {
      if (!isNullObj(overrideValues)) {
        const cachedObservable = new BehaviorSubject<any>({ ...overrideValues, loading: false });
        this.cache.set(code, cachedObservable);
        retCache = cachedObservable;
      } else {
        this.currentCodeList.push(code);
        const additionalProps = currentValues?.code
          ? { ...currentValues, label: currentValues?.label || currentValues?.name }
          : {};
        const cachedObservable = new BehaviorSubject<any>({ ...additionalProps, loading: true });
        this.cache.set(code, cachedObservable);
        retCache = cachedObservable;
      }
    }

    // Set a new timeout to call function2 after 30ms
    this.timeoutId = setTimeout(() => {
      this.fetchCodeList([...this.currentCodeList]);
      this.currentCodeList = [];
    }, 30);

    return retCache;
  }
  fetchCodeList(codeList: string[]) {
    if (codeList.length > 0) {
      this.hubDataService.ProcessCodes(codeList, { showLoading: false, showMsg: false }).subscribe((res) => {
        let codeChecklist = {};
        codeList.forEach((code) => {
          codeChecklist[code] = false;
        });
        Object.keys(res).forEach((key) => {
          const element = res[key];
          element.forEach((obj) => {
            this.cache.get(obj?.code)?.next(obj);
            codeChecklist[obj?.code] = true;
          });
        });
        Object.keys(codeChecklist).forEach((key) => {
          const element = codeChecklist[key];
          if (!element) {
            this.cache.get(key)?.next({ loading: false, notFound: true });
          }
        });
      });
    }
  }
}
