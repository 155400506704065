/**
 * Sightflare Service
 * Rest Api for Sightflare Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { LikelihoodValue } from './likelihoodValue';
import { ImpactValue } from './impactValue';


export interface DraftRisksItem { 
    name?: string;
    description?: string;
    inheritedLikelihood?: LikelihoodValue;
    inheritedRiskImpact?: ImpactValue;
    inheritedRisk?: number;
    inheritedRiskAcceptance?: DraftRisksItem.InheritedRiskAcceptanceEnum;
    residualRiskAcceptance?: DraftRisksItem.ResidualRiskAcceptanceEnum;
    inheritedClassification?: string;
    residualClassification?: string;
    riskThreats?: Array<string>;
    riskVulnerabilities?: Array<string>;
}
export namespace DraftRisksItem {
    export type InheritedRiskAcceptanceEnum = 'ACCEPTABLE' | 'NOT_ACCEPTABLE';
    export const InheritedRiskAcceptanceEnum = {
        Acceptable: 'ACCEPTABLE' as InheritedRiskAcceptanceEnum,
        NotAcceptable: 'NOT_ACCEPTABLE' as InheritedRiskAcceptanceEnum
    };
    export type ResidualRiskAcceptanceEnum = 'ACCEPTABLE' | 'NOT_ACCEPTABLE';
    export const ResidualRiskAcceptanceEnum = {
        Acceptable: 'ACCEPTABLE' as ResidualRiskAcceptanceEnum,
        NotAcceptable: 'NOT_ACCEPTABLE' as ResidualRiskAcceptanceEnum
    };
}


