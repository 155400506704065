import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Subject } from 'rxjs';
import { StaticValuesCodes } from './modules/users/services/data/static-values-data.service';

@Injectable()
export class ConfigService {
  tenantTitle: string;
  footerSlogan: string;
  tenantDescription: string;
  logoImgLight: string = environment.configuration + '/static-values/static/value/' + StaticValuesCodes.ICON_LIGHT;
  logoImgDark: string = environment.configuration + '/static-values/static/value/' + StaticValuesCodes.ICON_DARK;
  appNameImgLight: string = environment.configuration + '/static-values/static/value/' + StaticValuesCodes.LOGO_LIGHT;
  appNameImgDark: string = environment.configuration + '/static-values/static/value/' + StaticValuesCodes.LOGO_DARK;

  config: AppConfig = {
    theme: '',
    dark: true,
    inputStyle: '',
    ripple: true,
  };

  private configUpdate = new Subject<AppConfig>();

  configUpdate$ = this.configUpdate.asObservable();
  constructor() {
    this.config.dark = this.safeGetFromLocalStorage('theme_colorScheme', 'dark') == 'dark';
  }
  updateConfig(config: AppConfig) {
    this.config = config;
    this.configUpdate.next(config);
  }

  getConfig() {
    return this.config;
  }
  safeGetFromLocalStorage(key: string, defaultValue?: any) {
    return localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : defaultValue;
  }
}
export interface AppConfig {
  inputStyle?: string;
  dark?: boolean;
  theme?: string;
  ripple?: boolean;
}
