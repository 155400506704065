export const fab = [
  { value: 'fab fa-monero', label: 'monero' },
  { value: 'fab fa-hooli', label: 'hooli' },
  { value: 'fab fa-yelp', label: 'yelp' },
  { value: 'fab fa-cc-visa', label: 'cc-visa' },
  { value: 'fab fa-lastfm', label: 'lastfm' },
  { value: 'fab fa-shopware', label: 'shopware' },
  { value: 'fab fa-creative-commons-nc', label: 'creative-commons-nc' },
  { value: 'fab fa-aws', label: 'aws' },
  { value: 'fab fa-redhat', label: 'redhat' },
  { value: 'fab fa-yoast', label: 'yoast' },
  { value: 'fab fa-cloudflare', label: 'cloudflare' },
  { value: 'fab fa-ups', label: 'ups' },
  { value: 'fab fa-wpexplorer', label: 'wpexplorer' },
  { value: 'fab fa-dyalog', label: 'dyalog' },
  { value: 'fab fa-bity', label: 'bity' },
  { value: 'fab fa-stackpath', label: 'stackpath' },
  { value: 'fab fa-buysellads', label: 'buysellads' },
  { value: 'fab fa-first-order', label: 'first-order' },
  { value: 'fab fa-modx', label: 'modx' },
  { value: 'fab fa-guilded', label: 'guilded' },
  { value: 'fab fa-vnv', label: 'vnv' },
  { value: 'fab fa-square-js', label: 'square-js' },
  { value: 'fab fa-microsoft', label: 'microsoft' },
  { value: 'fab fa-qq', label: 'qq' },
  { value: 'fab fa-orcid', label: 'orcid' },
  { value: 'fab fa-java', label: 'java' },
  { value: 'fab fa-invision', label: 'invision' },
  { value: 'fab fa-creative-commons-pd-alt', label: 'creative-commons-pd-alt' },
  { value: 'fab fa-centercode', label: 'centercode' },
  { value: 'fab fa-glide-g', label: 'glide-g' },
  { value: 'fab fa-drupal', label: 'drupal' },
  { value: 'fab fa-hire-a-helper', label: 'hire-a-helper' },
  { value: 'fab fa-creative-commons-by', label: 'creative-commons-by' },
  { value: 'fab fa-unity', label: 'unity' },
  { value: 'fab fa-whmcs', label: 'whmcs' },
  { value: 'fab fa-rocketchat', label: 'rocketchat' },
  { value: 'fab fa-vk', label: 'vk' },
  { value: 'fab fa-untappd', label: 'untappd' },
  { value: 'fab fa-mailchimp', label: 'mailchimp' },
  { value: 'fab fa-css3-alt', label: 'css3-alt' },
  { value: 'fab fa-square-reddit', label: 'square-reddit' },
  { value: 'fab fa-vimeo-v', label: 'vimeo-v' },
  { value: 'fab fa-contao', label: 'contao' },
  { value: 'fab fa-square-font-awesome', label: 'square-font-awesome' },
  { value: 'fab fa-deskpro', label: 'deskpro' },
  { value: 'fab fa-sistrix', label: 'sistrix' },
  { value: 'fab fa-square-instagram', label: 'square-instagram' },
  { value: 'fab fa-battle-net', label: 'battle-net' },
  { value: 'fab fa-the-red-yeti', label: 'the-red-yeti' },
  { value: 'fab fa-square-hacker-news', label: 'square-hacker-news' },
  { value: 'fab fa-edge', label: 'edge' },
  { value: 'fab fa-napster', label: 'napster' },
  { value: 'fab fa-square-snapchat', label: 'square-snapchat' },
  { value: 'fab fa-google-plus-g', label: 'google-plus-g' },
  { value: 'fab fa-artstation', label: 'artstation' },
  { value: 'fab fa-markdown', label: 'markdown' },
  { value: 'fab fa-sourcetree', label: 'sourcetree' },
  { value: 'fab fa-google-plus', label: 'google-plus' },
  { value: 'fab fa-diaspora', label: 'diaspora' },
  { value: 'fab fa-foursquare', label: 'foursquare' },
  { value: 'fab fa-stack-overflow', label: 'stack-overflow' },
  { value: 'fab fa-github-alt', label: 'github-alt' },
  { value: 'fab fa-phoenix-squadron', label: 'phoenix-squadron' },
  { value: 'fab fa-pagelines', label: 'pagelines' },
  { value: 'fab fa-algolia', label: 'algolia' },
  { value: 'fab fa-red-river', label: 'red-river' },
  { value: 'fab fa-creative-commons-sa', label: 'creative-commons-sa' },
  { value: 'fab fa-safari', label: 'safari' },
  { value: 'fab fa-google', label: 'google' },
  { value: 'fab fa-square-font-awesome-stroke', label: 'square-font-awesome-stroke' },
  { value: 'fab fa-atlassian', label: 'atlassian' },
  { value: 'fab fa-linkedin-in', label: 'linkedin-in' },
  { value: 'fab fa-digital-ocean', label: 'digital-ocean' },
  { value: 'fab fa-nimblr', label: 'nimblr' },
  { value: 'fab fa-chromecast', label: 'chromecast' },
  { value: 'fab fa-evernote', label: 'evernote' },
  { value: 'fab fa-hacker-news', label: 'hacker-news' },
  { value: 'fab fa-creative-commons-sampling', label: 'creative-commons-sampling' },
  { value: 'fab fa-adversal', label: 'adversal' },
  { value: 'fab fa-creative-commons', label: 'creative-commons' },
  { value: 'fab fa-watchman-monitoring', label: 'watchman-monitoring' },
  { value: 'fab fa-fonticons', label: 'fonticons' },
  { value: 'fab fa-weixin', label: 'weixin' },
  { value: 'fab fa-shirtsinbulk', label: 'shirtsinbulk' },
  { value: 'fab fa-codepen', label: 'codepen' },
  { value: 'fab fa-git-alt', label: 'git-alt' },
  { value: 'fab fa-lyft', label: 'lyft' },
  { value: 'fab fa-rev', label: 'rev' },
  { value: 'fab fa-windows', label: 'windows' },
  { value: 'fab fa-wizards-of-the-coast', label: 'wizards-of-the-coast' },
  { value: 'fab fa-square-viadeo', label: 'square-viadeo' },
  { value: 'fab fa-meetup', label: 'meetup' },
  { value: 'fab fa-centos', label: 'centos' },
  { value: 'fab fa-adn', label: 'adn' },
  { value: 'fab fa-cloudsmith', label: 'cloudsmith' },
  { value: 'fab fa-pied-piper-alt', label: 'pied-piper-alt' },
  { value: 'fab fa-square-dribbble', label: 'square-dribbble' },
  { value: 'fab fa-codiepie', label: 'codiepie' },
  { value: 'fab fa-node', label: 'node' },
  { value: 'fab fa-mix', label: 'mix' },
  { value: 'fab fa-steam', label: 'steam' },
  { value: 'fab fa-cc-apple-pay', label: 'cc-apple-pay' },
  { value: 'fab fa-scribd', label: 'scribd' },
  { value: 'fab fa-openid', label: 'openid' },
  { value: 'fab fa-instalod', label: 'instalod' },
  { value: 'fab fa-expeditedssl', label: 'expeditedssl' },
  { value: 'fab fa-sellcast', label: 'sellcast' },
  { value: 'fab fa-square-twitter', label: 'square-twitter' },
  { value: 'fab fa-r-project', label: 'r-project' },
  { value: 'fab fa-delicious', label: 'delicious' },
  { value: 'fab fa-freebsd', label: 'freebsd' },
  { value: 'fab fa-vuejs', label: 'vuejs' },
  { value: 'fab fa-accusoft', label: 'accusoft' },
  { value: 'fab fa-ioxhost', label: 'ioxhost' },
  { value: 'fab fa-fonticons-fi', label: 'fonticons-fi' },
  { value: 'fab fa-app-store', label: 'app-store' },
  { value: 'fab fa-cc-mastercard', label: 'cc-mastercard' },
  { value: 'fab fa-itunes-note', label: 'itunes-note' },
  { value: 'fab fa-golang', label: 'golang' },
  { value: 'fab fa-kickstarter', label: 'kickstarter' },
  { value: 'fab fa-grav', label: 'grav' },
  { value: 'fab fa-weibo', label: 'weibo' },
  { value: 'fab fa-uncharted', label: 'uncharted' },
  { value: 'fab fa-firstdraft', label: 'firstdraft' },
  { value: 'fab fa-square-youtube', label: 'square-youtube' },
  { value: 'fab fa-wikipedia-w', label: 'wikipedia-w' },
  { value: 'fab fa-wpressr', label: 'wpressr' },
  { value: 'fab fa-angellist', label: 'angellist' },
  { value: 'fab fa-galactic-republic', label: 'galactic-republic' },
  { value: 'fab fa-nfc-directional', label: 'nfc-directional' },
  { value: 'fab fa-skype', label: 'skype' },
  { value: 'fab fa-joget', label: 'joget' },
  { value: 'fab fa-fedora', label: 'fedora' },
  { value: 'fab fa-stripe-s', label: 'stripe-s' },
  { value: 'fab fa-meta', label: 'meta' },
  { value: 'fab fa-laravel', label: 'laravel' },
  { value: 'fab fa-hotjar', label: 'hotjar' },
  { value: 'fab fa-bluetooth-b', label: 'bluetooth-b' },
  { value: 'fab fa-sticker-mule', label: 'sticker-mule' },
  { value: 'fab fa-creative-commons-zero', label: 'creative-commons-zero' },
  { value: 'fab fa-hips', label: 'hips' },
  { value: 'fab fa-behance', label: 'behance' },
  { value: 'fab fa-reddit', label: 'reddit' },
  { value: 'fab fa-discord', label: 'discord' },
  { value: 'fab fa-chrome', label: 'chrome' },
  { value: 'fab fa-app-store-ios', label: 'app-store-ios' },
  { value: 'fab fa-cc-discover', label: 'cc-discover' },
  { value: 'fab fa-wpbeginner', label: 'wpbeginner' },
  { value: 'fab fa-confluence', label: 'confluence' },
  { value: 'fab fa-mdb', label: 'mdb' },
  { value: 'fab fa-dochub', label: 'dochub' },
  { value: 'fab fa-accessible-icon', label: 'accessible-icon' },
  { value: 'fab fa-ebay', label: 'ebay' },
  { value: 'fab fa-amazon', label: 'amazon' },
  { value: 'fab fa-unsplash', label: 'unsplash' },
  { value: 'fab fa-yarn', label: 'yarn' },
  { value: 'fab fa-square-steam', label: 'square-steam' },
  { value: 'fab fa-500px', label: '500px' },
  { value: 'fab fa-square-vimeo', label: 'square-vimeo' },
  { value: 'fab fa-asymmetrik', label: 'asymmetrik' },
  { value: 'fab fa-font-awesome', label: 'font-awesome' },
  { value: 'fab fa-gratipay', label: 'gratipay' },
  { value: 'fab fa-apple', label: 'apple' },
  { value: 'fab fa-hive', label: 'hive' },
  { value: 'fab fa-gitkraken', label: 'gitkraken' },
  { value: 'fab fa-keybase', label: 'keybase' },
  { value: 'fab fa-apple-pay', label: 'apple-pay' },
  { value: 'fab fa-padlet', label: 'padlet' },
  { value: 'fab fa-amazon-pay', label: 'amazon-pay' },
  { value: 'fab fa-square-github', label: 'square-github' },
  { value: 'fab fa-stumbleupon', label: 'stumbleupon' },
  { value: 'fab fa-fedex', label: 'fedex' },
  { value: 'fab fa-phoenix-framework', label: 'phoenix-framework' },
  { value: 'fab fa-shopify', label: 'shopify' },
  { value: 'fab fa-neos', label: 'neos' },
  { value: 'fab fa-hackerrank', label: 'hackerrank' },
  { value: 'fab fa-researchgate', label: 'researchgate' },
  { value: 'fab fa-swift', label: 'swift' },
  { value: 'fab fa-angular', label: 'angular' },
  { value: 'fab fa-speakap', label: 'speakap' },
  { value: 'fab fa-angrycreative', label: 'angrycreative' },
  { value: 'fab fa-y-combinator', label: 'y-combinator' },
  { value: 'fab fa-empire', label: 'empire' },
  { value: 'fab fa-envira', label: 'envira' },
  { value: 'fab fa-square-gitlab', label: 'square-gitlab' },
  { value: 'fab fa-studiovinari', label: 'studiovinari' },
  { value: 'fab fa-pied-piper', label: 'pied-piper' },
  { value: 'fab fa-wordpress', label: 'wordpress' },
  { value: 'fab fa-product-hunt', label: 'product-hunt' },
  { value: 'fab fa-firefox', label: 'firefox' },
  { value: 'fab fa-linode', label: 'linode' },
  { value: 'fab fa-goodreads', label: 'goodreads' },
  { value: 'fab fa-square-odnoklassniki', label: 'square-odnoklassniki' },
  { value: 'fab fa-jsfiddle', label: 'jsfiddle' },
  { value: 'fab fa-sith', label: 'sith' },
  { value: 'fab fa-themeisle', label: 'themeisle' },
  { value: 'fab fa-page4', label: 'page4' },
  { value: 'fab fa-hashnode', label: 'hashnode' },
  { value: 'fab fa-react', label: 'react' },
  { value: 'fab fa-cc-paypal', label: 'cc-paypal' },
  { value: 'fab fa-squarespace', label: 'squarespace' },
  { value: 'fab fa-cc-stripe', label: 'cc-stripe' },
  { value: 'fab fa-creative-commons-share', label: 'creative-commons-share' },
  { value: 'fab fa-bitcoin', label: 'bitcoin' },
  { value: 'fab fa-keycdn', label: 'keycdn' },
  { value: 'fab fa-opera', label: 'opera' },
  { value: 'fab fa-itch-io', label: 'itch-io' },
  { value: 'fab fa-umbraco', label: 'umbraco' },
  { value: 'fab fa-galactic-senate', label: 'galactic-senate' },
  { value: 'fab fa-ubuntu', label: 'ubuntu' },
  { value: 'fab fa-draft2digital', label: 'draft2digital' },
  { value: 'fab fa-stripe', label: 'stripe' },
  { value: 'fab fa-houzz', label: 'houzz' },
  { value: 'fab fa-gg', label: 'gg' },
  { value: 'fab fa-dhl', label: 'dhl' },
  { value: 'fab fa-square-pinterest', label: 'square-pinterest' },
  { value: 'fab fa-xing', label: 'xing' },
  { value: 'fab fa-blackberry', label: 'blackberry' },
  { value: 'fab fa-creative-commons-pd', label: 'creative-commons-pd' },
  { value: 'fab fa-playstation', label: 'playstation' },
  { value: 'fab fa-quinscape', label: 'quinscape' },
  { value: 'fab fa-less', label: 'less' },
  { value: 'fab fa-blogger-b', label: 'blogger-b' },
  { value: 'fab fa-opencart', label: 'opencart' },
  { value: 'fab fa-vine', label: 'vine' },
  { value: 'fab fa-paypal', label: 'paypal' },
  { value: 'fab fa-gitlab', label: 'gitlab' },
  { value: 'fab fa-typo3', label: 'typo3' },
  { value: 'fab fa-reddit-alien', label: 'reddit-alien' },
  { value: 'fab fa-yahoo', label: 'yahoo' },
  { value: 'fab fa-dailymotion', label: 'dailymotion' },
  { value: 'fab fa-affiliatetheme', label: 'affiliatetheme' },
  { value: 'fab fa-pied-piper-pp', label: 'pied-piper-pp' },
  { value: 'fab fa-bootstrap', label: 'bootstrap' },
  { value: 'fab fa-odnoklassniki', label: 'odnoklassniki' },
  { value: 'fab fa-nfc-symbol', label: 'nfc-symbol' },
  { value: 'fab fa-ethereum', label: 'ethereum' },
  { value: 'fab fa-speaker-deck', label: 'speaker-deck' },
  { value: 'fab fa-creative-commons-nc-eu', label: 'creative-commons-nc-eu' },
  { value: 'fab fa-patreon', label: 'patreon' },
  { value: 'fab fa-avianex', label: 'avianex' },
  { value: 'fab fa-ello', label: 'ello' },
  { value: 'fab fa-gofore', label: 'gofore' },
  { value: 'fab fa-bimobject', label: 'bimobject' },
  { value: 'fab fa-facebook-f', label: 'facebook-f' },
  { value: 'fab fa-square-google-plus', label: 'square-google-plus' },
  { value: 'fab fa-mandalorian', label: 'mandalorian' },
  { value: 'fab fa-first-order-alt', label: 'first-order-alt' },
  { value: 'fab fa-osi', label: 'osi' },
  { value: 'fab fa-google-wallet', label: 'google-wallet' },
  { value: 'fab fa-d-and-d-beyond', label: 'd-and-d-beyond' },
  { value: 'fab fa-periscope', label: 'periscope' },
  { value: 'fab fa-fulcrum', label: 'fulcrum' },
  { value: 'fab fa-cloudscale', label: 'cloudscale' },
  { value: 'fab fa-forumbee', label: 'forumbee' },
  { value: 'fab fa-mizuni', label: 'mizuni' },
  { value: 'fab fa-schlix', label: 'schlix' },
  { value: 'fab fa-square-xing', label: 'square-xing' },
  { value: 'fab fa-bandcamp', label: 'bandcamp' },
  { value: 'fab fa-wpforms', label: 'wpforms' },
  { value: 'fab fa-cloudversify', label: 'cloudversify' },
  { value: 'fab fa-usps', label: 'usps' },
  { value: 'fab fa-megaport', label: 'megaport' },
  { value: 'fab fa-magento', label: 'magento' },
  { value: 'fab fa-spotify', label: 'spotify' },
  { value: 'fab fa-optin-monster', label: 'optin-monster' },
  { value: 'fab fa-fly', label: 'fly' },
  { value: 'fab fa-aviato', label: 'aviato' },
  { value: 'fab fa-itunes', label: 'itunes' },
  { value: 'fab fa-cuttlefish', label: 'cuttlefish' },
  { value: 'fab fa-blogger', label: 'blogger' },
  { value: 'fab fa-flickr', label: 'flickr' },
  { value: 'fab fa-viber', label: 'viber' },
  { value: 'fab fa-soundcloud', label: 'soundcloud' },
  { value: 'fab fa-digg', label: 'digg' },
  { value: 'fab fa-tencent-weibo', label: 'tencent-weibo' },
  { value: 'fab fa-symfony', label: 'symfony' },
  { value: 'fab fa-maxcdn', label: 'maxcdn' },
  { value: 'fab fa-etsy', label: 'etsy' },
  { value: 'fab fa-facebook-messenger', label: 'facebook-messenger' },
  { value: 'fab fa-audible', label: 'audible' },
  { value: 'fab fa-think-peaks', label: 'think-peaks' },
  { value: 'fab fa-bilibili', label: 'bilibili' },
  { value: 'fab fa-erlang', label: 'erlang' },
  { value: 'fab fa-cotton-bureau', label: 'cotton-bureau' },
  { value: 'fab fa-dashcube', label: 'dashcube' },
  { value: 'fab fa-42-group', label: '42-group' },
  { value: 'fab fa-stack-exchange', label: 'stack-exchange' },
  { value: 'fab fa-elementor', label: 'elementor' },
  { value: 'fab fa-square-pied-piper', label: 'square-pied-piper' },
  { value: 'fab fa-creative-commons-nd', label: 'creative-commons-nd' },
  { value: 'fab fa-palfed', label: 'palfed' },
  { value: 'fab fa-superpowers', label: 'superpowers' },
  { value: 'fab fa-resolving', label: 'resolving' },
  { value: 'fab fa-xbox', label: 'xbox' },
  { value: 'fab fa-searchengin', label: 'searchengin' },
  { value: 'fab fa-tiktok', label: 'tiktok' },
  { value: 'fab fa-square-facebook', label: 'square-facebook' },
  { value: 'fab fa-renren', label: 'renren' },
  { value: 'fab fa-linux', label: 'linux' },
  { value: 'fab fa-glide', label: 'glide' },
  { value: 'fab fa-linkedin', label: 'linkedin' },
  { value: 'fab fa-hubspot', label: 'hubspot' },
  { value: 'fab fa-deploydog', label: 'deploydog' },
  { value: 'fab fa-twitch', label: 'twitch' },
  { value: 'fab fa-ravelry', label: 'ravelry' },
  { value: 'fab fa-mixer', label: 'mixer' },
  { value: 'fab fa-square-lastfm', label: 'square-lastfm' },
  { value: 'fab fa-vimeo', label: 'vimeo' },
  { value: 'fab fa-mendeley', label: 'mendeley' },
  { value: 'fab fa-uniregistry', label: 'uniregistry' },
  { value: 'fab fa-figma', label: 'figma' },
  { value: 'fab fa-creative-commons-remix', label: 'creative-commons-remix' },
  { value: 'fab fa-cc-amazon-pay', label: 'cc-amazon-pay' },
  { value: 'fab fa-dropbox', label: 'dropbox' },
  { value: 'fab fa-instagram', label: 'instagram' },
  { value: 'fab fa-cmplid', label: 'cmplid' },
  { value: 'fab fa-facebook', label: 'facebook' },
  { value: 'fab fa-gripfire', label: 'gripfire' },
  { value: 'fab fa-jedi-order', label: 'jedi-order' },
  { value: 'fab fa-uikit', label: 'uikit' },
  { value: 'fab fa-fort-awesome-alt', label: 'fort-awesome-alt' },
  { value: 'fab fa-phabricator', label: 'phabricator' },
  { value: 'fab fa-ussunnah', label: 'ussunnah' },
  { value: 'fab fa-earlybirds', label: 'earlybirds' },
  { value: 'fab fa-trade-federation', label: 'trade-federation' },
  { value: 'fab fa-autoprefixer', label: 'autoprefixer' },
  { value: 'fab fa-whatsapp', label: 'whatsapp' },
  { value: 'fab fa-slideshare', label: 'slideshare' },
  { value: 'fab fa-google-play', label: 'google-play' },
  { value: 'fab fa-viadeo', label: 'viadeo' },
  { value: 'fab fa-line', label: 'line' },
  { value: 'fab fa-google-drive', label: 'google-drive' },
  { value: 'fab fa-servicestack', label: 'servicestack' },
  { value: 'fab fa-simplybuilt', label: 'simplybuilt' },
  { value: 'fab fa-bitbucket', label: 'bitbucket' },
  { value: 'fab fa-imdb', label: 'imdb' },
  { value: 'fab fa-deezer', label: 'deezer' },
  { value: 'fab fa-raspberry-pi', label: 'raspberry-pi' },
  { value: 'fab fa-jira', label: 'jira' },
  { value: 'fab fa-docker', label: 'docker' },
  { value: 'fab fa-screenpal', label: 'screenpal' },
  { value: 'fab fa-bluetooth', label: 'bluetooth' },
  { value: 'fab fa-gitter', label: 'gitter' },
  { value: 'fab fa-d-and-d', label: 'd-and-d' },
  { value: 'fab fa-microblog', label: 'microblog' },
  { value: 'fab fa-cc-diners-club', label: 'cc-diners-club' },
  { value: 'fab fa-gg-circle', label: 'gg-circle' },
  { value: 'fab fa-pied-piper-hat', label: 'pied-piper-hat' },
  { value: 'fab fa-kickstarter-k', label: 'kickstarter-k' },
  { value: 'fab fa-yandex', label: 'yandex' },
  { value: 'fab fa-readme', label: 'readme' },
  { value: 'fab fa-html5', label: 'html5' },
  { value: 'fab fa-sellsy', label: 'sellsy' },
  { value: 'fab fa-sass', label: 'sass' },
  { value: 'fab fa-wirsindhandwerk', label: 'wirsindhandwerk' },
  { value: 'fab fa-buromobelexperte', label: 'buromobelexperte' },
  { value: 'fab fa-salesforce', label: 'salesforce' },
  { value: 'fab fa-octopus-deploy', label: 'octopus-deploy' },
  { value: 'fab fa-medapps', label: 'medapps' },
  { value: 'fab fa-ns8', label: 'ns8' },
  { value: 'fab fa-pinterest-p', label: 'pinterest-p' },
  { value: 'fab fa-apper', label: 'apper' },
  { value: 'fab fa-fort-awesome', label: 'fort-awesome' },
  { value: 'fab fa-waze', label: 'waze' },
  { value: 'fab fa-cc-jcb', label: 'cc-jcb' },
  { value: 'fab fa-snapchat', label: 'snapchat' },
  { value: 'fab fa-fantasy-flight-games', label: 'fantasy-flight-games' },
  { value: 'fab fa-rust', label: 'rust' },
  { value: 'fab fa-wix', label: 'wix' },
  { value: 'fab fa-square-behance', label: 'square-behance' },
  { value: 'fab fa-supple', label: 'supple' },
  { value: 'fab fa-rebel', label: 'rebel' },
  { value: 'fab fa-css3', label: 'css3' },
  { value: 'fab fa-staylinked', label: 'staylinked' },
  { value: 'fab fa-kaggle', label: 'kaggle' },
  { value: 'fab fa-space-awesome', label: 'space-awesome' },
  { value: 'fab fa-deviantart', label: 'deviantart' },
  { value: 'fab fa-cpanel', label: 'cpanel' },
  { value: 'fab fa-goodreads-g', label: 'goodreads-g' },
  { value: 'fab fa-square-git', label: 'square-git' },
  { value: 'fab fa-square-tumblr', label: 'square-tumblr' },
  { value: 'fab fa-trello', label: 'trello' },
  { value: 'fab fa-creative-commons-nc-jp', label: 'creative-commons-nc-jp' },
  { value: 'fab fa-get-pocket', label: 'get-pocket' },
  { value: 'fab fa-perbyte', label: 'perbyte' },
  { value: 'fab fa-grunt', label: 'grunt' },
  { value: 'fab fa-weebly', label: 'weebly' },
  { value: 'fab fa-connectdevelop', label: 'connectdevelop' },
  { value: 'fab fa-leanpub', label: 'leanpub' },
  { value: 'fab fa-black-tie', label: 'black-tie' },
  { value: 'fab fa-themeco', label: 'themeco' },
  { value: 'fab fa-python', label: 'python' },
  { value: 'fab fa-android', label: 'android' },
  { value: 'fab fa-bots', label: 'bots' },
  { value: 'fab fa-free-code-camp', label: 'free-code-camp' },
  { value: 'fab fa-hornbill', label: 'hornbill' },
  { value: 'fab fa-js', label: 'js' },
  { value: 'fab fa-ideal', label: 'ideal' },
  { value: 'fab fa-git', label: 'git' },
  { value: 'fab fa-dev', label: 'dev' },
  { value: 'fab fa-sketch', label: 'sketch' },
  { value: 'fab fa-yandex-international', label: 'yandex-international' },
  { value: 'fab fa-cc-amex', label: 'cc-amex' },
  { value: 'fab fa-uber', label: 'uber' },
  { value: 'fab fa-github', label: 'github' },
  { value: 'fab fa-php', label: 'php' },
  { value: 'fab fa-alipay', label: 'alipay' },
  { value: 'fab fa-youtube', label: 'youtube' },
  { value: 'fab fa-skyatlas', label: 'skyatlas' },
  { value: 'fab fa-firefox-browser', label: 'firefox-browser' },
  { value: 'fab fa-replyd', label: 'replyd' },
  { value: 'fab fa-suse', label: 'suse' },
  { value: 'fab fa-jenkins', label: 'jenkins' },
  { value: 'fab fa-twitter', label: 'twitter' },
  { value: 'fab fa-rockrms', label: 'rockrms' },
  { value: 'fab fa-pinterest', label: 'pinterest' },
  { value: 'fab fa-buffer', label: 'buffer' },
  { value: 'fab fa-npm', label: 'npm' },
  { value: 'fab fa-yammer', label: 'yammer' },
  { value: 'fab fa-btc', label: 'btc' },
  { value: 'fab fa-dribbble', label: 'dribbble' },
  { value: 'fab fa-stumbleupon-circle', label: 'stumbleupon-circle' },
  { value: 'fab fa-internet-explorer', label: 'internet-explorer' },
  { value: 'fab fa-telegram', label: 'telegram' },
  { value: 'fab fa-old-republic', label: 'old-republic' },
  { value: 'fab fa-square-whatsapp', label: 'square-whatsapp' },
  { value: 'fab fa-node-js', label: 'node-js' },
  { value: 'fab fa-edge-legacy', label: 'edge-legacy' },
  { value: 'fab fa-slack', label: 'slack' },
  { value: 'fab fa-medrt', label: 'medrt' },
  { value: 'fab fa-usb', label: 'usb' },
  { value: 'fab fa-tumblr', label: 'tumblr' },
  { value: 'fab fa-vaadin', label: 'vaadin' },
  { value: 'fab fa-quora', label: 'quora' },
  { value: 'fab fa-reacteurope', label: 'reacteurope' },
  { value: 'fab fa-medium', label: 'medium' },
  { value: 'fab fa-amilia', label: 'amilia' },
  { value: 'fab fa-mixcloud', label: 'mixcloud' },
  { value: 'fab fa-flipboard', label: 'flipboard' },
  { value: 'fab fa-viacoin', label: 'viacoin' },
  { value: 'fab fa-critical-role', label: 'critical-role' },
  { value: 'fab fa-sitrox', label: 'sitrox' },
  { value: 'fab fa-discourse', label: 'discourse' },
  { value: 'fab fa-joomla', label: 'joomla' },
  { value: 'fab fa-mastodon', label: 'mastodon' },
  { value: 'fab fa-airbnb', label: 'airbnb' },
  { value: 'fab fa-wolf-pack-battalion', label: 'wolf-pack-battalion' },
  { value: 'fab fa-buy-n-large', label: 'buy-n-large' },
  { value: 'fab fa-gulp', label: 'gulp' },
  { value: 'fab fa-creative-commons-sampling-plus', label: 'creative-commons-sampling-plus' },
  { value: 'fab fa-strava', label: 'strava' },
  { value: 'fab fa-ember', label: 'ember' },
  { value: 'fab fa-canadian-maple-leaf', label: 'canadian-maple-leaf' },
  { value: 'fab fa-teamspeak', label: 'teamspeak' },
  { value: 'fab fa-pushed', label: 'pushed' },
  { value: 'fab fa-wordpress-simple', label: 'wordpress-simple' },
  { value: 'fab fa-nutritionix', label: 'nutritionix' },
  { value: 'fab fa-wodu', label: 'wodu' },
  { value: 'fab fa-google-pay', label: 'google-pay' },
  { value: 'fab fa-intercom', label: 'intercom' },
  { value: 'fab fa-zhihu', label: 'zhihu' },
  { value: 'fab fa-korvue', label: 'korvue' },
  { value: 'fab fa-pix', label: 'pix' },
  { value: 'fab fa-steam-symbol', label: 'steam-symbol' },
];
export const fas = [
  { value: 'fas fa-0', label: '0' },
  { value: 'fas fa-1', label: '1' },
  { value: 'fas fa-2', label: '2' },
  { value: 'fas fa-3', label: '3' },
  { value: 'fas fa-4', label: '4' },
  { value: 'fas fa-5', label: '5' },
  { value: 'fas fa-6', label: '6' },
  { value: 'fas fa-7', label: '7' },
  { value: 'fas fa-8', label: '8' },
  { value: 'fas fa-9', label: '9' },
  { value: 'fas fa-a', label: 'a' },
  { value: 'fas fa-rectangle-ad', label: 'rectangle-ad' },
  { value: 'fas fa-plus', label: 'plus' },
  { value: 'fas fa-address-book', label: 'address-book' },
  { value: 'fas fa-address-card', label: 'address-card' },
  { value: 'fas fa-circle-half-stroke', label: 'circle-half-stroke' },
  { value: 'fas fa-spray-can-sparkles', label: 'spray-can-sparkles' },
  { value: 'fas fa-align-center', label: 'align-center' },
  { value: 'fas fa-align-justify', label: 'align-justify' },
  { value: 'fas fa-align-left', label: 'align-left' },
  { value: 'fas fa-align-right', label: 'align-right' },
  { value: 'fas fa-hand-dots', label: 'hand-dots' },
  { value: 'fas fa-truck-medical', label: 'truck-medical' },
  { value: 'fas fa-hands-asl-interpreting', label: 'hands-asl-interpreting' },
  { value: 'fas fa-anchor', label: 'anchor' },
  { value: 'fas fa-anchor-circle-check', label: 'anchor-circle-check' },
  { value: 'fas fa-anchor-circle-exclamation', label: 'anchor-circle-exclamation' },
  { value: 'fas fa-anchor-circle-xmark', label: 'anchor-circle-xmark' },
  { value: 'fas fa-anchor-lock', label: 'anchor-lock' },
  { value: 'fas fa-angles-down', label: 'angles-down' },
  { value: 'fas fa-angles-left', label: 'angles-left' },
  { value: 'fas fa-angles-right', label: 'angles-right' },
  { value: 'fas fa-angles-up', label: 'angles-up' },
  { value: 'fas fa-angle-down', label: 'angle-down' },
  { value: 'fas fa-angle-left', label: 'angle-left' },
  { value: 'fas fa-angle-right', label: 'angle-right' },
  { value: 'fas fa-angle-up', label: 'angle-up' },
  { value: 'fas fa-face-angry', label: 'face-angry' },
  { value: 'fas fa-ankh', label: 'ankh' },
  { value: 'fas fa-apple-whole', label: 'apple-whole' },
  { value: 'fas fa-box-archive', label: 'box-archive' },
  { value: 'fas fa-archway', label: 'archway' },
  { value: 'fas fa-chart-area', label: 'chart-area' },
  { value: 'fas fa-circle-down', label: 'circle-down' },
  { value: 'fas fa-circle-left', label: 'circle-left' },
  { value: 'fas fa-circle-right', label: 'circle-right' },
  { value: 'fas fa-circle-up', label: 'circle-up' },
  { value: 'fas fa-circle-arrow-down', label: 'circle-arrow-down' },
  { value: 'fas fa-circle-arrow-left', label: 'circle-arrow-left' },
  { value: 'fas fa-circle-arrow-right', label: 'circle-arrow-right' },
  { value: 'fas fa-circle-arrow-up', label: 'circle-arrow-up' },
  { value: 'fas fa-arrow-down', label: 'arrow-down' },
  { value: 'fas fa-arrow-down-1-9', label: 'arrow-down-1-9' },
  { value: 'fas fa-arrow-down-9-1', label: 'arrow-down-9-1' },
  { value: 'fas fa-arrow-down-a-z', label: 'arrow-down-a-z' },
  { value: 'fas fa-arrow-down-long', label: 'arrow-down-long' },
  { value: 'fas fa-arrow-down-short-wide', label: 'arrow-down-short-wide' },
  { value: 'fas fa-arrow-down-up-across-line', label: 'arrow-down-up-across-line' },
  { value: 'fas fa-arrow-down-up-lock', label: 'arrow-down-up-lock' },
  { value: 'fas fa-arrow-down-wide-short', label: 'arrow-down-wide-short' },
  { value: 'fas fa-arrow-down-z-a', label: 'arrow-down-z-a' },
  { value: 'fas fa-arrow-left', label: 'arrow-left' },
  { value: 'fas fa-arrow-left-long', label: 'arrow-left-long' },
  { value: 'fas fa-arrow-rotate-left', label: 'arrow-rotate-left' },
  { value: 'fas fa-arrow-pointer', label: 'arrow-pointer' },
  { value: 'fas fa-arrow-right', label: 'arrow-right' },
  { value: 'fas fa-arrow-right-arrow-left', label: 'arrow-right-arrow-left' },
  { value: 'fas fa-arrow-right-from-bracket', label: 'arrow-right-from-bracket' },
  { value: 'fas fa-file-export', label: 'file-export' },
  { value: 'fas fa-arrow-right-long', label: 'arrow-right-long' },
  { value: 'fas fa-arrow-rotate-right', label: 'arrow-rotate-right' },
  { value: 'fas fa-arrow-right-to-bracket', label: 'arrow-right-to-bracket' },
  { value: 'fas fa-arrow-right-to-city', label: 'arrow-right-to-city' },
  { value: 'fas fa-file-import', label: 'file-import' },
  { value: 'fas fa-arrow-trend-down', label: 'arrow-trend-down' },
  { value: 'fas fa-arrow-trend-up', label: 'arrow-trend-up' },
  { value: 'fas fa-arrow-turn-down', label: 'arrow-turn-down' },
  { value: 'fas fa-share', label: 'share' },
  { value: 'fas fa-arrow-turn-up', label: 'arrow-turn-up' },
  { value: 'fas fa-arrow-up', label: 'arrow-up' },
  { value: 'fas fa-arrow-up-1-9', label: 'arrow-up-1-9' },
  { value: 'fas fa-arrow-up-9-1', label: 'arrow-up-9-1' },
  { value: 'fas fa-arrow-up-a-z', label: 'arrow-up-a-z' },
  { value: 'fas fa-arrow-up-from-bracket', label: 'arrow-up-from-bracket' },
  { value: 'fas fa-arrow-up-from-ground-water', label: 'arrow-up-from-ground-water' },
  { value: 'fas fa-arrow-up-from-water-pump', label: 'arrow-up-from-water-pump' },
  { value: 'fas fa-arrow-up-long', label: 'arrow-up-long' },
  { value: 'fas fa-arrow-up-right-dots', label: 'arrow-up-right-dots' },
  { value: 'fas fa-arrow-up-right-from-square', label: 'arrow-up-right-from-square' },
  { value: 'fas fa-arrow-up-short-wide', label: 'arrow-up-short-wide' },
  { value: 'fas fa-arrow-up-wide-short', label: 'arrow-up-wide-short' },
  { value: 'fas fa-arrow-up-z-a', label: 'arrow-up-z-a' },
  { value: 'fas fa-arrows-up-down-left-right', label: 'arrows-up-down-left-right' },
  { value: 'fas fa-up-down-left-right', label: 'up-down-left-right' },
  { value: 'fas fa-left-right', label: 'left-right' },
  { value: 'fas fa-up-down', label: 'up-down' },
  { value: 'fas fa-arrows-down-to-line', label: 'arrows-down-to-line' },
  { value: 'fas fa-arrows-down-to-people', label: 'arrows-down-to-people' },
  { value: 'fas fa-arrows-left-right', label: 'arrows-left-right' },
  { value: 'fas fa-arrows-left-right-to-line', label: 'arrows-left-right-to-line' },
  { value: 'fas fa-arrows-rotate', label: 'arrows-rotate' },
  { value: 'fas fa-arrows-spin', label: 'arrows-spin' },
  { value: 'fas fa-arrows-split-up-and-left', label: 'arrows-split-up-and-left' },
  { value: 'fas fa-arrows-to-circle', label: 'arrows-to-circle' },
  { value: 'fas fa-arrows-to-dot', label: 'arrows-to-dot' },
  { value: 'fas fa-arrows-to-eye', label: 'arrows-to-eye' },
  { value: 'fas fa-arrows-turn-right', label: 'arrows-turn-right' },
  { value: 'fas fa-arrows-turn-to-dots', label: 'arrows-turn-to-dots' },
  { value: 'fas fa-arrows-up-down', label: 'arrows-up-down' },
  { value: 'fas fa-arrows-up-to-line', label: 'arrows-up-to-line' },
  { value: 'fas fa-ear-listen', label: 'ear-listen' },
  { value: 'fas fa-asterisk', label: 'asterisk' },
  { value: 'fas fa-at', label: 'at' },
  { value: 'fas fa-book-atlas', label: 'book-atlas' },
  { value: 'fas fa-atom', label: 'atom' },
  { value: 'fas fa-audio-description', label: 'audio-description' },
  { value: 'fas fa-austral-sign', label: 'austral-sign' },
  { value: 'fas fa-car', label: 'car' },
  { value: 'fas fa-award', label: 'award' },
  { value: 'fas fa-b', label: 'b' },
  { value: 'fas fa-baby', label: 'baby' },
  { value: 'fas fa-baby-carriage', label: 'baby-carriage' },
  { value: 'fas fa-delete-left', label: 'delete-left' },
  { value: 'fas fa-backward', label: 'backward' },
  { value: 'fas fa-backward-fast', label: 'backward-fast' },
  { value: 'fas fa-backward-step', label: 'backward-step' },
  { value: 'fas fa-bacon', label: 'bacon' },
  { value: 'fas fa-bacteria', label: 'bacteria' },
  { value: 'fas fa-bacterium', label: 'bacterium' },
  { value: 'fas fa-bag-shopping', label: 'bag-shopping' },
  { value: 'fas fa-bahai', label: 'bahai' },
  { value: 'fas fa-baht-sign', label: 'baht-sign' },
  { value: 'fas fa-scale-balanced', label: 'scale-balanced' },
  { value: 'fas fa-scale-unbalanced', label: 'scale-unbalanced' },
  { value: 'fas fa-scale-unbalanced-flip', label: 'scale-unbalanced-flip' },
  { value: 'fas fa-ban', label: 'ban' },
  { value: 'fas fa-ban-smoking', label: 'ban-smoking' },
  { value: 'fas fa-bandage', label: 'bandage' },
  { value: 'fas fa-bangladeshi-taka-sign', label: 'bangladeshi-taka-sign' },
  { value: 'fas fa-building-columns', label: 'building-columns' },
  { value: 'fas fa-chart-bar', label: 'chart-bar' },
  { value: 'fas fa-barcode', label: 'barcode' },
  { value: 'fas fa-bars', label: 'bars' },
  { value: 'fas fa-bars-progress', label: 'bars-progress' },
  { value: 'fas fa-bars-staggered', label: 'bars-staggered' },
  { value: 'fas fa-baseball', label: 'baseball' },
  { value: 'fas fa-baseball-bat-ball', label: 'baseball-bat-ball' },
  { value: 'fas fa-basket-shopping', label: 'basket-shopping' },
  { value: 'fas fa-basketball', label: 'basketball' },
  { value: 'fas fa-bath', label: 'bath' },
  { value: 'fas fa-battery-full', label: 'battery-full' },
  { value: 'fas fa-battery-empty', label: 'battery-empty' },
  { value: 'fas fa-battery-quarter', label: 'battery-quarter' },
  { value: 'fas fa-battery-half', label: 'battery-half' },
  { value: 'fas fa-battery-three-quarters', label: 'battery-three-quarters' },
  { value: 'fas fa-car-battery', label: 'car-battery' },
  { value: 'fas fa-bed', label: 'bed' },
  { value: 'fas fa-bed-pulse', label: 'bed-pulse' },
  { value: 'fas fa-beer-mug-empty', label: 'beer-mug-empty' },
  { value: 'fas fa-bell', label: 'bell' },
  { value: 'fas fa-bell-concierge', label: 'bell-concierge' },
  { value: 'fas fa-bell-slash', label: 'bell-slash' },
  { value: 'fas fa-bezier-curve', label: 'bezier-curve' },
  { value: 'fas fa-book-bible', label: 'book-bible' },
  { value: 'fas fa-bicycle', label: 'bicycle' },
  { value: 'fas fa-person-biking', label: 'person-biking' },
  { value: 'fas fa-binoculars', label: 'binoculars' },
  { value: 'fas fa-biohazard', label: 'biohazard' },
  { value: 'fas fa-cake-candles', label: 'cake-candles' },
  { value: 'fas fa-bitcoin-sign', label: 'bitcoin-sign' },
  { value: 'fas fa-chalkboard', label: 'chalkboard' },
  { value: 'fas fa-blender', label: 'blender' },
  { value: 'fas fa-blender-phone', label: 'blender-phone' },
  { value: 'fas fa-person-walking-with-cane', label: 'person-walking-with-cane' },
  { value: 'fas fa-blog', label: 'blog' },
  { value: 'fas fa-bold', label: 'bold' },
  { value: 'fas fa-bolt', label: 'bolt' },
  { value: 'fas fa-bolt-lightning', label: 'bolt-lightning' },
  { value: 'fas fa-bomb', label: 'bomb' },
  { value: 'fas fa-bone', label: 'bone' },
  { value: 'fas fa-bong', label: 'bong' },
  { value: 'fas fa-book', label: 'book' },
  { value: 'fas fa-book-bookmark', label: 'book-bookmark' },
  { value: 'fas fa-book-skull', label: 'book-skull' },
  { value: 'fas fa-book-journal-whills', label: 'book-journal-whills' },
  { value: 'fas fa-book-medical', label: 'book-medical' },
  { value: 'fas fa-book-open', label: 'book-open' },
  { value: 'fas fa-book-open-reader', label: 'book-open-reader' },
  { value: 'fas fa-book-quran', label: 'book-quran' },
  { value: 'fas fa-book-tanakh', label: 'book-tanakh' },
  { value: 'fas fa-bookmark', label: 'bookmark' },
  { value: 'fas fa-border-all', label: 'border-all' },
  { value: 'fas fa-border-none', label: 'border-none' },
  { value: 'fas fa-border-top-left', label: 'border-top-left' },
  { value: 'fas fa-bore-hole', label: 'bore-hole' },
  { value: 'fas fa-bottle-droplet', label: 'bottle-droplet' },
  { value: 'fas fa-bottle-water', label: 'bottle-water' },
  { value: 'fas fa-bowl-food', label: 'bowl-food' },
  { value: 'fas fa-bowl-rice', label: 'bowl-rice' },
  { value: 'fas fa-bowling-ball', label: 'bowling-ball' },
  { value: 'fas fa-box', label: 'box' },
  { value: 'fas fa-box-open', label: 'box-open' },
  { value: 'fas fa-box-tissue', label: 'box-tissue' },
  { value: 'fas fa-boxes-stacked', label: 'boxes-stacked' },
  { value: 'fas fa-boxes-packing', label: 'boxes-packing' },
  { value: 'fas fa-braille', label: 'braille' },
  { value: 'fas fa-brain', label: 'brain' },
  { value: 'fas fa-brazilian-real-sign', label: 'brazilian-real-sign' },
  { value: 'fas fa-bread-slice', label: 'bread-slice' },
  { value: 'fas fa-bridge', label: 'bridge' },
  { value: 'fas fa-bridge-circle-check', label: 'bridge-circle-check' },
  { value: 'fas fa-bridge-circle-exclamation', label: 'bridge-circle-exclamation' },
  { value: 'fas fa-bridge-circle-xmark', label: 'bridge-circle-xmark' },
  { value: 'fas fa-bridge-lock', label: 'bridge-lock' },
  { value: 'fas fa-bridge-water', label: 'bridge-water' },
  { value: 'fas fa-briefcase', label: 'briefcase' },
  { value: 'fas fa-business-time', label: 'business-time' },
  { value: 'fas fa-briefcase-medical', label: 'briefcase-medical' },
  { value: 'fas fa-tower-broadcast', label: 'tower-broadcast' },
  { value: 'fas fa-broom', label: 'broom' },
  { value: 'fas fa-broom-ball', label: 'broom-ball' },
  { value: 'fas fa-brush', label: 'brush' },
  { value: 'fas fa-bucket', label: 'bucket' },
  { value: 'fas fa-bug', label: 'bug' },
  { value: 'fas fa-bug-slash', label: 'bug-slash' },
  { value: 'fas fa-bugs', label: 'bugs' },
  { value: 'fas fa-building', label: 'building' },
  { value: 'fas fa-building-circle-arrow-right', label: 'building-circle-arrow-right' },
  { value: 'fas fa-building-circle-check', label: 'building-circle-check' },
  { value: 'fas fa-building-circle-exclamation', label: 'building-circle-exclamation' },
  { value: 'fas fa-building-circle-xmark', label: 'building-circle-xmark' },
  { value: 'fas fa-building-flag', label: 'building-flag' },
  { value: 'fas fa-building-lock', label: 'building-lock' },
  { value: 'fas fa-building-ngo', label: 'building-ngo' },
  { value: 'fas fa-building-shield', label: 'building-shield' },
  { value: 'fas fa-building-un', label: 'building-un' },
  { value: 'fas fa-building-user', label: 'building-user' },
  { value: 'fas fa-building-wheat', label: 'building-wheat' },
  { value: 'fas fa-bullhorn', label: 'bullhorn' },
  { value: 'fas fa-bullseye', label: 'bullseye' },
  { value: 'fas fa-burger', label: 'burger' },
  { value: 'fas fa-fire-flame-simple', label: 'fire-flame-simple' },
  { value: 'fas fa-burst', label: 'burst' },
  { value: 'fas fa-bus', label: 'bus' },
  { value: 'fas fa-bus-simple', label: 'bus-simple' },
  { value: 'fas fa-c', label: 'c' },
  { value: 'fas fa-taxi', label: 'taxi' },
  { value: 'fas fa-cable-car', label: 'cable-car' },
  { value: 'fas fa-calculator', label: 'calculator' },
  { value: 'fas fa-calendar', label: 'calendar' },
  { value: 'fas fa-calendar-days', label: 'calendar-days' },
  { value: 'fas fa-calendar-check', label: 'calendar-check' },
  { value: 'fas fa-calendar-day', label: 'calendar-day' },
  { value: 'fas fa-calendar-minus', label: 'calendar-minus' },
  { value: 'fas fa-calendar-plus', label: 'calendar-plus' },
  { value: 'fas fa-calendar-xmark', label: 'calendar-xmark' },
  { value: 'fas fa-calendar-week', label: 'calendar-week' },
  { value: 'fas fa-camera', label: 'camera' },
  { value: 'fas fa-camera-retro', label: 'camera-retro' },
  { value: 'fas fa-camera-rotate', label: 'camera-rotate' },
  { value: 'fas fa-campground', label: 'campground' },
  { value: 'fas fa-candy-cane', label: 'candy-cane' },
  { value: 'fas fa-cannabis', label: 'cannabis' },
  { value: 'fas fa-capsules', label: 'capsules' },
  { value: 'fas fa-car-rear', label: 'car-rear' },
  { value: 'fas fa-car-burst', label: 'car-burst' },
  { value: 'fas fa-car-on', label: 'car-on' },
  { value: 'fas fa-car-side', label: 'car-side' },
  { value: 'fas fa-car-tunnel', label: 'car-tunnel' },
  { value: 'fas fa-caravan', label: 'caravan' },
  { value: 'fas fa-caret-down', label: 'caret-down' },
  { value: 'fas fa-caret-left', label: 'caret-left' },
  { value: 'fas fa-caret-right', label: 'caret-right' },
  { value: 'fas fa-square-caret-down', label: 'square-caret-down' },
  { value: 'fas fa-square-caret-left', label: 'square-caret-left' },
  { value: 'fas fa-square-caret-right', label: 'square-caret-right' },
  { value: 'fas fa-square-caret-up', label: 'square-caret-up' },
  { value: 'fas fa-caret-up', label: 'caret-up' },
  { value: 'fas fa-carrot', label: 'carrot' },
  { value: 'fas fa-cart-arrow-down', label: 'cart-arrow-down' },
  { value: 'fas fa-cart-flatbed', label: 'cart-flatbed' },
  { value: 'fas fa-cart-flatbed-suitcase', label: 'cart-flatbed-suitcase' },
  { value: 'fas fa-cart-plus', label: 'cart-plus' },
  { value: 'fas fa-cart-shopping', label: 'cart-shopping' },
  { value: 'fas fa-cash-register', label: 'cash-register' },
  { value: 'fas fa-cat', label: 'cat' },
  { value: 'fas fa-cedi-sign', label: 'cedi-sign' },
  { value: 'fas fa-cent-sign', label: 'cent-sign' },
  { value: 'fas fa-certificate', label: 'certificate' },
  { value: 'fas fa-link', label: 'link' },
  { value: 'fas fa-link-slash', label: 'link-slash' },
  { value: 'fas fa-chair', label: 'chair' },
  { value: 'fas fa-chalkboard-user', label: 'chalkboard-user' },
  { value: 'fas fa-champagne-glasses', label: 'champagne-glasses' },
  { value: 'fas fa-charging-station', label: 'charging-station' },
  { value: 'fas fa-chart-column', label: 'chart-column' },
  { value: 'fas fa-chart-gantt', label: 'chart-gantt' },
  { value: 'fas fa-chart-line', label: 'chart-line' },
  { value: 'fas fa-chart-pie', label: 'chart-pie' },
  { value: 'fas fa-chart-simple', label: 'chart-simple' },
  { value: 'fas fa-check', label: 'check' },
  { value: 'fas fa-circle-check', label: 'circle-check' },
  { value: 'fas fa-check-double', label: 'check-double' },
  { value: 'fas fa-square-check', label: 'square-check' },
  { value: 'fas fa-check-to-slot', label: 'check-to-slot' },
  { value: 'fas fa-cheese', label: 'cheese' },
  { value: 'fas fa-chess', label: 'chess' },
  { value: 'fas fa-chess-bishop', label: 'chess-bishop' },
  { value: 'fas fa-chess-board', label: 'chess-board' },
  { value: 'fas fa-chess-king', label: 'chess-king' },
  { value: 'fas fa-chess-knight', label: 'chess-knight' },
  { value: 'fas fa-chess-pawn', label: 'chess-pawn' },
  { value: 'fas fa-chess-queen', label: 'chess-queen' },
  { value: 'fas fa-chess-rook', label: 'chess-rook' },
  { value: 'fas fa-circle-chevron-down', label: 'circle-chevron-down' },
  { value: 'fas fa-circle-chevron-left', label: 'circle-chevron-left' },
  { value: 'fas fa-circle-chevron-right', label: 'circle-chevron-right' },
  { value: 'fas fa-circle-chevron-up', label: 'circle-chevron-up' },
  { value: 'fas fa-chevron-down', label: 'chevron-down' },
  { value: 'fas fa-chevron-left', label: 'chevron-left' },
  { value: 'fas fa-chevron-right', label: 'chevron-right' },
  { value: 'fas fa-chevron-up', label: 'chevron-up' },
  { value: 'fas fa-child', label: 'child' },
  { value: 'fas fa-child-combatant', label: 'child-combatant' },
  { value: 'fas fa-child-dress', label: 'child-dress' },
  { value: 'fas fa-child-reaching', label: 'child-reaching' },
  { value: 'fas fa-children', label: 'children' },
  { value: 'fas fa-church', label: 'church' },
  { value: 'fas fa-circle', label: 'circle' },
  { value: 'fas fa-circle-dollar-to-slot', label: 'circle-dollar-to-slot' },
  { value: 'fas fa-circle-dot', label: 'circle-dot' },
  { value: 'fas fa-circle-exclamation', label: 'circle-exclamation' },
  { value: 'fas fa-circle-h', label: 'circle-h' },
  { value: 'fas fa-circle-info', label: 'circle-info' },
  { value: 'fas fa-circle-minus', label: 'circle-minus' },
  { value: 'fas fa-circle-nodes', label: 'circle-nodes' },
  { value: 'fas fa-circle-notch', label: 'circle-notch' },
  { value: 'fas fa-circle-pause', label: 'circle-pause' },
  { value: 'fas fa-circle-play', label: 'circle-play' },
  { value: 'fas fa-circle-plus', label: 'circle-plus' },
  { value: 'fas fa-circle-question', label: 'circle-question' },
  { value: 'fas fa-circle-radiation', label: 'circle-radiation' },
  { value: 'fas fa-circle-stop', label: 'circle-stop' },
  { value: 'fas fa-circle-user', label: 'circle-user' },
  { value: 'fas fa-circle-xmark', label: 'circle-xmark' },
  { value: 'fas fa-city', label: 'city' },
  { value: 'fas fa-clapperboard', label: 'clapperboard' },
  { value: 'fas fa-house-chimney-medical', label: 'house-chimney-medical' },
  { value: 'fas fa-clipboard', label: 'clipboard' },
  { value: 'fas fa-clipboard-check', label: 'clipboard-check' },
  { value: 'fas fa-clipboard-list', label: 'clipboard-list' },
  { value: 'fas fa-clipboard-question', label: 'clipboard-question' },
  { value: 'fas fa-clipboard-user', label: 'clipboard-user' },
  { value: 'fas fa-clock', label: 'clock' },
  { value: 'fas fa-clock-rotate-left', label: 'clock-rotate-left' },
  { value: 'fas fa-clone', label: 'clone' },
  { value: 'fas fa-xmark', label: 'xmark' },
  { value: 'fas fa-closed-captioning', label: 'closed-captioning' },
  { value: 'fas fa-cloud', label: 'cloud' },
  { value: 'fas fa-cloud-arrow-down', label: 'cloud-arrow-down' },
  { value: 'fas fa-cloud-arrow-up', label: 'cloud-arrow-up' },
  { value: 'fas fa-cloud-bolt', label: 'cloud-bolt' },
  { value: 'fas fa-cloud-meatball', label: 'cloud-meatball' },
  { value: 'fas fa-cloud-moon', label: 'cloud-moon' },
  { value: 'fas fa-cloud-moon-rain', label: 'cloud-moon-rain' },
  { value: 'fas fa-cloud-rain', label: 'cloud-rain' },
  { value: 'fas fa-cloud-showers-heavy', label: 'cloud-showers-heavy' },
  { value: 'fas fa-cloud-showers-water', label: 'cloud-showers-water' },
  { value: 'fas fa-cloud-sun', label: 'cloud-sun' },
  { value: 'fas fa-cloud-sun-rain', label: 'cloud-sun-rain' },
  { value: 'fas fa-clover', label: 'clover' },
  { value: 'fas fa-yen-sign', label: 'yen-sign' },
  { value: 'fas fa-martini-glass-citrus', label: 'martini-glass-citrus' },
  { value: 'fas fa-code', label: 'code' },
  { value: 'fas fa-code-branch', label: 'code-branch' },
  { value: 'fas fa-code-commit', label: 'code-commit' },
  { value: 'fas fa-code-compare', label: 'code-compare' },
  { value: 'fas fa-code-fork', label: 'code-fork' },
  { value: 'fas fa-code-merge', label: 'code-merge' },
  { value: 'fas fa-code-pull-request', label: 'code-pull-request' },
  { value: 'fas fa-mug-saucer', label: 'mug-saucer' },
  { value: 'fas fa-gear', label: 'gear' },
  { value: 'fas fa-gears', label: 'gears' },
  { value: 'fas fa-coins', label: 'coins' },
  { value: 'fas fa-colon-sign', label: 'colon-sign' },
  { value: 'fas fa-table-columns', label: 'table-columns' },
  { value: 'fas fa-comment', label: 'comment' },
  { value: 'fas fa-message', label: 'message' },
  { value: 'fas fa-comment-dollar', label: 'comment-dollar' },
  { value: 'fas fa-comment-dots', label: 'comment-dots' },
  { value: 'fas fa-comment-medical', label: 'comment-medical' },
  { value: 'fas fa-comment-slash', label: 'comment-slash' },
  { value: 'fas fa-comment-sms', label: 'comment-sms' },
  { value: 'fas fa-comments', label: 'comments' },
  { value: 'fas fa-comments-dollar', label: 'comments-dollar' },
  { value: 'fas fa-compact-disc', label: 'compact-disc' },
  { value: 'fas fa-compass', label: 'compass' },
  { value: 'fas fa-compass-drafting', label: 'compass-drafting' },
  { value: 'fas fa-compress', label: 'compress' },
  { value: 'fas fa-down-left-and-up-right-to-center', label: 'down-left-and-up-right-to-center' },
  { value: 'fas fa-minimize', label: 'minimize' },
  { value: 'fas fa-computer', label: 'computer' },
  { value: 'fas fa-computer-mouse', label: 'computer-mouse' },
  { value: 'fas fa-cookie', label: 'cookie' },
  { value: 'fas fa-cookie-bite', label: 'cookie-bite' },
  { value: 'fas fa-copy', label: 'copy' },
  { value: 'fas fa-copyright', label: 'copyright' },
  { value: 'fas fa-couch', label: 'couch' },
  { value: 'fas fa-cow', label: 'cow' },
  { value: 'fas fa-credit-card', label: 'credit-card' },
  { value: 'fas fa-crop', label: 'crop' },
  { value: 'fas fa-crop-simple', label: 'crop-simple' },
  { value: 'fas fa-cross', label: 'cross' },
  { value: 'fas fa-crosshairs', label: 'crosshairs' },
  { value: 'fas fa-crow', label: 'crow' },
  { value: 'fas fa-crown', label: 'crown' },
  { value: 'fas fa-crutch', label: 'crutch' },
  { value: 'fas fa-cruzeiro-sign', label: 'cruzeiro-sign' },
  { value: 'fas fa-cube', label: 'cube' },
  { value: 'fas fa-cubes', label: 'cubes' },
  { value: 'fas fa-cubes-stacked', label: 'cubes-stacked' },
  { value: 'fas fa-scissors', label: 'scissors' },
  { value: 'fas fa-utensils', label: 'utensils' },
  { value: 'fas fa-d', label: 'd' },
  { value: 'fas fa-gauge', label: 'gauge' },
  { value: 'fas fa-database', label: 'database' },
  { value: 'fas fa-ear-deaf', label: 'ear-deaf' },
  { value: 'fas fa-outdent', label: 'outdent' },
  { value: 'fas fa-democrat', label: 'democrat' },
  { value: 'fas fa-desktop', label: 'desktop' },
  { value: 'fas fa-dharmachakra', label: 'dharmachakra' },
  { value: 'fas fa-person-dots-from-line', label: 'person-dots-from-line' },
  { value: 'fas fa-diagram-next', label: 'diagram-next' },
  { value: 'fas fa-diagram-predecessor', label: 'diagram-predecessor' },
  { value: 'fas fa-diagram-project', label: 'diagram-project' },
  { value: 'fas fa-diagram-successor', label: 'diagram-successor' },
  { value: 'fas fa-diamond', label: 'diamond' },
  { value: 'fas fa-diamond-turn-right', label: 'diamond-turn-right' },
  { value: 'fas fa-dice', label: 'dice' },
  { value: 'fas fa-dice-d20', label: 'dice-d20' },
  { value: 'fas fa-dice-d6', label: 'dice-d6' },
  { value: 'fas fa-dice-five', label: 'dice-five' },
  { value: 'fas fa-dice-four', label: 'dice-four' },
  { value: 'fas fa-dice-one', label: 'dice-one' },
  { value: 'fas fa-dice-six', label: 'dice-six' },
  { value: 'fas fa-dice-three', label: 'dice-three' },
  { value: 'fas fa-dice-two', label: 'dice-two' },
  { value: 'fas fa-person-digging', label: 'person-digging' },
  { value: 'fas fa-tachograph-digital', label: 'tachograph-digital' },
  { value: 'fas fa-disease', label: 'disease' },
  { value: 'fas fa-display', label: 'display' },
  { value: 'fas fa-divide', label: 'divide' },
  { value: 'fas fa-face-dizzy', label: 'face-dizzy' },
  { value: 'fas fa-dna', label: 'dna' },
  { value: 'fas fa-dog', label: 'dog' },
  { value: 'fas fa-dollar-sign', label: 'dollar-sign' },
  { value: 'fas fa-dolly', label: 'dolly' },
  { value: 'fas fa-dong-sign', label: 'dong-sign' },
  { value: 'fas fa-door-closed', label: 'door-closed' },
  { value: 'fas fa-door-open', label: 'door-open' },
  { value: 'fas fa-dove', label: 'dove' },
  { value: 'fas fa-down-long', label: 'down-long' },
  { value: 'fas fa-download', label: 'download' },
  { value: 'fas fa-dragon', label: 'dragon' },
  { value: 'fas fa-draw-polygon', label: 'draw-polygon' },
  { value: 'fas fa-id-card', label: 'id-card' },
  { value: 'fas fa-droplet', label: 'droplet' },
  { value: 'fas fa-droplet-slash', label: 'droplet-slash' },
  { value: 'fas fa-drum', label: 'drum' },
  { value: 'fas fa-drum-steelpan', label: 'drum-steelpan' },
  { value: 'fas fa-drumstick-bite', label: 'drumstick-bite' },
  { value: 'fas fa-dumbbell', label: 'dumbbell' },
  { value: 'fas fa-dumpster', label: 'dumpster' },
  { value: 'fas fa-dumpster-fire', label: 'dumpster-fire' },
  { value: 'fas fa-dungeon', label: 'dungeon' },
  { value: 'fas fa-e', label: 'e' },
  { value: 'fas fa-earth-americas', label: 'earth-americas' },
  { value: 'fas fa-earth-africa', label: 'earth-africa' },
  { value: 'fas fa-earth-asia', label: 'earth-asia' },
  { value: 'fas fa-earth-europe', label: 'earth-europe' },
  { value: 'fas fa-earth-oceania', label: 'earth-oceania' },
  { value: 'fas fa-pen-to-square', label: 'pen-to-square' },
  { value: 'fas fa-egg', label: 'egg' },
  { value: 'fas fa-eject', label: 'eject' },
  { value: 'fas fa-elevator', label: 'elevator' },
  { value: 'fas fa-ellipsis', label: 'ellipsis' },
  { value: 'fas fa-ellipsis-vertical', label: 'ellipsis-vertical' },
  { value: 'fas fa-envelope', label: 'envelope' },
  { value: 'fas fa-envelope-circle-check', label: 'envelope-circle-check' },
  { value: 'fas fa-envelope-open', label: 'envelope-open' },
  { value: 'fas fa-envelope-open-text', label: 'envelope-open-text' },
  { value: 'fas fa-square-envelope', label: 'square-envelope' },
  { value: 'fas fa-envelopes-bulk', label: 'envelopes-bulk' },
  { value: 'fas fa-equals', label: 'equals' },
  { value: 'fas fa-eraser', label: 'eraser' },
  { value: 'fas fa-ethernet', label: 'ethernet' },
  { value: 'fas fa-euro-sign', label: 'euro-sign' },
  { value: 'fas fa-right-left', label: 'right-left' },
  { value: 'fas fa-exclamation', label: 'exclamation' },
  { value: 'fas fa-triangle-exclamation', label: 'triangle-exclamation' },
  { value: 'fas fa-expand', label: 'expand' },
  { value: 'fas fa-up-right-and-down-left-from-center', label: 'up-right-and-down-left-from-center' },
  { value: 'fas fa-maximize', label: 'maximize' },
  { value: 'fas fa-explosion', label: 'explosion' },
  { value: 'fas fa-up-right-from-square', label: 'up-right-from-square' },
  { value: 'fas fa-square-arrow-up-right', label: 'square-arrow-up-right' },
  { value: 'fas fa-square-up-right', label: 'square-up-right' },
  { value: 'fas fa-eye', label: 'eye' },
  { value: 'fas fa-eye-dropper', label: 'eye-dropper' },
  { value: 'fas fa-eye-low-vision', label: 'eye-low-vision' },
  { value: 'fas fa-eye-slash', label: 'eye-slash' },
  { value: 'fas fa-f', label: 'f' },
  { value: 'fas fa-face-flushed', label: 'face-flushed' },
  { value: 'fas fa-face-frown', label: 'face-frown' },
  { value: 'fas fa-face-frown-open', label: 'face-frown-open' },
  { value: 'fas fa-face-grimace', label: 'face-grimace' },
  { value: 'fas fa-face-grin', label: 'face-grin' },
  { value: 'fas fa-face-grin-beam', label: 'face-grin-beam' },
  { value: 'fas fa-face-grin-beam-sweat', label: 'face-grin-beam-sweat' },
  { value: 'fas fa-face-grin-hearts', label: 'face-grin-hearts' },
  { value: 'fas fa-face-grin-squint', label: 'face-grin-squint' },
  { value: 'fas fa-face-grin-squint-tears', label: 'face-grin-squint-tears' },
  { value: 'fas fa-face-grin-stars', label: 'face-grin-stars' },
  { value: 'fas fa-face-grin-tears', label: 'face-grin-tears' },
  { value: 'fas fa-face-grin-tongue', label: 'face-grin-tongue' },
  { value: 'fas fa-face-grin-tongue-squint', label: 'face-grin-tongue-squint' },
  { value: 'fas fa-face-grin-tongue-wink', label: 'face-grin-tongue-wink' },
  { value: 'fas fa-face-grin-wide', label: 'face-grin-wide' },
  { value: 'fas fa-face-grin-wink', label: 'face-grin-wink' },
  { value: 'fas fa-face-kiss', label: 'face-kiss' },
  { value: 'fas fa-face-kiss-beam', label: 'face-kiss-beam' },
  { value: 'fas fa-face-kiss-wink-heart', label: 'face-kiss-wink-heart' },
  { value: 'fas fa-face-laugh', label: 'face-laugh' },
  { value: 'fas fa-face-laugh-beam', label: 'face-laugh-beam' },
  { value: 'fas fa-face-laugh-squint', label: 'face-laugh-squint' },
  { value: 'fas fa-face-laugh-wink', label: 'face-laugh-wink' },
  { value: 'fas fa-face-meh', label: 'face-meh' },
  { value: 'fas fa-face-meh-blank', label: 'face-meh-blank' },
  { value: 'fas fa-face-rolling-eyes', label: 'face-rolling-eyes' },
  { value: 'fas fa-face-sad-cry', label: 'face-sad-cry' },
  { value: 'fas fa-face-sad-tear', label: 'face-sad-tear' },
  { value: 'fas fa-face-smile', label: 'face-smile' },
  { value: 'fas fa-face-smile-beam', label: 'face-smile-beam' },
  { value: 'fas fa-face-smile-wink', label: 'face-smile-wink' },
  { value: 'fas fa-face-surprise', label: 'face-surprise' },
  { value: 'fas fa-face-tired', label: 'face-tired' },
  { value: 'fas fa-fan', label: 'fan' },
  { value: 'fas fa-forward-fast', label: 'forward-fast' },
  { value: 'fas fa-faucet', label: 'faucet' },
  { value: 'fas fa-faucet-drip', label: 'faucet-drip' },
  { value: 'fas fa-fax', label: 'fax' },
  { value: 'fas fa-feather', label: 'feather' },
  { value: 'fas fa-feather-pointed', label: 'feather-pointed' },
  { value: 'fas fa-rss', label: 'rss' },
  { value: 'fas fa-person-dress', label: 'person-dress' },
  { value: 'fas fa-ferry', label: 'ferry' },
  { value: 'fas fa-jet-fighter', label: 'jet-fighter' },
  { value: 'fas fa-file', label: 'file' },
  { value: 'fas fa-file-lines', label: 'file-lines' },
  { value: 'fas fa-file-zipper', label: 'file-zipper' },
  { value: 'fas fa-file-arrow-down', label: 'file-arrow-down' },
  { value: 'fas fa-file-arrow-up', label: 'file-arrow-up' },
  { value: 'fas fa-file-audio', label: 'file-audio' },
  { value: 'fas fa-file-circle-check', label: 'file-circle-check' },
  { value: 'fas fa-file-circle-exclamation', label: 'file-circle-exclamation' },
  { value: 'fas fa-file-circle-minus', label: 'file-circle-minus' },
  { value: 'fas fa-file-circle-plus', label: 'file-circle-plus' },
  { value: 'fas fa-file-circle-question', label: 'file-circle-question' },
  { value: 'fas fa-file-circle-xmark', label: 'file-circle-xmark' },
  { value: 'fas fa-paste', label: 'paste' },
  { value: 'fas fa-file-code', label: 'file-code' },
  { value: 'fas fa-file-contract', label: 'file-contract' },
  { value: 'fas fa-file-csv', label: 'file-csv' },
  { value: 'fas fa-file-pen', label: 'file-pen' },
  { value: 'fas fa-file-excel', label: 'file-excel' },
  { value: 'fas fa-file-image', label: 'file-image' },
  { value: 'fas fa-file-invoice', label: 'file-invoice' },
  { value: 'fas fa-file-invoice-dollar', label: 'file-invoice-dollar' },
  { value: 'fas fa-file-medical', label: 'file-medical' },
  { value: 'fas fa-file-waveform', label: 'file-waveform' },
  { value: 'fas fa-file-pdf', label: 'file-pdf' },
  { value: 'fas fa-file-powerpoint', label: 'file-powerpoint' },
  { value: 'fas fa-file-prescription', label: 'file-prescription' },
  { value: 'fas fa-file-shield', label: 'file-shield' },
  { value: 'fas fa-file-signature', label: 'file-signature' },
  { value: 'fas fa-file-video', label: 'file-video' },
  { value: 'fas fa-file-word', label: 'file-word' },
  { value: 'fas fa-fill', label: 'fill' },
  { value: 'fas fa-fill-drip', label: 'fill-drip' },
  { value: 'fas fa-film', label: 'film' },
  { value: 'fas fa-filter', label: 'filter' },
  { value: 'fas fa-filter-circle-dollar', label: 'filter-circle-dollar' },
  { value: 'fas fa-filter-circle-xmark', label: 'filter-circle-xmark' },
  { value: 'fas fa-fingerprint', label: 'fingerprint' },
  { value: 'fas fa-fire', label: 'fire' },
  { value: 'fas fa-fire-flame-curved', label: 'fire-flame-curved' },
  { value: 'fas fa-fire-burner', label: 'fire-burner' },
  { value: 'fas fa-fire-extinguisher', label: 'fire-extinguisher' },
  { value: 'fas fa-kit-medical', label: 'kit-medical' },
  { value: 'fas fa-fish', label: 'fish' },
  { value: 'fas fa-fish-fins', label: 'fish-fins' },
  { value: 'fas fa-hand-fist', label: 'hand-fist' },
  { value: 'fas fa-flag', label: 'flag' },
  { value: 'fas fa-flag-checkered', label: 'flag-checkered' },
  { value: 'fas fa-flag-usa', label: 'flag-usa' },
  { value: 'fas fa-flask', label: 'flask' },
  { value: 'fas fa-flask-vial', label: 'flask-vial' },
  { value: 'fas fa-floppy-disk', label: 'floppy-disk' },
  { value: 'fas fa-florin-sign', label: 'florin-sign' },
  { value: 'fas fa-folder', label: 'folder' },
  { value: 'fas fa-folder-closed', label: 'folder-closed' },
  { value: 'fas fa-folder-minus', label: 'folder-minus' },
  { value: 'fas fa-folder-open', label: 'folder-open' },
  { value: 'fas fa-folder-plus', label: 'folder-plus' },
  { value: 'fas fa-folder-tree', label: 'folder-tree' },
  { value: 'fas fa-font', label: 'font' },
  { value: 'fas fa-font-awesome', label: 'font-awesome' },
  { value: 'fas fa-football', label: 'football' },
  { value: 'fas fa-forward', label: 'forward' },
  { value: 'fas fa-forward-step', label: 'forward-step' },
  { value: 'fas fa-franc-sign', label: 'franc-sign' },
  { value: 'fas fa-frog', label: 'frog' },
  { value: 'fas fa-futbol', label: 'futbol' },
  { value: 'fas fa-g', label: 'g' },
  { value: 'fas fa-gamepad', label: 'gamepad' },
  { value: 'fas fa-gas-pump', label: 'gas-pump' },
  { value: 'fas fa-gauge-high', label: 'gauge-high' },
  { value: 'fas fa-gauge-simple', label: 'gauge-simple' },
  { value: 'fas fa-gauge-simple-high', label: 'gauge-simple-high' },
  { value: 'fas fa-gavel', label: 'gavel' },
  { value: 'fas fa-sterling-sign', label: 'sterling-sign' },
  { value: 'fas fa-gem', label: 'gem' },
  { value: 'fas fa-genderless', label: 'genderless' },
  { value: 'fas fa-ghost', label: 'ghost' },
  { value: 'fas fa-gift', label: 'gift' },
  { value: 'fas fa-gifts', label: 'gifts' },
  { value: 'fas fa-martini-glass-empty', label: 'martini-glass-empty' },
  { value: 'fas fa-martini-glass', label: 'martini-glass' },
  { value: 'fas fa-glass-water', label: 'glass-water' },
  { value: 'fas fa-glass-water-droplet', label: 'glass-water-droplet' },
  { value: 'fas fa-whiskey-glass', label: 'whiskey-glass' },
  { value: 'fas fa-glasses', label: 'glasses' },
  { value: 'fas fa-globe', label: 'globe' },
  { value: 'fas fa-golf-ball-tee', label: 'golf-ball-tee' },
  { value: 'fas fa-gopuram', label: 'gopuram' },
  { value: 'fas fa-graduation-cap', label: 'graduation-cap' },
  { value: 'fas fa-greater-than', label: 'greater-than' },
  { value: 'fas fa-greater-than-equal', label: 'greater-than-equal' },
  { value: 'fas fa-grip', label: 'grip' },
  { value: 'fas fa-grip-lines', label: 'grip-lines' },
  { value: 'fas fa-grip-lines-vertical', label: 'grip-lines-vertical' },
  { value: 'fas fa-grip-vertical', label: 'grip-vertical' },
  { value: 'fas fa-group-arrows-rotate', label: 'group-arrows-rotate' },
  { value: 'fas fa-guarani-sign', label: 'guarani-sign' },
  { value: 'fas fa-guitar', label: 'guitar' },
  { value: 'fas fa-gun', label: 'gun' },
  { value: 'fas fa-h', label: 'h' },
  { value: 'fas fa-square-h', label: 'square-h' },
  { value: 'fas fa-hammer', label: 'hammer' },
  { value: 'fas fa-hamsa', label: 'hamsa' },
  { value: 'fas fa-hand', label: 'hand' },
  { value: 'fas fa-hand-back-fist', label: 'hand-back-fist' },
  { value: 'fas fa-hand-holding', label: 'hand-holding' },
  { value: 'fas fa-hand-holding-dollar', label: 'hand-holding-dollar' },
  { value: 'fas fa-hand-holding-droplet', label: 'hand-holding-droplet' },
  { value: 'fas fa-hand-holding-hand', label: 'hand-holding-hand' },
  { value: 'fas fa-hand-holding-heart', label: 'hand-holding-heart' },
  { value: 'fas fa-hand-holding-medical', label: 'hand-holding-medical' },
  { value: 'fas fa-hand-lizard', label: 'hand-lizard' },
  { value: 'fas fa-hand-middle-finger', label: 'hand-middle-finger' },
  { value: 'fas fa-hand-peace', label: 'hand-peace' },
  { value: 'fas fa-hand-point-down', label: 'hand-point-down' },
  { value: 'fas fa-hand-point-left', label: 'hand-point-left' },
  { value: 'fas fa-hand-point-right', label: 'hand-point-right' },
  { value: 'fas fa-hand-point-up', label: 'hand-point-up' },
  { value: 'fas fa-hand-pointer', label: 'hand-pointer' },
  { value: 'fas fa-hand-scissors', label: 'hand-scissors' },
  { value: 'fas fa-hand-sparkles', label: 'hand-sparkles' },
  { value: 'fas fa-hand-spock', label: 'hand-spock' },
  { value: 'fas fa-handcuffs', label: 'handcuffs' },
  { value: 'fas fa-hands', label: 'hands' },
  { value: 'fas fa-hands-bound', label: 'hands-bound' },
  { value: 'fas fa-hands-bubbles', label: 'hands-bubbles' },
  { value: 'fas fa-hands-clapping', label: 'hands-clapping' },
  { value: 'fas fa-handshake-angle', label: 'handshake-angle' },
  { value: 'fas fa-hands-holding', label: 'hands-holding' },
  { value: 'fas fa-hands-holding-child', label: 'hands-holding-child' },
  { value: 'fas fa-hands-holding-circle', label: 'hands-holding-circle' },
  { value: 'fas fa-hands-praying', label: 'hands-praying' },
  { value: 'fas fa-handshake', label: 'handshake' },
  { value: 'fas fa-handshake-simple', label: 'handshake-simple' },
  { value: 'fas fa-handshake-simple-slash', label: 'handshake-simple-slash' },
  { value: 'fas fa-handshake-slash', label: 'handshake-slash' },
  { value: 'fas fa-hanukiah', label: 'hanukiah' },
  { value: 'fas fa-hard-drive', label: 'hard-drive' },
  { value: 'fas fa-helmet-safety', label: 'helmet-safety' },
  { value: 'fas fa-hashtag', label: 'hashtag' },
  { value: 'fas fa-hat-cowboy', label: 'hat-cowboy' },
  { value: 'fas fa-hat-cowboy-side', label: 'hat-cowboy-side' },
  { value: 'fas fa-hat-wizard', label: 'hat-wizard' },
  { value: 'fas fa-head-side-cough', label: 'head-side-cough' },
  { value: 'fas fa-head-side-cough-slash', label: 'head-side-cough-slash' },
  { value: 'fas fa-head-side-mask', label: 'head-side-mask' },
  { value: 'fas fa-head-side-virus', label: 'head-side-virus' },
  { value: 'fas fa-heading', label: 'heading' },
  { value: 'fas fa-headphones', label: 'headphones' },
  { value: 'fas fa-headphones-simple', label: 'headphones-simple' },
  { value: 'fas fa-headset', label: 'headset' },
  { value: 'fas fa-heart', label: 'heart' },
  { value: 'fas fa-heart-crack', label: 'heart-crack' },
  { value: 'fas fa-heart-circle-bolt', label: 'heart-circle-bolt' },
  { value: 'fas fa-heart-circle-check', label: 'heart-circle-check' },
  { value: 'fas fa-heart-circle-exclamation', label: 'heart-circle-exclamation' },
  { value: 'fas fa-heart-circle-minus', label: 'heart-circle-minus' },
  { value: 'fas fa-heart-circle-plus', label: 'heart-circle-plus' },
  { value: 'fas fa-heart-circle-xmark', label: 'heart-circle-xmark' },
  { value: 'fas fa-icons', label: 'icons' },
  { value: 'fas fa-heart-pulse', label: 'heart-pulse' },
  { value: 'fas fa-helicopter', label: 'helicopter' },
  { value: 'fas fa-helicopter-symbol', label: 'helicopter-symbol' },
  { value: 'fas fa-helmet-un', label: 'helmet-un' },
  { value: 'fas fa-highlighter', label: 'highlighter' },
  { value: 'fas fa-person-hiking', label: 'person-hiking' },
  { value: 'fas fa-hill-avalanche', label: 'hill-avalanche' },
  { value: 'fas fa-hill-rockslide', label: 'hill-rockslide' },
  { value: 'fas fa-hippo', label: 'hippo' },
  { value: 'fas fa-hockey-puck', label: 'hockey-puck' },
  { value: 'fas fa-holly-berry', label: 'holly-berry' },
  { value: 'fas fa-house', label: 'house' },
  { value: 'fas fa-house-chimney', label: 'house-chimney' },
  { value: 'fas fa-house-user', label: 'house-user' },
  { value: 'fas fa-horse', label: 'horse' },
  { value: 'fas fa-horse-head', label: 'horse-head' },
  { value: 'fas fa-hospital', label: 'hospital' },
  { value: 'fas fa-hospital-user', label: 'hospital-user' },
  { value: 'fas fa-hot-tub-person', label: 'hot-tub-person' },
  { value: 'fas fa-hotdog', label: 'hotdog' },
  { value: 'fas fa-hotel', label: 'hotel' },
  { value: 'fas fa-hourglass', label: 'hourglass' },
  { value: 'fas fa-hourglass-start', label: 'hourglass-start' },
  { value: 'fas fa-hourglass-half', label: 'hourglass-half' },
  { value: 'fas fa-hourglass-end', label: 'hourglass-end' },
  { value: 'fas fa-house-chimney-crack', label: 'house-chimney-crack' },
  { value: 'fas fa-house-chimney-user', label: 'house-chimney-user' },
  { value: 'fas fa-house-chimney-window', label: 'house-chimney-window' },
  { value: 'fas fa-house-circle-check', label: 'house-circle-check' },
  { value: 'fas fa-house-circle-exclamation', label: 'house-circle-exclamation' },
  { value: 'fas fa-house-circle-xmark', label: 'house-circle-xmark' },
  { value: 'fas fa-house-crack', label: 'house-crack' },
  { value: 'fas fa-house-fire', label: 'house-fire' },
  { value: 'fas fa-house-flag', label: 'house-flag' },
  { value: 'fas fa-house-flood-water', label: 'house-flood-water' },
  { value: 'fas fa-house-flood-water-circle-arrow-right', label: 'house-flood-water-circle-arrow-right' },
  { value: 'fas fa-house-laptop', label: 'house-laptop' },
  { value: 'fas fa-house-lock', label: 'house-lock' },
  { value: 'fas fa-house-medical', label: 'house-medical' },
  { value: 'fas fa-house-medical-circle-check', label: 'house-medical-circle-check' },
  { value: 'fas fa-house-medical-circle-exclamation', label: 'house-medical-circle-exclamation' },
  { value: 'fas fa-house-medical-circle-xmark', label: 'house-medical-circle-xmark' },
  { value: 'fas fa-house-medical-flag', label: 'house-medical-flag' },
  { value: 'fas fa-house-signal', label: 'house-signal' },
  { value: 'fas fa-house-tsunami', label: 'house-tsunami' },
  { value: 'fas fa-hryvnia-sign', label: 'hryvnia-sign' },
  { value: 'fas fa-hurricane', label: 'hurricane' },
  { value: 'fas fa-i', label: 'i' },
  { value: 'fas fa-i-cursor', label: 'i-cursor' },
  { value: 'fas fa-ice-cream', label: 'ice-cream' },
  { value: 'fas fa-icicles', label: 'icicles' },
  { value: 'fas fa-id-badge', label: 'id-badge' },
  { value: 'fas fa-id-card-clip', label: 'id-card-clip' },
  { value: 'fas fa-igloo', label: 'igloo' },
  { value: 'fas fa-shekel-sign', label: 'shekel-sign' },
  { value: 'fas fa-image', label: 'image' },
  { value: 'fas fa-image-portrait', label: 'image-portrait' },
  { value: 'fas fa-images', label: 'images' },
  { value: 'fas fa-inbox', label: 'inbox' },
  { value: 'fas fa-indent', label: 'indent' },
  { value: 'fas fa-indian-rupee-sign', label: 'indian-rupee-sign' },
  { value: 'fas fa-industry', label: 'industry' },
  { value: 'fas fa-infinity', label: 'infinity' },
  { value: 'fas fa-info', label: 'info' },
  { value: 'fas fa-italic', label: 'italic' },
  { value: 'fas fa-j', label: 'j' },
  { value: 'fas fa-jar', label: 'jar' },
  { value: 'fas fa-jar-wheat', label: 'jar-wheat' },
  { value: 'fas fa-jedi', label: 'jedi' },
  { value: 'fas fa-jet-fighter-up', label: 'jet-fighter-up' },
  { value: 'fas fa-joint', label: 'joint' },
  { value: 'fas fa-jug-detergent', label: 'jug-detergent' },
  { value: 'fas fa-k', label: 'k' },
  { value: 'fas fa-kaaba', label: 'kaaba' },
  { value: 'fas fa-key', label: 'key' },
  { value: 'fas fa-keyboard', label: 'keyboard' },
  { value: 'fas fa-khanda', label: 'khanda' },
  { value: 'fas fa-kip-sign', label: 'kip-sign' },
  { value: 'fas fa-kitchen-set', label: 'kitchen-set' },
  { value: 'fas fa-kiwi-bird', label: 'kiwi-bird' },
  { value: 'fas fa-won-sign', label: 'won-sign' },
  { value: 'fas fa-l', label: 'l' },
  { value: 'fas fa-water-ladder', label: 'water-ladder' },
  { value: 'fas fa-land-mine-on', label: 'land-mine-on' },
  { value: 'fas fa-landmark', label: 'landmark' },
  { value: 'fas fa-landmark-dome', label: 'landmark-dome' },
  { value: 'fas fa-landmark-flag', label: 'landmark-flag' },
  { value: 'fas fa-language', label: 'language' },
  { value: 'fas fa-laptop', label: 'laptop' },
  { value: 'fas fa-laptop-code', label: 'laptop-code' },
  { value: 'fas fa-laptop-file', label: 'laptop-file' },
  { value: 'fas fa-laptop-medical', label: 'laptop-medical' },
  { value: 'fas fa-lari-sign', label: 'lari-sign' },
  { value: 'fas fa-layer-group', label: 'layer-group' },
  { value: 'fas fa-leaf', label: 'leaf' },
  { value: 'fas fa-left-long', label: 'left-long' },
  { value: 'fas fa-lemon', label: 'lemon' },
  { value: 'fas fa-less-than', label: 'less-than' },
  { value: 'fas fa-less-than-equal', label: 'less-than-equal' },
  { value: 'fas fa-turn-down', label: 'turn-down' },
  { value: 'fas fa-turn-up', label: 'turn-up' },
  { value: 'fas fa-life-ring', label: 'life-ring' },
  { value: 'fas fa-lightbulb', label: 'lightbulb' },
  { value: 'fas fa-lines-leaning', label: 'lines-leaning' },
  { value: 'fas fa-lira-sign', label: 'lira-sign' },
  { value: 'fas fa-list', label: 'list' },
  { value: 'fas fa-list-ol', label: 'list-ol' },
  { value: 'fas fa-rectangle-list', label: 'rectangle-list' },
  { value: 'fas fa-list-check', label: 'list-check' },
  { value: 'fas fa-list-ul', label: 'list-ul' },
  { value: 'fas fa-litecoin-sign', label: 'litecoin-sign' },
  { value: 'fas fa-location-crosshairs', label: 'location-crosshairs' },
  { value: 'fas fa-location-arrow', label: 'location-arrow' },
  { value: 'fas fa-location-dot', label: 'location-dot' },
  { value: 'fas fa-location-pin', label: 'location-pin' },
  { value: 'fas fa-location-pin-lock', label: 'location-pin-lock' },
  { value: 'fas fa-lock', label: 'lock' },
  { value: 'fas fa-lock-open', label: 'lock-open' },
  { value: 'fas fa-locust', label: 'locust' },
  { value: 'fas fa-right-long', label: 'right-long' },
  { value: 'fas fa-up-long', label: 'up-long' },
  { value: 'fas fa-lungs', label: 'lungs' },
  { value: 'fas fa-lungs-virus', label: 'lungs-virus' },
  { value: 'fas fa-m', label: 'm' },
  { value: 'fas fa-wand-magic', label: 'wand-magic' },
  { value: 'fas fa-wand-magic-sparkles', label: 'wand-magic-sparkles' },
  { value: 'fas fa-magnet', label: 'magnet' },
  { value: 'fas fa-magnifying-glass', label: 'magnifying-glass' },
  { value: 'fas fa-magnifying-glass-arrow-right', label: 'magnifying-glass-arrow-right' },
  { value: 'fas fa-magnifying-glass-chart', label: 'magnifying-glass-chart' },
  { value: 'fas fa-magnifying-glass-dollar', label: 'magnifying-glass-dollar' },
  { value: 'fas fa-magnifying-glass-location', label: 'magnifying-glass-location' },
  { value: 'fas fa-magnifying-glass-minus', label: 'magnifying-glass-minus' },
  { value: 'fas fa-magnifying-glass-plus', label: 'magnifying-glass-plus' },
  { value: 'fas fa-reply', label: 'reply' },
  { value: 'fas fa-reply-all', label: 'reply-all' },
  { value: 'fas fa-person', label: 'person' },
  { value: 'fas fa-manat-sign', label: 'manat-sign' },
  { value: 'fas fa-map', label: 'map' },
  { value: 'fas fa-map-location', label: 'map-location' },
  { value: 'fas fa-map-location-dot', label: 'map-location-dot' },
  { value: 'fas fa-map-pin', label: 'map-pin' },
  { value: 'fas fa-signs-post', label: 'signs-post' },
  { value: 'fas fa-marker', label: 'marker' },
  { value: 'fas fa-mars', label: 'mars' },
  { value: 'fas fa-mars-and-venus', label: 'mars-and-venus' },
  { value: 'fas fa-mars-and-venus-burst', label: 'mars-and-venus-burst' },
  { value: 'fas fa-mars-double', label: 'mars-double' },
  { value: 'fas fa-mars-stroke', label: 'mars-stroke' },
  { value: 'fas fa-mars-stroke-right', label: 'mars-stroke-right' },
  { value: 'fas fa-mars-stroke-up', label: 'mars-stroke-up' },
  { value: 'fas fa-mask', label: 'mask' },
  { value: 'fas fa-mask-face', label: 'mask-face' },
  { value: 'fas fa-mask-ventilator', label: 'mask-ventilator' },
  { value: 'fas fa-masks-theater', label: 'masks-theater' },
  { value: 'fas fa-mattress-pillow', label: 'mattress-pillow' },
  { value: 'fas fa-medal', label: 'medal' },
  { value: 'fas fa-suitcase-medical', label: 'suitcase-medical' },
  { value: 'fas fa-memory', label: 'memory' },
  { value: 'fas fa-menorah', label: 'menorah' },
  { value: 'fas fa-mercury', label: 'mercury' },
  { value: 'fas fa-meteor', label: 'meteor' },
  { value: 'fas fa-microchip', label: 'microchip' },
  { value: 'fas fa-microphone', label: 'microphone' },
  { value: 'fas fa-microphone-lines', label: 'microphone-lines' },
  { value: 'fas fa-microphone-lines-slash', label: 'microphone-lines-slash' },
  { value: 'fas fa-microphone-slash', label: 'microphone-slash' },
  { value: 'fas fa-microscope', label: 'microscope' },
  { value: 'fas fa-mill-sign', label: 'mill-sign' },
  { value: 'fas fa-minus', label: 'minus' },
  { value: 'fas fa-square-minus', label: 'square-minus' },
  { value: 'fas fa-mitten', label: 'mitten' },
  { value: 'fas fa-mobile', label: 'mobile' },
  { value: 'fas fa-mobile-screen-button', label: 'mobile-screen-button' },
  { value: 'fas fa-mobile-screen', label: 'mobile-screen' },
  { value: 'fas fa-mobile-button', label: 'mobile-button' },
  { value: 'fas fa-mobile-retro', label: 'mobile-retro' },
  { value: 'fas fa-money-bill', label: 'money-bill' },
  { value: 'fas fa-money-bill-1', label: 'money-bill-1' },
  { value: 'fas fa-money-bill-1-wave', label: 'money-bill-1-wave' },
  { value: 'fas fa-money-bill-transfer', label: 'money-bill-transfer' },
  { value: 'fas fa-money-bill-trend-up', label: 'money-bill-trend-up' },
  { value: 'fas fa-money-bill-wave', label: 'money-bill-wave' },
  { value: 'fas fa-money-bill-wheat', label: 'money-bill-wheat' },
  { value: 'fas fa-money-bills', label: 'money-bills' },
  { value: 'fas fa-money-check', label: 'money-check' },
  { value: 'fas fa-money-check-dollar', label: 'money-check-dollar' },
  { value: 'fas fa-monument', label: 'monument' },
  { value: 'fas fa-moon', label: 'moon' },
  { value: 'fas fa-mortar-pestle', label: 'mortar-pestle' },
  { value: 'fas fa-mosque', label: 'mosque' },
  { value: 'fas fa-mosquito', label: 'mosquito' },
  { value: 'fas fa-mosquito-net', label: 'mosquito-net' },
  { value: 'fas fa-motorcycle', label: 'motorcycle' },
  { value: 'fas fa-mound', label: 'mound' },
  { value: 'fas fa-mountain', label: 'mountain' },
  { value: 'fas fa-mountain-city', label: 'mountain-city' },
  { value: 'fas fa-mountain-sun', label: 'mountain-sun' },
  { value: 'fas fa-mug-hot', label: 'mug-hot' },
  { value: 'fas fa-music', label: 'music' },
  { value: 'fas fa-n', label: 'n' },
  { value: 'fas fa-naira-sign', label: 'naira-sign' },
  { value: 'fas fa-network-wired', label: 'network-wired' },
  { value: 'fas fa-neuter', label: 'neuter' },
  { value: 'fas fa-newspaper', label: 'newspaper' },
  { value: 'fas fa-not-equal', label: 'not-equal' },
  { value: 'fas fa-notdef', label: 'notdef' },
  { value: 'fas fa-note-sticky', label: 'note-sticky' },
  { value: 'fas fa-notes-medical', label: 'notes-medical' },
  { value: 'fas fa-o', label: 'o' },
  { value: 'fas fa-object-group', label: 'object-group' },
  { value: 'fas fa-object-ungroup', label: 'object-ungroup' },
  { value: 'fas fa-oil-can', label: 'oil-can' },
  { value: 'fas fa-oil-well', label: 'oil-well' },
  { value: 'fas fa-om', label: 'om' },
  { value: 'fas fa-otter', label: 'otter' },
  { value: 'fas fa-p', label: 'p' },
  { value: 'fas fa-pager', label: 'pager' },
  { value: 'fas fa-paintbrush', label: 'paintbrush' },
  { value: 'fas fa-paint-roller', label: 'paint-roller' },
  { value: 'fas fa-palette', label: 'palette' },
  { value: 'fas fa-pallet', label: 'pallet' },
  { value: 'fas fa-panorama', label: 'panorama' },
  { value: 'fas fa-paper-plane', label: 'paper-plane' },
  { value: 'fas fa-paperclip', label: 'paperclip' },
  { value: 'fas fa-parachute-box', label: 'parachute-box' },
  { value: 'fas fa-paragraph', label: 'paragraph' },
  { value: 'fas fa-square-parking', label: 'square-parking' },
  { value: 'fas fa-passport', label: 'passport' },
  { value: 'fas fa-spaghetti-monster-flying', label: 'spaghetti-monster-flying' },
  { value: 'fas fa-pause', label: 'pause' },
  { value: 'fas fa-paw', label: 'paw' },
  { value: 'fas fa-peace', label: 'peace' },
  { value: 'fas fa-pen', label: 'pen' },
  { value: 'fas fa-pen-clip', label: 'pen-clip' },
  { value: 'fas fa-pen-fancy', label: 'pen-fancy' },
  { value: 'fas fa-pen-nib', label: 'pen-nib' },
  { value: 'fas fa-pen-ruler', label: 'pen-ruler' },
  { value: 'fas fa-square-pen', label: 'square-pen' },
  { value: 'fas fa-pencil', label: 'pencil' },
  { value: 'fas fa-people-arrows', label: 'people-arrows' },
  { value: 'fas fa-people-carry-box', label: 'people-carry-box' },
  { value: 'fas fa-people-group', label: 'people-group' },
  { value: 'fas fa-people-line', label: 'people-line' },
  { value: 'fas fa-people-pulling', label: 'people-pulling' },
  { value: 'fas fa-people-robbery', label: 'people-robbery' },
  { value: 'fas fa-people-roof', label: 'people-roof' },
  { value: 'fas fa-pepper-hot', label: 'pepper-hot' },
  { value: 'fas fa-percent', label: 'percent' },
  { value: 'fas fa-person-arrow-down-to-line', label: 'person-arrow-down-to-line' },
  { value: 'fas fa-person-arrow-up-from-line', label: 'person-arrow-up-from-line' },
  { value: 'fas fa-person-booth', label: 'person-booth' },
  { value: 'fas fa-person-breastfeeding', label: 'person-breastfeeding' },
  { value: 'fas fa-person-burst', label: 'person-burst' },
  { value: 'fas fa-person-cane', label: 'person-cane' },
  { value: 'fas fa-person-chalkboard', label: 'person-chalkboard' },
  { value: 'fas fa-person-circle-check', label: 'person-circle-check' },
  { value: 'fas fa-person-circle-exclamation', label: 'person-circle-exclamation' },
  { value: 'fas fa-person-circle-minus', label: 'person-circle-minus' },
  { value: 'fas fa-person-circle-plus', label: 'person-circle-plus' },
  { value: 'fas fa-person-circle-question', label: 'person-circle-question' },
  { value: 'fas fa-person-circle-xmark', label: 'person-circle-xmark' },
  { value: 'fas fa-person-dress-burst', label: 'person-dress-burst' },
  { value: 'fas fa-person-drowning', label: 'person-drowning' },
  { value: 'fas fa-person-falling', label: 'person-falling' },
  { value: 'fas fa-person-falling-burst', label: 'person-falling-burst' },
  { value: 'fas fa-person-half-dress', label: 'person-half-dress' },
  { value: 'fas fa-person-harassing', label: 'person-harassing' },
  { value: 'fas fa-person-military-pointing', label: 'person-military-pointing' },
  { value: 'fas fa-person-military-rifle', label: 'person-military-rifle' },
  { value: 'fas fa-person-military-to-person', label: 'person-military-to-person' },
  { value: 'fas fa-person-praying', label: 'person-praying' },
  { value: 'fas fa-person-pregnant', label: 'person-pregnant' },
  { value: 'fas fa-person-rays', label: 'person-rays' },
  { value: 'fas fa-person-rifle', label: 'person-rifle' },
  { value: 'fas fa-person-running', label: 'person-running' },
  { value: 'fas fa-person-shelter', label: 'person-shelter' },
  { value: 'fas fa-person-skating', label: 'person-skating' },
  { value: 'fas fa-person-skiing', label: 'person-skiing' },
  { value: 'fas fa-person-skiing-nordic', label: 'person-skiing-nordic' },
  { value: 'fas fa-person-snowboarding', label: 'person-snowboarding' },
  { value: 'fas fa-person-swimming', label: 'person-swimming' },
  { value: 'fas fa-person-through-window', label: 'person-through-window' },
  { value: 'fas fa-person-walking', label: 'person-walking' },
  { value: 'fas fa-person-walking-arrow-loop-left', label: 'person-walking-arrow-loop-left' },
  { value: 'fas fa-person-walking-arrow-right', label: 'person-walking-arrow-right' },
  { value: 'fas fa-person-walking-dashed-line-arrow-right', label: 'person-walking-dashed-line-arrow-right' },
  { value: 'fas fa-person-walking-luggage', label: 'person-walking-luggage' },
  { value: 'fas fa-peseta-sign', label: 'peseta-sign' },
  { value: 'fas fa-peso-sign', label: 'peso-sign' },
  { value: 'fas fa-phone', label: 'phone' },
  { value: 'fas fa-phone-flip', label: 'phone-flip' },
  { value: 'fas fa-phone-slash', label: 'phone-slash' },
  { value: 'fas fa-square-phone', label: 'square-phone' },
  { value: 'fas fa-square-phone-flip', label: 'square-phone-flip' },
  { value: 'fas fa-phone-volume', label: 'phone-volume' },
  { value: 'fas fa-photo-film', label: 'photo-film' },
  { value: 'fas fa-piggy-bank', label: 'piggy-bank' },
  { value: 'fas fa-pills', label: 'pills' },
  { value: 'fas fa-table-tennis-paddle-ball', label: 'table-tennis-paddle-ball' },
  { value: 'fas fa-pizza-slice', label: 'pizza-slice' },
  { value: 'fas fa-place-of-worship', label: 'place-of-worship' },
  { value: 'fas fa-plane', label: 'plane' },
  { value: 'fas fa-plane-arrival', label: 'plane-arrival' },
  { value: 'fas fa-plane-circle-check', label: 'plane-circle-check' },
  { value: 'fas fa-plane-circle-exclamation', label: 'plane-circle-exclamation' },
  { value: 'fas fa-plane-circle-xmark', label: 'plane-circle-xmark' },
  { value: 'fas fa-plane-departure', label: 'plane-departure' },
  { value: 'fas fa-plane-lock', label: 'plane-lock' },
  { value: 'fas fa-plane-slash', label: 'plane-slash' },
  { value: 'fas fa-plane-up', label: 'plane-up' },
  { value: 'fas fa-plant-wilt', label: 'plant-wilt' },
  { value: 'fas fa-plate-wheat', label: 'plate-wheat' },
  { value: 'fas fa-play', label: 'play' },
  { value: 'fas fa-plug', label: 'plug' },
  { value: 'fas fa-plug-circle-bolt', label: 'plug-circle-bolt' },
  { value: 'fas fa-plug-circle-check', label: 'plug-circle-check' },
  { value: 'fas fa-plug-circle-exclamation', label: 'plug-circle-exclamation' },
  { value: 'fas fa-plug-circle-minus', label: 'plug-circle-minus' },
  { value: 'fas fa-plug-circle-plus', label: 'plug-circle-plus' },
  { value: 'fas fa-plug-circle-xmark', label: 'plug-circle-xmark' },
  { value: 'fas fa-plus-minus', label: 'plus-minus' },
  { value: 'fas fa-square-plus', label: 'square-plus' },
  { value: 'fas fa-podcast', label: 'podcast' },
  { value: 'fas fa-square-poll-vertical', label: 'square-poll-vertical' },
  { value: 'fas fa-square-poll-horizontal', label: 'square-poll-horizontal' },
  { value: 'fas fa-poo', label: 'poo' },
  { value: 'fas fa-poo-storm', label: 'poo-storm' },
  { value: 'fas fa-poop', label: 'poop' },
  { value: 'fas fa-power-off', label: 'power-off' },
  { value: 'fas fa-prescription', label: 'prescription' },
  { value: 'fas fa-prescription-bottle', label: 'prescription-bottle' },
  { value: 'fas fa-prescription-bottle-medical', label: 'prescription-bottle-medical' },
  { value: 'fas fa-print', label: 'print' },
  { value: 'fas fa-pump-medical', label: 'pump-medical' },
  { value: 'fas fa-pump-soap', label: 'pump-soap' },
  { value: 'fas fa-puzzle-piece', label: 'puzzle-piece' },
  { value: 'fas fa-q', label: 'q' },
  { value: 'fas fa-qrcode', label: 'qrcode' },
  { value: 'fas fa-question', label: 'question' },
  { value: 'fas fa-quote-left', label: 'quote-left' },
  { value: 'fas fa-quote-right', label: 'quote-right' },
  { value: 'fas fa-r', label: 'r' },
  { value: 'fas fa-radiation', label: 'radiation' },
  { value: 'fas fa-radio', label: 'radio' },
  { value: 'fas fa-rainbow', label: 'rainbow' },
  { value: 'fas fa-shuffle', label: 'shuffle' },
  { value: 'fas fa-ranking-star', label: 'ranking-star' },
  { value: 'fas fa-receipt', label: 'receipt' },
  { value: 'fas fa-record-vinyl', label: 'record-vinyl' },
  { value: 'fas fa-rectangle-xmark', label: 'rectangle-xmark' },
  { value: 'fas fa-recycle', label: 'recycle' },
  { value: 'fas fa-rotate-right', label: 'rotate-right' },
  { value: 'fas fa-registered', label: 'registered' },
  { value: 'fas fa-text-slash', label: 'text-slash' },
  { value: 'fas fa-repeat', label: 'repeat' },
  { value: 'fas fa-republican', label: 'republican' },
  { value: 'fas fa-restroom', label: 'restroom' },
  { value: 'fas fa-retweet', label: 'retweet' },
  { value: 'fas fa-ribbon', label: 'ribbon' },
  { value: 'fas fa-right-from-bracket', label: 'right-from-bracket' },
  { value: 'fas fa-right-to-bracket', label: 'right-to-bracket' },
  { value: 'fas fa-ring', label: 'ring' },
  { value: 'fas fa-road', label: 'road' },
  { value: 'fas fa-road-barrier', label: 'road-barrier' },
  { value: 'fas fa-road-bridge', label: 'road-bridge' },
  { value: 'fas fa-road-circle-check', label: 'road-circle-check' },
  { value: 'fas fa-road-circle-exclamation', label: 'road-circle-exclamation' },
  { value: 'fas fa-road-circle-xmark', label: 'road-circle-xmark' },
  { value: 'fas fa-road-lock', label: 'road-lock' },
  { value: 'fas fa-road-spikes', label: 'road-spikes' },
  { value: 'fas fa-robot', label: 'robot' },
  { value: 'fas fa-rocket', label: 'rocket' },
  { value: 'fas fa-staff-snake', label: 'staff-snake' },
  { value: 'fas fa-rotate', label: 'rotate' },
  { value: 'fas fa-rotate-left', label: 'rotate-left' },
  { value: 'fas fa-ruble-sign', label: 'ruble-sign' },
  { value: 'fas fa-route', label: 'route' },
  { value: 'fas fa-square-rss', label: 'square-rss' },
  { value: 'fas fa-rug', label: 'rug' },
  { value: 'fas fa-ruler', label: 'ruler' },
  { value: 'fas fa-ruler-combined', label: 'ruler-combined' },
  { value: 'fas fa-ruler-horizontal', label: 'ruler-horizontal' },
  { value: 'fas fa-ruler-vertical', label: 'ruler-vertical' },
  { value: 'fas fa-rupee-sign', label: 'rupee-sign' },
  { value: 'fas fa-rupiah-sign', label: 'rupiah-sign' },
  { value: 'fas fa-s', label: 's' },
  { value: 'fas fa-sack-dollar', label: 'sack-dollar' },
  { value: 'fas fa-sack-xmark', label: 'sack-xmark' },
  { value: 'fas fa-sailboat', label: 'sailboat' },
  { value: 'fas fa-satellite', label: 'satellite' },
  { value: 'fas fa-satellite-dish', label: 'satellite-dish' },
  { value: 'fas fa-school', label: 'school' },
  { value: 'fas fa-school-circle-check', label: 'school-circle-check' },
  { value: 'fas fa-school-circle-exclamation', label: 'school-circle-exclamation' },
  { value: 'fas fa-school-circle-xmark', label: 'school-circle-xmark' },
  { value: 'fas fa-school-flag', label: 'school-flag' },
  { value: 'fas fa-school-lock', label: 'school-lock' },
  { value: 'fas fa-screwdriver', label: 'screwdriver' },
  { value: 'fas fa-screwdriver-wrench', label: 'screwdriver-wrench' },
  { value: 'fas fa-scroll', label: 'scroll' },
  { value: 'fas fa-scroll-torah', label: 'scroll-torah' },
  { value: 'fas fa-sd-card', label: 'sd-card' },
  { value: 'fas fa-section', label: 'section' },
  { value: 'fas fa-seedling', label: 'seedling' },
  { value: 'fas fa-server', label: 'server' },
  { value: 'fas fa-shapes', label: 'shapes' },
  { value: 'fas fa-share-nodes', label: 'share-nodes' },
  { value: 'fas fa-square-share-nodes', label: 'square-share-nodes' },
  { value: 'fas fa-share-from-square', label: 'share-from-square' },
  { value: 'fas fa-sheet-plastic', label: 'sheet-plastic' },
  { value: 'fas fa-shield', label: 'shield' },
  { value: 'fas fa-shield-halved', label: 'shield-halved' },
  { value: 'fas fa-shield-cat', label: 'shield-cat' },
  { value: 'fas fa-shield-dog', label: 'shield-dog' },
  { value: 'fas fa-shield-heart', label: 'shield-heart' },
  { value: 'fas fa-shield-virus', label: 'shield-virus' },
  { value: 'fas fa-ship', label: 'ship' },
  { value: 'fas fa-truck-fast', label: 'truck-fast' },
  { value: 'fas fa-shirt', label: 'shirt' },
  { value: 'fas fa-shoe-prints', label: 'shoe-prints' },
  { value: 'fas fa-shop', label: 'shop' },
  { value: 'fas fa-shop-lock', label: 'shop-lock' },
  { value: 'fas fa-shop-slash', label: 'shop-slash' },
  { value: 'fas fa-shower', label: 'shower' },
  { value: 'fas fa-shrimp', label: 'shrimp' },
  { value: 'fas fa-shuttle-space', label: 'shuttle-space' },
  { value: 'fas fa-van-shuttle', label: 'van-shuttle' },
  { value: 'fas fa-sign-hanging', label: 'sign-hanging' },
  { value: 'fas fa-signal', label: 'signal' },
  { value: 'fas fa-signature', label: 'signature' },
  { value: 'fas fa-sim-card', label: 'sim-card' },
  { value: 'fas fa-sink', label: 'sink' },
  { value: 'fas fa-sitemap', label: 'sitemap' },
  { value: 'fas fa-skull', label: 'skull' },
  { value: 'fas fa-skull-crossbones', label: 'skull-crossbones' },
  { value: 'fas fa-slash', label: 'slash' },
  { value: 'fas fa-sleigh', label: 'sleigh' },
  { value: 'fas fa-sliders', label: 'sliders' },
  { value: 'fas fa-smog', label: 'smog' },
  { value: 'fas fa-smoking', label: 'smoking' },
  { value: 'fas fa-snowflake', label: 'snowflake' },
  { value: 'fas fa-snowman', label: 'snowman' },
  { value: 'fas fa-snowplow', label: 'snowplow' },
  { value: 'fas fa-soap', label: 'soap' },
  { value: 'fas fa-socks', label: 'socks' },
  { value: 'fas fa-solar-panel', label: 'solar-panel' },
  { value: 'fas fa-sort', label: 'sort' },
  { value: 'fas fa-sort-up', label: 'sort-up' },
  { value: 'fas fa-sort-down', label: 'sort-down' },
  { value: 'fas fa-spa', label: 'spa' },
  { value: 'fas fa-spell-check', label: 'spell-check' },
  { value: 'fas fa-spider', label: 'spider' },
  { value: 'fas fa-spinner', label: 'spinner' },
  { value: 'fas fa-splotch', label: 'splotch' },
  { value: 'fas fa-spoon', label: 'spoon' },
  { value: 'fas fa-spray-can', label: 'spray-can' },
  { value: 'fas fa-square', label: 'square' },
  { value: 'fas fa-square-full', label: 'square-full' },
  { value: 'fas fa-square-nfi', label: 'square-nfi' },
  { value: 'fas fa-square-person-confined', label: 'square-person-confined' },
  { value: 'fas fa-square-root-variable', label: 'square-root-variable' },
  { value: 'fas fa-square-virus', label: 'square-virus' },
  { value: 'fas fa-square-xmark', label: 'square-xmark' },
  { value: 'fas fa-stairs', label: 'stairs' },
  { value: 'fas fa-stamp', label: 'stamp' },
  { value: 'fas fa-stapler', label: 'stapler' },
  { value: 'fas fa-star', label: 'star' },
  { value: 'fas fa-star-and-crescent', label: 'star-and-crescent' },
  { value: 'fas fa-star-half', label: 'star-half' },
  { value: 'fas fa-star-half-stroke', label: 'star-half-stroke' },
  { value: 'fas fa-star-of-david', label: 'star-of-david' },
  { value: 'fas fa-star-of-life', label: 'star-of-life' },
  { value: 'fas fa-stethoscope', label: 'stethoscope' },
  { value: 'fas fa-stop', label: 'stop' },
  { value: 'fas fa-stopwatch', label: 'stopwatch' },
  { value: 'fas fa-stopwatch-20', label: 'stopwatch-20' },
  { value: 'fas fa-store', label: 'store' },
  { value: 'fas fa-store-slash', label: 'store-slash' },
  { value: 'fas fa-street-view', label: 'street-view' },
  { value: 'fas fa-strikethrough', label: 'strikethrough' },
  { value: 'fas fa-stroopwafel', label: 'stroopwafel' },
  { value: 'fas fa-subscript', label: 'subscript' },
  { value: 'fas fa-train-subway', label: 'train-subway' },
  { value: 'fas fa-suitcase', label: 'suitcase' },
  { value: 'fas fa-suitcase-rolling', label: 'suitcase-rolling' },
  { value: 'fas fa-sun', label: 'sun' },
  { value: 'fas fa-sun-plant-wilt', label: 'sun-plant-wilt' },
  { value: 'fas fa-superscript', label: 'superscript' },
  { value: 'fas fa-swatchbook', label: 'swatchbook' },
  { value: 'fas fa-synagogue', label: 'synagogue' },
  { value: 'fas fa-syringe', label: 'syringe' },
  { value: 'fas fa-t', label: 't' },
  { value: 'fas fa-table', label: 'table' },
  { value: 'fas fa-table-cells', label: 'table-cells' },
  { value: 'fas fa-table-cells-large', label: 'table-cells-large' },
  { value: 'fas fa-table-list', label: 'table-list' },
  { value: 'fas fa-tablet', label: 'tablet' },
  { value: 'fas fa-tablet-screen-button', label: 'tablet-screen-button' },
  { value: 'fas fa-tablet-button', label: 'tablet-button' },
  { value: 'fas fa-tablets', label: 'tablets' },
  { value: 'fas fa-tag', label: 'tag' },
  { value: 'fas fa-tags', label: 'tags' },
  { value: 'fas fa-tape', label: 'tape' },
  { value: 'fas fa-tarp', label: 'tarp' },
  { value: 'fas fa-tarp-droplet', label: 'tarp-droplet' },
  { value: 'fas fa-teeth', label: 'teeth' },
  { value: 'fas fa-teeth-open', label: 'teeth-open' },
  { value: 'fas fa-tty', label: 'tty' },
  { value: 'fas fa-tv', label: 'tv' },
  { value: 'fas fa-temperature-empty', label: 'temperature-empty' },
  { value: 'fas fa-temperature-quarter', label: 'temperature-quarter' },
  { value: 'fas fa-temperature-half', label: 'temperature-half' },
  { value: 'fas fa-temperature-three-quarters', label: 'temperature-three-quarters' },
  { value: 'fas fa-temperature-full', label: 'temperature-full' },
  { value: 'fas fa-temperature-arrow-down', label: 'temperature-arrow-down' },
  { value: 'fas fa-temperature-arrow-up', label: 'temperature-arrow-up' },
  { value: 'fas fa-temperature-high', label: 'temperature-high' },
  { value: 'fas fa-temperature-low', label: 'temperature-low' },
  { value: 'fas fa-tenge-sign', label: 'tenge-sign' },
  { value: 'fas fa-tent', label: 'tent' },
  { value: 'fas fa-tent-arrow-down-to-line', label: 'tent-arrow-down-to-line' },
  { value: 'fas fa-tent-arrow-left-right', label: 'tent-arrow-left-right' },
  { value: 'fas fa-tent-arrow-turn-left', label: 'tent-arrow-turn-left' },
  { value: 'fas fa-tent-arrows-down', label: 'tent-arrows-down' },
  { value: 'fas fa-tents', label: 'tents' },
  { value: 'fas fa-terminal', label: 'terminal' },
  { value: 'fas fa-text-height', label: 'text-height' },
  { value: 'fas fa-text-width', label: 'text-width' },
  { value: 'fas fa-thermometer', label: 'thermometer' },
  { value: 'fas fa-thumbtack', label: 'thumbtack' },
  { value: 'fas fa-thumbs-down', label: 'thumbs-down' },
  { value: 'fas fa-thumbs-up', label: 'thumbs-up' },
  { value: 'fas fa-ticket', label: 'ticket' },
  { value: 'fas fa-ticket-simple', label: 'ticket-simple' },
  { value: 'fas fa-timeline', label: 'timeline' },
  { value: 'fas fa-toggle-off', label: 'toggle-off' },
  { value: 'fas fa-toggle-on', label: 'toggle-on' },
  { value: 'fas fa-toilet', label: 'toilet' },
  { value: 'fas fa-toilet-paper', label: 'toilet-paper' },
  { value: 'fas fa-toilet-paper-slash', label: 'toilet-paper-slash' },
  { value: 'fas fa-toilet-portable', label: 'toilet-portable' },
  { value: 'fas fa-toilets-portable', label: 'toilets-portable' },
  { value: 'fas fa-toolbox', label: 'toolbox' },
  { value: 'fas fa-tooth', label: 'tooth' },
  { value: 'fas fa-torii-gate', label: 'torii-gate' },
  { value: 'fas fa-tornado', label: 'tornado' },
  { value: 'fas fa-tower-cell', label: 'tower-cell' },
  { value: 'fas fa-tower-observation', label: 'tower-observation' },
  { value: 'fas fa-tractor', label: 'tractor' },
  { value: 'fas fa-trademark', label: 'trademark' },
  { value: 'fas fa-traffic-light', label: 'traffic-light' },
  { value: 'fas fa-trailer', label: 'trailer' },
  { value: 'fas fa-train', label: 'train' },
  { value: 'fas fa-train-tram', label: 'train-tram' },
  { value: 'fas fa-transgender', label: 'transgender' },
  { value: 'fas fa-trash', label: 'trash' },
  { value: 'fas fa-trash-can', label: 'trash-can' },
  { value: 'fas fa-trash-arrow-up', label: 'trash-arrow-up' },
  { value: 'fas fa-trash-can-arrow-up', label: 'trash-can-arrow-up' },
  { value: 'fas fa-tree', label: 'tree' },
  { value: 'fas fa-tree-city', label: 'tree-city' },
  { value: 'fas fa-trophy', label: 'trophy' },
  { value: 'fas fa-trowel', label: 'trowel' },
  { value: 'fas fa-trowel-bricks', label: 'trowel-bricks' },
  { value: 'fas fa-truck', label: 'truck' },
  { value: 'fas fa-truck-arrow-right', label: 'truck-arrow-right' },
  { value: 'fas fa-truck-droplet', label: 'truck-droplet' },
  { value: 'fas fa-truck-field', label: 'truck-field' },
  { value: 'fas fa-truck-field-un', label: 'truck-field-un' },
  { value: 'fas fa-truck-front', label: 'truck-front' },
  { value: 'fas fa-truck-ramp-box', label: 'truck-ramp-box' },
  { value: 'fas fa-truck-monster', label: 'truck-monster' },
  { value: 'fas fa-truck-moving', label: 'truck-moving' },
  { value: 'fas fa-truck-pickup', label: 'truck-pickup' },
  { value: 'fas fa-truck-plane', label: 'truck-plane' },
  { value: 'fas fa-turkish-lira-sign', label: 'turkish-lira-sign' },
  { value: 'fas fa-u', label: 'u' },
  { value: 'fas fa-umbrella', label: 'umbrella' },
  { value: 'fas fa-umbrella-beach', label: 'umbrella-beach' },
  { value: 'fas fa-underline', label: 'underline' },
  { value: 'fas fa-universal-access', label: 'universal-access' },
  { value: 'fas fa-unlock', label: 'unlock' },
  { value: 'fas fa-unlock-keyhole', label: 'unlock-keyhole' },
  { value: 'fas fa-upload', label: 'upload' },
  { value: 'fas fa-user', label: 'user' },
  { value: 'fas fa-user-large', label: 'user-large' },
  { value: 'fas fa-user-large-slash', label: 'user-large-slash' },
  { value: 'fas fa-user-astronaut', label: 'user-astronaut' },
  { value: 'fas fa-user-check', label: 'user-check' },
  { value: 'fas fa-user-clock', label: 'user-clock' },
  { value: 'fas fa-user-gear', label: 'user-gear' },
  { value: 'fas fa-user-doctor', label: 'user-doctor' },
  { value: 'fas fa-user-pen', label: 'user-pen' },
  { value: 'fas fa-user-group', label: 'user-group' },
  { value: 'fas fa-user-graduate', label: 'user-graduate' },
  { value: 'fas fa-user-injured', label: 'user-injured' },
  { value: 'fas fa-user-lock', label: 'user-lock' },
  { value: 'fas fa-user-minus', label: 'user-minus' },
  { value: 'fas fa-user-ninja', label: 'user-ninja' },
  { value: 'fas fa-user-nurse', label: 'user-nurse' },
  { value: 'fas fa-user-plus', label: 'user-plus' },
  { value: 'fas fa-user-secret', label: 'user-secret' },
  { value: 'fas fa-user-shield', label: 'user-shield' },
  { value: 'fas fa-user-slash', label: 'user-slash' },
  { value: 'fas fa-user-tag', label: 'user-tag' },
  { value: 'fas fa-user-tie', label: 'user-tie' },
  { value: 'fas fa-user-xmark', label: 'user-xmark' },
  { value: 'fas fa-users', label: 'users' },
  { value: 'fas fa-users-between-lines', label: 'users-between-lines' },
  { value: 'fas fa-users-gear', label: 'users-gear' },
  { value: 'fas fa-users-line', label: 'users-line' },
  { value: 'fas fa-users-rays', label: 'users-rays' },
  { value: 'fas fa-users-rectangle', label: 'users-rectangle' },
  { value: 'fas fa-users-slash', label: 'users-slash' },
  { value: 'fas fa-users-viewfinder', label: 'users-viewfinder' },
  { value: 'fas fa-v', label: 'v' },
  { value: 'fas fa-vault', label: 'vault' },
  { value: 'fas fa-vector-square', label: 'vector-square' },
  { value: 'fas fa-venus', label: 'venus' },
  { value: 'fas fa-venus-double', label: 'venus-double' },
  { value: 'fas fa-venus-mars', label: 'venus-mars' },
  { value: 'fas fa-vest', label: 'vest' },
  { value: 'fas fa-vest-patches', label: 'vest-patches' },
  { value: 'fas fa-vial', label: 'vial' },
  { value: 'fas fa-vial-circle-check', label: 'vial-circle-check' },
  { value: 'fas fa-vial-virus', label: 'vial-virus' },
  { value: 'fas fa-vials', label: 'vials' },
  { value: 'fas fa-video', label: 'video' },
  { value: 'fas fa-video-slash', label: 'video-slash' },
  { value: 'fas fa-vihara', label: 'vihara' },
  { value: 'fas fa-virus', label: 'virus' },
  { value: 'fas fa-virus-covid', label: 'virus-covid' },
  { value: 'fas fa-virus-covid-slash', label: 'virus-covid-slash' },
  { value: 'fas fa-virus-slash', label: 'virus-slash' },
  { value: 'fas fa-viruses', label: 'viruses' },
  { value: 'fas fa-voicemail', label: 'voicemail' },
  { value: 'fas fa-volcano', label: 'volcano' },
  { value: 'fas fa-volleyball', label: 'volleyball' },
  { value: 'fas fa-volume-low', label: 'volume-low' },
  { value: 'fas fa-volume-high', label: 'volume-high' },
  { value: 'fas fa-volume-xmark', label: 'volume-xmark' },
  { value: 'fas fa-volume-off', label: 'volume-off' },
  { value: 'fas fa-vr-cardboard', label: 'vr-cardboard' },
  { value: 'fas fa-w', label: 'w' },
  { value: 'fas fa-walkie-talkie', label: 'walkie-talkie' },
  { value: 'fas fa-wallet', label: 'wallet' },
  { value: 'fas fa-wand-sparkles', label: 'wand-sparkles' },
  { value: 'fas fa-warehouse', label: 'warehouse' },
  { value: 'fas fa-water', label: 'water' },
  { value: 'fas fa-wave-square', label: 'wave-square' },
  { value: 'fas fa-weight-scale', label: 'weight-scale' },
  { value: 'fas fa-weight-hanging', label: 'weight-hanging' },
  { value: 'fas fa-wheat-awn', label: 'wheat-awn' },
  { value: 'fas fa-wheat-awn-circle-exclamation', label: 'wheat-awn-circle-exclamation' },
  { value: 'fas fa-wheelchair', label: 'wheelchair' },
  { value: 'fas fa-wheelchair-move', label: 'wheelchair-move' },
  { value: 'fas fa-wifi', label: 'wifi' },
  { value: 'fas fa-wind', label: 'wind' },
  { value: 'fas fa-window-maximize', label: 'window-maximize' },
  { value: 'fas fa-window-minimize', label: 'window-minimize' },
  { value: 'fas fa-window-restore', label: 'window-restore' },
  { value: 'fas fa-wine-bottle', label: 'wine-bottle' },
  { value: 'fas fa-wine-glass', label: 'wine-glass' },
  { value: 'fas fa-wine-glass-empty', label: 'wine-glass-empty' },
  { value: 'fas fa-worm', label: 'worm' },
  { value: 'fas fa-wrench', label: 'wrench' },
  { value: 'fas fa-x', label: 'x' },
  { value: 'fas fa-x-ray', label: 'x-ray' },
  { value: 'fas fa-xmarks-lines', label: 'xmarks-lines' },
  { value: 'fas fa-y', label: 'y' },
  { value: 'fas fa-yin-yang', label: 'yin-yang' },
  { value: 'fas fa-z', label: 'z' },
  { value: 'fas fa-undefined' },
  { value: 'fas fa-undefined' },
];
export const far = [
  { value: 'far fa-trash-can', label: 'trash-can' },
  { value: 'far fa-message', label: 'message' },
  { value: 'far fa-file-lines', label: 'file-lines' },
  { value: 'far fa-calendar-days', label: 'calendar-days' },
  { value: 'far fa-hand-point-right', label: 'hand-point-right' },
  { value: 'far fa-face-smile-beam', label: 'face-smile-beam' },
  { value: 'far fa-face-grin-stars', label: 'face-grin-stars' },
  { value: 'far fa-address-book', label: 'address-book' },
  { value: 'far fa-comments', label: 'comments' },
  { value: 'far fa-paste', label: 'paste' },
  { value: 'far fa-face-grin-tongue-squint', label: 'face-grin-tongue-squint' },
  { value: 'far fa-face-flushed', label: 'face-flushed' },
  { value: 'far fa-square-caret-right', label: 'square-caret-right' },
  { value: 'far fa-square-minus', label: 'square-minus' },
  { value: 'far fa-compass', label: 'compass' },
  { value: 'far fa-square-caret-down', label: 'square-caret-down' },
  { value: 'far fa-face-kiss-beam', label: 'face-kiss-beam' },
  { value: 'far fa-lightbulb', label: 'lightbulb' },
  { value: 'far fa-flag', label: 'flag' },
  { value: 'far fa-square-check', label: 'square-check' },
  { value: 'far fa-circle-dot', label: 'circle-dot' },
  { value: 'far fa-face-dizzy', label: 'face-dizzy' },
  { value: 'far fa-futbol', label: 'futbol' },
  { value: 'far fa-pen-to-square', label: 'pen-to-square' },
  { value: 'far fa-hourglass-half', label: 'hourglass-half' },
  { value: 'far fa-eye-slash', label: 'eye-slash' },
  { value: 'far fa-hand', label: 'hand' },
  { value: 'far fa-hand-spock', label: 'hand-spock' },
  { value: 'far fa-face-kiss', label: 'face-kiss' },
  { value: 'far fa-face-grin-tongue', label: 'face-grin-tongue' },
  { value: 'far fa-chess-bishop', label: 'chess-bishop' },
  { value: 'far fa-face-grin-wink', label: 'face-grin-wink' },
  { value: 'far fa-face-grin-wide', label: 'face-grin-wide' },
  { value: 'far fa-face-frown-open', label: 'face-frown-open' },
  { value: 'far fa-hand-point-up', label: 'hand-point-up' },
  { value: 'far fa-bookmark', label: 'bookmark' },
  { value: 'far fa-hand-point-down', label: 'hand-point-down' },
  { value: 'far fa-folder', label: 'folder' },
  { value: 'far fa-user', label: 'user' },
  { value: 'far fa-square-caret-left', label: 'square-caret-left' },
  { value: 'far fa-star', label: 'star' },
  { value: 'far fa-chess-knight', label: 'chess-knight' },
  { value: 'far fa-face-laugh-squint', label: 'face-laugh-squint' },
  { value: 'far fa-face-laugh', label: 'face-laugh' },
  { value: 'far fa-folder-open', label: 'folder-open' },
  { value: 'far fa-clipboard', label: 'clipboard' },
  { value: 'far fa-chess-queen', label: 'chess-queen' },
  { value: 'far fa-hand-back-fist', label: 'hand-back-fist' },
  { value: 'far fa-square-caret-up', label: 'square-caret-up' },
  { value: 'far fa-chart-bar', label: 'chart-bar' },
  { value: 'far fa-window-restore', label: 'window-restore' },
  { value: 'far fa-square-plus', label: 'square-plus' },
  { value: 'far fa-image', label: 'image' },
  { value: 'far fa-folder-closed', label: 'folder-closed' },
  { value: 'far fa-lemon', label: 'lemon' },
  { value: 'far fa-handshake', label: 'handshake' },
  { value: 'far fa-gem', label: 'gem' },
  { value: 'far fa-circle-play', label: 'circle-play' },
  { value: 'far fa-circle-check', label: 'circle-check' },
  { value: 'far fa-circle-stop', label: 'circle-stop' },
  { value: 'far fa-id-badge', label: 'id-badge' },
  { value: 'far fa-face-laugh-beam', label: 'face-laugh-beam' },
  { value: 'far fa-registered', label: 'registered' },
  { value: 'far fa-address-card', label: 'address-card' },
  { value: 'far fa-face-tired', label: 'face-tired' },
  { value: 'far fa-font-awesome', label: 'font-awesome' },
  { value: 'far fa-face-smile-wink', label: 'face-smile-wink' },
  { value: 'far fa-file-word', label: 'file-word' },
  { value: 'far fa-file-powerpoint', label: 'file-powerpoint' },
  { value: 'far fa-envelope-open', label: 'envelope-open' },
  { value: 'far fa-file-zipper', label: 'file-zipper' },
  { value: 'far fa-square', label: 'square' },
  { value: 'far fa-snowflake', label: 'snowflake' },
  { value: 'far fa-newspaper', label: 'newspaper' },
  { value: 'far fa-face-kiss-wink-heart', label: 'face-kiss-wink-heart' },
  { value: 'far fa-star-half-stroke', label: 'star-half-stroke' },
  { value: 'far fa-file-excel', label: 'file-excel' },
  { value: 'far fa-face-grin-beam', label: 'face-grin-beam' },
  { value: 'far fa-object-ungroup', label: 'object-ungroup' },
  { value: 'far fa-circle-right', label: 'circle-right' },
  { value: 'far fa-face-rolling-eyes', label: 'face-rolling-eyes' },
  { value: 'far fa-object-group', label: 'object-group' },
  { value: 'far fa-heart', label: 'heart' },
  { value: 'far fa-face-surprise', label: 'face-surprise' },
  { value: 'far fa-circle-pause', label: 'circle-pause' },
  { value: 'far fa-circle', label: 'circle' },
  { value: 'far fa-circle-up', label: 'circle-up' },
  { value: 'far fa-file-audio', label: 'file-audio' },
  { value: 'far fa-file-image', label: 'file-image' },
  { value: 'far fa-circle-question', label: 'circle-question' },
  { value: 'far fa-face-meh-blank', label: 'face-meh-blank' },
  { value: 'far fa-eye', label: 'eye' },
  { value: 'far fa-face-sad-cry', label: 'face-sad-cry' },
  { value: 'far fa-file-code', label: 'file-code' },
  { value: 'far fa-window-maximize', label: 'window-maximize' },
  { value: 'far fa-face-frown', label: 'face-frown' },
  { value: 'far fa-floppy-disk', label: 'floppy-disk' },
  { value: 'far fa-comment-dots', label: 'comment-dots' },
  { value: 'far fa-face-grin-squint', label: 'face-grin-squint' },
  { value: 'far fa-hand-pointer', label: 'hand-pointer' },
  { value: 'far fa-hand-scissors', label: 'hand-scissors' },
  { value: 'far fa-face-grin-tears', label: 'face-grin-tears' },
  { value: 'far fa-calendar-xmark', label: 'calendar-xmark' },
  { value: 'far fa-file-video', label: 'file-video' },
  { value: 'far fa-file-pdf', label: 'file-pdf' },
  { value: 'far fa-comment', label: 'comment' },
  { value: 'far fa-envelope', label: 'envelope' },
  { value: 'far fa-hourglass', label: 'hourglass' },
  { value: 'far fa-calendar-check', label: 'calendar-check' },
  { value: 'far fa-hard-drive', label: 'hard-drive' },
  { value: 'far fa-face-grin-squint-tears', label: 'face-grin-squint-tears' },
  { value: 'far fa-rectangle-list', label: 'rectangle-list' },
  { value: 'far fa-calendar-plus', label: 'calendar-plus' },
  { value: 'far fa-circle-left', label: 'circle-left' },
  { value: 'far fa-money-bill-1', label: 'money-bill-1' },
  { value: 'far fa-clock', label: 'clock' },
  { value: 'far fa-keyboard', label: 'keyboard' },
  { value: 'far fa-closed-captioning', label: 'closed-captioning' },
  { value: 'far fa-images', label: 'images' },
  { value: 'far fa-face-grin', label: 'face-grin' },
  { value: 'far fa-face-meh', label: 'face-meh' },
  { value: 'far fa-id-card', label: 'id-card' },
  { value: 'far fa-sun', label: 'sun' },
  { value: 'far fa-face-laugh-wink', label: 'face-laugh-wink' },
  { value: 'far fa-circle-down', label: 'circle-down' },
  { value: 'far fa-thumbs-down', label: 'thumbs-down' },
  { value: 'far fa-chess-pawn', label: 'chess-pawn' },
  { value: 'far fa-credit-card', label: 'credit-card' },
  { value: 'far fa-bell', label: 'bell' },
  { value: 'far fa-file', label: 'file' },
  { value: 'far fa-hospital', label: 'hospital' },
  { value: 'far fa-chess-rook', label: 'chess-rook' },
  { value: 'far fa-star-half', label: 'star-half' },
  { value: 'far fa-chess-king', label: 'chess-king' },
  { value: 'far fa-circle-user', label: 'circle-user' },
  { value: 'far fa-copy', label: 'copy' },
  { value: 'far fa-share-from-square', label: 'share-from-square' },
  { value: 'far fa-copyright', label: 'copyright' },
  { value: 'far fa-map', label: 'map' },
  { value: 'far fa-bell-slash', label: 'bell-slash' },
  { value: 'far fa-hand-lizard', label: 'hand-lizard' },
  { value: 'far fa-face-smile', label: 'face-smile' },
  { value: 'far fa-hand-peace', label: 'hand-peace' },
  { value: 'far fa-face-grin-hearts', label: 'face-grin-hearts' },
  { value: 'far fa-building', label: 'building' },
  { value: 'far fa-face-grin-beam-sweat', label: 'face-grin-beam-sweat' },
  { value: 'far fa-moon', label: 'moon' },
  { value: 'far fa-calendar', label: 'calendar' },
  { value: 'far fa-face-grin-tongue-wink', label: 'face-grin-tongue-wink' },
  { value: 'far fa-clone', label: 'clone' },
  { value: 'far fa-face-angry', label: 'face-angry' },
  { value: 'far fa-rectangle-xmark', label: 'rectangle-xmark' },
  { value: 'far fa-paper-plane', label: 'paper-plane' },
  { value: 'far fa-life-ring', label: 'life-ring' },
  { value: 'far fa-face-grimace', label: 'face-grimace' },
  { value: 'far fa-calendar-minus', label: 'calendar-minus' },
  { value: 'far fa-circle-xmark', label: 'circle-xmark' },
  { value: 'far fa-thumbs-up', label: 'thumbs-up' },
  { value: 'far fa-window-minimize', label: 'window-minimize' },
  { value: 'far fa-square-full', label: 'square-full' },
  { value: 'far fa-note-sticky', label: 'note-sticky' },
  { value: 'far fa-face-sad-tear', label: 'face-sad-tear' },
  { value: 'far fa-hand-point-left', label: 'hand-point-left' },
];
