/**
 * Sightflare Service
 * Rest Api for Sightflare Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AccessRecord } from './accessRecord';
import { UserOperationPrinciple } from './userOperationPrinciple';


export interface MetricDto { 
    id?: string;
    searchScore?: number;
    uuid?: string;
    description?: string;
    recordStatus?: MetricDto.RecordStatusEnum;
    code?: string;
    label?: string;
    tags?: Array<string>;
    tagsCodes?: Array<string>;
    version?: number;
    lockedForEdit?: boolean;
    creatorName?: string;
    lastModifierName?: string;
    lockedBy?: string;
    userAccessLevel?: AccessRecord;
    module?: MetricDto.ModuleEnum;
    systemGenerated?: boolean;
    parent?: string;
    parents?: Array<string>;
    creationDate?: string;
    softDeleteDate?: string;
    lastModificationDate?: string;
    lockedUntil?: string;
    qsr?: string;
    name?: string;
    category?: string;
    type?: MetricDto.TypeEnum;
    frequency?: number;
    status?: MetricDto.StatusEnum;
    target?: Array<string>;
    priority?: MetricDto.PriorityEnum;
    startDate?: string;
    owner?: UserOperationPrinciple;
    reporter?: UserOperationPrinciple;
    calculationType?: MetricDto.CalculationTypeEnum;
    campaignScore?: MetricDto.CampaignScoreEnum;
    currentValue?: any;
    minValue?: any;
    maxValue?: any;
    orangeThreshold?: any;
    redThreshold?: any;
    classification?: any;
    aggregateFrom?: any;
    aggregationQuestion?: any;
}
export namespace MetricDto {
    export type RecordStatusEnum = 'DELETED' | 'LOCKED' | 'ACTIVE' | 'IN_ACTIVE';
    export const RecordStatusEnum = {
        Deleted: 'DELETED' as RecordStatusEnum,
        Locked: 'LOCKED' as RecordStatusEnum,
        Active: 'ACTIVE' as RecordStatusEnum,
        InActive: 'IN_ACTIVE' as RecordStatusEnum
    };
    export type ModuleEnum = 'ALL' | 'DOCUMENT_APP' | 'ACKNOWLEDGMENT_APP' | 'ADMIN_APP' | 'CONFIG_APP' | 'DATA_STRUCTURE_APP' | 'BRANDING_APP' | 'ENTITY_APP' | 'FRAMEWORK_APP' | 'ISSUES_APP' | 'QUESTION_APP' | 'RELATION_APP' | 'RISK_APP' | 'USER_APP' | 'USER_OPERATIONS_APP' | 'AUDIT_APP' | 'HUB_APP' | 'TRANSPARENCY_APP' | 'PERFORMANCE_APP' | 'BCM_APP';
    export const ModuleEnum = {
        All: 'ALL' as ModuleEnum,
        DocumentApp: 'DOCUMENT_APP' as ModuleEnum,
        AcknowledgmentApp: 'ACKNOWLEDGMENT_APP' as ModuleEnum,
        AdminApp: 'ADMIN_APP' as ModuleEnum,
        ConfigApp: 'CONFIG_APP' as ModuleEnum,
        DataStructureApp: 'DATA_STRUCTURE_APP' as ModuleEnum,
        BrandingApp: 'BRANDING_APP' as ModuleEnum,
        EntityApp: 'ENTITY_APP' as ModuleEnum,
        FrameworkApp: 'FRAMEWORK_APP' as ModuleEnum,
        IssuesApp: 'ISSUES_APP' as ModuleEnum,
        QuestionApp: 'QUESTION_APP' as ModuleEnum,
        RelationApp: 'RELATION_APP' as ModuleEnum,
        RiskApp: 'RISK_APP' as ModuleEnum,
        UserApp: 'USER_APP' as ModuleEnum,
        UserOperationsApp: 'USER_OPERATIONS_APP' as ModuleEnum,
        AuditApp: 'AUDIT_APP' as ModuleEnum,
        HubApp: 'HUB_APP' as ModuleEnum,
        TransparencyApp: 'TRANSPARENCY_APP' as ModuleEnum,
        PerformanceApp: 'PERFORMANCE_APP' as ModuleEnum,
        BcmApp: 'BCM_APP' as ModuleEnum
    };
    export type TypeEnum = 'KPI' | 'KRI' | 'KCI' | 'KQI';
    export const TypeEnum = {
        Kpi: 'KPI' as TypeEnum,
        Kri: 'KRI' as TypeEnum,
        Kci: 'KCI' as TypeEnum,
        Kqi: 'KQI' as TypeEnum
    };
    export type StatusEnum = 'DRAFT' | 'MONITOR' | 'RETIRED';
    export const StatusEnum = {
        Draft: 'DRAFT' as StatusEnum,
        Monitor: 'MONITOR' as StatusEnum,
        Retired: 'RETIRED' as StatusEnum
    };
    export type PriorityEnum = 'LESS_THAN' | 'MORE_THAN';
    export const PriorityEnum = {
        LessThan: 'LESS_THAN' as PriorityEnum,
        MoreThan: 'MORE_THAN' as PriorityEnum
    };
    export type CalculationTypeEnum = 'MANUAL' | 'ENGAGEMENT' | 'AGGREGATION';
    export const CalculationTypeEnum = {
        Manual: 'MANUAL' as CalculationTypeEnum,
        Engagement: 'ENGAGEMENT' as CalculationTypeEnum,
        Aggregation: 'AGGREGATION' as CalculationTypeEnum
    };
    export type CampaignScoreEnum = 'SUM' | 'AVG' | 'MIN' | 'MAX';
    export const CampaignScoreEnum = {
        Sum: 'SUM' as CampaignScoreEnum,
        Avg: 'AVG' as CampaignScoreEnum,
        Min: 'MIN' as CampaignScoreEnum,
        Max: 'MAX' as CampaignScoreEnum
    };
}


