/**
 * Sightflare Service
 * Rest Api for Sightflare Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UploadBody { 
    idOrPath?: string;
    description?: string;
    versionName?: string;
    location?: UploadBody.LocationEnum;
}
export namespace UploadBody {
    export type LocationEnum = 'PATH' | 'ID';
    export const LocationEnum = {
        Path: 'PATH' as LocationEnum,
        Id: 'ID' as LocationEnum
    };
}


