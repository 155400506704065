import { Component, ComponentRef, OnInit, Type, ViewContainerRef } from '@angular/core';
import { FilterItem, MessageKeys } from '@shared/classes';
import { LinkedRelationItem } from '@shared/classes/model/backend/audit/model/linkedRelationItem';
import { BaseRequestControllerService } from '@shared/services/api/base-request-controller.service';
import { ToastService } from '@shared/services/toast.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-base-form-popup',
  templateUrl: './base-form-popup.component.html',
  styleUrls: ['./base-form-popup.component.scss'],
})
export class BaseFormPopupComponent implements OnInit {
  viewForm: Type<any>;
  component: ComponentRef<any>;
  dataService: BaseRequestControllerService<any>;
  filters: FilterItem[] = [];
  selectedRows: any[] = [];
  patchData: boolean = true;
  formData: any = {};
  linkedManualRelations: LinkedRelationItem[];
  options: any;
  constructor(
    public viewContainerRef: ViewContainerRef,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public toast: ToastService
  ) {
    this.viewForm = this.config?.data?.dynamicViewComponent;
    this.dataService = this.config?.data?.dataService;
    this.filters = [...this.config?.data?.filters];
    this.selectedRows = this.config?.data?.selectedRows;
    this.patchData = this.config?.data?.patchData ?? this.patchData;
    this.formData = this.config?.data?.formData ?? this.formData;
    this.options = this.config?.data?.options ?? this.options;
    this.linkedManualRelations = this.config?.data?.linkedManualRelations ?? this.linkedManualRelations;
  }

  ngOnInit(): void {}

  onFormSubmit(data: any) {
    if (this.patchData) {
      if (this.selectedRows && this.selectedRows.length > 0)
        this.filters.push({ property: 'id', operation: 'IN', value: this.selectedRows.map((x) => x.id) });
      this.dataService?.patchUpdate({ filters: this.filters, updateItems: data }).subscribe({
        next: (res) => {
          this.toast.success(MessageKeys.success, MessageKeys.updateAllMatchingRows);
          this.ref.close(true);
        },
      });
    } else {
      this.onSubmit(data);
    }
  }
  onSubmit(data: any) {
    this.ref.close(data);
  }
  onClose() {
    this.ref.close(false);
  }
}
