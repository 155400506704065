import { Injectable } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { DataTypeEnum, IFieldDefinition } from '@shared/classes';
import { BasicInputComponent } from '@shared/components/ui/input-fields/basic-input/basic-input.component';
import { DateInputComponent } from '@shared/components/ui/input-fields/date-input/date-input.component';
import { TextEditorComponent } from '@shared/components/ui/input-fields/text-editor/text-editor.component';
import { BaseFieldDefinitionsService } from '@shared/services';

@Injectable({
  providedIn: 'root',
})
export class AuthorityMappingService extends BaseFieldDefinitionsService {
  recordFields: IFieldDefinition[] = [
    {
      name: 'Name',
      key: 'name',
      dataType: DataTypeEnum.Text,
      required: false,
      showInTable: true,
      description: 'Name of the record',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
      formField: {
        componentType: BasicInputComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Enter a name',
          control: new FormControl('', Validators.required),
          dataType: DataTypeEnum.Text,
          showLabelInViewMode: false,
        },
      },
    },
    {
      name: 'Alias Name',
      key: 'aliasName',
      dataType: DataTypeEnum.Text,
      required: false,
      showInTable: true,
      description: 'Alias Name of the record',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
      formField: {
        componentType: BasicInputComponent,
        options: {
          label: '',
          name: '',
          control: new FormControl('', Validators.required),
          dataType: DataTypeEnum.Text,
          showLabelInViewMode: false,
        },
      },
    },
    {
      name: 'Version',
      key: 'versionName',
      dataType: DataTypeEnum.Text,
      required: false,
      showInTable: true,
      description: 'Version of the record',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
      formField: {
        componentType: BasicInputComponent,
        options: {
          label: '',
          name: '',
          control: new FormControl('', Validators.required),
          dataType: DataTypeEnum.Text,
          showLabelInViewMode: false,
        },
      },
    },
    {
      name: 'Unique Id',
      key: 'uniqueId',
      dataType: DataTypeEnum.Text,
      required: false,
      showInTable: true,
      description: 'Unique Id of the record',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
      formField: {
        componentType: BasicInputComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Enter a name',
          control: new FormControl('', Validators.required),
          dataType: DataTypeEnum.Text,
          showLabelInViewMode: false,
        },
      },
    },
    {
      name: 'Valid From',
      key: 'validFrom',
      dataType: DataTypeEnum.DateLong,
      required: false,
      showInTable: true,
      description: 'Record is valid from',
      tableDefinition: {
        filter: {
          type: 'date',
          matchMode: 'dateBefore',
        },
      },
      formField: {
        componentType: DateInputComponent,
        options: {
          label: '',
          name: '',
          control: new FormControl(null),
        },
      },
    },
    {
      name: 'Valid To',
      key: 'validTo',
      dataType: DataTypeEnum.DateLong,
      required: false,
      showInTable: true,
      description: 'Record is valid to',
      tableDefinition: {
        filter: {
          type: 'date',
          matchMode: 'dateBefore',
        },
      },
      formField: {
        componentType: DateInputComponent,
        options: {
          label: '',
          name: '',
          control: new FormControl(null),
        },
      },
    },
    {
      name: 'Description',
      key: 'description',
      dataType: DataTypeEnum.LongWithHoverText,
      required: false,
      showInTable: false,
      description: 'Description of the record',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
      useInBulk: true,
      formField: {
        componentType: TextEditorComponent,
        options: {
          inputOptions: {
            textEditorInput: {
              advanced: true
            }
          },
          label: '',
          name: '',
          control: new FormControl(''),
          dataType: DataTypeEnum.Text,
          showLabelInViewMode: false,
        },
      },
    },
    {
      name: 'External References',
      key: 'externalReferences',
      dataType: DataTypeEnum.Text,
      required: false,
      showInTable: true,
      description: 'External References Id of the record',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
  ];
  constructor() {
    super();
    this.setAllFields();
  }
}
