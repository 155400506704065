<app-input-view-switch
  [showLabelInViewMode]="showLabelInViewMode"
  [mode]="viewMode"
  [data]="data || control?.value?.label || control?.value?.name || control?.value?.value || control?.value"
  [dataType]="dataType"
  [label]="label"
  [keepValueCallback]="setFieldResetable.bind(this)"
  [resetFieldCheckVisable]="resetFieldCheckVisable"
  [resetableChecked]="resetable"
>
  <div class="field" [class.mb-1]="control?.dirty && this.control.errors">
    <span
      class="relative flex flex-row md:flex-nowrap flex-wrap gap-1 w-full algin-items-start"
      [class.p-float-label]="floatLabel"
      [class.p-input-icon-left]="leftIcon"
      [class.p-input-icon-right]="rightIcon"
    >
      @if (!floatLabel) {
        @if (label && !instanceInFormRepeater) {
          <label [for]="name || label" class="md:w-12rem md:max-w-12rem md:min-w-12rem w-full input-field-label"
            >{{ label }}
            @if (isControlRequired) {
              <span class="text-red-500"> *</span>
            }
            @if (resetCheckTemplate) {
              <br />
              <ng-container *ngTemplateOutlet="resetCheckTemplate"></ng-container>
            } @else if (resetFieldCheckVisable) {
              <br />
              <app-reset-switch-input
                [checked]="!resetable"
                (onChange)="setFieldResetable($event)"
              ></app-reset-switch-input>
            }
          </label>
        }
      }

      <p-autoComplete
        [class.hide-selection]="!isFocused"
        [inputStyle]="isFocused ? null : { color: 'transparent', userSelect: 'none' }"
        (onFocus)="onFocusFilter($event)"
        (focusout)="onLoseFocusFilter($event)"
        [(ngModel)]="searchModel"
        [ngModelOptions]="{ standalone: true }"
        class="w-full"
        [class.ng-invalid]="fControl.invalid"
        [class.ng-dirty]="fControl.dirty"
        styleClass="pl-0 pr-0 w-full"
        inputStyleClass="w-full"
        [dropdown]="dropdown"
        (onSelect)="onSelectItem($event)"
        (onUnselect)="onSelectItem($event)"
        [suggestions]="items"
        [field]="optionLabel"
        (completeMethod)="onCompleteMethod($event)"
        [multiple]="multi"
        [inputId]="name"
        [showClear]="false"
        [placeholder]="placeholder"
        [lazy]="lazy"
        (onLazyLoad)="onLazyLoadCommand($event)"
        [appendTo]="appendTo"
        [dataKey]="dataKey"
        [dropdownIcon]="loading ? 'pi pi-spin pi-spinner' : 'pi pi-chevron-down'"
        [virtualScroll]="virtualScroll"
        [virtualScrollItemSize]="virtualScrollItemSize"
        [completeOnFocus]="completeOnFocus"
        [forceSelection]="true"
        [group]="group"
        [disabled]="fControl.disabled"
      >
        <!-- <ng-template let-option
        pTemplate="loader">
        T
      </ng-template>
      <ng-template let-option
        pTemplate="loadingicon">
        T
      </ng-template> -->
        <!-- <ng-template let-option
      pTemplate="item">
      <div class="flex flex-1 gap-2">
        <app-badge-item [styleClass]="'text-xs'"
          [status]="option?.recordStatus || 'cyan'"
        [text]="option.code"></app-badge-item>
        <div>{{option[optionLabel]}}</div>
      </div>
    </ng-template> -->
        <ng-template let-group pTemplate="group">
          <div class="flex align-items-center gap-1">
            @if (group?.icon) {
              <i [class]="group?.icon"></i>
            }
            <span>{{ group[optionLabel] }}</span>
          </div>
        </ng-template>
        <ng-template let-item pTemplate="item">
          <ng-container
            *ngTemplateOutlet="itemTemplate ? itemTemplate : defaultItemTemplate; context: { $implicit: item }"
          ></ng-container>
        </ng-template>
      </p-autoComplete>
      @if (floatLabel) {
        @if (label && !instanceInFormRepeater) {
          <label [for]="name || label" class="md:w-12rem md:max-w-12rem md:min-w-12rem w-full input-field-label"
            >{{ label }}
            @if (isControlRequired) {
              <span class="text-red-500"> *</span>
            }
            @if (resetCheckTemplate) {
              <br />
              <ng-container *ngTemplateOutlet="resetCheckTemplate"></ng-container>
            } @else if (resetFieldCheckVisable) {
              <br />
              <app-reset-switch-input
                [checked]="!resetable"
                (onChange)="setFieldResetable($event)"
              ></app-reset-switch-input>
            }
          </label>
        }
      }

      <!-- <label *ngIf="floatLabel && label" >{{label}}<span *ngIf="isControlRequired" class="text-red-500"> *</span></label> -->
      <div
        class="p-inputtext flex flex-column gap-2 inner-absolute-input-value"
        [style]="
          'background:transparent; width:fit-content; max-width:calc(100% - (41px + ' +
          (label ? '12rem' : '0rem') +
          ')); position:absolute; height:100%;'
        "
        [class.left-0]="!label"
      >
        @if (multi) {
          @for (item of editModalControl?.value; track item) {
            @if (item) {
              <app-code-navigation [showTooltip]="true" [data]="getViewData()"></app-code-navigation>
            }
          }
        } @else {
          @if (editModalControl?.value && !isFocused) {
            <app-code-navigation [showTooltip]="true" [data]="getViewData()"></app-code-navigation>
          }
        }
      </div>
      @if (editModalControl?.value && !isFocused && !control.disabled) {
        <i
          (click)="onClear()"
          class="pi pi-times cursor-pointer right-0"
          style="width: fit-content; position: absolute; height: fit-content; bottom: 0; padding: 0.6rem"
        ></i>
      }
    </span>
  </div>
  @if (control?.dirty) {
    @if (control?.hasError('required')) {
      <small class="p-error p-invalid">{{ label }} {{ 'is Required' }}</small>
    }
    @if (control?.hasError('email')) {
      <small class="p-error p-invalid">Enter a Valid Email</small>
    }
    @if (control?.hasError('pattern')) {
      <small class="p-error p-invalid">{{ label }} {{ patternError }}</small>
    }
    @if (control?.hasError('minlength')) {
      <small class="p-error p-invalid">
        {{ label }} must have a minimum length of {{ control?.errors?.minlength?.requiredLength }}
      </small>
    }
    @if (control?.hasError('maxlength')) {
      <small class="p-error p-invalid">
        {{ label }} must have a maximum length of {{ control?.errors?.maxlength?.requiredLength }}
      </small>
    }
    @if (control?.hasError('min')) {
      <small class="p-error p-invalid">
        {{ label }} must be greater than or equal to {{ control?.errors?.min?.min }}
      </small>
    }
    @if (control?.hasError('max')) {
      <small class="p-error p-invalid">
        {{ label }} must be less than or equal to {{ control?.errors?.max?.max }}
      </small>
    }
    @if (customError && control?.invalid) {
      <small class="p-error p-invalid">{{ customErrorLabel }}</small>
    }
  }
  @if (helpText) {
    <small class="text-500 block">{{ helpText }}</small>
  }
</app-input-view-switch>
<ng-template #defaultItemTemplate let-item>
  <!-- Default item template -->
  <span>{{ item?.[optionLabel] }}</span>
</ng-template>
