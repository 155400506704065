/**
 * Sightflare Service
 * Rest Api for Sightflare Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ApiResponseControlGroupEvent { 
    status?: number;
    data?: ApiResponseControlGroupEvent.DataEnum;
    error?: boolean;
    errorMessage?: string;
}
export namespace ApiResponseControlGroupEvent {
    export type DataEnum = 'ADD_EXCEPTION' | 'REMOVE_EXCEPTION' | 'TO_END';
    export const DataEnum = {
        AddException: 'ADD_EXCEPTION' as DataEnum,
        RemoveException: 'REMOVE_EXCEPTION' as DataEnum,
        ToEnd: 'TO_END' as DataEnum
    };
}


