import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BaseResponse, PolicyBody, RequestHandlerOptions } from '@shared/classes';
import { ApiResponseRemediationEvent, Remediation } from '@shared/classes/model/backend/issue';
import { BaseRequestControllerWithRuleService } from '@shared/services/api/custom-api-services/base-request-controller-with-rule.service';
import { DataService } from '@shared/services/api/data.service';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RemediationPlanDataService extends BaseRequestControllerWithRuleService<
  Remediation,
  ApiResponseRemediationEvent.DataEnum
> {
  constructor(
    private dataService: DataService,
    private router: Router
  ) {
    super(dataService, environment.issue, '/remediations', [
      'id',
      'code',
      'tags',
      'tagsCodes',
      'lockedBy',
      'lockedForEdit',
      'lockedUntil',
      'recordStatus',
      'creatorName',
      'lastModifierName',
      'creationDate',
      'lastModificationDate',
      'name',
      'description',
      'owner',
      'approvers',
      'approvedBy',
      'issues',
      'progress',
      'status',
      'startDate',
      'actualStartDate',
      'expectedDueDate',
      'actualCloseDate',
    ]);
  }

  apiOptions: RequestHandlerOptions = {
    showLoading: true,
    showMsg: true,
  };

  navigateToListPage() {
    this.router.navigateByUrl(`issue/remediation/list`);
  }

  public getApprove(policyIdOrCode) {
    const newLocal = this.url + `/${policyIdOrCode}/approve`;
    return this.dataService.getData<BaseResponse<PolicyBody>>(newLocal, this.apiOptions) as Observable<
      BaseResponse<any>
    >;
  }
}
