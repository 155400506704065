import { Component, Input, OnInit } from '@angular/core';
import { DataTypeEnum, DynamicComponentBase, ITablePageable, fab, far, fas } from '@shared/classes';
import { concat } from 'lodash-es';
import { PrimeIcons } from 'primeng/api';

@Component({
  selector: 'app-icon-selector',
  templateUrl: './icon-selector.component.html',
  styleUrls: ['./icon-selector.component.scss'],
  providers: [{ provide: DynamicComponentBase, useExisting: IconSelectorComponent }],
})
export class IconSelectorComponent extends DynamicComponentBase implements OnInit {
  @Input() multi: boolean = false;
  @Input() optionValue: string = 'value';
  @Input() appendTo: string = null;
  constructor() {
    super();
  }
  items: any[] = concat(
    fas,
    far,
    fab,
    Object.keys(PrimeIcons).map((x) => {
      return { value: PrimeIcons[x], label: x };
    })
  );
  pageInfo: ITablePageable = new ITablePageable();
  isLoading: boolean = false;
  dataType: DataTypeEnum = DataTypeEnum.Icon;
  fallbackIcon = 'pi pi-info-circle';

  ngOnInit(): void {}

  setInputOptions() {
    this.multi = this.inputOptions?.dropDownInput?.multi ?? this.multi;
    this.appendTo = this.inputOptions?.dropDownInput?.appendTo ?? this.appendTo;
    this.items = concat(
      fas,
      far,
      fab,
      Object.keys(PrimeIcons).map((x) => {
        return { value: PrimeIcons[x], label: x };
      })
    );
    this.dataType = DataTypeEnum.Icon;
    this.fallbackIcon = 'pi pi-info-circle';
  }
  onIconPickerSelect(icon) {
    this.fControl.setValue(icon);
  }
}
