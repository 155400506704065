import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { ApiResponseAuditUniverseEvent, AuditUniverse } from '@shared/classes/model/backend/audit';
import { BaseRequestControllerWithRuleService } from '@shared/services/api/custom-api-services/base-request-controller-with-rule.service';

import { DataService } from '@shared/services/api/data.service';

@Injectable({
  providedIn: 'root',
})
export class AuditUniverseDataService extends BaseRequestControllerWithRuleService<
  AuditUniverse,
  ApiResponseAuditUniverseEvent.DataEnum
> {
  constructor(
    private dataService: DataService,
    private router: Router
  ) {
    super(dataService, environment.audit, '/audit-universes', [
      'id',
      'code',
      'tags',
      'tagsCodes',
      'description',
      'name',
      'label',
      'lockedBy',
      'lockedForEdit',
      'lockedUntil',
      'userAccessLevel',
      'recordStatus',
      'creatorName',
      'lastModifierName',
      'creationDate',
      'lastModificationDate',
      'entities',
      'approver',
      'auditUniverseStatus',
    ]);
  }

  navigateToListPage() {
    this.router.navigateByUrl(`audit/audit-universe/list`);
  }
}
