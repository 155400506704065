import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { DataTypeEnum, DynamicComponentBase } from '@shared/classes';

@Component({
  selector: 'app-time-input',
  templateUrl: './time-input.component.html',
  styleUrls: ['./time-input.component.scss'],
  providers: [{ provide: DynamicComponentBase, useExisting: TimeInputComponent }],
})
export class TimeInputComponent extends DynamicComponentBase implements OnInit {
  dataType: DataTypeEnum = DataTypeEnum.TimeView;
  timeViewOptions;
  @Output() onFocus: EventEmitter<any> = new EventEmitter();

  timeStringFControl: FormControl = new FormControl();

  timeString: string = '';
  effectiveTime: number = 0;

  private _year: number = 365;
  private _month: number = 30;
  private _week: number = 7;
  private _day: number = 8;
  private _hour: number = 60;

  @Input()
  set year(value: number) {
    if (value) {
      this._year = value;
    }
  }
  get year(): number {
    return this._year;
  }

  @Input()
  set month(value: number) {
    if (value) {
      this._month = value;
    }
  }
  get month(): number {
    return this._month;
  }

  @Input()
  set week(value: number) {
    if (value) {
      this._week = value;
    }
  }
  get week(): number {
    return this._week;
  }

  @Input()
  set day(value: number) {
    if (value) {
      this._day = value;
    }
  }
  get day(): number {
    return this._day;
  }

  @Input()
  set hour(value: number) {
    if (value) {
      this._hour = value;
    }
  }
  get hour(): number {
    return this._hour;
  }

  minutesInHour: number;
  minutesInDay: number;
  minutesInWeek: number;
  minutesInMonth: number;
  minutesInYear: number;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.minutesInHour = this.hour;
    this.minutesInDay = this.day * this.minutesInHour;
    this.minutesInWeek = this.week * this.minutesInDay;
    this.minutesInMonth = this.month * this.minutesInDay;
    this.minutesInYear = this.year * this.minutesInDay;
    this.timeViewOptions = {
      year: this.year,
      month: this.month,
      week: this.week,
      day: this.day,
      hour: this.hour,
    };

    this.timeStringFControl.setValidators([
      Validators.pattern(
        '^(\\d{1,2}y\\s*)?^(\\d{1,2}M\\s*)?(\\d{1,2}w\\s*)?(\\d{1,4}d\\s*)?(\\d{1,4}h\\s*)?(\\d{1,2}m\\s*)?$'
      ),
    ]);
    this.patternError = 'must use the format: 1M 2w 4d 6h 45m';
    this.placeholder = 'format: 2w 4d 6h 45m';
    if (this.fControl?.getRawValue() !== null) {
      if (this.fControl?.getRawValue()?.value) {
        this.timeString = this.fControl?.getRawValue().value;
        this.timeStringFControl.patchValue(this.timeString, { emitEvent: false });
      }
      if (this.fControl?.getRawValue()?.effectiveTime) {
        this.effectiveTime = this.fControl?.getRawValue().effectiveTime;
      }
    }
    this.subs.sink = this.fControl.valueChanges.subscribe((value) => {
      if (value?.value) {
        this.timeString = value;
      }
      if (value?.effectiveTime) {
        this.effectiveTime = value?.effectiveTime;
      }
    });
    this.subs.sink = this.timeStringFControl.valueChanges.subscribe((value) => {
      this.timeString = value;
      this.effectiveTime = this.extractMinutes(this.timeString);
      if (this.timeString && this.effectiveTime) {
        const obj = { value: this.timeString, effectiveTime: this.effectiveTime };
        this.fControl.patchValue(obj);
      } else {
        this.fControl.patchValue(null);
      }
    });
  }

  extractMinutes(input: string) {
    const units = input.trim().split(' ');
    let totalMinutes = 0;
    try {
      for (let i = 0; i < units.length; i++) {
        const element = units[i];
        if (element) {
          const value = parseInt(element.substring(0, element.length - 1));
          const unit = element[element.length - 1];
          switch (unit) {
            case 'y':
              totalMinutes += value * this.minutesInYear;
              break;
            case 'M':
              totalMinutes += value * this.minutesInMonth;
              break;
            case 'w':
              totalMinutes += value * this.minutesInWeek;
              break;
            case 'd':
              totalMinutes += value * this.minutesInDay;
              break;
            case 'h':
              totalMinutes += value * this.minutesInHour;
              break;
            case 'm':
              totalMinutes += value;
              break;
            default:
              break;
          }
        }
      }
    } catch (e) {
      console.error('Error while extracting minutes from time string');
    }
    return totalMinutes;
  }

  setInputOptions() {}
  onFocusChange(event) {
    this.onFocus.emit(event);
  }
}
