import { Component, ContentChild, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DataTypeEnum, getOptionsFromStringArray, isNullObj } from '@shared/classes';
import { DynamicComponentBase } from '@shared/classes/view/DynamicComponentBase';
import { LazyLoadEvent } from 'primeng/api';
import { PickList } from 'primeng/picklist';
import { Subject, SubscriptionLike, debounceTime, distinctUntilChanged } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-drop-down-input',
  templateUrl: './drop-down-input.component.html',
  styleUrls: ['./drop-down-input.component.scss'],
  providers: [{ provide: DynamicComponentBase, useExisting: DropDownInputComponent }],
})
export class DropDownInputComponent extends DynamicComponentBase implements OnInit {
  sourceElements: any[] = [];
  targetElements: any[] = [];
  dataType: DataTypeEnum = DataTypeEnum.Text;
  @Input() editable = false;
  @Input() multi = false;
  private _items: any[] = [];
  currentChangesSub: SubscriptionLike;
  @Input() set items(items: any[]) {
    this._items = items;
    this.calculateSourceAndTargetElements();
  }
  @Input() optionLabel: string;
  @Input() showClear = true;
  @Input() optionValue: string; //@Note: only 1 field is bindable through primeng, if we need to modify the object a lot more then consider changing the formcontrol into onSelect changes
  private _badgeView = false;
  @Input() set badgeView(badgeView) {
    this._badgeView = badgeView;
    this.dataType = this.badgeView ? DataTypeEnum.Badge : DataTypeEnum.Text;
  }
  get badgeView() {
    return this._badgeView;
  }
  @Input() viewType: DataTypeEnum = DataTypeEnum.Text;
  @Input() dataKey = null;

  @Input() virtualScroll: boolean; //@TODO the implementation for virtual scroll should be remapped to handle pagination
  @Input() virtualScrollItemSize: number = 38;
  @Input() lazy: boolean = false;
  @Input() onLazyLoadCommand: Function = (event: LazyLoadEvent) => {};
  @Input() onFilterCommand: Function = (event: any) => {};
  @Input() loading: boolean = false;
  @Input() customFilter: boolean = false;
  @Input() showFilter: boolean = true;
  @Input() showStatusBadge: boolean = true;
  @Input() showStatusBadgeStatusField: string = 'recordStatus';
  @Input() showStatusBadgeTextField: string = 'code';
  @Input() appendTo: string = null;
  @Input() linkedControl: FormControl = null;
  @Input() linkedControlValue: any = null;
  @Input() useCustomBadgeMode: boolean = false;
  @Input() customBadgeColorField: string = 'color';
  @Input() customBadgeIconField: string = 'icon';
  @Input() group: boolean = false;
  @Input() listBoxMode: boolean = false;
  @Input() pickListMode: boolean = false;
  @Input() allowDuplicates: boolean = false;
  @Input() scored: boolean = false;
  @Input() showScore: boolean = false;
  @Input() passingScore: number = 0;
  dataTypes = DataTypeEnum;

  public filterValue: string;
  filterValueUpdate = new Subject<string>();
  @ContentChild('itemTemplate') itemTemplate: TemplateRef<any>;
  @ContentChild('preItemTemplate') preItemTemplate: TemplateRef<any>;
  @ContentChild('postItemTemplate') postItemTemplate: TemplateRef<any>;
  @ContentChild('selectedItemTemplate') selectedItemTemplate: TemplateRef<any>;
  @ContentChild('resetCheckTemplate') resetCheckTemplate: TemplateRef<any>;
  @ViewChild('pickList') pickList: PickList;

  typesControl: FormControl = new FormControl(null);
  @Input() useTypesSelector: boolean = true;
  private _listOfTypes: any[] = [];
  get listOfTypes() {
    return this._listOfTypes;
  }
  @Input() set listOfTypes(listOfTypes: any[]) {
    this._listOfTypes = getOptionsFromStringArray(listOfTypes);
    this.typesControl?.patchValue(listOfTypes);
  }
  @Output() onSelectTypes: EventEmitter<any> = new EventEmitter<any>();
  @Output() onChangesObject: EventEmitter<any> = new EventEmitter();

  constructor() {
    super();
    this.subs.sink = this.filterValueUpdate.pipe(debounceTime(500), distinctUntilChanged()).subscribe((value) => {
      this.onFilterCommand(value);
    });
    const comparer = (prevValue: string[], currValue: string[]) => {
      // Custom comparison logic
      // Return true if the values are considered equal, false otherwise
      return prevValue.every((x) => currValue.includes(x)) && currValue.every((x) => prevValue.includes(x)); // Example: Strict equality comparison
    };
    this.subs.sink = this.typesControl?.valueChanges
      ?.pipe(debounceTime(500), distinctUntilChanged(comparer))
      .subscribe((values) => {
        this.onSelectTypes.emit(values);
      });
  }
  moveRight() {
    this.pickList.moveRight();
  }
  moveLeft() {
    this.pickList.moveLeft();
  }
  moveAllRight() {
    this.pickList.moveAllRight();
  }
  moveAllLeft() {
    this.pickList.moveAllLeft();
  }
  onPickListKeyDown(event: KeyboardEvent) {
    if (event.key === 'ArrowRight' && event.shiftKey) {
      // Handle shift + right arrow key press

      this.moveAllRight();
    } else if (event.key === 'ArrowLeft' && event.shiftKey) {
      // Handle shift + left arrow key press

      this.moveAllLeft();
    } else if (event.key === 'ArrowRight') {
      // Handle right arrow key press

      this.moveRight();
    } else if (event.key === 'ArrowLeft') {
      // Handle left arrow key press

      this.moveLeft();
    }
  }
  ngOnInit(): void {
    this.registerChanges();
  }
  onChangeObject(event) {
    if (this.optionValue === undefined || this.multi) {
      this.onChangesObject.emit(event?.value);
    } else {
      const val = this.items?.find((item) => item?.[this.optionValue] === event?.value);
      this.onChangesObject.emit(val);
    }
  }
  getFormattedItemKey(item: any, dataKey: string = this.dataKey, optionValue: string = this.optionValue) {
    if (isNullObj(item)) return null;
    return dataKey ? item?.[dataKey] : optionValue ? item : typeof item == 'string' ? item : item?.code;
  }
  getItemKey(item: any, dataKey: string = this.dataKey, optionValue: string = this.optionValue) {
    if (isNullObj(item)) return null;
    return dataKey ? item?.[dataKey] : optionValue ? item?.[optionValue] : typeof item == 'string' ? item : item?.code;
  }
  checkIfItemIncluded(
    val: any,
    itemKey: any,
    useGetFormattedKey: boolean = true,
    multi: boolean = this.multi,
    dataKey: string = this.dataKey,
    optionValue: string = this.optionValue
  ) {
    if (isNullObj(val)) return false;
    if (isNullObj(itemKey)) return false;
    return multi
      ? (val as any[])?.find(
          (x) =>
            (useGetFormattedKey
              ? this.getFormattedItemKey(x, dataKey, optionValue)
              : this.getItemKey(x, dataKey, optionValue)) == itemKey
        )
        ? true
        : false
      : (useGetFormattedKey
            ? this.getFormattedItemKey(val, dataKey, optionValue)
            : this.getItemKey(val, dataKey, optionValue)) == itemKey
        ? true
        : false;
  }
  setInputOptions() {
    this.multi = this.inputOptions?.dropDownInput?.multi ?? this.multi;
    this.items = this.inputOptions?.dropDownInput?.items ?? this.items;
    this.optionLabel = this.inputOptions?.dropDownInput?.optionLabel ?? this.optionLabel;
    this.showClear = this.inputOptions?.dropDownInput?.showClear ?? this.showClear;
    this.optionValue = this.inputOptions?.dropDownInput?.optionValue ?? this.optionValue;
    this.badgeView = this.inputOptions?.dropDownInput?.badgeView ?? this.badgeView;
    this.dataKey = this.inputOptions?.dropDownInput?.dataKey ?? this.dataKey;

    this.dataType = this.badgeView ? DataTypeEnum.Badge : DataTypeEnum.Text;

    this.virtualScroll = this.inputOptions?.dropDownInput?.virtualScroll ?? this.virtualScroll;
    this.virtualScrollItemSize = this.inputOptions?.dropDownInput?.virtualScrollItemSize ?? this.virtualScrollItemSize;
    this.lazy = this.inputOptions?.dropDownInput?.lazy ?? this.lazy;
    this.loading = this.inputOptions?.dropDownInput?.loading ?? this.loading;
    this.onLazyLoadCommand = this.inputOptions?.dropDownInput?.onLazyLoadCommand ?? this.onLazyLoadCommand;
    this.onFilterCommand = this.inputOptions?.dropDownInput?.onFilterCommand ?? this.onFilterCommand;
    this.customFilter = this.inputOptions?.dropDownInput?.customFilter ?? this.customFilter;
    this.showFilter = this.inputOptions?.dropDownInput?.showFilter ?? this.showFilter;
    this.appendTo = this.inputOptions?.dropDownInput?.appendTo ?? this.appendTo;
    this.showStatusBadge = this.inputOptions?.dropDownInput?.showStatusBadge ?? this.showStatusBadge;
    this.showStatusBadgeStatusField =
      this.inputOptions?.dropDownInput?.showStatusBadgeStatusField ?? this.showStatusBadgeStatusField;
    this.showStatusBadgeTextField =
      this.inputOptions?.dropDownInput?.showStatusBadgeTextField ?? this.showStatusBadgeTextField;
    this.useCustomBadgeMode = this.inputOptions?.dropDownInput?.useCustomBadgeMode ?? this.useCustomBadgeMode;
    this.customBadgeColorField = this.inputOptions?.dropDownInput?.customBadgeColorField ?? this.customBadgeColorField;
    this.customBadgeIconField = this.inputOptions?.dropDownInput?.customBadgeIconField ?? this.customBadgeIconField;
    this.scored = this.inputOptions?.dropDownInput?.scored ?? this.scored;
    this.showScore = this.inputOptions?.dropDownInput?.showScore ?? this.showScore;
    this.passingScore = this.inputOptions?.dropDownInput?.passingScore ?? this.passingScore;

    this.linkedControl = this.inputOptions?.linkedControl ?? this.linkedControl;
    this.linkedControlValue = this.inputOptions?.linkedControlValue ?? this.linkedControlValue;
    // if(this.inputOptions?.dropDownInput?.defaultOption){
    //     if(this.multi){
    //         this.fControl?.patchValue([this.inputOptions?.dropDownInput?.defaultOption])
    //     }else{
    //         this.fControl?.patchValue(this.inputOptions?.dropDownInput?.defaultOption)
    //     }
    // }
    if (this.linkedControl) {
      if (this.currentChangesSub) {
        this.currentChangesSub.unsubscribe();
      }
      this.currentChangesSub = this.linkedControl.valueChanges.subscribe((value) => {
        this.linkedControlValue = value;
      });
      this.subs.sink = this.currentChangesSub;
    }
    this.formatGetItems = this.inputOptions?.dropDownInput?.formatGetItems ?? this.formatGetItems;
  }
  logData(event) {}
  @Input() formatGetItems(items: any[], linkedControlValue?: any): any[] {
    return items;
  }
  get items() {
    return this.formatGetItems(this._items, this.linkedControlValue);
  }
  calculateSourceAndTargetElements() {
    const val = this?.fControl?.getRawValue();
    if (!isNullObj(val)) {
      this.sourceElements = this.allowDuplicates
        ? [...this.items]
        : this.items?.filter((item) => !this.checkIfItemIncluded(val, this.getItemKey(item)));
      this.targetElements = this.allowDuplicates
        ? []
        : this.items
            ?.filter((item) => this.checkIfItemIncluded(val, this.getItemKey(item)))
            .map((x) => {
              return { ...x, _pickListTargetItem: true };
            });
      if (Array.isArray(val)) {
        val.forEach((element) => {
          const s = this.targetElements.find((item) =>
            this.dataKey
              ? item?.[this.dataKey] == element?.[this.dataKey]
              : this.optionValue
                ? item?.[this.optionValue] == element
                : item == element
          );
          if (!s) {
            this.targetElements.push(
              this.allowDuplicates && !element?.uid ? { ...element, uid: uuidv4(), _pickListTargetItem: true } : element
            );
          }
        });
      } else {
        const s = this.targetElements.find((item) =>
          this.dataKey
            ? item?.[this.dataKey] == val?.[this.dataKey]
            : this.optionValue
              ? item?.[this.optionValue] == val
              : item == val
        );
        if (!s) {
          this.targetElements.push(val);
        }
      }
    } else {
      this.sourceElements = [...this.items];
      this.targetElements = [];
    }
  }
  onPickListChange() {
    this.fControl?.patchValue(
      this.optionValue
        ? this.multi
          ? this.targetElements.map((x) => x?.[this.optionValue])
          : this.targetElements?.[0]?.[this.optionValue]
        : this.multi
          ? this.allowDuplicates
            ? this.targetElements.map((x) => {
                return { ...x, uid: uuidv4(), _pickListTargetItem: true };
              })
            : this.targetElements
          : this.targetElements?.[0]
    );
    this.onChangeObject({ value: this.multi ? this.targetElements : (this.targetElements?.[0] ?? null) });
  }
  registerChanges() {
    this.calculateSourceAndTargetElements();
    this.fControl.valueChanges.subscribe((res) => {
      this.calculateSourceAndTargetElements();
    });
  }
  logItem(item) {}
  removeItem(item, event) {
    event?.preventDefault();
    event?.stopPropagation();
    const ind = this.targetElements?.findIndex((x) =>
      this.checkIfItemIncluded(this.multi ? [item] : this.getItemKey(item), this.getItemKey(x))
    );
    if (ind != -1) this.targetElements?.splice(ind, 1);
    this.targetElements = [...this.targetElements];
    this.sourceElements.push({ ...item, _pickListTargetItem: false });
    this.sourceElements = [...this.sourceElements];
    this.onPickListChange();
  }
}
