/**
 * Sightflare Service
 * Rest Api for Sightflare Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UserOperationPrinciple { 
    name?: string;
    mode?: UserOperationPrinciple.ModeEnum;
    principleType?: UserOperationPrinciple.PrincipleTypeEnum;
}
export namespace UserOperationPrinciple {
    export type ModeEnum = 'ALL' | 'ONE';
    export const ModeEnum = {
        All: 'ALL' as ModeEnum,
        One: 'ONE' as ModeEnum
    };
    export type PrincipleTypeEnum = 'USER' | 'RESPONSIBILITY' | 'GROUP';
    export const PrincipleTypeEnum = {
        User: 'USER' as PrincipleTypeEnum,
        Responsibility: 'RESPONSIBILITY' as PrincipleTypeEnum,
        Group: 'GROUP' as PrincipleTypeEnum
    };
}


