import { Component, Input, OnInit } from '@angular/core';
import { BaseViewItem, DataTypeEnum, IProgressBar } from '@shared/classes';

@Component({
  selector: 'app-stacked-progress-bar-view',
  templateUrl: './stacked-progress-bar-view.component.html',
  styleUrls: ['./stacked-progress-bar-view.component.scss'],
})
export class StackedProgressBarViewComponent extends BaseViewItem<IProgressBar[]> implements OnInit {
  @Input() viewType: DataTypeEnum = DataTypeEnum.ProgressBarStacked;
  @Input() containerStyle: string;
  constructor() {
    super();
  }

  ngOnInit(): void {}
}
