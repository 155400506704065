import { Observable } from 'rxjs';
import { ActionTypesEnum, IViewMode } from './view-enums';

export enum ActionRole {
  Primary,
  Secondary,
}
export interface IAction {
  id: any;
  icon?: string;
  iconPos?: 'left' | 'right' | 'top' | 'bottom';
  label?: string;
  color?:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'info'
    | 'warning'
    | 'help'
    | 'danger'
    | 'red'
    | 'pink'
    | 'purple'
    | 'indigo'
    | 'blue'
    | 'cyan'
    | 'teal'
    | 'green'
    | 'yellow'
    | 'orange'
    | 'deep-orange'
    | 'brown'
    | 'gray'
    | 'deep-gray'
    | 'black'
    | 'white';
  buttonStyle?: 'raised' | 'rounded' | 'text' | 'raised-text' | 'outlined' | 'outlined-rounded' | 'rounded-text';
  badge?: string;
  badgeClass?: string;
  buttonClass?: string;
  loading$?: Observable<boolean>;
  size?: 'sm' | 'lg';
  type?: ActionTypesEnum;
  buttonType?: 'button' | 'submit' | 'reset';
  command?: (event?: any) => void;
  role?: ActionRole;
  status$?: Observable<boolean>;
  displayCommand?: (row: any) => boolean;
  visible?: boolean;
  passEvent?: boolean;
  permission?: string;
  viewMode$?: Observable<IViewMode>;
  tooltipText?: string;
  tooltipPosition?: 'top' | 'bottom' | 'left' | 'right';
  tooltipOptions?: any;
  extraData?: any;
  group?: { id: number; type: 'split' | 'list' | 'set' | 'speed' | 'dropdown' };
  items?: IAction[];
  separator?: boolean;
}
export enum ButtonColor {
  Primary = 'primary',
  Secondary = 'secondary',
  Success = 'success',
  Info = 'info',
  Warning = 'warning',
  Help = 'help',
  Danger = 'danger',
  'red' = 'red',
  'pink' = 'pink',
  'purple' = 'purple',
  'indigo' = 'indigo',
  'blue' = 'blue',
  'cyan' = 'cyan',
  'teal' = 'teal',
  'green' = 'green',
  'yellow' = 'yellow',
  'orange' = 'orange',
  'deep-orange' = 'deep-orange',
  'brown' = 'brown',
  'gray' = 'gray',
  'deep-gray' = 'deep-gray',
  'black' = 'black',
  'white' = 'white',
}
export enum ButtonStyle {
  Raised = 'raised',
  Secondary = 'rounded',
  Text = 'text',
  RaisedText = 'raised-text',
  Outlined = 'outlined',
  OutlinedRounded = 'outlined-rounded',
  RoundedText = 'rounded-text',
}
export enum ButtonListType {
  Split = 'split',
  List = 'list',
  Speed = 'speed',
  Set = 'set',
}
