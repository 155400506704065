import { Injectable } from '@angular/core';
import { IFieldDefinition } from '@shared/classes/view/AppFieldDefinition';
import { BaseFieldDefinitionsService } from '@shared/services/base-field-definitions.service';

@Injectable({
  providedIn: 'root',
})
export class AuditTrailMappingService extends BaseFieldDefinitionsService {
  recordFields: IFieldDefinition[] = [];

  constructor() {
    super();
    this.setAllFields();
  }
}
