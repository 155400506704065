import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DataTypeEnum, DynamicComponentBase } from '@shared/classes';

@Component({
  selector: 'app-suggestion-input',
  templateUrl: './suggestion-input.component.html',
  styleUrls: ['./suggestion-input.component.scss'],
  providers: [{ provide: DynamicComponentBase, useExisting: SuggestionInputComponent }],
})
export class SuggestionInputComponent extends DynamicComponentBase implements OnInit {
  dataType: DataTypeEnum = DataTypeEnum.Text;
  @Input() mode = 'decimal';
  @Input() showButtons = false;
  @Input() maxFractionDigits = 5;
  @Input() buttonLayout: 'stacked' | 'horizontal' | 'vertical' = 'stacked';
  @Input() incrementButtonClass = 'p-button-primary';
  @Input() decrementButtonClass = 'p-button-primary';
  @Input() incrementButtonIcon = 'pi pi-angle-up';
  @Input() decrementButtonIcon = 'pi pi-angle-down';
  @Input() min: number = undefined;
  @Input() max: number = undefined;
  @Output() onFocus: EventEmitter<any> = new EventEmitter();
  private _items = [];
  get items() {
    return this._items;
  }
  @Input() set items(items: any[]) {
    items.forEach((element) => {
      element.command = this.onItemSelect.bind(this, element.value);
    });
    this._items = items;
  }
  constructor() {
    super();
  }

  ngOnInit(): void {}
  setInputOptions() {
    this.mode = this.inputOptions?.numberInput?.mode ?? this.mode;
    this.showButtons = this.inputOptions?.numberInput?.showButtons ?? this.showButtons;
    this.maxFractionDigits = this.inputOptions?.numberInput?.maxFractionDigits ?? this.maxFractionDigits;
    this.buttonLayout = this.inputOptions?.numberInput?.buttonLayout ?? this.buttonLayout;
    this.incrementButtonClass = this.inputOptions?.numberInput?.incrementButtonClass ?? this.incrementButtonClass;
    this.decrementButtonClass = this.inputOptions?.numberInput?.decrementButtonClass ?? this.decrementButtonClass;
    this.incrementButtonIcon = this.inputOptions?.numberInput?.incrementButtonIcon ?? this.incrementButtonIcon;
    this.decrementButtonIcon = this.inputOptions?.numberInput?.decrementButtonIcon ?? this.decrementButtonIcon;
    this.min = this.inputOptions?.numberInput?.min ?? this.min;
    this.max = this.inputOptions?.numberInput?.max ?? this.max;
  }
  onFocusChange(event) {
    this.onFocus.emit(event);
  }
  onItemSelect(value) {
    this.control.patchValue(value);
  }
}
