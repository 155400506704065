import { DOCUMENT } from '@angular/common';
import {
  AfterContentInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  Inject,
  Injector,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR, NgControl, Validators } from '@angular/forms';
import { DynamicComponentBase, isNullObj } from '@shared/classes';

export const INPUT_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => RunAsInputFormControlComponent),
  multi: true,
};
@Component({
  selector: 'app-run-as-input-form-control',
  templateUrl: './run-as-input-form-control.component.html',
  styleUrl: './run-as-input-form-control.component.scss',
  providers: [INPUT_VALUE_ACCESSOR],
})
export class RunAsInputFormControlComponent
  extends DynamicComponentBase
  implements OnInit, AfterContentInit, OnChanges, ControlValueAccessor
{
  private ngControl: NgControl | null = null; // assign the current formControl to have access to all options ex: errors, dirty, hasValidator ...etc
  value: any; // end value of the control component
  public changed: (value: any) => void;
  public touched: () => void;
  public isDisabled: boolean;
  public formGroup = new FormGroup({
    withoutGroups: new FormControl(null, [Validators.required]),
    // withGroups: new FormControl(null),
    // mainPrinciple: new FormControl(null),
    // mainPrincipleName: new FormControl(null),
  });
  constructor(
    @Inject(DOCUMENT) private document: Document,
    public el: ElementRef,
    private cd: ChangeDetectorRef,
    private readonly injector: Injector
  ) {
    super();
  }

  setInputOptions(): void {
    // for dynamic generation of the input
  }
  ngOnInit(): void {
    this.ngControl = this.injector.get(NgControl, null, { optional: true });
  }
  ngAfterContentInit(): void {
    // for custom templates assignments, EX: pre-item-template
  }
  ngOnChanges(changes: SimpleChanges): void {
    //for checks on @Input() items changes
  }
  writeValue(value: any): void {
    // On control.patchValue
    this.value = value;
    if (!isNullObj(value)) {
      this.formGroup.patchValue(value);
    } else {
      this.formGroup.reset();
    }
    // this.cd.markForCheck(); // if ChangeDetectionStrategy is OnPush then it's needed
  }
  onInputValueChange(value: any) {
    this.value = { ...value };
    this.changed({ ...value });
  }
  registerOnChange(fn: any): void {
    this.changed = fn;
    // this.formGroup.valueChanges.subscribe(values=>{
    //     this.onInputValueChange(values);
    // })
  }
  registerOnTouched(fn: any): void {
    this.touched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
    // this.cd.markForCheck(); // if ChangeDetectionStrategy is OnPush then it's needed
  }
  onAfterViewInit(): void {
    this.afterViewInit();
  }
  afterViewInit(): void {
    this.formGroup.valueChanges.subscribe((values) => {
      this.onInputValueChange(values);
    });
  }
}
