import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DataTypeEnum } from '@shared/classes';
import { DynamicComponentBase } from '@shared/classes/view/DynamicComponentBase';

@Component({
  selector: 'app-check-box',
  templateUrl: './check-box.component.html',
  styleUrls: ['./check-box.component.scss'],
  providers: [{ provide: DynamicComponentBase, useExisting: CheckBoxComponent }],
})
export class CheckBoxComponent extends DynamicComponentBase implements OnInit {
  @Input() labelWidthFit = false;
  @Input() binary = true;
  @Input() value = null;
  @Input() inputId = null;
  @Input() messageWrapper:boolean = false;
  @Input() messageLabel = null;
  @Output() onCheckboxChange: EventEmitter<any> = new EventEmitter();

  dataType: DataTypeEnum = DataTypeEnum.Boolean;
  constructor() {
    super();
  }

  ngOnInit(): void {}
  setInputOptions() {
    this.binary = this.inputOptions?.checkBox?.binary ?? this.binary;
    this.messageLabel = this.inputOptions?.checkBox?.messageLabel ?? this.messageLabel;
    this.messageWrapper = this.inputOptions?.checkBox?.messageWrapper ?? this.messageWrapper;
  }
  onCheckChange(event) {
    this.onCheckboxChange.emit(event);
  }
  toggleCheckbox(){
    if(this.control.enabled){
      this.control.patchValue(!this.control?.value)
    }
  }
}
