/**
 * Sightflare Service
 * Rest Api for Sightflare Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ReportControlObjectiveItem } from './reportControlObjectiveItem';


export interface ComplianceReportResultItem { 
    code?: string;
    cobResult?: Array<ReportControlObjectiveItem>;
    name?: string;
    parent?: string;
    subject?: string;
    body?: string;
    type?: ComplianceReportResultItem.TypeEnum;
    isExempted?: boolean;
    mandatory?: boolean;
    authorityDocument?: string;
}
export namespace ComplianceReportResultItem {
    export type TypeEnum = 'GENERAL' | 'COMPLIANCE_REQUIREMENTS';
    export const TypeEnum = {
        General: 'GENERAL' as TypeEnum,
        ComplianceRequirements: 'COMPLIANCE_REQUIREMENTS' as TypeEnum
    };
}


