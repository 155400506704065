import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { RequestHandlerOptions, TaskTemplateDto } from '@shared/classes';
import { BaseRequestControllerService } from '@shared/services/api/base-request-controller.service';
import { DataService } from '@shared/services/api/data.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TaskTemplatesDataService extends BaseRequestControllerService<TaskTemplateDto> {
  constructor(
    private dataService: DataService,
    private router: Router
  ) {
    super(dataService, environment.userOperations, '/task-templates', [
      'id',
      'code',
      'tags',
      'tagsCodes',
      'lockedBy',
      'lockedForEdit',
      'lockedUntil',
      'userAccessLevel',
      'recordStatus',
      'creatorName',
      'lastModifierName',
      'creationDate',
      'lastModificationDate',
      'name',
      'label',
      'module',
      'systemGenerated',
      'key',
      'titleTemplate',
      'sender',
      'principle',
      'dueDateDays',
    ]);
  }
  navigateToListPage() {
    this.router.navigateByUrl(`activity-feed/task-template/list`);
  }
  getContextValues(options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.dataService.getData(`${this.url}/available-context-task-values`, options) as Observable<string[]>;
  }
}
