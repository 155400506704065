/**
 * Sightflare Service
 * Rest Api for Sightflare Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface GroupingField { 
    fieldName?: string;
    dateTimePart?: GroupingField.DateTimePartEnum;
    typeShape?: GroupingField.TypeShapeEnum;
}
export namespace GroupingField {
    export type DateTimePartEnum = 'DAY_OF_MONTH' | 'DAY_OF_WEEK' | 'DAY_OF_YEAR' | 'HOUR' | 'MINUTE' | 'MONTH' | 'SECOND' | 'WEEK' | 'YEAR' | 'ISO_DAY_OF_WEEK' | 'ISO_WEEK' | 'ISO_WEEK_YEAR' | 'QUARTER';
    export const DateTimePartEnum = {
        DayOfMonth: 'DAY_OF_MONTH' as DateTimePartEnum,
        DayOfWeek: 'DAY_OF_WEEK' as DateTimePartEnum,
        DayOfYear: 'DAY_OF_YEAR' as DateTimePartEnum,
        Hour: 'HOUR' as DateTimePartEnum,
        Minute: 'MINUTE' as DateTimePartEnum,
        Month: 'MONTH' as DateTimePartEnum,
        Second: 'SECOND' as DateTimePartEnum,
        Week: 'WEEK' as DateTimePartEnum,
        Year: 'YEAR' as DateTimePartEnum,
        IsoDayOfWeek: 'ISO_DAY_OF_WEEK' as DateTimePartEnum,
        IsoWeek: 'ISO_WEEK' as DateTimePartEnum,
        IsoWeekYear: 'ISO_WEEK_YEAR' as DateTimePartEnum,
        Quarter: 'QUARTER' as DateTimePartEnum
    };
    export type TypeShapeEnum = 'DATETIME' | 'DATETIME_PART' | 'NORMAL';
    export const TypeShapeEnum = {
        Datetime: 'DATETIME' as TypeShapeEnum,
        DatetimePart: 'DATETIME_PART' as TypeShapeEnum,
        Normal: 'NORMAL' as TypeShapeEnum
    };
}


