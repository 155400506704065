/**
 * Sightflare Service
 * Rest Api for Sightflare Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface LikelihoodValue { 
    value?: number;
    name?: string;
    code?: string;
    description?: string;
    occuresFrom?: number;
    occuresTo?: number;
    occurrenceDuration?: LikelihoodValue.OccurrenceDurationEnum;
}
export namespace LikelihoodValue {
    export type OccurrenceDurationEnum = 'WEEK' | 'MONTH' | 'YEAR';
    export const OccurrenceDurationEnum = {
        Week: 'WEEK' as OccurrenceDurationEnum,
        Month: 'MONTH' as OccurrenceDurationEnum,
        Year: 'YEAR' as OccurrenceDurationEnum
    };
}


