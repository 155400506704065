import { Component, Input, OnInit } from '@angular/core';
import { BaseViewItem, DataTypeEnum } from '@shared/classes';

@Component({
  selector: 'app-progressbar-view',
  templateUrl: './progressbar-view.component.html',
  styleUrls: ['./progressbar-view.component.scss'],
})
export class ProgressbarViewComponent extends BaseViewItem<number> implements OnInit {
  @Input() viewType: DataTypeEnum = DataTypeEnum.ProgressBar;
  @Input() bgColor: string;
  @Input() styleBasedColor: string;
  @Input() tooltipHint: string;
  @Input() striped: boolean = false;
  @Input() animated: boolean = false;
  @Input() tooltipPosition: 'top' | 'bottom' | 'left' | 'right' = 'top';
  @Input() containerStyle: string;

  constructor() {
    super();
  }

  ngOnInit(): void {}
}
