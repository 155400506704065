import { Component, OnInit } from '@angular/core';
import { DataTypeEnum, DynamicComponentBase } from '@shared/classes';

@Component({
  selector: 'app-mac-input',
  templateUrl: './mac-input.component.html',
  styleUrls: ['./mac-input.component.scss'],
})
export class MacInputComponent extends DynamicComponentBase implements OnInit {
  dataType: DataTypeEnum = DataTypeEnum.Text;
  value: string = ':::';
  disabledBlocks: boolean[] = [false, false, false, false];
  // macAddress: RegExp = /^(?:(?'1'[0-9a-fA-F]{2})(?:\:)(?'2'[0-9a-fA-F]{2})(?:\:)(?'3'[0-9a-fA-F]{2})(?:\:)(?'4'[0-9a-fA-F]{2})(?:\:)(?'5'[0-9a-fA-F]{2})(?:\:)(?'6'[0-9a-fA-F]{2}))$|^(?:(?'1'[0-9a-fA-F]{2})(?:\-)(?'2'[0-9a-fA-F]{2})(?:\-)(?'3'[0-9a-fA-F]{2})(?:\-)(?'4'[0-9a-fA-F]{2})(?:\-)(?'5'[0-9a-fA-F]{2})(?:\-)(?'6'[0-9a-fA-F]{2}))$/;
  constructor() {
    super();
  }

  ngOnInit(): void {}
  setInputOptions() {
    this.setValue();
    this.setDisabledBlocks();
  }

  setDisabledBlocks() {
    if (this.inputOptions.networkInput.disabledBlocks) {
      this.inputOptions.networkInput.disabledBlocks.forEach((x) => {
        this.disabledBlocks[x] = true;
      });
    }
  }

  setValue() {
    if (
      this.inputOptions.networkInput.defaultBlockValues &&
      this.inputOptions.networkInput.defaultBlockValues.length >= 4 &&
      !this.control.value
    ) {
      let val = '';

      for (let i = 0; i < 4; i++) {
        const element = this.inputOptions.networkInput.defaultBlockValues[i];
        if (i == 0) val = element;
        else val = val + ':' + element;
      }
      if (val) this.value = val;
    }
  }
}
