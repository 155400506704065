import { Component, OnInit } from '@angular/core';
import { Acknowledgment, BaseViewItem, IProgressBar, ProgressDto } from '@shared/classes';

@Component({
  selector: 'app-acknowledgement-request-progress-bar',
  templateUrl: './acknowledgement-request-progress-bar.component.html',
  styleUrls: ['./acknowledgement-request-progress-bar.component.scss'],
})
export class AcknowledgementRequestProgressBarComponent extends BaseViewItem implements OnInit {
  progressBarList: IProgressBar[] = [];
  isLoading: boolean = false;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.processProgressBarList();
  }

  isSumZero(progressValue: { [key: string]: number }): boolean {
    let sum = 0;

    for (const key in progressValue) {
      if (progressValue.hasOwnProperty(key)) {
        sum += progressValue[key];
      }
    }

    return sum === 0;
  }

  processProgressBarList() {
    let progress: ProgressDto = this.data?.progress;
    let progressValue: { [key: string]: number } = progress.value;
    const sumIsZero = this.isSumZero(progressValue);

    this.progressBarList = [
      {
        value: progressValue[Acknowledgment.StateEnum.Draft],
        bgColor: 'bg-gray-400',
        tooltipHint: progressValue[Acknowledgment.StateEnum.Draft].toFixed(1) + '% Draft',
      },
      {
        value: progressValue[Acknowledgment.StateEnum.Submitted],
        bgColor: 'bg-yellow-400',
        tooltipHint: progressValue[Acknowledgment.StateEnum.Submitted].toFixed(1) + '% Submitted',
      },
      {
        value: progressValue[Acknowledgment.StateEnum.Approved],
        bgColor: 'bg-green-400',
        tooltipHint: progressValue[Acknowledgment.StateEnum.Approved].toFixed(1) + '% Approved',
      },
      {
        value: progressValue[Acknowledgment.StateEnum.Rejected],
        bgColor: 'bg-red-400',
        tooltipHint: progressValue[Acknowledgment.StateEnum.Rejected].toFixed(1) + '% Rejected',
      },
      {
        value: sumIsZero ? 100 : 0,
        bgColor: 'bg-gray-400',
        tooltipHint: (sumIsZero ? 100 : 0).toFixed(1) + '% No Response Yet',
      },
    ];
  }
}
