import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { BaseResponse, RequestHandlerOptions, StaticValue } from '@shared/classes';
import { BaseRequestControllerService } from '@shared/services/api/base-request-controller.service';
import { DataService } from '@shared/services/api/data.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StaticValuesDataService extends BaseRequestControllerService<StaticValue> {
  constructor(
    private dataService: DataService,
    private router: Router
  ) {
    super(dataService, environment.configuration, '/static-values', ['id', 'name']);
  }
  updateStaticValue<T = StaticValue>(body: T, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.dataService.putData<BaseResponse<T>>(this.url, body, options) as Observable<BaseResponse<T>>;
  }
  navigateToListPage() {
    this.router.navigateByUrl(`user-management/static-values/list`);
  }
  getValueByCode(
    code: string,
    options: RequestHandlerOptions = { ...new RequestHandlerOptions(), showLoading: false }
  ) {
    // options.headers = new HttpHeaders({
    //     'Accept': '*/*'
    // })
    return this.dataService.getTextData(this.url + '/static/value/' + code, options) as Observable<any>;
  }
  getStaticLink(code: string) {
    return this.url + '/static/value/' + code;
  }
}
export const StaticValuesCodes = {
  ICON_DARK: 'ICON_DARK',
  LOGO_DARK: 'LOGO_DARK',
  ICON_LIGHT: 'ICON_LIGHT',
  LOGO_LIGHT: 'LOGO_LIGHT',
  // FAVICON:'FAVICON',
  // TENANT_TITLE:'TENANT_TITLE',
  // TENANT_DESCRIPTION:'TENANT_DESCRIPTION',
  // TENANT_OG_IMAGE:'TENANT_OG_IMAGE',
  // THEME_DEFAULT_COLOR_MODE:'THEME_DEFAULT_COLOR_MODE',
  // FOOTER_SLOGAN:'FOOTER_SLOGAN',
  // DEFAULT_THEME:'DEFAULT_THEME',
  // BANNER_TEXT:'BANNER_TEXT',
  BANNER: 'BANNER',
  TENANT_NAME: 'TENANT_NAME',
  // LOADER_LOGO_LIGHT:'LOADER_LOGO_LIGHT',
  // PDF_EXPORT_FOOTER:'PDF_EXPORT_FOOTER',
  // PDF_EXPORT_HEADER:'PDF_EXPORT_HEADER',
  // LOADER_LOGO_DARK:'LOADER_LOGO_DARK'
};
