import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IAction } from '@shared/classes/view/ButtonAction';
import { ViewModeService } from '@shared/services/view-mode.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnInit {
  @Input() action: IAction;
  @Input() commandData: any;
  @Input() customStatus$: Observable<boolean> = null;
  @Input() displayData: any;
  @Input() customLabel: string;
  @Input() customIcon: string;
  @Output() onClickAction: EventEmitter<any> = new EventEmitter();
  constructor(private viewModeService: ViewModeService) {}

  ngOnInit(): void {
    // setTimeout(() => {
    //     this.customStatus$.subscribe(currentStats => {
    //     })
    //     this.action.status$.subscribe(currentStats => {
    //     })
    // }, 1000);
  }
  onButtonClick(event) {
    if (this.action.command) {
      if (this.commandData != null && this.commandData != undefined && this.action.passEvent) {
        this.action.command({ event: event, data: this.commandData });
      } else if (this.commandData != null && this.commandData != undefined) {
        this.action.command(this.commandData);
      } else if (this.action.passEvent) {
        this.action.command(event);
      } else {
        this.action.command();
      }
    }
    this.onClickAction.emit(event);
  }
}
