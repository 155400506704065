import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { AuthorityDocument, BaseResponse, RequestHandlerOptions } from '@shared/classes';
import { BaseRequestControllerService } from '@shared/services/api/base-request-controller.service';
import { DataService } from '@shared/services/api/data.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthorityDataService extends BaseRequestControllerService<AuthorityDocument> {
  constructor(
    private dataService: DataService,
    private router: Router
  ) {
    super(dataService, environment.framework, '/authorities', [
      'id',
      'code',
      'tags',
      'tagsCodes',
      'userId',
      'name',
      'aliasName',
      'uniqueId',
      'validFrom',
      'validTo',
      'versionName',
      'description',
      'externalReferences',
      'lockedBy',
      'lockedForEdit',
      'lockedUntil',
      'userAccessLevel',
      'recordStatus',
      'creatorName',
      'lastModifierName',
      'creationDate',
      'lastModificationDate',
    ]);
  }

  navigateToListPage() {
    this.router.navigateByUrl(`framework/authority/authority/list`);
  }
  checkUniqueName(
    body: { name: string; versionName: any },
    options: RequestHandlerOptions = new RequestHandlerOptions()
  ) {
    return this.dataService.postData<BaseResponse<any>>(this.url + '/check-unique-name', body, options) as Observable<
      BaseResponse<any>
    >;
  }
}
