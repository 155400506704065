import { Component, Input, OnInit } from '@angular/core';
import { JWTTokenService } from '@core/services/JWT-token/jwttoken.service';
import { DataTypeEnum, DynamicComponentBase, extractItemCode, isNullObj } from '@shared/classes';
import { UserDataService } from 'app/modules/users/services/data/user-data.service';
import { unionBy } from 'lodash-es';

@Component({
  selector: 'app-user-and-group-selector',
  templateUrl: './user-and-group-selector.component.html',
  styleUrls: ['./user-and-group-selector.component.scss'],
  providers: [{ provide: DynamicComponentBase, useExisting: UserAndGroupSelectorComponent }],
})
export class UserAndGroupSelectorComponent extends DynamicComponentBase implements OnInit {
  @Input() multi: boolean = true;
  users: any[] = [];
  dataType: DataTypeEnum = DataTypeEnum.Text;
  @Input() dropdown: boolean = false;
  @Input() appendTo: string = 'body';
  @Input() optionLabel: string = 'name';
  @Input() optionValue: string = 'name';
  uiChange: boolean = false;
  // private _excludeSuggestions:string[] = [];
  get excludeSuggestions() {
    return [
      ...this.extraExcludedSuggestions,
      ...((this.multi ? this.fControl?.value?.map((x) => x) : this.fControl?.value ? [this.fControl?.value] : []) ||
        []),
    ];
  }
  @Input() extraExcludedSuggestions = [];
  @Input() principleMode: boolean = false;
  @Input() strictPrincipleMode: boolean = false;
  @Input() itemTypes: ('RESPONSIBILITIES' | 'GROUPS' | 'USERS')[] = ['RESPONSIBILITIES'];
  @Input() principleModeSelection: 'ALL' | 'ONE' | 'SELECTION' = 'ONE';

  isLoading: boolean = false;
  responsibilityModeOptions = [
    { label: '', value: 'ONE', icon: 'pi pi-user', tooltip: 'One Person' },
    { label: '', value: 'ALL', icon: 'pi pi-users', tooltip: 'All People' },
  ];
  allUsersMap: { [x: string]: any } = {};
  constructor(
    private service: UserDataService,
    private jwtService: JWTTokenService
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.dropdown) this.getOptions();

    if (this.fControl.getRawValue()) {
      const tempVal = JSON.stringify(this.fControl.getRawValue());
      this.fControl.patchValue(null);
      setTimeout(() => {
        this.fControl.patchValue(JSON.parse(tempVal));
      }, 10);
    }
    this.onChanges.subscribe((res) => {
      // this.users = this.formatItemList(this.users);

      this.updateControl();
    });
  }
  updateControl() {
    if (!this.uiChange) {
      this.uiChange = true;
      if (this.multi) {
        this.users.forEach((user) => {
          let controlIndex = (this.fControl.value as any[]).findIndex((x) => x.name == user.name);
          if (controlIndex != -1) {
            const controlVal = this.fControl.value[controlIndex];
            if (!this.strictPrincipleMode) {
              if (controlVal.mode) {
                user.mode = controlVal.mode;
              } else {
                // controlVal.mode = 'ALL';
                user.mode = 'ONE';
              }
            }
          }
        });
        this.users = [...this.users];
        this.fControl.patchValue(
          this.fControl.value.map((x) => {
            return this.strictPrincipleMode ? { ...x } : { ...x, mode: x.mode ? x.mode : 'ONE' };
          })
        );
      } else {
        // this.fControl.patchValue(this.fControl.value.map(x=>{return{...x,mode: x.mode ? x.mode : 'ALL'}}))
      }
    } else {
      this.uiChange = false;
    }
  }
  setInputOptions() {
    this.multi = this.inputOptions?.dropDownInput?.multi ?? this.multi;
    this.appendTo = this.inputOptions?.dropDownInput?.appendTo ?? this.appendTo;
    this.optionLabel = this.inputOptions?.dropDownInput?.optionLabel ?? this.optionLabel;
    this.optionValue = this.inputOptions?.dropDownInput?.optionValue
      ? this.inputOptions?.dropDownInput?.optionValue === 'undefined'
        ? undefined
        : this.inputOptions?.dropDownInput?.optionValue
      : this.optionValue;
    this.principleMode = this.inputOptions?.extra?.principleMode ?? this.principleMode;
    this.strictPrincipleMode = this.inputOptions?.extra?.strictPrincipleMode ?? this.strictPrincipleMode;
    this.principleModeSelection = this.inputOptions?.extra?.principleModeSelection ?? this.principleModeSelection;
    this.itemTypes = this.inputOptions?.extra?.itemTypes ?? this.itemTypes;
    this.extraExcludedSuggestions = this.inputOptions?.extra?.extraExcludedSuggestions ?? this.extraExcludedSuggestions;
  }
  unformattedOptions = [];
  getOptions(username = null) {
    this.isLoading = true;
    const filter = this.excludeSuggestions?.length
      ? {
          excludedItems: {
            users: this.excludeSuggestions
              ?.filter((x) => (x?.principleType ?? x?.type) == 'USER')
              .map((x) => x?.principle ?? x?.name),
            groups: this.excludeSuggestions
              ?.filter((x) => (x?.principleType ?? x?.type) == 'GROUP')
              .map((x) => x?.principle ?? x?.name),
            responsibility: this.excludeSuggestions
              ?.filter((x) => (x?.principleType ?? x?.type) == 'RESPONSIBILITY')
              .map((x) => x?.principle ?? x?.name),
          },
          types: this.searchTypes,
        }
      : {
          excludedItems: { users: [], groups: [], responsibility: [] },
          types: this.searchTypes,
        };
    this.subs.sink = (
      isNullObj(username)
        ? this.service.getUserAndGroupSelector(filter, { showLoading: false, showMsg: false })
        : this.service.searchUserAndGroup(username, filter, { showLoading: false, showMsg: false })
    ).subscribe({
      next: (res) => {
        this.allUsersMap = {};
        const currentUsername = this.jwtService.getPreferredUsername();
        res.forEach((element) => {
          const { code, label } = this.extractItemCode(element.name);
          this.allUsersMap[element.name] = {
            ...element,
            isMember: element?.members ? (element?.members?.find((x) => x == currentUsername) ? true : false) : false,
            responsibilityCode: element.type == 'RESPONSIBILITY' ? code : null,
            responsibilityName: element.type == 'RESPONSIBILITY' ? label : null,
          };
        });
        const resData = this.principleMode
          ? res.map((x) => {
              return this.strictPrincipleMode
                ? { principle: x.name, principleType: x.type }
                : {
                    name: x.name,
                    principleType: x.type,
                    mode: this.principleModeSelection != 'SELECTION' ? this.principleModeSelection : null,
                  };
            })
          : res;

        const allData = this.formatItemList(resData)?.filter((x) => this.excludeSuggestions.indexOf(x.name) == -1);
        const responsibilities = allData.filter(
          (x) => x[this.principleMode ? 'principleType' : 'type'] == 'RESPONSIBILITY'
        );
        const groups = allData.filter((x) => x[this.principleMode ? 'principleType' : 'type'] == 'GROUP');
        const users = allData.filter((x) => x[this.principleMode ? 'principleType' : 'type'] == 'USER');
        const tempItems = [];
        if (this.itemTypes?.indexOf('RESPONSIBILITIES') != -1 && responsibilities && responsibilities?.length > 0) {
          tempItems.push({
            name: 'Responsibilities',
            items: responsibilities.map((x) => {
              return { ...x, [this.principleMode ? 'principleType' : 'type']: 'RESPONSIBILITY' };
            }),
            icon: 'pi pi-user-plus',
          });
        }
        if (this.itemTypes?.indexOf('GROUPS') != -1 && groups && groups?.length > 0) {
          tempItems.push({ name: 'Groups', items: groups, icon: 'pi pi-users' });
        }
        if (this.itemTypes?.indexOf('USERS') != -1 && users && users?.length > 0) {
          tempItems.push({ name: 'Users', items: users, icon: 'pi pi-user' });
        }
        this.users = tempItems;
        this.unformattedOptions = [...allData];
      },
      complete: () => {
        this.isLoading = false;
      },
    });
  }
  extractItemCode(inputString: string) {
    // Regular expression to match the pattern "RES-[NUMBERS]"
    const regex = /(RES-\d+)(.*)/;

    // Find the first match in the input string
    const match = inputString.match(regex);

    // Extract the code and label if a match is found
    if (match) {
      return { code: match[1], label: match?.[2]?.substring(1) };
    } else {
      return { code: null, label: null };
    }
  }
  getViewData() {
    let value = this.data || this.control.value;
    if (typeof value === 'string' || value instanceof String) {
      const code = extractItemCode(value as any)?.code;
      if (code) this.dataType = DataTypeEnum.CodeLink;
      else this.dataType = DataTypeEnum.Text;
      return code || value;
    } else if (Array.isArray(value)) {
      const ret = value.map((x) =>
        x.name ? extractItemCode(x.name)?.code : x?.principle ? extractItemCode(x.principle)?.code : x
      );
      this.dataType = DataTypeEnum.CodeLink;
      return ret;
    } else {
      const ret = value?.name ? value?.name : value?.principle ? value.principle : value;
      if (typeof ret !== 'string') {
        return '';
      } else {
        const code = extractItemCode(ret)?.code;
        if (code) this.dataType = DataTypeEnum.CodeLink;
        else this.dataType = DataTypeEnum.Text;
        return code || ret;
      }
    }
  }
  onFilter(event) {
    this.getOptions(event);
  }
  formatItemList(data) {
    let tempUsers = data;
    if (!isNullObj(this.fControl.value)) {
      if (this.multi) {
        if (this.optionValue != undefined) {
          tempUsers = unionBy(
            tempUsers,
            this.fControl.value.map((x) => {
              return { [this.optionValue]: x };
            }),
            this.optionValue
          );
        } else {
          tempUsers = unionBy(tempUsers, this.fControl.value, 'name');
        }
      } else {
        if (this.optionValue != undefined) {
          tempUsers = unionBy(tempUsers, [{ [this.optionValue]: this.fControl.value }] as any[], this.optionValue);
        } else {
          tempUsers = unionBy(tempUsers, [this.fControl.value], 'name');
        }
      }
    }

    return tempUsers;
  }
  onChangeRespMode(mode: { originalEvent?: any; value?: any }, node) {
    mode?.originalEvent?.stopPropagation();
    if (this.multi) {
      let values = [...this.fControl.getRawValue()];
      let ind = values?.findIndex((x) => x?.name === node?.name);
      if (ind != -1) {
        values[ind] = { ...values[ind], mode: mode?.value };
        this.fControl.patchValue(values);
      }
    } else {
      let val = { ...this.fControl.getRawValue() };
      if (val.name === node?.name) {
        val = { ...val, mode: mode?.value };
        this.fControl.patchValue(val);
      }
    }
  }
  onOptionClick(event: { originalEvent?: any; option?: any; index?: any }) {
    event?.originalEvent?.stopPropagation();
  }
  get searchTypes() {
    return this.itemTypes.map((type) => (type == 'RESPONSIBILITIES' ? 'RESPONSIBILITY' : type));
  }
}
