import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { BaseResponse, DocumentVersion, DocumentVersionsDto, RequestHandlerOptions } from '@shared/classes';
import { BaseRequestControllerService } from '@shared/services/api/base-request-controller.service';
import { DataService } from '@shared/services/api/data.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FilesDataService extends BaseRequestControllerService<any> {
  constructor(
    private dataService: DataService,
    private router: Router
  ) {
    super(dataService, environment.fileManager, '/document', [
      'id',
      'code',
      'tags',
      'tagsCodes',
      'userId',
      'name',
      'uniqueId',
      'lockedBy',
      'lockedForEdit',
      'lockedUntil',
      'userAccessLevel',
      'recordStatus',
      'creatorName',
      'lastModifierName',
      'creationDate',
      'lastModificationDate',
    ]);
  }

  navigateToListPage() {
    this.router.navigateByUrl(`file/list`);
  }
  uploadFile(
    file,
    body: { description: string; versionName: any },
    bucketId: string = 'root',
    options: RequestHandlerOptions = new RequestHandlerOptions()
  ) {
    let formBody = new FormData();
    formBody.append('description', body.description);
    formBody.append('versionName', body.versionName);
    formBody.append('file', file, body.versionName);
    return this.dataService.postData<BaseResponse<any>>(
      this.url + '/upload/' + bucketId,
      formBody,
      options
    ) as Observable<DocumentVersion>;
  }
  uploadFileWithProgress(
    file,
    body: { description: string; versionName: any },
    bucketId: string = 'root',
    options: RequestHandlerOptions = new RequestHandlerOptions()
  ) {
    let formBody = new FormData();
    // formBody.append('description',body.description);
    // formBody.append('versionName',body.versionName);
    formBody.append('file', file, body.versionName);
    return this.dataService.uploadWithProgress<DocumentVersionsDto>(
      `${this.url}/upload${bucketId ? `/${bucketId}` : ''}`,
      formBody,
      options
    );
  }
  downloadFile(fileId: string, params = null) {
    return this.dataService.download(`${this.url}/download/${fileId}`, params);
  }
  downloadFile2(fileId: string, params = null) {
    return this.dataService.downloadV2(`${this.url}/download/${fileId}`, params);
  }
  downloadFileWithProgress(fileId: string, downloadFileUrl = '/download/', params = null) {
    return this.dataService.downloadWithProgress(`${this.url}${downloadFileUrl}${fileId}`, params);
  }
  getAllAttachmentsByItemCode(code: String | string, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.dataService.getData(`${this.url}/attachments/${code}`, options);
  }
  moveToTrash(documents: string[], options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.dataService.postData(`${this.url}/move-to-trash`, { documents: documents }, options);
  }
  getListVersionsByDocumentCodes(codes: string[], options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.dataService.postData(`${this.url}/list/versions`, codes, options);
  }
}
