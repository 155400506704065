/**
 * Sightflare Service
 * Rest Api for Sightflare Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ApprovedByItemDto { 
    user?: string;
    approvedDate?: string;
    approveMode?: ApprovedByItemDto.ApproveModeEnum;
}
export namespace ApprovedByItemDto {
    export type ApproveModeEnum = 'APPROVE' | 'REJECT';
    export const ApproveModeEnum = {
        Approve: 'APPROVE' as ApproveModeEnum,
        Reject: 'REJECT' as ApproveModeEnum
    };
}


