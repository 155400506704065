<app-input-view-switch
  [showLabelInViewMode]="showLabelInViewMode"
  [mode]="viewMode"
  [data]="value"
  [dataType]="dataType"
  [label]="label"
  [options]="{ value: value, riskMeth: riskMeth }"
>
  <div class="field">
    <span
      class="flex flex-row md:flex-nowrap flex-wrap gap-1 w-full algin-items-start"
      [class.p-float-label]="floatLabel"
    >
      @if (!floatLabel) {
        @if (label && !instanceInFormRepeater) {
          <label [for]="name || label" class="md:w-12rem md:max-w-12rem md:min-w-12rem w-full input-field-label">{{
            label
          }}</label>
        }
      }
      <app-risk-meter-view class="w-full" [value]="value" [riskMeth]="riskMeth"></app-risk-meter-view>
      <input
        hidden
        class="p-inputtext p-component p-element ng-dirty ng-valid p-filled ng-touched"
        name="riskMeter"
        [value]="'10'"
      />
      @if (floatLabel) {
        @if (label && !instanceInFormRepeater) {
          <label [for]="name || label" class="md:w-12rem md:max-w-12rem md:min-w-12rem w-full input-field-label">{{
            label
          }}</label>
        }
      }
    </span>
  </div>
</app-input-view-switch>
