/**
 * Sightflare Service
 * Rest Api for Sightflare Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface IssueEntityDto { 
    code?: string;
    criticality?: IssueEntityDto.CriticalityEnum;
    issueStatus?: IssueEntityDto.IssueStatusEnum;
}
export namespace IssueEntityDto {
    export type CriticalityEnum = 'CRITICAL' | 'HIGH' | 'MODERATE' | 'LOW' | 'INFO';
    export const CriticalityEnum = {
        Critical: 'CRITICAL' as CriticalityEnum,
        High: 'HIGH' as CriticalityEnum,
        Moderate: 'MODERATE' as CriticalityEnum,
        Low: 'LOW' as CriticalityEnum,
        Info: 'INFO' as CriticalityEnum
    };
    export type IssueStatusEnum = 'DRAFT' | 'ANALYSIS' | 'REMEDIATION' | 'CLOSED_RESOLVED' | 'CLOSED_UNRESOLVED' | 'END';
    export const IssueStatusEnum = {
        Draft: 'DRAFT' as IssueStatusEnum,
        Analysis: 'ANALYSIS' as IssueStatusEnum,
        Remediation: 'REMEDIATION' as IssueStatusEnum,
        ClosedResolved: 'CLOSED_RESOLVED' as IssueStatusEnum,
        ClosedUnresolved: 'CLOSED_UNRESOLVED' as IssueStatusEnum,
        End: 'END' as IssueStatusEnum
    };
}


