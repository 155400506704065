import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { ApiResponseGuidelineEvent, Guideline } from '@shared/classes';
import { BaseRequestControllerWithRuleService } from '@shared/services/api/custom-api-services/base-request-controller-with-rule.service';
import { DataService } from '@shared/services/api/data.service';
@Injectable({
  providedIn: 'root',
})
export class GuidelineDataService extends BaseRequestControllerWithRuleService<
  Guideline,
  ApiResponseGuidelineEvent.DataEnum
> {
  constructor(
    private dataService: DataService,
    private router: Router
  ) {
    super(dataService, environment.framework, '/guidelines', [
      'creatorName',
      'creationDate',
      'lastModifierName',
      'lastModificationDate',
      'id',
      'tags',
      'tagsCodes',
      'searchScore',
      'recordStatus',
      'uuid',
      'description',
      'code',
      'label',
      'parent',
      'parents',
      'module',
      'systemGenerated',
      'softDeleteDate',
      'name',
      'details',
      'attachments',
      'status',
      'state',
      'codePrefix',
      'lockedUntil',
      'lockedForEdit',
      'lockedBy',
      'userAccessLevel',
    ]);
  }

  navigateToListPage() {
    this.router.navigateByUrl(`framework/guideline/list`);
  }
}
