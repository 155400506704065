import { Injectable } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { DataTypeEnum, IFieldDefinition, TargetTypeEnum } from '@shared/classes';
import { TargetCodeSelectorComponent } from '@shared/components/selectors/target-code-selector/target-code-selector.component';
import { UserAndGroupSelectorComponent } from '@shared/components/selectors/user-and-group-selector/user-and-group-selector.component';
import { BasicInputComponent } from '@shared/components/ui/input-fields/basic-input/basic-input.component';
import { SuggestionInputComponent } from '@shared/components/ui/input-fields/suggestion-input/suggestion-input.component';
import { BaseFieldDefinitionsService } from '@shared/services';

@Injectable({
  providedIn: 'root'
})
export class ReportSchedulerMappingService extends BaseFieldDefinitionsService {
  recordFields: IFieldDefinition[] = [
    {
      name: 'Name',
      key: 'name',
      dataType: DataTypeEnum.Text,
      required: true,
      showInTable: true,
      description: 'Name of the record',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
      formField: {
        componentType: BasicInputComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Enter a name',
          control: new FormControl('', Validators.required),
          dataType: DataTypeEnum.Text,
          showLabelInViewMode: false,
        },
      },
    },
    {
      name: 'Report',
      key: 'report',
      dataType: DataTypeEnum.CodeLink,
      required: true,
      showInTable: true,
      description: 'Linked Report to the record',
      tableDefinition: {
        filter: {
          type: 'recordCode',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          recordCodeType: TargetTypeEnum.Report,
        },
      },
      useInBulk: true,
      formField: {
        componentType: TargetCodeSelectorComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Search Records',
          control: new FormControl(null),
          inputOptions: {
            codeSelectorInput: {
              targetTypes: ['REPORT'],
            },
            dropDownInput: {
              multi: false,
              items: null,
              optionLabel: 'label',
              optionValue: 'code'
            },
          },
          showLabelInViewMode: false,
        },
      },
    },
    {
      name: 'Frequency',
      key: 'frequency',
      dataType: DataTypeEnum.Number,
      required: true,
      showInTable: true,
      // permissions: AppPermissions.ReadGroup,
      description: 'Frequency of the report',
      tableDefinition: {
        filter: {
          type: 'numeric',
          display: 'menu',
          matchMode: 'lte',
        },
      },
      useInBulk: true,
      formField: {
        componentType: SuggestionInputComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Ex: 7 days',
          control: new FormControl(''),
          dataType: DataTypeEnum.Text,
          showLabelInViewMode: false,
          inputOptions: {
            dropDownInput: {
              optionValue: 'undefined',
              multi: false,
              items: [
                { label: 'Yearly', value: 365 },
                { label: 'Quarterly', value: 90 },
                { label: 'Monthly', value: 30 },
                { label: 'Weekly', value: 7 },
                { label: 'Daily', value: 1 },
              ],
              optionLabel: 'name'
            },
            numberInput: {
              min: 0,
              maxFractionDigits: 0,

            },
          },
        },
      },
    },
    {
      name: 'Report Template',
      key: 'reportTemplate',
      dataType: DataTypeEnum.CodeLink,
      required: true,
      showInTable: true,
      description: 'Linked Report Template to the record',
      tableDefinition: {
        filter: {
          type: 'recordCode',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          recordCodeType: TargetTypeEnum.ReportConfigurationTemplate,
        },
      },
    },
    {
      name: 'Last Run Date',
      key: 'lastRunDate',
      dataType: DataTypeEnum.DateLong,
      required: false,
      showInTable: true,
      description: 'Last Run Date of the report',
      tableDefinition: {
        filter: {
          type: 'date',
          matchMode: 'dateBefore',
        },
      },
    },
    {
      name: 'Report Configuration',
      key: 'reportTemplate',
      dataType: DataTypeEnum.CodeLink,
      required: true,
      showInTable: false,
      description: 'Report Configuration to the record',
      tableDefinition: {
        filter: {
          type: 'recordCode',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          recordCodeType: TargetTypeEnum.ReportConfigurationTemplate,
        },
      },
      useInBulk: true,
      formField: {
        componentType: TargetCodeSelectorComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Search Records',
          control: new FormControl(null),
          inputOptions: {
            codeSelectorInput: {
              targetTypes: ['REPORT_CONFIGURATION_TEMPLATE'],
            },
            dropDownInput: {
              multi: false,
              items: null,
              optionLabel: 'label',
              optionValue: 'code'
            },
          },
          showLabelInViewMode: false,
        },
      },
    },
    {
      name: 'Accessed By',
      key: 'accessedBy',
      dataType: DataTypeEnum.ResponsibilityListView,
      required: true,
      showInTable: false,
      // permissions: AppPermissions.ReadGroup,
      description: "Users who can access this record",
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
      useInBulk: true,
      formField: {
        componentType: UserAndGroupSelectorComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Search...',
          control: new FormControl(null),
          inputOptions: {
            extra: { principleMode: true },
            dropDownInput: {
              optionValue: 'undefined',
              multi: true,
              items: null,
              optionLabel: undefined
            },
          },
          showLabelInViewMode: false,
        },
      },
    },
    {
      name: 'Send Email To',
      key: 'sendEmailTo',
      dataType: DataTypeEnum.ResponsibilityListView,
      required: true,
      showInTable: false,
      // permissions: AppPermissions.ReadGroup,
      description: "Users who will receive emails",
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
      useInBulk: true,
      formField: {
        componentType: UserAndGroupSelectorComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Search...',
          control: new FormControl(null),
          inputOptions: {
            extra: { principleMode: true },
            dropDownInput: {
              optionValue: 'undefined',
              multi: true,
              items: null,
              optionLabel: undefined
            },
          },
          showLabelInViewMode: false,
        },
      },
    },
  ];
  constructor() {
    super();
    this.setAllFields();
  }
}
