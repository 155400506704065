/**
 * Sightflare Service
 * Rest Api for Sightflare Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ReviewedByItemDto { 
    user?: string;
    approvedDate?: string;
    reviewMode?: ReviewedByItemDto.ReviewModeEnum;
}
export namespace ReviewedByItemDto {
    export type ReviewModeEnum = 'COMMENTS_ADDED' | 'SEEMS_OK';
    export const ReviewModeEnum = {
        CommentsAdded: 'COMMENTS_ADDED' as ReviewModeEnum,
        SeemsOk: 'SEEMS_OK' as ReviewModeEnum
    };
}


