/**
 * Sightflare Service
 * Rest Api for Sightflare Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UsersGroupsDto { 
    name?: string;
    email?: string;
    type?: UsersGroupsDto.TypeEnum;
    members?: Array<string>;
}
export namespace UsersGroupsDto {
    export type TypeEnum = 'GROUP' | 'RESPONSIBILITY' | 'USER';
    export const TypeEnum = {
        Group: 'GROUP' as TypeEnum,
        Responsibility: 'RESPONSIBILITY' as TypeEnum,
        User: 'USER' as TypeEnum
    };
}


