<ng-template
  appDynamicFormComponent
  [dynamicComponent]="viewForm"
  [formData]="formData"
  [formLinkedManualRelations]="linkedManualRelations"
  (formSubmitSave)="onFormSubmit($event)"
  (cancelEvent)="onClose()"
  [showSaveAndClose]="false"
  [popupOptions]="options"
></ng-template>
