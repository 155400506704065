<div class="col-12 md:col-6">
    <app-user-and-group-selector
    [label]="'Run As'"
    [placeholder]="'Search...'"
    [principleMode]="true"
    [control]="formGroup?.controls?.withoutGroups"
    [optionValue]="undefined"
    [multi]="true"
    [viewMode]="viewMode"
  >
  </app-user-and-group-selector>
</div>