import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '@env/environment';
import { DataTypeEnum, DynamicComponentBase, IAction } from '@shared/classes';
import { ToastService } from '@shared/services';
import { FilesDataService } from 'app/modules/file-manager-module/services/files-data.service';
import { ImageCroppedEvent, ImageCropperComponent, LoadedImage, OutputFormat } from 'ngx-image-cropper';

@Component({
  selector: 'app-image-input',
  templateUrl: './image-input.component.html',
  styleUrls: ['./image-input.component.scss'],
  providers: [{ provide: DynamicComponentBase, useExisting: ImageInputComponent }],
})
export class ImageInputComponent extends DynamicComponentBase implements OnInit {
  @Input() maintainAspectRatio: boolean = true;
  @Input() aspectRatio: number = 1; //The width / height ratio (e.g. 1 / 1 for a square, 4 / 3, 16 / 9 ...)
  @Input() format: OutputFormat = 'png'; //Output format (png, jpeg, webp, bmp, ico) (not all browsers support all types, png is always supported, others are optional)
  imageChangedEvent: any = '';
  croppedImage: any = '';
  dataType: DataTypeEnum = DataTypeEnum.Image;
  showCancelFile = false;
  cancelFileSelectionAction: IAction = {
    id: 2,
    label: 'Cancel',
    buttonType: 'button',
    command: this.clearFile.bind(this),
    icon: 'pi pi-times',
  };
  @Output() onFileUpload: EventEmitter<any> = new EventEmitter();
  @Input() bucketId: string = 'root';
  @Input() mode: 'basic' | 'advanced' = 'basic';
  @Input() multiple: boolean = false;
  @Input() accept: string = 'image/*';
  @Input() maxFileSize: number = null;
  @Input() fileLimit: number = null;
  @Input() popupMode: boolean = false;
  @Input() outputMode: 'blob' | 'base64' = 'base64';
  @ViewChild(ImageCropperComponent) imageCropper: ImageCropperComponent;
  displayDialog: boolean;
  viewOnlyChange = false;

  constructor(
    private fileService: FilesDataService,
    private sanitizer: DomSanitizer,
    private toastService: ToastService
  ) {
    super();
  }

  ngOnInit(): void {}
  clearData() {
    this.imageChangedEvent = null;
    this.data = null;
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64; // this.sanitizer.bypassSecurityTrustUrl(event.objectUrl);

    this.viewOnlyChange = true;
    this.control.patchValue(event.base64, { emitEvent: false });
    // event.blob can be used to upload the cropped image
  }
  imageLoaded(image: LoadedImage) {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }
  setInputOptions() {
    this.bucketId = this.inputOptions?.attachmentInput?.bucketId ?? this.bucketId;
    this.mode = this.inputOptions?.attachmentInput?.mode ?? this.mode;
    // this.multiple = this.inputOptions?.attachmentInput?.multiple ?? this.multiple;
    this.accept = this.inputOptions?.attachmentInput?.accept ?? this.accept;
    this.maxFileSize = this.inputOptions?.attachmentInput?.maxFileSize ?? this.maxFileSize;
    this.fileLimit = this.inputOptions?.attachmentInput?.fileLimit ?? this.fileLimit;

    this.maintainAspectRatio = this.checkIfIcon()
      ? true
      : (this.inputOptions?.imageInput?.maintainAspectRatio ?? this.maintainAspectRatio);
    this.aspectRatio = this.checkIfIcon()
      ? this.aspectRatio
      : (this.inputOptions?.imageInput?.aspectRatio ?? this.aspectRatio);
    this.format = this.inputOptions?.imageInput?.format ?? this.format;
  }

  checkIfIcon() {
    const n = this.name.toLowerCase();
    return n == 'icon_dark' || n == 'icon_light';
  }

  onBasicUpload(event) {
    for (let file of event.files) {
      this.fileService
        .uploadFile(file, { description: file.name, versionName: file.name }, this.bucketId)
        .subscribe((res) => {
          this.onFileUpload.emit(`${environment.websiteUrl}/file-manager/documents/download/${res.fileId}`);
          this.control.patchValue(`${environment.websiteUrl}/file-manager/documents/download/${res.fileId}`);
        });
    }
  }
  isImage = (file) => file['type'].includes('image');
  onSelectFile(event) {
    let isValidFile = true;
    event?.files.forEach((file) => {
      if (!this.isImage(file)) {
        isValidFile = false;
      }
    });
    if (!isValidFile) {
      this.toastService.error('Invalid File', 'Please select an image file');
      return;
    }
    this.showCancelFile = true;
    this.imageChangedEvent = { target: { files: [...event?.files] } };

    setTimeout(() => {}, 2000);
    // this.imageCropper.fileChangeListener(this.imageChangedEvent);
    // this.imageChangedEvent = {...this.imageChangedEvent};
  }
  clearFile(fileBrowser) {
    fileBrowser?.clear();
    this.showCancelFile = false;
    this.imageChangedEvent = null;
  }
  showDialog() {
    this.displayDialog = true;
  }
  isDragOverTarget: boolean = false;
  dragLeave(event) {
    event.preventDefault();
    // document.getElementById("file-drop-zone").innerHTML = "The text is no longer OVER the droptarget.";
    // event.target.style.border = "4px dotted blue";
    this.isDragOverTarget = false;
  }
  dragOver(event) {
    event.preventDefault();
    // document.getElementById("file-drop-zone").innerHTML = "The text is OVER the droptarget.";
    // event.target.style.border = "4px dotted green";
    this.isDragOverTarget = true;
  }

  drop(event) {
    event.preventDefault();
    event.stopPropagation();
    this.onSelectFile(event.dataTransfer);
    this.isDragOverTarget = false;
    // const data = event.dataTransfer.getData("Text");
    // event.target.appendChild(document.getElementById(data));
    // document.getElementById("file-drop-zone").innerHTML = "The text was dropped.";
  }
}
