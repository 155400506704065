/**
 * Sightflare Service
 * Rest Api for Sightflare Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserOperationPrinciple } from './userOperationPrinciple';


export interface SubscriptionItem { 
    type?: SubscriptionItem.TypeEnum;
    principle?: UserOperationPrinciple;
    date?: string;
}
export namespace SubscriptionItem {
    export type TypeEnum = 'DIRECT' | 'WATCHING';
    export const TypeEnum = {
        Direct: 'DIRECT' as TypeEnum,
        Watching: 'WATCHING' as TypeEnum
    };
}


