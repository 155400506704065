import { Component, OnInit } from '@angular/core';
import { BaseViewItem, Control, IProgressBar, ProgressDto } from '@shared/classes';

@Component({
  selector: 'app-policy-progress-bar',
  templateUrl: './policy-progress-bar.component.html',
})
export class ControlComplianceProgressBarComponent extends BaseViewItem implements OnInit {
  progressBarList: IProgressBar[] = [];
  isLoading: boolean = false;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.processProgressBarList();
  }

  isSumZero(progressValue: { [key: string]: number }): boolean {
    let sum = 0;

    for (const key in progressValue) {
      if (progressValue.hasOwnProperty(key)) {
        sum += progressValue[key];
      }
    }

    return sum === 0;
  }

  processProgressBarList() {
    let progress: ProgressDto = this.data?.progress;
    let progressValue: { [key: string]: number } = progress.value;
    const sumIsZero = this.isSumZero(progressValue);

    this.progressBarList = [
      {
        value: progressValue[Control.ControlComplianceStatusEnum.Compliant] * 100,
        bgColor: 'bg-green-400',
        tooltipHint: progressValue[Control.ControlComplianceStatusEnum.Compliant] + ' Compliant',
      },
      {
        value: progressValue[Control.ControlComplianceStatusEnum.NonCompliant] * 100,
        bgColor: 'bg-red-400',
        tooltipHint: progressValue[Control.ControlComplianceStatusEnum.NonCompliant] + ' Non-Compliant',
      },
      {
        value: (sumIsZero ? progress?.count : progressValue['nulls']) * 100,
        bgColor: 'bg-gray-400',
        tooltipHint: (sumIsZero ? progress?.count : progressValue['nulls']) + ' No Response Yet',
      },
    ];
  }
}
