import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BaseViewItem } from '@shared/classes/view/BaseViewItem';
import { DataTypeEnum } from '@shared/classes/view/view-enums';

@Component({
  selector: 'app-view-switch',
  templateUrl: './view-switch.component.html',
  styleUrls: ['./view-switch.component.scss'],
})
export class ViewSwitchComponent extends BaseViewItem implements OnInit {
  @Input() viewType: DataTypeEnum = DataTypeEnum.Text;

  dataTypes = DataTypeEnum;

  @Input() options: any;
  @Input() useField: boolean = false;
  @Input() fieldLabel: string = '';
  formControl = new FormControl({ value: null, disabled: false });
  disabledFormControl = new FormControl({ value: null, disabled: true });
  oldDate = new Date('1900-01-01');
  //   badgeList = getEnumOptions(BadgeColor);
  constructor() {
    super();
  }

  ngOnInit(): void {}
  onSetData(): void {
    this.formControl.patchValue(this.data);
    this.disabledFormControl.patchValue(this.data);
  }
}
