<div class="bs-progress" [style]="containerStyle">
  <div
    [pTooltip]="tooltipHint || (data | toFixedDigits) + '%'"
    [tooltipPosition]="tooltipPosition"
    [class]="
      'bs-progress-bar ' +
      (striped ? ' bs-progress-bar-striped ' : '') +
      (animated ? ' bs-progress-bar ' : '') +
      (bgColor ?? '')
    "
    role="progressbar"
    [style]="'width: ' + data + '%; ' + (styleBasedColor ? ' background-color:' + styleBasedColor + '; ' : '')"
  ></div>
</div>
