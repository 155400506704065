/**
 * Sightflare Service
 * Rest Api for Sightflare Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ApiResponseListModuleName { 
    status?: number;
    data?: Array<ApiResponseListModuleName.DataEnum>;
    error?: boolean;
    errorMessage?: string;
}
export namespace ApiResponseListModuleName {
    export type DataEnum = 'ALL' | 'DOCUMENT_APP' | 'ACKNOWLEDGMENT_APP' | 'ADMIN_APP' | 'CONFIG_APP' | 'DATA_STRUCTURE_APP' | 'BRANDING_APP' | 'ENTITY_APP' | 'FRAMEWORK_APP' | 'ISSUES_APP' | 'QUESTION_APP' | 'RELATION_APP' | 'RISK_APP' | 'USER_APP' | 'USER_OPERATIONS_APP' | 'AUDIT_APP' | 'HUB_APP' | 'TRANSPARENCY_APP' | 'PERFORMANCE_APP' | 'BCM_APP';
    export const DataEnum = {
        All: 'ALL' as DataEnum,
        DocumentApp: 'DOCUMENT_APP' as DataEnum,
        AcknowledgmentApp: 'ACKNOWLEDGMENT_APP' as DataEnum,
        AdminApp: 'ADMIN_APP' as DataEnum,
        ConfigApp: 'CONFIG_APP' as DataEnum,
        DataStructureApp: 'DATA_STRUCTURE_APP' as DataEnum,
        BrandingApp: 'BRANDING_APP' as DataEnum,
        EntityApp: 'ENTITY_APP' as DataEnum,
        FrameworkApp: 'FRAMEWORK_APP' as DataEnum,
        IssuesApp: 'ISSUES_APP' as DataEnum,
        QuestionApp: 'QUESTION_APP' as DataEnum,
        RelationApp: 'RELATION_APP' as DataEnum,
        RiskApp: 'RISK_APP' as DataEnum,
        UserApp: 'USER_APP' as DataEnum,
        UserOperationsApp: 'USER_OPERATIONS_APP' as DataEnum,
        AuditApp: 'AUDIT_APP' as DataEnum,
        HubApp: 'HUB_APP' as DataEnum,
        TransparencyApp: 'TRANSPARENCY_APP' as DataEnum,
        PerformanceApp: 'PERFORMANCE_APP' as DataEnum,
        BcmApp: 'BCM_APP' as DataEnum
    };
}


