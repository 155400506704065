import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DataTypeEnum } from '@shared/classes';
import { DynamicComponentBase } from '@shared/classes/view/DynamicComponentBase';

@Component({
  selector: 'app-reset-switch-input',
  templateUrl: './reset-switch-input.component.html',
  styleUrls: ['./reset-switch-input.component.scss'],
})
export class ResetSwitchInputComponent implements OnInit {
  @Input() checked = true;
  @Output() onChange: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}
  setInputOptions() {}
  onValueChange(event) {
    if (event?.originalEvent) {
      event.originalEvent.stopPropagation();
      event.originalEvent.preventDefault();
    }
    this.onChange.emit(event);
  }
}
