/**
 * Sightflare Service
 * Rest Api for Sightflare Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { GroupAndUserExcludedItems } from './groupAndUserExcludedItems';


export interface GroupAndUserRequest { 
    excludedItems?: GroupAndUserExcludedItems;
    types?: Array<GroupAndUserRequest.TypesEnum>;
}
export namespace GroupAndUserRequest {
    export type TypesEnum = 'USERS' | 'GROUPS' | 'RESPONSIBILITY';
    export const TypesEnum = {
        Users: 'USERS' as TypesEnum,
        Groups: 'GROUPS' as TypesEnum,
        Responsibility: 'RESPONSIBILITY' as TypesEnum
    };
}


