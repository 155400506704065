import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { BaseResponse, ControlsGapReportDto, PolicyBody, RequestHandlerOptions } from '@shared/classes';
import { BaseRequestControllerService, DataService } from '@shared/services';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ControlsGapReportDataService extends BaseRequestControllerService<ControlsGapReportDto> {
  constructor(
    private dataService: DataService,
    private router: Router
  ) {
    super(dataService, environment.framework, '/controls-gaps-reports', [
      'id',
      'code',
      'tags',
      'tagsCodes',
      'name',
      'description',
      'date',
      'items',
      'targetCode',
      'control',
      'reportCode',
      'frequency',
      'policy',
      'complianceReportResults',
      'lockedBy',
      'lockedForEdit',
      'lockedUntil',
      'userAccessLevel',
      'recordStatus',
      'creatorName',
      'lastModifierName',
      'creationDate',
      'lastModificationDate',
      'complianceReportType'
    ]);
  }
  navigateToListPage() {
    this.router.navigateByUrl(`framework/controls-gap-report/list`);
  }
  public startReport(code, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    const newLocal = this.url + `/start-report/${code}`;
    return this.dataService.getData<BaseResponse<PolicyBody>>(newLocal, options) as Observable<BaseResponse<any>>;
  }
}
