import { FileViewerType } from '../view';

export const mimeToExtensionMap: { [key: string]: string } = {
  // Application MIME types
  'application/pdf': '.pdf',
  'application/msword': '.doc',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': '.docx',
  'application/vnd.ms-excel': '.xls',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': '.xlsx',
  'application/vnd.ms-powerpoint': '.ppt',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': '.pptx',
  'application/json': '.json',
  'application/zip': '.zip',
  'application/x-rar-compressed': '.rar',
  'application/x-tar': '.tar',
  'application/x-7z-compressed': '.7z',
  'application/x-bzip': '.bz',
  'application/x-bzip2': '.bz2',
  'application/javascript': '.js',
  'application/ld+json': '.jsonld',
  'application/xml': '.xml',
  'application/octet-stream': '', // Binary data; extension depends on the file type
  'application/epub+zip': '.epub',
  'application/x-shockwave-flash': '.swf',
  'application/x-font-woff': '.woff',
  'application/vnd.visio': '.vsd',
  'application/ogg': '.ogx',

  // Image MIME types
  'image/jpeg': '.jpg',
  'image/png': '.png',
  'image/gif': '.gif',
  'image/bmp': '.bmp',
  'image/webp': '.webp',
  'image/svg+xml': '.svg',
  'image/tiff': '.tiff',
  'image/x-icon': '.ico',

  // Audio MIME types
  'audio/mpeg': '.mp3',
  'audio/ogg': '.oga',
  'audio/wav': '.wav',
  'audio/x-wav': '.wav',
  'audio/x-aiff': '.aiff',
  'audio/midi': '.midi',
  'audio/webm': '.weba',

  // Video MIME types
  'video/mp4': '.mp4',
  'video/mpeg': '.mpeg',
  'video/ogg': '.ogv',
  'video/webm': '.webm',
  'video/x-msvideo': '.avi',
  'video/quicktime': '.mov',
  'video/x-flv': '.flv',

  // Text MIME types
  'text/plain': '.txt',
  'text/html': '.html',
  'text/css': '.css',
  'text/csv': '.csv',
  'text/xml': '.xml',
  'text/javascript': '.js',
  'text/markdown': '.md',

  // Font MIME types
  'font/otf': '.otf',
  'font/ttf': '.ttf',
  'font/woff': '.woff',
  'font/woff2': '.woff2',

  // Multipart MIME types
  'multipart/form-data': '',
  'multipart/related': '',
  'multipart/mixed': '',

  // Other MIME types
  'application/vnd.android.package-archive': '.apk',
  'application/x-apple-diskimage': '.dmg',
  'application/vnd.mozilla.xul+xml': '.xul',
  'application/x-www-form-urlencoded': '',
};

// Helper function to get the file extension based on the content type
export function getExtensionFromMimeType(contentType: string): string {
  return mimeToExtensionMap[contentType] || ''; // Return an empty string if the type isn't known
}

export function getFileViewerType(fileName: string) {
  function getFileExtension(filename: string): string {
    return filename.split('.').pop()?.toLowerCase() || '';
  }
  function isImageFile(filename: string): boolean {
    // prettier-ignore
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg', 'webp', 'tiff', 'tif'];
    const ext = getFileExtension(filename);
    return imageExtensions.includes(ext);
  }
  function isPdfFile(filename: string): boolean {
    return getFileExtension(filename) === 'pdf';
  }
  function isTextFile(filename: string): boolean {
    // prettier-ignore
    const textExtensions = [
          'txt', 'md', 'html', 'css', 'js', 'jsx', 'ts', 'tsx', 'json', 'php', 'py', 'java', 'cpp', 'c', 'cs', 'sh', 'rb', 'pl', 'sql', 'xml', 'yml', 'yaml', 'csv'
      ];
    const ext = getFileExtension(filename);
    return textExtensions.includes(ext);
  }
  function isVideoFile(filename: string): boolean {
    // prettier-ignore
    const extensions = [
          'mp4', 'mpeg', 'ogv', 'webm', 'avi', 'mov', 'flv',
      ];
    const ext = getFileExtension(filename);
    return extensions.includes(ext);
  }
  function isAudioFile(filename: string): boolean {
    // prettier-ignore
    const extensions = [
          'mp3', 'oga', 'wav', 'wav', 'aiff', 'midi', 'weba',
      ];
    const ext = getFileExtension(filename);
    return extensions.includes(ext);
  }
  if (isImageFile(fileName)) {
    return FileViewerType.IMAGE;
  } else if (isPdfFile(fileName)) {
    return FileViewerType.PDF;
  } else if (isTextFile(fileName)) {
    return FileViewerType.TEXT;
  } else if (isVideoFile(fileName)) {
    return FileViewerType.VIDEO;
  } else if (isAudioFile(fileName)) {
    return FileViewerType.AUDIO;
  } else {
    return FileViewerType.OTHER;
  }
}
