import {
  ComponentRef,
  Directive,
  EventEmitter,
  forwardRef,
  Injector,
  Input,
  OnInit,
  Output,
  ViewContainerRef,
} from '@angular/core';
import { AbstractControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { IDynamicComponent, isNullObj, IViewMode, UnsubscribeOnDestroyAdapter } from '@shared/classes';

@Directive({
  selector: '[appDynamicComponent]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DynamicComponentDirective),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DynamicComponentDirective),
      multi: true,
    },
  ],
})
export class DynamicComponentDirective extends UnsubscribeOnDestroyAdapter implements OnInit, Validator {
  //@NOTE: this directive handles both view only components and form fields components

  @Input() mode: IViewMode = IViewMode.create;
  @Input() dynamicComponentInfo: IDynamicComponent;
  private _liveData: any;
  @Input() set liveData(liveData: any) {
    this._liveData = liveData;
    if (!isNullObj(this.component?.instance?.setLiveData)) {
      this.component?.instance?.setLiveData(liveData);
    }
  }
  get liveData() {
    return this._liveData;
  }
  private _manualValue: any;
  @Input() set manualValue(value) {
    this._manualValue = value;
    if (!isNullObj(this.component?.instance?.manualPatchData) && this.manualValue !== undefined) {
      this.component?.instance?.manualPatchData(value, { emitEvent: false });
    }
  }
  get manualValue() {
    return this._manualValue;
  }
  // @Input() componentType: Type<any>; //The component Class Name to be the reference for creating new component

  @Output() changes: EventEmitter<any> = new EventEmitter();

  component: ComponentRef<any>;

  constructor(
    private injector: Injector, //Important for getting a NgControl instance to use for value accessor
    public viewContainerRef: ViewContainerRef
  ) {
    super();
  }
  ngOnInit(): void {
    if (this.mode == 'view') {
      this._renderViewComponent();
    }

    if (this.mode == 'edit' || this.mode == 'create') {
      this._renderEditComponent();
    }
  }
  _renderViewComponent() {
    this.component = this.viewContainerRef.createComponent(this.dynamicComponentInfo.componentType);
    this.component.instance.data = this.dynamicComponentInfo?.options?.data;
    if (this.component?.instance?.setBaseOptions != undefined)
      this.component?.instance?.setBaseOptions({ ...this.dynamicComponentInfo.options, viewMode: 'view' }); // set all passed info into the component
    if (this.component?.instance?.setInputOptions != undefined) this.component?.instance?.setInputOptions();
    if (this.component?.instance?.changes != undefined)
      this.subs.sink = this.component?.instance?.changes?.subscribe((data) => {
        this.changes.emit(data);
      });
    if (!isNullObj(this.component?.instance?.setLiveData)) {
      this.component?.instance?.setLiveData(this.liveData);
    }
    if (!isNullObj(this.component?.instance?.manualPatchData) && this.manualValue !== undefined) {
      this.component?.instance?.manualPatchData(this.manualValue, { emitEvent: false });
    }
  }
  _renderEditComponent() {
    this.component = this.viewContainerRef.createComponent(this.dynamicComponentInfo.componentType);
    this.component.instance.setBaseOptions({ ...this.dynamicComponentInfo.options, viewMode: this.mode }); // set all passed info into the component
    this.component.instance.setInputOptions();
    this.subs.sink = this.component.instance?.onChanges?.subscribe((data) => {
      this.changes.emit(data);
    });
    if (!isNullObj(this.component?.instance?.setLiveData)) {
      this.component?.instance?.setLiveData(this.liveData);
    }
    if (!isNullObj(this.component?.instance?.manualPatchData) && this.manualValue !== undefined) {
      this.component?.instance?.manualPatchData(this.manualValue, { emitEvent: false });
    }
  }

  registerOnValidatorChange?(fn: () => void): void {
    this.component.instance.registerOnValidatorChange(fn);
  }

  validate(control: AbstractControl): ValidationErrors {
    return this.component.instance.validate(control) || null;
  }

  setInputOptions() {}
}
