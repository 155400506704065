import { Injectable } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { DataTypeEnum, FieldDto, IFieldDefinition } from '@shared/classes';
import { DynamicFieldTypeSelectorComponent } from '@shared/components/selectors/dynamic-field-type-selector/dynamic-field-type-selector.component';
import { BasicInputComponent } from '@shared/components/ui/input-fields/basic-input/basic-input.component';
import { DropDownInputComponent } from '@shared/components/ui/input-fields/drop-down-input/drop-down-input.component';
import { BaseFieldDefinitionsService } from '@shared/services';

@Injectable({
  providedIn: 'root',
})
export class FieldsMappingService extends BaseFieldDefinitionsService {
  recordFields: IFieldDefinition[] = [
    {
      name: 'Name',
      key: 'name',
      dataType: DataTypeEnum.Text,
      required: true,
      showInTable: true,
      description: 'Name of the record',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
      formField: {
        componentType: BasicInputComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Enter a name',
          control: new FormControl('', Validators.required),
          dataType: DataTypeEnum.Text,
          showLabelInViewMode: false,
        },
      },
    },
    {
      name: 'Type',
      key: 'type',
      dataType: DataTypeEnum.Badge,
      required: true,
      showInTable: true,
      description: 'Type of the record',
      tableDefinition: {
        filter: {
          type: 'enum',
          display: 'menu',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          enumClass: FieldDto.TypeEnum,
        },
      },
      useInBulk: true,
      formField: {
        componentType: DynamicFieldTypeSelectorComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Search Types',
          control: new FormControl('', Validators.required),
        },
      },
    },
    {
      name: 'Category',
      key: 'fieldCategory',
      dataType: DataTypeEnum.Badge,
      required: true,
      showInTable: true,
      description: 'Category of the record',
      tableDefinition: {
        filter: {
          type: 'enum',
          display: 'menu',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          enumClass: FieldDto.FieldCategoryEnum,
        },
      },
      formField: {
        componentType: DropDownInputComponent,
        options: {
          label: '',
          name: '',
          control: new FormControl(null)
        },
      },
    },
  ];
  constructor() {
    super();
    this.setAllFields();
  }
}
