import { Router } from '@angular/router';
import { BaseRequestControllerService } from '@shared/services/api/base-request-controller.service';
import { ToastService } from '@shared/services/toast.service';
import { UnsubscribeOnDestroyAdapter } from '../controller/UnsubscribeOnDestroyAdapter';
import { toKebabCase } from '../helpers';
import { BreadCrumb } from '../model';
import { BasePage } from './BasePage';
import { ButtonColor, IAction } from './ButtonAction';
import { ImporterColumnDefinition } from './ImporterDefinitions';
import { MessageKeys } from './message-keys.enum';
import {
  ImportPageConfigParams,
  ItemPageConfigParams,
  PageConfigParams,
  PageStaticConfigParams,
} from './PageConfigParams';
import { IViewMode, TargetTypeEnum } from './view-enums';
declare const luckysheet;
declare const LuckyExcel;

export class ImportBasePage extends BasePage {
  /**
   * importer columns binding information
   *
   * @type {ImporterColumnDefinition}
   * @memberof ImportBasePage
   */
  public colDefinitions: ImporterColumnDefinition[] = [];
  /**
   * importer extra body fields to be added per element
   *
   * @type {any}
   * @memberof ImportBasePage
   */
  public extraBodyObjects: any = null;
  /**
   * Request service to handle creating items
   *
   * @type {BaseRequestControllerService<any>}
   * @memberof ImportBasePage
   */
  public requestService: BaseRequestControllerService<any>;

  /**
   * Relations table required mapper
   *
   * @type {TargetTypeEnum}
   * @memberof ImportBasePage
   */
  public fromType: TargetTypeEnum;

  /**
   * Decides if route has item id in it
   *
   * @type {Boolean}
   * @memberof ImportBasePage
   */
  public editMode: boolean = false;

  public SetPageValues(config: ImportPageConfigParams = new ImportPageConfigParams()) {
    this.breadCrumb = config.breadCrumb;
    this.colDefinitions = config.colDefinitions?.map((x) => {
      return { ...x };
    });
    this.extraBodyObjects = config.extraBodyObjects;
    // this.pageActions = config.pageActions ??  [];
    // this.initAsyncDropDownFields();
  }
  constructor(
    config: PageStaticConfigParams = new PageStaticConfigParams(),
    private reqService: BaseRequestControllerService<any>
  ) {
    super(config);
    this.requestService = reqService;
    this.moduleKeyword = config.moduleKeyword ?? '';
    this.routePrefix = config.routePrefix ? config.routePrefix + '/' : (config.routePrefix ?? '');
    this.routeMiddlefix = config.routeMiddlefix ? '/' + config.routeMiddlefix : (config.routeMiddlefix ?? '');
  }
  initAsyncDropDownFields() {}
}
