<div class="flex flex-column gap-2">
  @for (item of principleList; track item) {
    <div class="flex flex-row gap-2 align-items-center">
      @if (showModeIcon) {
        <i [class]="item?.modeIcon"></i>
      }
      <app-code-navigation
        [showTooltip]="true"
        [data]="item"
        [sendRequest]="item?.isResponsibility"
      ></app-code-navigation>
    </div>
  }
</div>
