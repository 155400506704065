<app-input-view-switch
  [showLabelInViewMode]="showLabelInViewMode"
  [mode]="viewMode"
  [data]="data || control?.value"
  [dataType]="dataType"
  [label]="label"
  [keepValueCallback]="setFieldResetable.bind(this)"
  [resetFieldCheckVisable]="resetFieldCheckVisable"
  [resetableChecked]="resetable"
>
  <div class="field" [class.mb-1]="control?.dirty && control?.hasError('required')">
    <span
      class="flex flex-row md:flex-nowrap flex-wrap gap-1 w-full algin-items-start"
      [class.p-float-label]="floatLabel"
    >
      @if (!floatLabel) {
        @if (label && !instanceInFormRepeater) {
          <label [for]="name || label" class="md:w-12rem md:max-w-12rem md:min-w-12rem w-full input-field-label"
            >{{ label }}
            @if (isControlRequired) {
              <span class="text-red-500"> *</span>
            }
            @if (resetFieldCheckVisable) {
              <br />
              <app-reset-switch-input
                [checked]="!resetable"
                (onChange)="setFieldResetable($event)"
              ></app-reset-switch-input>
            }
          </label>
        }
      }
      <p-calendar
        [showClear]="showClear"
        [showIcon]="showIcon"
        [placeholder]="placeholder"
        [inputId]="name"
        [dateFormat]="dateFormat"
        [hourFormat]="'24'"
        [showTime]="showTime"
        [rangeSeparator]="'to'"
        [ngModel]="
          selectionMode == 'range' && mode == 'year' && !fControl?.value?.length
            ? null
            : (fControl?.value | toDateObj: selectionMode != 'single')
        "
        (ngModelChange)="onDateChange($event)"
        [minDate]="minDate"
        [maxDate]="maxDate"
        [view]="mode"
        [selectionMode]="selectionMode"
        [appendTo]="appendTo"
        [disabled]="fControl?.disabled"
        [readonlyInput]="readOnly"
        class="w-full"
      >
        <!-- <ng-template pTemplate="date" let-Date="Date">
          <p>{{ Date }} test date</p>
        </ng-template>
        <ng-template pTemplate="decade" let-Date="Date">
          <p>{{ Date }} test decade</p>
        </ng-template> -->
      </p-calendar>
      @if (floatLabel) {
        @if (label && !instanceInFormRepeater) {
          <label [for]="name || label" class="md:w-12rem md:max-w-12rem md:min-w-12rem w-full input-field-label"
            >{{ label }}
            @if (isControlRequired) {
              <span class="text-red-500"> *</span>
            }
            @if (resetFieldCheckVisable) {
              <br />
              <app-reset-switch-input
                [checked]="!resetable"
                (onChange)="setFieldResetable($event)"
              ></app-reset-switch-input>
            }
          </label>
        }
      }
      <!-- <label *ngIf="floatLabel"  [for]="name">{{label}}<span *ngIf="isControlRequired" class="text-red-500"> *</span></label> -->
    </span>
    @if (control?.dirty) {
      @if (control?.hasError('required')) {
        <small class="p-error p-invalid">{{ label }} {{ 'is Required' }}</small>
      }
      @if (customError) {
        <small class="p-error p-invalid">{{ customErrorLabel }}</small>
      }
    }
  </div>
</app-input-view-switch>
