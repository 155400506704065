import { Component, OnInit } from '@angular/core';
import { DynamicComponentBase } from '@shared/classes/view/DynamicComponentBase';
import { DataTypeEnum } from '@shared/classes/view/view-enums';

@Component({
  selector: 'app-ipv4-mask-input',
  templateUrl: './ipv4-mask-input.component.html',
  styleUrls: ['./ipv4-mask-input.component.scss'],
})
export class Ipv4MaskInputComponent extends DynamicComponentBase implements OnInit {
  dataType: DataTypeEnum = DataTypeEnum.Text;
  value: string = '.../';
  disabledBlocks: boolean[] = [false, false, false, false, false];
  // macAddress: RegExp = /^(?:(?'1'[0-9a-fA-F]{2})(?:\:)(?'2'[0-9a-fA-F]{2})(?:\:)(?'3'[0-9a-fA-F]{2})(?:\:)(?'4'[0-9a-fA-F]{2})(?:\:)(?'5'[0-9a-fA-F]{2})(?:\:)(?'6'[0-9a-fA-F]{2}))$|^(?:(?'1'[0-9a-fA-F]{2})(?:\-)(?'2'[0-9a-fA-F]{2})(?:\-)(?'3'[0-9a-fA-F]{2})(?:\-)(?'4'[0-9a-fA-F]{2})(?:\-)(?'5'[0-9a-fA-F]{2})(?:\-)(?'6'[0-9a-fA-F]{2}))$/;
  constructor() {
    super();
  }

  ngOnInit(): void {}
  setInputOptions() {
    this.setValue();
    this.setDisabledBlocks();
  }

  setDisabledBlocks() {
    if (this.inputOptions.networkInput.disabledBlocks) {
      this.inputOptions.networkInput.disabledBlocks.forEach((x) => {
        this.disabledBlocks[x] = true;
      });
    }
  }

  setValue() {
    if (
      this.inputOptions.networkInput.defaultBlockValues &&
      this.inputOptions.networkInput.defaultBlockValues.length >= 5 &&
      !this.control.value
    ) {
      let val = '';

      for (let i = 0; i < 5; i++) {
        const element = this.inputOptions.networkInput.defaultBlockValues[i];
        if (i == 0) val = element;
        else if (i == 4) val = val + '/' + element;
        else val = val + '.' + element;
      }
      if (val) this.value = val;
    }
  }
}
