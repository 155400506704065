import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { GeneratedReportDto } from '@shared/classes';
import { BaseRequestControllerService, DataService } from '@shared/services';

@Injectable({
  providedIn: 'root',
})
export class GeneratedReportDataService extends BaseRequestControllerService<GeneratedReportDto> {
  constructor(
    private dataService: DataService,
    private router: Router
  ) {
    super(dataService, environment.dataStructure, '/generated-reports', [
      'id',
      'code',
      'tags',
      'tagsCodes',
      'lockedBy',
      'lockedForEdit',
      'lockedUntil',
      'userAccessLevel',
      'recordStatus',
      'creatorName',
      'lastModifierName',
      'creationDate',
      'lastModificationDate',
      'name',
      'type',
      'label',
      'targetType',
      'view',
      'notes',
      'runAs',
      'withGroups',
      'withoutGroups',
      'mainPrinciple',
      'report',
      'date',
      'status',
      'reportGeneration',
      'reportScheduler',
      'pdfRenderConfig',
      'format',
    ]);
  }

  navigateToListPage() {
    this.router.navigateByUrl(`data-management/generated-report/list`);
  }
}
