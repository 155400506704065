import { Injectable } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { DataTypeEnum, IFieldDefinition } from '@shared/classes';
import { BasicInputComponent } from '@shared/components/ui/input-fields/basic-input/basic-input.component';
import { TextEditorComponent } from '@shared/components/ui/input-fields/text-editor/text-editor.component';
import { BaseFieldDefinitionsService } from '@shared/services';

@Injectable({
  providedIn: 'root',
})
export class CitationTypeMappingService extends BaseFieldDefinitionsService {
  recordFields: IFieldDefinition[] = [
    {
      name: 'Name',
      key: 'name',
      dataType: DataTypeEnum.Text,
      required: true,
      showInTable: true,
      description: 'Name of the record',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
      useInBulk: true,
      formField: {
        componentType: BasicInputComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Enter Name',
          control: new FormControl('', Validators.required),
          dataType: DataTypeEnum.Text,
          showLabelInViewMode: false,
        },
      },
    },
    {
      name: "Description",
      key: "description",
      dataType: DataTypeEnum.LongText,
      required: false,
      showInTable: false,
      description: "Record Description",
      tableDefinition: {
        filter: {
          type: "text",
          display: "menu",
          matchMode: "contains",
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
      useInBulk: true,
      formField: {
        componentType: TextEditorComponent,
        options: {
          inputOptions: {
            textEditorInput: {
              advanced: true
            }
          },
          label: '',
          name: '',
          control: new FormControl(''),
          dataType: DataTypeEnum.Text,
          showLabelInViewMode: false,
        },
      },
    },
  ];
  constructor() {
    super();
    this.setAllFields();
  }
}
