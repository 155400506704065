import { Injectable } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { DataTypeEnum, EmailTemplateDto, IFieldDefinition, getEnumOptions } from '@shared/classes';
import { BasicInputComponent } from '@shared/components/ui/input-fields/basic-input/basic-input.component';
import { CheckBoxComponent } from '@shared/components/ui/input-fields/check-box/check-box.component';
import { DropDownInputComponent } from '@shared/components/ui/input-fields/drop-down-input/drop-down-input.component';
import { TextInputComponent } from '@shared/components/ui/input-fields/text-input/text-input.component';
import { BaseFieldDefinitionsService } from '@shared/services';

@Injectable({
  providedIn: 'root',
})
export class EmailTemplatesMappingService extends BaseFieldDefinitionsService {
  recordFields: IFieldDefinition[] = [
    {
      name: 'Name',
      key: 'name',
      dataType: DataTypeEnum.Text,
      required: true,
      showInTable: true,
      description: 'Name of the record',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
      useInBulk: true,
      formField: {
        componentType: BasicInputComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Enter a name',
          control: new FormControl('', Validators.required),
          dataType: DataTypeEnum.Text,
          showLabelInViewMode: false,
        },
      }
    },
    {
      name: 'Subject',
      key: 'subjectTemplate',
      dataType: DataTypeEnum.Text,
      required: true,
      showInTable: true,
      description: 'Subject template of the record',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
      useInBulk: true,
      formField: {
        componentType: TextInputComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Enter Text...',
          control: new FormControl('', Validators.required),
          dataType: DataTypeEnum.Text,
          showLabelInViewMode: false,
          inputOptions: {
            textInput: {
              rows: 2,
              cols: 0
            },
          },
        },
      },
    },
    {
      name: 'Template Type',
      key: 'templateType',
      dataType: DataTypeEnum.Badge,
      required: true,
      showInTable: true,
      description: 'Template Type of the record',
      tableDefinition: {
        filter: {
          type: 'enum',
          display: 'menu',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          enumClass: EmailTemplateDto.TemplateTypeEnum,
        },
      },
      useInBulk: true,
      formField: {
        componentType: DropDownInputComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Select Item',
          control: new FormControl('', Validators.required),
          dataType: DataTypeEnum.Text,
          showLabelInViewMode: false,
          inputOptions: {
            dropDownInput: {
              optionLabel: 'label',
              optionValue: 'value',
              appendTo: 'body',
              items: getEnumOptions(EmailTemplateDto.TemplateTypeEnum),
              multi: false
            },
          },
        },
      }
    },
    {
      name: 'Apply To',
      key: 'applyTo',
      dataType: DataTypeEnum.Badge,
      required: true,
      showInTable: true,
      description: 'The record applies to what?',
      tableDefinition: {
        filter: {
          type: 'enum',
          display: 'menu',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          enumClass: EmailTemplateDto.ApplyToEnum,
        },
      },
      useInBulk: true,
      formField: {
        componentType: DropDownInputComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Select Item',
          control: new FormControl('', Validators.required),
          dataType: DataTypeEnum.Text,
          showLabelInViewMode: false,
          inputOptions: {
            dropDownInput: {
              optionLabel: 'label',
              optionValue: 'value',
              appendTo: 'body',
              items: getEnumOptions(EmailTemplateDto.ApplyToEnum),
              multi: false
            },
          },
        },
      }
    },
    {
      name: 'System Generated',
      key: 'systemGenerated',
      dataType: DataTypeEnum.Badge,
      required: false,
      showInTable: true,
      description: 'Is the record system generated?',
      tableDefinition: {
        filter: {
          type: 'boolean',
          display: 'menu',
          matchMode: 'equals',
        },
      },
      formField: {
        componentType: CheckBoxComponent,
        options: {
          label: '',
          name: '',
          control: new FormControl(null)
        }
      }
    },
    {
      name: 'Default',
      key: 'isDefault',
      dataType: DataTypeEnum.Badge,
      required: false,
      showInTable: true,
      description: 'Is the record default?',
      tableDefinition: {
        filter: {
          type: 'boolean',
          display: 'menu',
          matchMode: 'equals',
        },
      },
      formField: {
        componentType: CheckBoxComponent,
        options: {
          label: '',
          name: '',
          control: new FormControl(null)
        }
      }
    },
  ];
  constructor() {
    super();
    this.setAllFields();
  }
}
