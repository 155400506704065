import { Component, Input, OnInit } from '@angular/core';
import { DataTypeEnum, isNullObj } from '@shared/classes';
import { DynamicComponentBase } from '@shared/classes/view/DynamicComponentBase';
import { isDate } from 'lodash-es';

@Component({
  selector: 'app-date-input',
  templateUrl: './date-input.component.html',
  styleUrls: ['./date-input.component.scss'],
  providers: [{ provide: DynamicComponentBase, useExisting: DateInputComponent }],
})
export class DateInputComponent extends DynamicComponentBase implements OnInit {
  @Input() showIcon = true;
  @Input() showTime = false;
  @Input() showClear = false; //@Note: doesn't work with showIcon review primeng changes
  private _minDate = null;
  get minDate() {
    return this._minDate;
  }
  @Input() set minDate(minDate) {
    this._minDate = isNullObj(minDate) ? null : isDate(minDate) ? minDate : new Date(Date.parse(minDate));
  }
  private _maxDate = null;
  get maxDate() {
    return this._maxDate;
  }
  @Input() set maxDate(maxDate) {
    this._maxDate = isNullObj(maxDate) ? null : isDate(maxDate) ? maxDate : new Date(Date.parse(maxDate));
  }
  @Input() selectionMode: 'single' | 'multiple' | 'range' = 'single';
  @Input() mode: 'date' | 'month' | 'year' = 'date';
  @Input() appendTo: any = null;
  dataType: DataTypeEnum = DataTypeEnum.DateLong;
  modelData = [];
  constructor() {
    super();
  }
  rangeDates: Date[] | undefined | null = null;

  ngOnInit(): void { }
  setInputOptions() {
    this.showIcon = this.inputOptions?.dateInput?.showIcon ?? this.showIcon;
    this.showClear = this.inputOptions?.dateInput?.showClear ?? this.showClear;
    this.showTime = this.inputOptions?.dateInput?.showTime ?? this.showTime;
    this.minDate = this.inputOptions?.dateInput?.minDate ?? this.minDate;
    this.maxDate = this.inputOptions?.dateInput?.maxDate ?? this.maxDate;
    this.selectionMode = this.inputOptions?.dateInput?.selectionMode ?? this.selectionMode;
    this.mode = this.inputOptions?.dateInput?.mode ?? this.mode;
  }
  onDateChange(date) {
    this.control.patchValue(date);
  }

  get dateFormat() {
    return dateFormatMapper[this.mode]
  }
}

const dateFormatMapper = {
  'date': 'yy-mm-dd',
  'month': 'yy-mm',
  'year': 'yy'
} 