import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  private _loader = new BehaviorSubject(false);
  loadingState = this._loader.asObservable();

  get loader() {
    return this._loader.getValue();
  }
  set loader(state: boolean) {
    this._loader.next(state);
  }
  constructor() {}

  toggleLoader(state: boolean = null) {
    state = state == null ? !this.loader : state;
    this.loader = state;
  }
}
