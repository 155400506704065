import { Pipe, PipeTransform } from '@angular/core';
import { IAction } from '@shared/classes/view/ButtonAction';

@Pipe({
  name: 'getButtonClass',
})
export class GetButtonClassPipe implements PipeTransform {
  transform(action: IAction, ...args: any[]): string {
    let color = action.color ? 'p-button-' + action.color : '';
    let rounded =
      action.buttonStyle == 'rounded' ||
      action.buttonStyle == 'outlined-rounded' ||
      action.buttonStyle == 'rounded-text'
        ? 'p-button-rounded'
        : '';
    let text =
      action.buttonStyle == 'text' || action.buttonStyle == 'raised-text' || action.buttonStyle == 'rounded-text'
        ? 'p-button-text'
        : '';
    let outlined =
      action.buttonStyle == 'outlined' || action.buttonStyle == 'outlined-rounded' ? 'p-button-outlined' : '';
    let raised = action.buttonStyle == 'raised' || action.buttonStyle == 'raised-text' ? 'p-button-raised' : '';
    return color + ' ' + rounded + ' ' + text + ' ' + outlined + ' ' + raised + ' ' + (action.buttonClass ?? '');
  }
}
