import { Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';
import { BehaviorSubject } from 'rxjs';
import { loggedInUser } from '../../models';

@Injectable({
  providedIn: 'root',
})
export class JWTTokenService {
  jwtToken: string;
  decodedToken: { [key: string]: any };

  private _token = new BehaviorSubject(null);
  tokenState = this._token.asObservable();

  constructor() {}

  setToken(token: string): void {
    if (token) {
      this.jwtToken = token;
      this._token.next(token);
    }
  }

  decodeToken() {
    if (this.jwtToken) {
      this.decodedToken = jwt_decode(this.jwtToken);
    }
  }

  getDecodeToken(): loggedInUser {
    if (this.jwtToken) {
      return jwt_decode(this.jwtToken);
    } else {
      return null;
    }
  }

  getUser() {
    this.decodeToken();
    return this.decodedToken ? this.decodedToken.displayname : null;
  }

  getPreferredUsername() {
    this.decodeToken();

    return this.decodedToken ? this.decodedToken.preferred_username : null;
  }
  getSfAdmin() {
    this.decodeToken();
    return this.decodedToken ? this.decodedToken.sf_sys_admin : null;
  }

  getPermissions() {
    this.decodeToken();
    let perms: string[] = [];
    if (this.decodedToken) {
      Object.keys(this.decodedToken.resource_access).forEach((key) => {
        perms = [...perms, ...this.decodedToken.resource_access[key].roles];
      });
      return perms;
    } else {
      return null;
    }
  }

  getEmailId() {
    this.decodeToken();
    return this.decodedToken ? this.decodedToken.email : null;
  }

  getExpiryTime() {
    this.decodeToken();
    return this.decodedToken ? this.decodedToken.exp : null;
  }

  isTokenExpired(): boolean {
    const expiryTime = this.getExpiryTime();
    if (expiryTime) {
      return 1000 * expiryTime - new Date().getTime() < 5000;
    } else {
      return false;
    }
  }
}
