import { Injectable } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { DataTypeEnum, Guideline, IFieldDefinition, getEnumOptions } from '@shared/classes';
import { AttachmentInputComponent } from '@shared/components/ui/input-fields/attachment-input/attachment-input.component';
import { BasicInputComponent } from '@shared/components/ui/input-fields/basic-input/basic-input.component';
import { DropDownInputComponent } from '@shared/components/ui/input-fields/drop-down-input/drop-down-input.component';
import { TextEditorComponent } from '@shared/components/ui/input-fields/text-editor/text-editor.component';
import { BaseFieldDefinitionsService } from '@shared/services';

@Injectable({
  providedIn: 'root',
})
export class GuidelineMappingService extends BaseFieldDefinitionsService {
  recordFields: IFieldDefinition[] = [
    {
      name: 'Name',
      key: 'name',
      dataType: DataTypeEnum.Text,
      required: true,
      showInTable: true,
      description: 'Record Name',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
      importerDefinition: {
        type: 'text',
        field: {
          validators: null,
        },
      },
      formField: {
        componentType: BasicInputComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Enter Name',
          control: new FormControl('', Validators.required),
          dataType: DataTypeEnum.Text,
          showLabelInViewMode: false,
        },
      },
    },
    {
      name: 'Status',
      key: 'status',
      dataType: DataTypeEnum.Badge,
      required: false,
      showInTable: true,
      // permissions: AppPermissions.ReadGroup,
      description: 'Guidline Status',
      tableDefinition: {
        filter: {
          type: 'enum',
          display: 'menu',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          enumClass: Guideline.StatusEnum,
        },
      },
      formField: {
        componentType: DropDownInputComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Select Item',
          control: new FormControl(null),
          inputOptions: {
            dropDownInput: {
              optionLabel: 'label',
              optionValue: 'value',
              appendTo: 'body',
              items: getEnumOptions(Guideline.StatusEnum),
              multi: false
            },
          },
        },
      },
    },
    {
      name: 'Details',
      key: 'details',
      dataType: DataTypeEnum.LongWithHoverText,
      required: false,
      showInTable: false,
      description: 'Record Details',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
      importerDefinition: {
        type: 'text',
        field: {
          validators: null,
        },
      },
      formField: {
        componentType: TextEditorComponent,
        options: {
          inputOptions: {
            textEditorInput: {
              advanced: true
            }
          },
          label: '',
          name: '',
          control: new FormControl(''),
          dataType: DataTypeEnum.Text,
          showLabelInViewMode: false,
        },
      },
    },
    {
      name: 'Description',
      key: 'description',
      dataType: DataTypeEnum.LongWithHoverText,
      required: false,
      showInTable: false,
      description: 'Record Description',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
      importerDefinition: {
        type: 'text',
        field: {
          validators: null,
        },
      },
      formField: {
        componentType: TextEditorComponent,
        options: {
          inputOptions: {
            textEditorInput: {
              advanced: true
            }
          },
          label: '',
          name: '',
          control: new FormControl(''),
          dataType: DataTypeEnum.Text,
          showLabelInViewMode: false,
        },
      },
    },

    {
      name: 'Attachments',
      key: 'attachments',
      dataType: DataTypeEnum.Text,
      required: false,
      showInTable: false,
      description: 'Cases linked to the record',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
      formField: {
        componentType: AttachmentInputComponent,
        options: {
          label: 'Attachments',
          name: 'attachments',
          control: new FormControl(''),
          dataType: DataTypeEnum.Text,
          showLabelInViewMode: false,
          inputOptions: {
            attachmentInput: {
              multiple: true,
              uploadLocation: 'CODE',
              bucketId: '',
              mode: 'basic',
              accept: '',
              maxFileSize: 0,
              fileLimit: 0
            },
          },
        },
      },
    },
  ];
  constructor() {
    super();
    this.setAllFields();
  }
}
