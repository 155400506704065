import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AppPermissions, AttestationDto, DataTypeEnum, IFieldDefinition, getEnumOptions } from '@shared/classes';
import { DropDownInputComponent } from '@shared/components/ui/input-fields/drop-down-input/drop-down-input.component';
import { BaseFieldDefinitionsService } from '@shared/services';

@Injectable({
  providedIn: 'root',
})
export class AttestationsMappingService extends BaseFieldDefinitionsService {
  recordFields: IFieldDefinition[] = [
    {
      name: 'Questionnaire Answer',
      key: 'questionnaireValueId',
      dataType: DataTypeEnum.CodeLink,
      required: false,
      showInTable: true,
      permissions: AppPermissions.ReadQuestionnaireValue,
      description: 'Questionnaire Answer',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
    {
      name: 'Compliance Status',
      key: 'controlComplianceStatus',
      dataType: DataTypeEnum.Badge,
      required: true,
      showInTable: true,
      description: 'Control Compliance Status',
      tableDefinition: {
        filter: {
          type: 'enum',
          display: 'menu',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          enumClass: AttestationDto.ControlComplianceStatusEnum,
        },
      },
      useInBulk: true,
      formField: {
        componentType: DropDownInputComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Select Item',
          control: new FormControl(null),
          dataType: DataTypeEnum.Text,
          showLabelInViewMode: false,
          inputOptions: {
            dropDownInput: {
              optionLabel: 'label',
              optionValue: 'value',
              appendTo: 'body',
              items: getEnumOptions(AttestationDto.ControlComplianceStatusEnum),
              multi: false
            },
          },
        },
      },
    },
    {
      name: 'Controls',
      key: 'controls',
      dataType: DataTypeEnum.Text,
      required: false,
      showInTable: true,
      description: 'Record Linked Controls',
      tableDefinition: {
        filter: {
          type: 'text',
          matchMode: 'startsWith',
        },
      },
    },
  ];
  constructor() {
    super();
    this.setAllFields();
  }
}
