/**
 * Sightflare Service
 * Rest Api for Sightflare Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ApiResponseExemptionEvent { 
    status?: number;
    data?: ApiResponseExemptionEvent.DataEnum;
    error?: boolean;
    errorMessage?: string;
}
export namespace ApiResponseExemptionEvent {
    export type DataEnum = 'TO_DRAFT' | 'TO_SUBMITTED' | 'TO_REJECTED' | 'TO_APPROVED' | 'TO_RETIRED';
    export const DataEnum = {
        Draft: 'TO_DRAFT' as DataEnum,
        Submitted: 'TO_SUBMITTED' as DataEnum,
        Rejected: 'TO_REJECTED' as DataEnum,
        Approved: 'TO_APPROVED' as DataEnum,
        Retired: 'TO_RETIRED' as DataEnum
    };
}


