/**
 * Sightflare Service
 * Rest Api for Sightflare Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ApiResponseTestingExerciseEvent { 
    status?: number;
    data?: ApiResponseTestingExerciseEvent.DataEnum;
    error?: boolean;
    errorMessage?: string;
}
export namespace ApiResponseTestingExerciseEvent {
    export type DataEnum = 'TO_DRAFT' | 'TO_SUBMITTED' | 'TO_APPROVED' | 'TO_STARTED' | 'TO_FAIL' | 'TO_PASS' | 'TO_PASS_WITH_OBSERVATION';
    export const DataEnum = {
        Draft: 'TO_DRAFT' as DataEnum,
        Submitted: 'TO_SUBMITTED' as DataEnum,
        Approved: 'TO_APPROVED' as DataEnum,
        Started: 'TO_STARTED' as DataEnum,
        Fail: 'TO_FAIL' as DataEnum,
        Pass: 'TO_PASS' as DataEnum,
        PassWithObservation: 'TO_PASS_WITH_OBSERVATION' as DataEnum
    };
}


