import { Component, Input, OnInit } from '@angular/core';
import { DataTypeEnum } from '@shared/classes';
import { DynamicComponentBase } from '@shared/classes/view/DynamicComponentBase';

@Component({
  selector: 'app-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss'],
  providers: [{ provide: DynamicComponentBase, useExisting: TextInputComponent }],
})
export class TextInputComponent extends DynamicComponentBase implements OnInit {
  @Input() rows = 5;
  @Input() cols = 30;
  dataType: DataTypeEnum = DataTypeEnum.Text;
  constructor() {
    super();
  }

  ngOnInit(): void {}

  setInputOptions() {
    this.rows = this.inputOptions?.textInput?.rows ?? this.rows;
    this.cols = this.inputOptions?.textInput?.cols ?? this.cols;
  }
}
