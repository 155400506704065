import {
  AfterViewInit,
  Directive,
  EventEmitter,
  Input,
  Output,
  QueryList,
  Signal,
  ViewChildren,
  computed,
} from '@angular/core';
import { FormControl, FormControlStatus, FormGroup } from '@angular/forms';
import { LoaderService } from '@shared/services/loader.service';
import { ToastService } from '@shared/services/toast.service';
import { ViewModeService } from '@shared/services/view-mode.service';
import { AppInjector } from 'app/app-injector';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Base } from '../controller';
import { isDocumentCode, markFormGroupTouched, routeToLocaleCase, toCamelCase } from '../helpers';
import { LinkedRelationItem } from '../model/backend/audit/model/linkedRelationItem';
import { IAction } from './ButtonAction';
import { ModuleKeywordRootPath } from './CodeMapper';
import { DynamicComponentBase } from './DynamicComponentBase';
import { ModuleKeywords } from './ModuleKeywords';
import { IViewMode } from './view-enums';

@Directive()
export abstract class BaseForm<T> extends Base implements AfterViewInit {
  private formValid = new Subject<boolean>();
  formValid$ = this.formValid.asObservable();
  private loadingState = new Subject<boolean>();
  loadingState$ = this.loadingState.asObservable();
  linkedManualRelations: LinkedRelationItem[];
  @Input() data: T;
  @Input() type: FormComponentModeEnum;
  @Input() showSaveAndClose: boolean = true;
  @Input() popupOptions: any = {};
  @Input() viewModeOnly: boolean = false;
  @Input() cancelAction: Function;
  @Input() submitButtonAction: IAction = {
    id: 1,
    label: 'Save & Close',
    buttonType: 'button',
    command: this.onSubmitForm.bind(this),
    icon: 'pi pi-save',
    // status$: this.formValid$,
    loading$: this.loadingState$,
    // buttonStyle: 'outlined'
  };
  @Input() submitSaveButtonAction: IAction = {
    id: 3,
    label: 'Save',
    buttonType: 'button',
    command: this.onSubmitFormSave.bind(this),
    icon: 'pi pi-save',
    // status$: this.formValid$,
    loading$: this.loadingState$,
  };
  @Input() submitInPlaceButtonAction: IAction = {
    id: 4,
    label: 'Save',
    buttonType: 'button',
    command: this.onSubmitFormInPlace.bind(this),
    icon: 'pi pi-save',
    // status$: this.formValid$,
    loading$: this.loadingState$,
  };
  @Input() cancelButtonAction: IAction = {
    id: 2,
    label: 'Cancel',
    buttonType: 'button',
    command: this.onCancel.bind(this),
    // icon: "pi pi-times",
    color: 'secondary',
    // buttonStyle: 'outlined'
  };
  @Output() formChanges: EventEmitter<T> = new EventEmitter();
  @Output() formStatusChanged: EventEmitter<FormControlStatus> = new EventEmitter();
  @Output() formSubmit: EventEmitter<T> = new EventEmitter();
  @Output() formSubmitSave: EventEmitter<T> = new EventEmitter();
  @Output() formSubmitInPlace: EventEmitter<T> = new EventEmitter();
  @Output() cancelEvent: EventEmitter<T> = new EventEmitter();
  @Output() valueEditChanged: EventEmitter<boolean> = new EventEmitter();
  @ViewChildren(DynamicComponentBase) inputFields!: QueryList<DynamicComponentBase>;
  // @ViewChildren(DynamicFieldFormListInputComponent) dynamicListFeilds!: QueryList<DynamicFieldFormListInputComponent>;

  @Input() set formData(data: T) {
    this.setData(data);
  }
  @Input() set resetForm(event) {
    if (event) {
      //this.formGroup.reset();
      this.resetFormGroup();
    }
  }

  formGroup: FormGroup;
  // @ViewChild('form') form: NgForm;

  readOnly: boolean;

  // formDeff: { //@TODO implement code wise generated form when the system needs it
  //     name: string;
  //     fields: Ifield[];
  // };
  componentMode = FormComponentModeEnum;
  inPlaceCreateControl: FormControl = new FormControl(false);
  formValidSignal: Signal<boolean> = computed(() => this.formGroup.valid);
  private formModuleKeyword: ModuleKeywords;
  constructor(
    public viewModeService: ViewModeService,
    moduleKeyword?: ModuleKeywords
  ) {
    super();
    this.formModuleKeyword = moduleKeyword;
    this.initFormStructure();
  }
  ngAfterViewInit(): void {
    this._setupSubscriptions();
  }

  protected _setupSubscriptions(): void {
    this.formGroup.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((data) => {
      this.formChanges.emit(this.getRealtimeData());
      this.valueEditChanged.emit(this.getChangedFormValues().hasChanges);
    });
    this.formGroup.statusChanges.pipe(takeUntil(this.destroy$), distinctUntilChanged()).subscribe((data) => {
      this.formStatusChanged.emit(data);
      setTimeout(() => {
        this.checkFormIsValid(data);
      }, 10);
      this.checkFormIsValid(data);
    });
    setTimeout(() => {
      this.checkFormIsValid(this.formGroup.status);
    }, 10);
    this.inputFields.changes.subscribe((changes) => {});
    // this.dynamicListFeilds.changes.subscribe((changes) => {

    // });
    this.inPlaceCreateControl.valueChanges.subscribe((value) => {
      this.inputFields?.forEach((field) => {
        if (field) {
          const currentField = field as any;
          if (currentField.dynamicFieldLists) {
            currentField.dynamicFieldLists.forEach((dynamicField) => {
              if (dynamicField?.inputFields) {
                dynamicField?.inputFields.forEach((innerFields) => {
                  if (innerFields?.component) {
                    innerFields?.component?.instance?.setFieldResetCheckerVisablity(value);
                    if (value) {
                      innerFields?.component?.instance?.setFieldResetable(true);
                    }
                  }
                });
              }
            });
          } else if (currentField.inputFields) {
            currentField?.inputFields.forEach((innerFields) => {
              if (innerFields?.component) {
                innerFields?.component?.instance?.setFieldResetCheckerVisablity(value);
                if (value) {
                  innerFields?.component?.instance?.setFieldResetable(true);
                }
              }
            });
          } else {
            field.setFieldResetCheckerVisablity(value);
            if (value) {
              field.setFieldResetable(true);
            }
          }
        }
      });
    });
    this.checkFormIsValid(this.formGroup.status);
    this.formStatusChanged.emit(this.formGroup.status);
    const loaderService = AppInjector.get(LoaderService);
    loaderService.loadingState.pipe(takeUntil(this.destroy$)).subscribe((loadingState) => {
      this.loadingState.next(loadingState);
    });
  }

  abstract getData(): any;
  abstract setData(data: T): any;
  abstract initFormStructure(): void;
  getRealtimeData() {
    return this.formGroup.getRawValue();
  }
  onSubmitForm() {
    if (this.viewModeService.viewMode == 'list') {
      this.viewModeService.showConfirmation(
        {
          accept: () => {
            this.formSubmit.emit(this.getData());
          },
        },
        { message: 'This Will Change All Filtered Table Items, Proceed?' }
      );
    } else {
      if (this.formGroup?.valid) {
        this.formSubmit.emit(this.getData());
      } else {
        markFormGroupTouched(this.formGroup);
        const toastService = AppInjector.get(ToastService);
        toastService.warn('Validation Error', 'Please Fill Form Correctly');
      }
    }
  }
  onSubmitFormSave() {
    if (this.viewModeService.viewMode == 'list') {
      this.viewModeService.showConfirmation(
        {
          accept: () => {
            this.formSubmitSave.emit(this.getData());
          },
        },
        { message: 'This Will Change All Filtered Table Items, Proceed?' }
      );
    } else {
      if (this.formGroup.valid) {
        this.formSubmitSave.emit(this.getData());
      } else {
        markFormGroupTouched(this.formGroup);
        const toastService = AppInjector.get(ToastService);
        toastService.warn('Validation Error', 'Please Fill Form Correctly');
      }
    }
  }
  onSubmitFormInPlace() {
    if (this.viewModeService.viewMode == 'list') {
      this.viewModeService.showConfirmation(
        {
          accept: () => {
            this.formSubmitInPlace.emit(this.getData());
          },
        },
        { message: 'This Will Change All Filtered Table Items, Proceed?' }
      );
    } else {
      if (this.formGroup.valid) {
        this.formSubmitInPlace.emit(this.getData());
      } else {
        markFormGroupTouched(this.formGroup);
        const toastService = AppInjector.get(ToastService);
        toastService.warn('Validation Error', 'Please Fill Form Correctly');
      }
    }
  }
  onCancel() {
    this.cancelEvent.emit(this.data);
  }
  cancel() {
    this.cancelAction();
  }
  checkFormIsValid(formState: FormControlStatus) {
    if (this.viewModeService.viewMode == 'list') {
      this.formValid.next(true);
    } else {
      this.formValid.next(formState == 'VALID');
    }
  }
  checkFormValueChanged() {
    let formCurrentData = { ...this.formGroup.getRawValue() };
    let valueChanged = false;
    Object.keys(formCurrentData).forEach((key) => {
      if (!this.data || formCurrentData[key] != this.data[key]) valueChanged = true;
    });
    return valueChanged;
  }
  getChangedFormValues(formCurrentData = { ...this.formGroup.getRawValue() }) {
    let valueChanged = false;
    let changedFields: { key: string; value: any }[] = [];
    Object.keys(formCurrentData).forEach((key) => {
      if (
        this.checkValueChanged(formCurrentData, key)
        // !this.data ||
        // &&
        // formCurrentData[key] != null &&
        // formCurrentData[key] != ""
      ) {
        valueChanged = true;
        changedFields.push({ key: key, value: formCurrentData[key] !== undefined ? formCurrentData[key] : null });
      }
    });

    return { hasChanges: valueChanged, updateItems: changedFields };
  }
  checkValueChanged(formCurrentData: any, key: string) {
    return this.data && formCurrentData[key] != this.data[key];
  }
  getDataKeyValueFormat(formCurrentData = { ...this.formGroup.getRawValue() }, createMode = true) {
    let createItems: { key: string; value: any }[] = [];
    let linkedDocuments: string[] = [];
    Object.keys(formCurrentData).forEach((key) => {
      if (formCurrentData[key] != undefined && formCurrentData[key] != null && formCurrentData[key] !== '') {
        if (key === 'dynamics') {
          Object.keys(formCurrentData[key]).forEach((element) => {
            if (Array.isArray(formCurrentData?.[key]?.[element]?.value)) {
              formCurrentData?.[key]?.[element]?.value?.forEach((val) => {
                const isDocument = isDocumentCode(val, /(DOC-\d+)(.*)/);
                if (isDocument && createMode) {
                  linkedDocuments.push(val);
                }
              });
            } else {
              const isDocument = isDocumentCode(formCurrentData?.[key]?.[element]?.value, /(DOC-\d+)(.*)/);
              if (isDocument && createMode) {
                linkedDocuments.push(formCurrentData?.[key]?.[element]?.value);
              }
            }
          });
        }
        createItems.push({ key: key, value: formCurrentData[key] });
      }
    });
    return {
      createItems: createItems,
      linkedDocuments: linkedDocuments,
      linkedManualRelations: this.linkedManualRelations,
    };
  }

  resetFormGroup() {
    this.inputFields.forEach((field) => {
      const currentField = field as any;
      if (currentField.dynamicFieldLists) {
        currentField.dynamicFieldLists.forEach((dynamicField) => {
          if (dynamicField?.inputFields) {
            dynamicField?.inputFields.forEach((innerFields) => {
              if (innerFields?.component) {
                if (innerFields?.component?.instance?.resetable) {
                  innerFields?.component?.instance?.control.reset();
                }
              }
            });
          }
        });
      } else if (currentField.inputFields) {
        currentField?.inputFields.forEach((innerFields) => {
          if (innerFields?.component) {
            if (innerFields?.component?.instance?.resetable) {
              innerFields?.component?.instance?.control.reset();
            }
          }
        });
      } else {
        if (field.resetable) {
          field.control.reset();
        }
      }
    });
  }

  get fieldViewMode(): IViewMode {
    return this.viewModeOnly ? 'view' : this.viewModeService.viewMode;
  }
  get showSaveButton(): boolean {
    const vMode = this.viewModeOnly ? 'view' : this.viewModeService.viewMode;
    return (
      !this.inPlaceCreateControl.value &&
      (vMode == 'edit' || vMode == 'create' || (!this.showSaveAndClose && vMode != 'view'))
    );
  }
  get showInPlaceButton(): boolean {
    return this.inPlaceCreateControl.value;
  }
  get moduleFieldString(): string {
    return `modules.${routeToLocaleCase(ModuleKeywordRootPath[this.formModuleKeyword])}.${toCamelCase(this.formModuleKeyword)}.fields`;
  }
}
export enum FormComponentModeEnum {
  create = 0,
  update = 1,
}
