/**
 * Sightflare Service
 * Rest Api for Sightflare Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface StatisticViewConfig { 
    collectorCode?: string;
    viewType?: StatisticViewConfig.ViewTypeEnum;
    useSystemColors?: boolean;
    dataPosition?: StatisticViewConfig.DataPositionEnum;
    colorPalette?: Array<string>;
    chartType?: StatisticViewConfig.ChartTypeEnum;
    chartVariation?: StatisticViewConfig.ChartVariationEnum;
}
export namespace StatisticViewConfig {
    export type ViewTypeEnum = 'DATA' | 'CHART' | 'CHART_AND_DATA';
    export const ViewTypeEnum = {
        Data: 'DATA' as ViewTypeEnum,
        Chart: 'CHART' as ViewTypeEnum,
        ChartAndData: 'CHART_AND_DATA' as ViewTypeEnum
    };
    export type DataPositionEnum = 'UP' | 'DOWN' | 'LEFT' | 'RIGHT';
    export const DataPositionEnum = {
        Up: 'UP' as DataPositionEnum,
        Down: 'DOWN' as DataPositionEnum,
        Left: 'LEFT' as DataPositionEnum,
        Right: 'RIGHT' as DataPositionEnum
    };
    export type ChartTypeEnum = 'TIME_SCALE' | 'BAR_RACE' | 'LINE_RACE';
    export const ChartTypeEnum = {
        TimeScale: 'TIME_SCALE' as ChartTypeEnum,
        BarRace: 'BAR_RACE' as ChartTypeEnum,
        LineRace: 'LINE_RACE' as ChartTypeEnum
    };
    export type ChartVariationEnum = 'STANDARD' | 'LINE' | 'AREA';
    export const ChartVariationEnum = {
        Standard: 'STANDARD' as ChartVariationEnum,
        Line: 'LINE' as ChartVariationEnum,
        Area: 'AREA' as ChartVariationEnum
    };
}


