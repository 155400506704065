import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DataTypeEnum, IFieldDefinition, PDFRenderConfig, ReportConfigurationTemplateDto, getEnumOptions } from '@shared/classes';
import { BasicInputComponent } from '@shared/components/ui/input-fields/basic-input/basic-input.component';
import { DropDownInputComponent } from '@shared/components/ui/input-fields/drop-down-input/drop-down-input.component';
import { SwitchInputComponent } from '@shared/components/ui/input-fields/switch-input/switch-input.component';
import { RunAsInputComponent } from '@shared/components/ui/input-fields/wrapped-components/run-as-input/run-as-input/run-as-input.component';
import { BaseFieldDefinitionsService } from '@shared/services';

@Injectable({
  providedIn: 'root',
})
export class ReportConfigurationTemplateMappingService extends BaseFieldDefinitionsService {
  recordFields: IFieldDefinition[] = [
    {
      name: 'Name',
      key: 'name',
      dataType: DataTypeEnum.Text,
      required: true,
      showInTable: true,
      description: 'Name of the record',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
      formField: {
        componentType: BasicInputComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Enter a name',
          control: new FormControl(''),
          dataType: DataTypeEnum.Text,
          showLabelInViewMode: false,
        },
      },
    },
    {
      name: 'Report Format',
      key: 'reportFormat',
      dataType: DataTypeEnum.Badge,
      required: false,
      showInTable: false,
      description: 'Report Format of the record',
      useInBulk: true,
      formField: {
        componentType: DropDownInputComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Select Item',
          control: new FormControl(null),
          inputOptions: {
            dropDownInput: {
              optionLabel: 'name',
              optionValue: 'code',
              appendTo: 'body',
              items: getEnumOptions(ReportConfigurationTemplateDto.ReportFormatEnum),
              multi: false
            },
          },
        },
      },
    },
    {
      name: 'Run As',
      key: 'runAs',
      dataType: DataTypeEnum.Badge,
      required: false,
      showInTable: false,
      useInBulk: true,
      formField: {
        componentType: RunAsInputComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Select Item',
          control: new FormControl(null),
        },
      },
    },
    {
      name: 'Export Raw',
      key: 'exportRaw',
      dataType: DataTypeEnum.Badge,
      required: false,
      showInTable: false,
      useInBulk: true,
      formField: {
        componentType: SwitchInputComponent,
        options: {
          label: '',
          name: '',
          control: new FormControl(null),
        },
      },
    },
    {
      name: 'PDF Size',
      key: 'pdfSize',
      dataType: DataTypeEnum.Badge,
      required: false,
      showInTable: false,
      useInBulk: true,
      formField: {
        componentType: DropDownInputComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Select Item',
          control: new FormControl(null),
          dataType: DataTypeEnum.Text,
          showLabelInViewMode: false,
          inputOptions: {
            dropDownInput: {
              optionLabel: 'label',
              optionValue: 'value',
              appendTo: 'body',
              items: getEnumOptions(PDFRenderConfig.PdfSizeEnum),
              multi: false
            },
          },
        },
      },
    },
    {
      name: 'Include Table Of Contents',
      key: 'includeTableOfContents',
      dataType: DataTypeEnum.Badge,
      required: false,
      showInTable: false,
      useInBulk: true,
      formField: {
        componentType: SwitchInputComponent,
        options: {
          label: '',
          name: '',
          control: new FormControl(null),
        },
      },
    },
    {
      name: 'Include Report Details',
      key: 'includeReportDetails',
      dataType: DataTypeEnum.Badge,
      required: false,
      showInTable: false,
      useInBulk: true,
      formField: {
        componentType: SwitchInputComponent,
        options: {
          label: '',
          name: '',
          control: new FormControl(null),
        },
      },
    },
    {
      name: 'Include Header',
      key: 'includeHeader',
      dataType: DataTypeEnum.Badge,
      required: false,
      showInTable: false,
      useInBulk: true,
      formField: {
        componentType: SwitchInputComponent,
        options: {
          label: '',
          name: '',
          control: new FormControl(null),
        },
      },
    },
    {
      name: 'Include Footer',
      key: 'includeFooter',
      dataType: DataTypeEnum.Badge,
      required: false,
      showInTable: false,
      useInBulk: true,
      formField: {
        componentType: SwitchInputComponent,
        options: {
          label: '',
          name: '',
          control: new FormControl(null),
        },
      },
    },
    {
      name: 'Include Cover Page',
      key: 'includeCoverPage',
      dataType: DataTypeEnum.Badge,
      required: false,
      showInTable: false,
      useInBulk: true,
      formField: {
        componentType: SwitchInputComponent,
        options: {
          label: '',
          name: '',
          control: new FormControl(null),
        },
      },
    },
  ];
  constructor() {
    super();
    this.setAllFields();
  }
}
